import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  InputAdornment,
  Typography,
  Button,
  CircularProgress,
  Tooltip,
  Checkbox,
  Alert,
} from '@mui/material'
import renderIf from 'render-if'
import { LoadingButton } from '@mui/lab'
import { useAtom } from 'jotai'
import { useSnackbar } from 'notistack'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import InfoIcon from '@mui/icons-material/Info'
import CancelIcon from '@mui/icons-material/Cancel'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import { DriverScheduleDialogAtom, actionCodeAtom } from 'constants/atoms'
import { getWorkSchedules, updateWorkSchedules } from 'services/driverApi'
import { getPersonnel } from 'components/login/loginActions'
import { getDatesForCurrentWeek, formatDateObj } from 'utils/date'
import { driverScheduleNote } from 'utils/labels'
import { useStyles } from 'utils/styles'
import './styles.scss'
import { pathOr } from 'ramda'

const transformToApiFormat = (personnelsSchedule) => {
  const requestPayload = {}
  Object.keys(personnelsSchedule)
    .filter((key) => personnelsSchedule[key].status === 'A')
    .map((key) => {
      const { scheduleInfo } = personnelsSchedule[key]
      const scheduledDays = scheduleInfo
        .filter(({ availability }) => availability !== '')
        .map(({ date, availability }) => ({
          schedule_date: date,
          available: availability,
          reason_code: '',
        }))
      if (scheduledDays.length) requestPayload[key] = scheduledDays
    })
  return requestPayload
}

const DriverScheduleDialog = () => {
  const [isDriverScheduleDialogOpen, setDriverScheduleDialogOpen] = useAtom(
    DriverScheduleDialogAtom,
  )
  const [, setActionCode] = useAtom(actionCodeAtom)
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(true)
  const [personnelsSchedule, setPersonnelsSchedule] = useState({})
  const [editMode, setEditMode] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [searchScheduleList, setSearchScheduleList] = useState({})
  const [currentWeek, setCurrentWeek] = useState(0)
  const [existingSchedule, setExistingSchedule] = useState([])
  const [vendorPersonnels, setVendorPersonnels] = useState([])
  const todayDate = formatDateObj(new Date(), 'yyyy-MM-dd')

  useEffect(() => {
    const fetchData = async () => {
      if (isDriverScheduleDialogOpen) {
        const newPersonnelData = await getPersonnel()
        const newWorkSchedule = await getWorkSchedules()

        setExistingSchedule(newWorkSchedule)
        setVendorPersonnels(newPersonnelData)

        setLoading(false)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    formatScheduleData(existingSchedule)
  }, [vendorPersonnels, existingSchedule])

  useEffect(() => {
    if (Object.keys(personnelsSchedule).length || searchText) {
      const filteredResults = handleSearch(searchText, personnelsSchedule)
      setSearchScheduleList({ ...filteredResults })
    }
  }, [personnelsSchedule, searchText])

  const handleSave = async () => {
    setLoading(true)
    const transformedData = transformToApiFormat(personnelsSchedule)
    const response = await updateWorkSchedules(transformedData)

    if (response.status === 'success') {
      const data = pathOr({}, ['data'], response)
      setExistingSchedule(data)
      enqueueSnackbar(driverScheduleNote.scheduleUpdateSuccess, {
        variant: 'success',
      })
    } else {
      enqueueSnackbar(driverScheduleNote.scheduleUpdateFailure, {
        variant: 'error',
      })
    }
    setEditMode(false)
    setLoading(false)
  }

  const toggleEdit = () => {
    formatScheduleData(existingSchedule)
    setEditMode(!editMode)
  }

  const handleClose = () => {
    setDriverScheduleDialogOpen(false)
    setActionCode(null)
    setEditMode(false)
  }

  const formatScheduleData = (driversSchedule) => {
    const datesInFullFormat = getDatesForCurrentWeek(new Date(), 'yyyy-MM-dd')
    const scheduleInfo = { ...personnelsSchedule }
    vendorPersonnels.forEach(
      ({
        vendorPersonnelId,
        firstName,
        lastName,
        status,
        dispatchableFlag,
      }) => {
        scheduleInfo[vendorPersonnelId] = {
          personnelName: `${firstName} ${lastName}`,
          vendorPersonnelId,
          status,
          dispatchableFlag,
          scheduleInfo: datesInFullFormat.map((date) => ({
            date,
            availability: '',
          })),
        }
        const exists = vendorPersonnelId in driversSchedule
        if (exists) {
          driversSchedule[vendorPersonnelId].forEach(
            ({ start_date: date, available_status_code: availability }) => {
              const findSchedule = scheduleInfo[
                vendorPersonnelId
              ].scheduleInfo.find(
                ({ date: scheduleDate }) => date.split('T')[0] === scheduleDate,
              )
              if (findSchedule) findSchedule.availability = availability
            },
          )
        }
      },
    )
    setPersonnelsSchedule({ ...scheduleInfo })
  }

  const handleSearch = (searchString, completeList) => {
    if (searchString === '') return completeList
    else {
      let filteredList = {}
      Object.keys(completeList).forEach((key) => {
        const { personnelName = '' } = completeList[key]
        if (
          personnelName.toLowerCase().search(searchString.toLowerCase()) !== -1
        ) {
          filteredList[key] = completeList[key]
        }
      })

      return filteredList
    }
  }

  const handleSchedule = (checked, selectedDate, personnelId) => {
    if (editMode && selectedDate >= todayDate) {
      const scheduleDetails = { ...personnelsSchedule }
      const personnelSchedule = Object.values(scheduleDetails).find(
        ({ vendorPersonnelId }) => personnelId === vendorPersonnelId,
      )

      if (personnelSchedule) {
        const { scheduleInfo } = personnelSchedule
        const findDay = scheduleInfo.find(({ date }) => date === selectedDate)
        findDay.availability = checked ? 'Yes' : 'No'
      }

      setPersonnelsSchedule({ ...scheduleDetails })
    }
  }

  const handleEntireWeekSelection = (checked, personnelId) => {
    const scheduleDetails = { ...personnelsSchedule }
    const personnelSchedule = Object.values(scheduleDetails).find(
      ({ vendorPersonnelId }) => personnelId === vendorPersonnelId,
    )

    if (personnelSchedule) {
      const { scheduleInfo } = personnelSchedule
      for (let i = 0 + currentWeek * 5; i < 5 + currentWeek * 5; i++) {
        if (scheduleInfo[i].date >= todayDate) {
          scheduleInfo[i].availability = checked ? 'Yes' : 'No'
        }
      }
    }

    setPersonnelsSchedule({ ...scheduleDetails })
  }

  const checkIfEntireWeekSelected = (personnelId) => {
    const scheduleDetails = { ...personnelsSchedule }
    const personnelSchedule = Object.values(scheduleDetails).find(
      ({ vendorPersonnelId }) => personnelId === vendorPersonnelId,
    )

    if (personnelSchedule) {
      const { scheduleInfo } = personnelSchedule
      const availableDays = scheduleInfo.filter(
        ({ date }, index) =>
          date >= todayDate &&
          index >= currentWeek * 5 &&
          index < 5 + currentWeek * 5,
      )
      const scheduledDays = availableDays.filter(
        ({ availability }) => availability === 'Yes',
      )

      return availableDays.length === scheduledDays.length
    }
  }

  const handleSchduleForAllDrivers = (checked) => {
    const scheduleDetails = { ...personnelsSchedule }
    Object.values(scheduleDetails).forEach((personnel) => {
      const { scheduleInfo } = personnel

      for (let i = 0 + currentWeek * 5; i < 5 + currentWeek * 5; i++) {
        if (scheduleInfo[i].date >= todayDate) {
          scheduleInfo[i].availability = checked ? 'Yes' : 'No'
        }
      }
    })

    setPersonnelsSchedule({ ...scheduleDetails })
  }

  const checkIfAllDriversScheduled = () => {
    const scheduleDetails = { ...personnelsSchedule }

    return Object.values(scheduleDetails)
      .map((personnel) => {
        const { scheduleInfo } = personnel
        const availableDays = scheduleInfo.filter(
          ({ date }, index) =>
            date >= todayDate &&
            index >= currentWeek * 5 &&
            index < 5 + currentWeek * 5,
        )
        const scheduledDays = availableDays.filter(
          ({ availability }) => availability === 'Yes',
        )

        return availableDays.length === scheduledDays.length
      })
      .every((value) => value === true)
  }

  const getTileClassName = (availability, date) => {
    let classNames = ''
    if (editMode) {
      classNames += 'pointer '
      if (date < todayDate) classNames += 'disabled-cell '
      if (availability === 'Yes') classNames += 'green-cell '
      else if (availability === 'No') classNames += 'red-cell'
    }
    return classNames
  }

  const classes = useStyles()

  const DATES = getDatesForCurrentWeek(new Date(), 'MM/dd', true)

  return (
    <Dialog
      open={isDriverScheduleDialogOpen}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        style: {
          minWidth: '950px',
        },
      }}
    >
      <div className="scheduleContainer">
        <DialogTitle>
          <div style={{ color: 'white' }}>
            <Typography
              classes={{
                root: classes.root,
              }}
            >
              Driver Schedule
            </Typography>
          </div>
          <IconButton
            onClick={handleClose}
            style={{ position: 'absolute', right: '8px', top: '8px' }}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className="rowStack">
            <TextField
              variant="outlined"
              size="small"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={'Search by Driver Name'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ width: '40%' }}
            />
            <div style={{ marginLeft: 'auto' }}>
              {renderIf(!editMode)(
                <div className="rowStack" style={{ marginLeft: 'auto' }}>
                  <div className="rowStack">
                    <CheckCircleIcon color="success" />
                    <Typography> Available</Typography>
                  </div>
                  <div className="rowStack">
                    <CancelIcon sx={{ color: 'red' }} />
                    <Typography> Unavailable</Typography>
                  </div>
                  <div className="rowStack">
                    <DoNotDisturbIcon />
                    <Typography> No Schedule</Typography>
                  </div>
                </div>,
              )}
              {renderIf(editMode)(
                <div className="allAvailabilityCheck">
                  <div>
                    <Checkbox
                      onChange={(e) =>
                        handleSchduleForAllDrivers(e.target.checked)
                      }
                      checked={checkIfAllDriversScheduled()}
                    />
                  </div>
                  <div>Mark as all available</div>
                  <Tooltip title="It will establish the weekly schedule as available for all the drivers">
                    <InfoIcon />
                  </Tooltip>
                </div>,
              )}
            </div>
            <div className="rowStack">
              <Tooltip title="Previous week">
                <span>
                  <IconButton
                    onClick={() => {
                      if (currentWeek === 1) setCurrentWeek(currentWeek - 1)
                    }}
                    disabled={!(currentWeek === 1)}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Next week">
                <span>
                  <IconButton
                    onClick={() => {
                      if (currentWeek === 0) setCurrentWeek(currentWeek + 1)
                    }}
                    disabled={!(currentWeek === 0)}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </div>
          {renderIf(editMode)(
            <Alert severity="info" style={{ margin: '10px 0px' }}>
              Please select the checkbox to set the driver as available for the
              entire week
              <br />
              Clicking the cell would set the driver as available, and clicking
              again would set the driver as unavailable
            </Alert>,
          )}
          <div
            className="scheduleInfoWrap"
            style={{ height: editMode ? '78%' : '89%' }}
          >
            <table>
              <thead>
                <tr>
                  <th></th>
                  {DATES.slice(0 + currentWeek * 5, 5 + currentWeek * 5).map(
                    (date, index) => (
                      <th key={`schedule-date-${index}`}>
                        <div className="dateStyle">{date}</div>
                      </th>
                    ),
                  )}
                </tr>
              </thead>

              {loading ? (
                <tbody>
                  <tr>
                    <td colSpan="8" className="loading">
                      <div className="loaderPosition">
                        <CircularProgress />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {Object.values(searchScheduleList)
                    .filter(
                      (personnel) =>
                        personnel.status === 'A' &&
                        personnel.dispatchableFlag === true,
                    )
                    .map(
                      (
                        {
                          personnelName = '',
                          scheduleInfo = [],
                          vendorPersonnelId = '',
                        },
                        index,
                      ) => (
                        <tr key={`schedule-info-${index}`}>
                          <td>
                            <div
                              className="driverNameHeader"
                              style={
                                !editMode ? { justifyContent: 'center' } : {}
                              }
                            >
                              {renderIf(editMode)(
                                <div style={{ lineHeight: '1px' }}>
                                  <Checkbox
                                    onChange={(e) =>
                                      handleEntireWeekSelection(
                                        e.target.checked,
                                        vendorPersonnelId,
                                      )
                                    }
                                    checked={checkIfEntireWeekSelected(
                                      vendorPersonnelId,
                                    )}
                                  />
                                </div>,
                              )}
                              <div className="driverName">{personnelName}</div>
                            </div>
                          </td>
                          {scheduleInfo
                            .slice(0 + currentWeek * 5, 5 + currentWeek * 5)
                            .map(({ date, availability }, index) => (
                              <td
                                key={`schedule-check-${index}`}
                                className={getTileClassName(availability, date)}
                                onClick={() =>
                                  handleSchedule(
                                    availability === '' ||
                                      availability === 'No',
                                    date,
                                    vendorPersonnelId,
                                  )
                                }
                              >
                                <div>
                                  {renderIf(!editMode)(
                                    <div>
                                      {['Yes', 'No'].includes(availability) ? (
                                        availability === 'Yes' ? (
                                          <CheckCircleIcon color="success" />
                                        ) : (
                                          <CancelIcon sx={{ color: 'red' }} />
                                        )
                                      ) : (
                                        <div className="notAny">
                                          <DoNotDisturbIcon />
                                        </div>
                                      )}
                                    </div>,
                                  )}
                                </div>
                              </td>
                            ))}
                        </tr>
                      ),
                    )}
                </tbody>
              )}
            </table>
          </div>
        </DialogContent>
        <DialogActions>
          {editMode ? (
            <>
              <Button variant="outlined" onClick={toggleEdit}>
                Cancel
              </Button>
              <LoadingButton
                loading={loading}
                variant="contained"
                onClick={() => {
                  handleSave()
                }}
                sx={{ backgroundColor: 'green' }}
              >
                Save
              </LoadingButton>
            </>
          ) : (
            <Button variant="contained" onClick={toggleEdit}>
              Edit
            </Button>
          )}
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default DriverScheduleDialog
