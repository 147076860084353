export const getEndpoints = (params = {}) => {
  const {
    vendorId = '',
    assignmentDetailId = '',
    lotNumber = '',
    truckId = '',
    tripId = '',
    messageId = '',
    country = '',
    vendorPersonnelId = '',
    catSubhaulerId = '',
  } = params
  return {
    // View Api
    lotsView: '/services/ycsdispatchus/transporter/assignments',
    tripsView: '/services/ycsdispatchus/transporter/trips',
    driversView: '/services/ycsdispatchus/transporter/drivers',
    workRequestsView: '/services/ycsdispatchus/transporter/work_requests',
    catTowProvidersView: '/services/ycsdispatchus/transporter/drivers',
    truckManagementView: `services/ycstsmus/tsm/${vendorId}/trucks`,

    // Login Api
    login: '/services/ycsdispatchus/transporter/login',
    userDetails: '/services/ycsdispatchus/transporter/current_user',
    externalUserSettings: '/services/ycstsmus/personnel/external_user_settings',
    sendOTP: '/services/ycstsmus/personnel/external_user/otp',
    verifyOTP: '/services/ycstsmus/personnel/external_user/otp/validate',
    changePassword: '/services/ycsdispatchus/transporter/password',
    refreshToken: '/services/ycsdispatchus/transporter/client/token',
    personnels: `/services/transportersetup-ws/v1/vendors/${vendorId}/personnel`,

    //Delete Account
    deleteAccount: `/services/ycsbuyerpickupus/buyer_pickup/transporter/${vendorId}/account`,

    // Reference Data
    referenceData: '/services/ycstsmus/tsm/reference_data?data_type=all',

    //Quick Counts
    quickCounts: '/services/ycsdispatchus/transporter/quick_counts',
    issueQuickCounts: '/services/ycsdispatchus/transporter/issues_quick_counts',

    //Work Requests
    createRequest: '/services/ycsdispatchus/transporter/work_requests',
    deleteRequest: '/services/ycsdispatchus/transporter/work_requests',

    //View Lot
    getLotInfo: `/services/ycsdispatchus/transporter/assignments/${assignmentDetailId}`,
    getNotes: `/services/notes-ws/lots/${lotNumber}/notes`,
    getCharges: `/services/ycsdispatchus/transporter/assignments/${assignmentDetailId}/charges`,

    //Truck Management
    addTruck: `/services/ycstsmus/tsm/${vendorId}/trucks`,
    updateTruck: `/services/ycstsmus/tsm/${vendorId}/trucks/${truckId}`,
    upload: '/services/idsupload-ws/v4/upload',
    removeTruckAssociation: `/services/ycstsmus/tsm/${vendorId}/trucks/${truckId}`,

    //Trips
    overrideDeclineTrip: `/ycsdispatchus/transporter/trips/${tripId}/override_rejection`,
    reclaimTrip: '/services/ycsdispatchus/transporter/trips/reclaim',
    transitionTrip: '/services/ycsdispatchus/transporter/trips/transition',
    cancelDeclineTrip: `/services/ycsdispatchus/transporter/trips/${tripId}/override_rejection`,
    declineReason: `/services/ycsdispatchus/transporter/decline_reasons`,
    updateTransitions: `/services/ycsdispatchus/transporter/trips/transition`,

    //Lots
    available_issues: `/services/ycsdispatchus/transporter/assignments/${assignmentDetailId}/available_issues`,
    submitIssue: `/services/ycsdispatchus/transporter/assignments/${assignmentDetailId}/issues`,
    distributeTrip: `/services/ycsdispatchus/transporter/vendors/${vendorId}/groups`,
    submitDistributeTripAssignments:
      '/services/ycsdispatchus/transporter/assignments/distribute',
    submitDistributeTrips:
      '/services/ycsdispatchus/transporter/trips/distribute',
    getIssueDetail: `/services/ycsdispatchus/transporter/assignments/${assignmentDetailId}/issues`,
    resolveIssue: `/services/ycsdispatchus/transporter/assignments/${assignmentDetailId}/issues/resolve`,
    escalate: `/services/ycsdispatchus/transporter/assignments/${assignmentDetailId}/issues/escalate`,
    markAsRead: `/services/ycsdispatchus/transporter/assignments/${assignmentDetailId}/issues/${messageId}/read`,
    sendEmail: `/services/ycsdispatchus/transporter/feedback`,
    workingDays: '/services/ycsdispatchus/dispatch/two_working_days',
    states: `/services/g2geoservices-ws/v2/countries/${country}/states`,

    //Drivers
    addPersonnel: `/services/ycsdispatchus/transporter/vendors/${vendorId}/personnel`,
    updatePersonnel: `/services/ycsdispatchus/transporter/vendors/${vendorId}/personnel/${vendorPersonnelId}`,
    workSchedules: `/services/ycstsmus/tsm/${vendorId}/work_schedules`,
    subhauler: `/services/ycstsmus/subhauler_deployment/subhauler/${vendorId}`,
    subhaulers: `/services/ycstsmus/subhauler_deployment/subhaulers/${catSubhaulerId}`,
    catList: '/services/ycstsmus/subhauler_deployment/cat_list',
    manageDriver: `/services/ycstsmus/subhauler_deployment/subhaulers/${catSubhaulerId}/manage_drivers`,
    association: `/services/ycstsmus/subhauler_deployment/subhaulers/${catSubhaulerId}/drivers/association`,
    deletePcard: `/services/ycsdispatchus/transporter/personnel/${vendorPersonnelId}/pcard`,
    requestPcard: `/services/ycsdispatchus/transporter/personnel/${vendorPersonnelId}/pcard`,
    updateDriverSchedule: `/services/ycstsmus/tsm/drivers/${vendorPersonnelId}/update_driver_schedule`,
  }
}
