import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  TextField,
  MenuItem,
  Button,
  Typography,
} from '@mui/material'
import { useAtom } from 'jotai'
import { useSnackbar } from 'notistack'
import { isEmpty } from 'ramda'
import CloseIcon from '@mui/icons-material/Close'
import { globalPostAtom, actionCodeAtom } from 'constants/atoms'
import {
  addPersonnel,
  updatePersonnel,
  updateDriverSchedule,
} from 'services/driverApi'
import { EMAIL_REGEX, isValidUSPhoneNumber, isNumeric } from 'utils/regex'
import EmailInput from 'components/customInputFields/emailInput'
import { useStyles } from 'utils/styles'
import { ACTION_CODES } from 'constants/actionCodes'

const transformData = (data) => {
  return {
    firstName: data.first_name || '',
    middleName: data.middle_name || '',
    lastName: data.last_name || '',
    phoneNum: data.phone_number || '',
    email: data.email || '',
    startDate: data.start_date ? data.start_date.split('T')[0] : '',
    dispatchableFlag: data.dispatch_flag ? true : false,
    status: data.status || '',
  }
}

const defaultData = {
  firstName: '',
  middleName: '',
  lastName: '',
  phoneNum: '',
  email: '',
  startDate: '',
  dispatchableFlag: true,
  status: '',
}

const PersonnelDialog = ({ rowData = [], onClose }) => {
  const [, setGlobalLoading] = useAtom(globalPostAtom)
  const [actionCode, setActionCode] = useAtom(actionCodeAtom)
  const [buttonLoading, setButtonLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const vendorPersonnelId = rowData?.vendor_personnel_id

  const isValidEmail = (email) => EMAIL_REGEX.test(email)

  const areRequiredFieldsFilled = () => {
    const requiredFields = ['firstName', 'lastName', 'phoneNum', 'startDate']

    return (
      requiredFields.every((field) => Boolean(formData[field])) &&
      isValidEmail(formData.email) &&
      isValidUSPhoneNumber(formData.phoneNum)
    )
  }

  const [formData, setFormData] = useState(
    !isEmpty(rowData) ? transformData(rowData) : defaultData,
  )
  const [initialData, setInitialData] = useState({})
  const [editMode, setEditMode] = useState(false)
  const handleInputChange = (event) => {
    const { name, value } = event.target
    if (name === 'phoneNum' && !isNumeric(value)) {
      return
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleEmailInputChange = (value) => {
    setFormData((prevData) => ({ ...prevData, email: value }))
  }

  const handleSubmit = async () => {
    setButtonLoading(true)
    if (editMode) {
      const payload = {
        dispatchableFlag: formData.dispatchableFlag,
        lastUpdateUser: formData.email,
        status: formData.status,
      }
      const response = await updatePersonnel(payload, vendorPersonnelId)
      if (response.status === 200) {
        if (
          initialData.dispatchableFlag === false &&
          formData.dispatchableFlag === true
        ) {
          await updateDriverSchedule(vendorPersonnelId)
        }
        handleClose()
        setButtonLoading(false)
        enqueueSnackbar('Updated driver successfully', { variant: 'success' })
      } else {
        enqueueSnackbar('Unable to update the driver', { variant: 'error' })
        setButtonLoading(false)
      }
    } else {
      const response = await addPersonnel({ ...formData, status: 'A' })
      if (response.status === 200) {
        handleClose()
        setButtonLoading(false)
        enqueueSnackbar('Added driver successfully', { variant: 'success' })
      } else {
        enqueueSnackbar('Unable to add a driver', { variant: 'error' })
        setButtonLoading(false)
      }
    }
  }

  const handleClose = () => {
    setFormData(defaultData)
    setGlobalLoading(true)
    setActionCode('')
    onClose()
  }

  useEffect(() => {
    if (!isEmpty(rowData)) {
      setInitialData(transformData(rowData))
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(rowData)) {
      setEditMode(true)
      setFormData(transformData(rowData))
    } else {
      setEditMode(false)
    }
  }, [rowData])

  const classes = useStyles()
  return (
    <Dialog open={actionCode === ACTION_CODES.ADD_DRIVER} onClose={handleClose}>
      <DialogTitle>
        <div style={{ color: 'white' }}>
          <Typography
            classes={{
              root: classes.root,
            }}
          >
            {editMode ? 'Edit Personnel' : 'Create Personnel'}
          </Typography>
        </div>
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: '8px', top: '8px' }}
        >
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="First Name"
              variant={editMode ? 'filled' : 'outlined'}
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              InputProps={{
                readOnly: editMode,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Middle Name"
              variant={editMode ? 'filled' : 'outlined'}
              name="middleName"
              value={formData.middleName}
              onChange={handleInputChange}
              InputProps={{
                readOnly: editMode,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="Last Name"
              variant={editMode ? 'filled' : 'outlined'}
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              InputProps={{
                readOnly: editMode,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="Contact Number"
              variant={editMode ? 'filled' : 'outlined'}
              name="phoneNum"
              value={formData.phoneNum}
              onChange={handleInputChange}
              inputProps={{
                readOnly: editMode,
                maxLength: 10,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <EmailInput
              fullWidth
              required={true}
              label="Email Address"
              variant={editMode ? 'filled' : 'outlined'}
              value={formData.email}
              handleChange={({ target }) =>
                handleEmailInputChange(target.value)
              }
              showAdornment={false}
              autoFocus={false}
              readOnly={editMode}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="Date"
              type="date"
              variant={editMode ? 'filled' : 'outlined'}
              name="startDate"
              value={formData.startDate}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: editMode,
              }}
            />
          </Grid>
          {editMode && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Status"
                variant="outlined"
                select
                name="status"
                value={formData.status}
                onChange={handleInputChange}
              >
                <MenuItem value={'A'}>A - Active</MenuItem>
                <MenuItem value={'I'}>I - Inactive</MenuItem>
              </TextField>
            </Grid>
          )}
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Dispatchable"
              variant="outlined"
              select
              name="dispatchableFlag"
              value={formData.dispatchableFlag}
              onChange={handleInputChange}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {editMode && (
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!areRequiredFieldsFilled() || buttonLoading}
        >
          {editMode ? 'Update' : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PersonnelDialog
