export const ACTION_CODES = {
  ADD_DRIVER: 'ADD_DRIVER',
  CREATE_REQUEST: 'CREATE_REQUEST',
  ADD_TRUCK: 'ADD_TRUCK',
  DELETE_REQUEST: 'DELETE_REQUEST',
  MANAGE_DRIVER: 'MANAGE_DRIVER',
  DRIVER_SCHEDULE: 'DRIVER_SCHEDULE',
  DELETE_PCARD: 'DELETE_PCARD',
  // ... add more codes as needed

  // LOTS
  CREATE_ISSUE: 'CREATE_ISSUE',
  DISTRIBUTE_TRIP: 'DISTRIBUTE_TRIP',
  DOWNLOAD: 'DOWNLOAD',
  CAN_PICKUP: 'CAN_PICKUP',
  RESOLVE_ISSUE: 'RESOLVE_ISSUE',
  MARK_ISSUE_AS_READ: 'MARK_ISSUE_AS_READ',
  VIEW_LOT: 'VIEW_LOT',
  SEND_EMAIL: 'SEND_EMAIL',
  OVERRIDE_ISSUE: 'OVERRIDE_ISSUE',
  CANT_CLEAR: 'CANT_CLEAR',

  // TRIPS
  ACKNOWLEDGE_TRIP: 'ACKNOWLEDGE_TRIP',
  OVERRIDE_DECLINE: 'OVERRIDE_DECLINE',
  REMOVE_DRIVER: 'REMOVE_DRIVER',
  TAKE_BACK_AND_ACCEPT: 'TAKE_BACK_AND_ACCEPT',
}
