import axios from 'services/customAxios'
import { pathOr } from 'ramda'
import { getItem } from 'utils/localstorage'
import { getEndpoints } from 'constants/endpoints'

export const addPersonnel = async (payload) => {
  try {
    const { vendor_id: vendorId, email } = getItem('userData')
    const endpoint = getEndpoints({ vendorId }).addPersonnel

    const updatedPayload = {
      ...payload,
      createUser: email,
      pcardFlg: 'N',
      roleId: '3',
      vendorId,
    }

    const response = await axios.post(endpoint, updatedPayload)

    if (response.status === 200) {
      const { vendor_personnel_id: personnelId } = response.data
      updateDriverSchedule(personnelId)
      return response
    }
  } catch (error) {
    console.error('Error while adding personnel', error)
  }
}

export const updatePersonnel = async (payload, vendorPersonnelId) => {
  try {
    const { vendor_id: vendorId } = getItem('userData')
    const endpoint = getEndpoints({
      vendorId,
      vendorPersonnelId,
    }).updatePersonnel

    const response = await axios.patch(endpoint, payload)

    if (response.status === 200) {
      return response
    }
  } catch (error) {
    console.error('Error while adding personnel:', error)
  }
}

export const getWorkSchedules = async () => {
  try {
    const { vendor_id: vendorId } = getItem('userData')
    const url = getEndpoints({ vendorId }).workSchedules
    const response = await axios.get(url)
    if (response.status === 200) {
      return response.data.data
    }
  } catch (error) {
    console.error(error)
  }
}

export const updateWorkSchedules = async (payload) => {
  try {
    const { vendor_id: vendorId } = getItem('userData')
    const url = getEndpoints({ vendorId }).workSchedules
    const updatedPayload = { work_schedules: payload }
    const response = await axios.post(url, updatedPayload)
    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    console.error(error)
  }
}

export const getSubhaulers = async () => {
  try {
    const userData = getItem('userData')
    const vendorId = pathOr('', ['vendor', 'vendor_id'], userData)
    const url = getEndpoints({ vendorId }).subhauler
    const response = await axios.get(url)
    if (response.status === 200) {
      return response.data.subhaulers
    }
  } catch (error) {
    console.error(error)
  }
}

export const getSubhaulerDriversInfo = async (catSubhaulerId) => {
  try {
    const url = getEndpoints({ catSubhaulerId }).subhaulers
    const response = await axios.get(url)
    if (response.status === 200) {
      return response
    }
  } catch (error) {
    console.error(error)
  }
}

export const getDestinationFacilities = (catId) => {
  const payload = {
    filter_by: {
      cat_id: catId,
      is_active: true,
    },
    page: 1,
    page_size: 500,
    sort_by: [
      {
        cat_id: 'desc',
      },
      {
        cat_name: 'desc',
      },
    ],
  }
  const url = getEndpoints().catList
  return axios
    .post(url, payload)
    .then((response) => {
      const list = pathOr([], ['data', 'data', '0', 'facilities'], response)
      return list.map(({ facility_id: code, facility_name: description }) => ({
        code,
        description,
      }))
    })
    .catch((error) => {
      console.error(error)
    })
}

const reverseMapDriverAssociation = (
  { driversAssocToVendor = [] },
  vendorEmail = '',
  manageDriver,
) => ({
  vendor_personnels: driversAssocToVendor.map(
    ({
      vendorPersonnelId,
      isDeploymentRequired,
      estimatedArrivalDate,
      subhaulerDriverId = '',
      truckType = '',
      deleteVP = false,
      destinationFacilityId = '',
    }) => ({
      ...(manageDriver && subhaulerDriverId
        ? { subhauler_driver_id: subhaulerDriverId }
        : { vendor_per_id: vendorPersonnelId }),
      cat_deployment_flag: isDeploymentRequired === 'Y',
      estimated_time_of_arrival_date: estimatedArrivalDate,
      truck_type_code: truckType,
      destination_facility_id: destinationFacilityId,
      vendor_email_id: vendorEmail,
      ...(deleteVP ? { delete: true } : {}),
    }),
  ),
})

export const manageDrivers = (payload, manageDriver = false) => {
  try {
    const { email } = getItem('userData')
    const { catSubhaulerId } = payload
    const URL = !manageDriver
      ? getEndpoints({ catSubhaulerId }).association
      : getEndpoints({ catSubhaulerId }).manageDriver
    return axios.post(
      URL,
      reverseMapDriverAssociation(payload, email, manageDriver),
    )
  } catch (error) {
    console.error(error)
  }
}

export const requestPcard = async (vendorPersonnelId, payload) => {
  try {
    const endpoint = getEndpoints({ vendorPersonnelId }).requestPcard
    const params = new URLSearchParams(payload).toString()

    const urlWithParams = `${endpoint}?${params}`

    const response = await axios.post(urlWithParams)

    return {
      success: true,
      data: response.data,
      status: response.status,
    }
  } catch (error) {
    console.error('Error while requesting Pcard', error)

    return {
      success: false,
      error: error.response ? error.response.data : 'Failed to request Pcard',
      status: error.response ? error.response.status : 500,
    }
  }
}

export const deletePcard = async (vendorPersonnelId) => {
  try {
    const endpoint = getEndpoints({ vendorPersonnelId }).deletePcard
    const response = await axios.delete(endpoint)

    return {
      success: true,
      data: response.data,
      status: response.status,
    }
  } catch (error) {
    console.error('Error while deleting Pcard', error)

    return {
      success: false,
      error: error.response ? error.response.data : 'Failed to delete Pcard',
      status: error.response ? error.response.status : 500,
    }
  }
}

export const updateDriverSchedule = async (vendorPersonnelId) => {
  try {
    const endpoint = getEndpoints({ vendorPersonnelId }).updateDriverSchedule
    const response = await axios.post(endpoint)

    if (response.status === 200) {
      return response
    }
  } catch (error) {
    console.error('Error while updating driver schedule', error)
  }
}
