import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  TextField,
  MenuItem,
  Button,
} from '@mui/material'
import { useAtom } from 'jotai'
import CloseIcon from '@mui/icons-material/Close'
import { useSnackbar } from 'notistack'
import {
  catAssocListAtom,
  globalPostAtom,
  actionCodeAtom,
} from 'constants/atoms'
import { pathOr, propOr } from 'ramda'
import { getDestinationFacilities, manageDrivers } from 'services/driverApi'
import EmailInput from '../../customInputFields/emailInput'
import { truckTypeMap } from 'constants/columns'
import ConfirmationDialog from '../../customInputFields/dialog'
import { EMAIL_REGEX } from 'utils/regex'
import { getDateTimeFromDateFns } from 'utils/date'
import { CATDriversLabels } from 'utils/labels'
import { getSelectedDepEvent } from 'utils/driverUtils'
import { ACTION_CODES } from 'constants/actionCodes'

const transformData = (data) => {
  return {
    fullName: data.first_name + ' ' + data.last_name || '',
    phoneNum: data.phone_number || '',
    email: data.email || '',
    estimatedArrivalDate: data.estimatedArrivalDate
      ? data.estimatedArrivalDate
      : null,
    dispatchableFlag: data.dispatch_flag === 'Yes' ? true : false,
    deploymentEvent: getSelectedDepEvent(data),
    truckType: data.truckType || '',
    isDeploymentRequired: data.isDeploymentRequired || false,
    destinationFacilityId: data.destinationFacilityId || '',
  }
}

const defaultData = {
  fullName: '',
  phoneNum: '',
  email: '',
  estimatedArrivalDate: '',
  isDeploymentRequired: '',
  deploymentEvent: '',
  truckType: '',
  destinationFacilityId: '',
}

const ManageDriverDialog = ({ rowData = null, onClose }) => {
  const [actionCode, setActionCode] = useAtom(actionCodeAtom)
  const [catAssocList] = useAtom(catAssocListAtom)
  const [formData, setFormData] = useState(
    rowData && rowData.length ? transformData(rowData[0]) : defaultData,
  )
  const [destFacilities, setDestFacilities] = useState([])
  const [assoicateDriver, setAssociateDriver] = useState(false)
  const [openConfirmationDialog, setConfirmationDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [, setGlobalLoading] = useAtom(globalPostAtom)
  const { enqueueSnackbar } = useSnackbar()
  const deploymentId = pathOr('', ['deploymentEvent'], formData)
  const deploymentType = propOr(
    '',
    'deployment_type',
    catAssocList.find(({ code }) => code == deploymentId),
  )
  const isValidEmail = (email) => EMAIL_REGEX.test(email)

  const areRequiredFieldsFilled = () => {
    const requiredFields = ['truckType']
    if (deploymentType === 'CAT') {
      requiredFields.push('destinationFacilityId')
    }

    if (formData.isDeploymentRequired === 'Y')
      requiredFields.push('estimatedArrivalDate')

    return (
      requiredFields.every((field) => Boolean(formData[field])) &&
      isValidEmail(formData.email)
    )
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleEmailInputChange = (value) => {
    setFormData((prevData) => ({ ...prevData, email: value }))
  }

  const getRequestPayload = (
    associateDriver = false,
    deleteAssociation = false,
  ) => {
    let payload = {}
    const deploymentInfo = { ...formData }
    const vendorPersonnelId = pathOr('', ['0', 'vendor_personnel_id'], rowData)
    payload = {
      vendorPersonnelId: vendorPersonnelId,
      isDeploymentRequired: deploymentInfo.isDeploymentRequired,
      estimatedArrivalDate: getDateTimeFromDateFns(
        deploymentInfo.estimatedArrivalDate,
      ),
      truckType: deploymentInfo.truckType,
      destinationFacilityId: deploymentInfo.destinationFacilityId,
    }
    if (!associateDriver) {
      const subhaulerDriverId = pathOr('', ['0', 'subhaulerDriverId'], rowData)
      payload.subhaulerDriverId = subhaulerDriverId
    }

    if (deleteAssociation) {
      payload.deleteVP = true
    }
    return payload
  }

  const manageDriverAssociation = (removeAssociation = false) => {
    setLoading(true)
    const catId = pathOr('', ['deploymentEvent'], formData).toString()
    const deploymentSubhaulerId = propOr(
      '',
      'deploymentSubhaulerId',
      catAssocList.find(({ code }) => code == catId),
    )
    manageDrivers(
      {
        catSubhaulerId: deploymentSubhaulerId,
        driversAssocToVendor: [
          getRequestPayload(assoicateDriver, removeAssociation),
        ],
      },
      !assoicateDriver,
    )
      .then((response) => {
        if (response.status === 200) {
          setGlobalLoading(true)
          handleClose()
          const { associationSuccess, removeAssocSuccess, updateAssocSuccess } =
            CATDriversLabels
          const messageLabel = removeAssociation
            ? removeAssocSuccess
            : assoicateDriver
            ? associationSuccess
            : updateAssocSuccess
          enqueueSnackbar(messageLabel, {
            variant: 'success',
          })
        }
      })
      .catch((err) => {
        const { associationFailure, removeAssocFailure, updateAssocFailure } =
          CATDriversLabels
        const messageLabel = removeAssociation
          ? removeAssocFailure
          : assoicateDriver
          ? associationFailure
          : updateAssocFailure
        enqueueSnackbar(messageLabel, {
          variant: 'error',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleClose = () => {
    setFormData(defaultData)
    setActionCode('')
    onClose()
    setConfirmationDialog(false)
  }

  useEffect(() => {
    setFormData(
      rowData && rowData.length ? transformData(rowData[0]) : defaultData,
    )
    const deploymentTypeCode = pathOr('', ['0', 'deploymentTypeCode'], rowData)
    setAssociateDriver(deploymentTypeCode === '')
  }, [rowData])

  useEffect(() => {
    if (formData.deploymentEvent) {
      if (deploymentType === 'CAT') {
        getDestinationFacilities(formData.deploymentEvent).then((response) => {
          setDestFacilities(response)
          setFormData({
            ...formData,
            destinationFacilityId:
              response.find(
                ({ code }) => code === formData.destinationFacilityId,
              )?.code ?? '',
          })
        })
      } else {
        setFormData({
          ...formData,
          destinationFacilityId: deploymentId,
        })
      }
    }
  }, [formData.deploymentEvent, deploymentId, deploymentType])

  useEffect(() => {
    if (formData.isDeploymentRequired === 'N' && rowData === null) {
      setFormData({
        ...formData,
        estimatedArrivalDate: null,
      })
    }
  }, [formData.isDeploymentRequired])

  return (
    <Dialog
      open={actionCode === ACTION_CODES.MANAGE_DRIVER}
      onClose={handleClose}
    >
      <DialogTitle>
        {assoicateDriver ? 'Associate Driver' : 'Manage Driver'}
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: '8px', top: '8px' }}
        >
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Full Name"
              variant="filled"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <EmailInput
              fullWidth
              label="Email Address"
              variant="outlined"
              value={formData.email}
              handleChange={({ target }) =>
                handleEmailInputChange(target.value)
              }
              showAdornment={false}
              autoFocus={false}
              readOnly={true}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Contact Number"
              variant="filled"
              name="phoneNum"
              value={formData.phoneNum}
              onChange={handleInputChange}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Deployment Event"
              variant={!assoicateDriver ? 'filled' : 'outlined'}
              name="deploymentEvent"
              select
              value={formData.deploymentEvent}
              onChange={handleInputChange}
              InputProps={{
                readOnly: !assoicateDriver,
              }}
            >
              {catAssocList.map(({ code, description }) => (
                <MenuItem key={code} value={code}>
                  {description}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {deploymentType === 'CAT' ? (
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                label="Destination Facility"
                variant="outlined"
                name="destinationFacilityId"
                value={formData.destinationFacilityId}
                onChange={handleInputChange}
                select
              >
                {destFacilities.map(({ code, description }) => (
                  <MenuItem key={code} value={code}>
                    {code} - {description}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ) : null}
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="Truck Type"
              variant="outlined"
              select
              name="truckType"
              value={formData.truckType}
              onChange={handleInputChange}
            >
              {Object.entries(truckTypeMap).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Is Deployment Required?"
              variant="outlined"
              select
              name="isDeploymentRequired"
              value={formData.isDeploymentRequired}
              onChange={handleInputChange}
            >
              <MenuItem value={'Y'}>Yes</MenuItem>
              <MenuItem value={'N'}>No</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Estimated Arrival Date"
              type="datetime-local"
              variant="outlined"
              name="estimatedArrivalDate"
              value={getDateTimeFromDateFns(
                formData.estimatedArrivalDate,
                "yyyy-MM-dd'T'HH:mm:ss",
              )}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              required={formData?.isDeploymentRequired === 'Y'}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={!assoicateDriver ? { justifyContent: 'space-between' } : {}}
      >
        {!assoicateDriver ? (
          <Button
            variant="contained"
            color="error"
            onClick={() => setConfirmationDialog(true)}
            disabled={loading}
          >
            Remove Association
          </Button>
        ) : null}
        <Button
          variant="contained"
          color="primary"
          onClick={() => manageDriverAssociation()}
          disabled={!areRequiredFieldsFilled() || loading}
        >
          Save and Confirm
        </Button>
      </DialogActions>

      <ConfirmationDialog
        open={openConfirmationDialog}
        onClose={() => setConfirmationDialog(false)}
        title="Remove Driver Association"
        content="Are you sure you want to remove the driver?"
        actions={[
          {
            name: 'cancel',
            onClick: () => setConfirmationDialog(false),
            disabled: loading,
            variant: 'contained',
            sx: {
              color: 'black',
              backgroundColor: 'white',
              ':hover': { backgroundColor: 'white', color: 'black' },
            },
          },
          {
            name: 'confirm',
            onClick: () => manageDriverAssociation(true),
            disabled: loading,
            variant: 'contained',
          },
        ]}
      />
    </Dialog>
  )
}

export default ManageDriverDialog
