import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Button,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useAtom } from 'jotai'
import CloseIcon from '@mui/icons-material/Close'
import { globalPostAtom, actionCodeAtom } from 'constants/atoms'
import { deleteRequest } from 'services/workRequestApi'
import { useStyles } from 'utils/styles'
import { ACTION_CODES } from 'constants/actionCodes'

const DeleteWorkRequestDialog = ({ rowData = null }) => {
  const [actionCode, setActionCode] = useAtom(actionCodeAtom)

  const defaultData = {
    comments: '',
  }

  const [formData, setFormData] = useState(defaultData)
  const [, setGlobalLoading] = useAtom(globalPostAtom)
  const { enqueueSnackbar } = useSnackbar()

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleSubmit = async () => {
    const payload = {
      comments: formData.comments,
      message_header_ids: [rowData.message_header_id],
    }

    const response = await deleteRequest(payload)
    if (response.status === 200) {
      setGlobalLoading(true)
      handleClose()
      enqueueSnackbar('Work request cancelled successfully', {
        variant: 'success',
      })
    } else {
      enqueueSnackbar('Unable to cancel work request', {
        variant: 'error',
      })
    }
  }

  const handleClose = () => {
    setFormData(defaultData)
    setActionCode('')
  }

  const classes = useStyles()
  return (
    <Dialog
      open={actionCode === ACTION_CODES.DELETE_REQUEST}
      onClose={handleClose}
    >
      <DialogTitle>
        <div style={{ color: 'white' }}>
          <Typography
            classes={{
              root: classes.root,
            }}
          >
            Cancel Work Request
          </Typography>
        </div>
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: '8px', top: '8px' }}
        >
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ width: '500px' }}>
        <TextField
          fullWidth
          required
          label="Comments"
          variant="outlined"
          name="comments"
          value={formData.comments}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={formData.comments === ''}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteWorkRequestDialog
