import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  TextField,
  MenuItem,
  Divider,
  Tooltip,
  InputAdornment,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useAtom } from 'jotai'
import { head, isEmpty, propOr, pathOr } from 'ramda'
import { CircularProgress } from '@mui/material'
import { useSnackbar } from 'notistack'
import CloseIcon from '@mui/icons-material/Close'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EmailIcon from '@mui/icons-material/Email'
import MultiViewSidebar from 'components/views/dialogs/multiViewSidebar'

import {
  reverseDispatchStatusMapper,
  moveToNextItemInMultiView,
  formAddress,
} from 'utils/dispatchutils'
import {
  globalPostAtom,
  actionCodeAtom,
  completedRowDataAtom,
} from 'constants/atoms'
import { CreateIssueIcon } from 'components/icons/icons'
import { getIssues, submitIssue } from 'services/lotsViewServicesApi'
import { useStyles } from 'utils/styles'
import { ACTION_CODES } from 'constants/actionCodes'
import SendEmailDialog from '../sendEmailDialog'

const defaultData = {
  type: '',
  subtype: '',
  comments: '',
}

const CreateIssueDialog = ({ rowData, onClose }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [actionCode, setActionCode] = useAtom(actionCodeAtom)
  const [globalLoading, setGlobalLoading] = useAtom(globalPostAtom)
  const [issueData, setIssueData] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(head(rowData))
  const [createdIssues, setCreatedIssues] = useAtom(completedRowDataAtom)
  const [formData, setFormData] = React.useState(defaultData)
  const [expanded, setExpanded] = useState({
    location: true,
    transporter: false,
    seller: false,
  })
  const {
    dispatch_assignment_detail_id: selectedAssignmentId = '',
    lot_number: selectedLotNumber = '',
    lot_suffix: selectedLotSuffix = '',
    lot_description: selectedLotDescription = '',
    dispatch_status: selectedDispatchStatus = '',
  } = selectedRowData

  const {
    is_towable: isTowable = ' ',
    lot_trip_zone: lotTripZone = ' ',
    phone_number: phoneNumber = ' ',
    email: emailId = ' ',
    tow_truck_type: towTruckType = ' ',
    created_by_name: createdByName = ' ',
    created_by_vendor: createdByVendor = ' ',
    vendor_phone_number: vendorPhoneNumber = ' ',
    seller_code: sellerCode = ' ',
    owner_name: ownerName = ' ',
    adjuster: adjusterName = ' ',
    insured_name: insuredName = ' ',
    adjuster_phone_number: adjusterPhoneNumber = ' ',
    claim_number: claimNumber = ' ',
    policy_number: policyNumber = ' ',
  } = propOr({}, 'lot_info', issueData)

  const locationAddress = formAddress(propOr({}, 'lot_info', issueData))

  const resetFormData = () => {
    setFormData(defaultData)
  }
  const [sendEmail, setSendEmail] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const apiData = await getIssues(selectedAssignmentId)
      setIssueData(apiData)
      setLoading(false)
    }
    fetchData()
  }, [selectedRowData])

  useEffect(() => {
    if (isEmpty(createdIssues)) {
      return
    }
    if (rowData.length === createdIssues.length) {
      handleClose()
    } else if (rowData.length > createdIssues.length) {
      let curIndex = rowData.indexOf(selectedRowData)
      const nextIndex = moveToNextItemInMultiView(
        curIndex,
        createdIssues,
        rowData,
        'lot_number',
      )
      setSelectedRowData(rowData[nextIndex])
      resetFormData()
    }
  }, [createdIssues])

  const areRequiredFieldsFilled = () => {
    const requiredFields = ['type', 'subtype']
    if (formData.type === 'other') {
      requiredFields.push('comments')
    }
    return requiredFields.every((field) => Boolean(formData[field]))
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }
  const handleIssueInputChange = (event) => {
    const { name, value } = event.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleSendEmailClick = () => {
    setSendEmail(true)
  }

  const handleClose = () => {
    resetFormData()
    setActionCode('')
    onClose()
    setCreatedIssues([])
  }

  const handleSubmit = async () => {
    const result = await submitIssue(formData, selectedAssignmentId)
    if (result.success) {
      setGlobalLoading(true)
      enqueueSnackbar(`Issue has been created for lot ${selectedLotNumber}`, {
        variant: 'success',
      })
      resetFormData()
      setCreatedIssues([...createdIssues, selectedLotNumber])
    } else {
      enqueueSnackbar('Error occured while creating issue', {
        variant: 'error',
      })
    }
  }

  const classes = useStyles()
  const { location, transporter, seller } = expanded
  const handleChange = (name) =>
    setExpanded({ ...expanded, [name]: !expanded[name] })
  return (
    <Dialog
      open={actionCode === ACTION_CODES.CREATE_ISSUE}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      scroll="paper"
    >
      <DialogTitle style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '10px',
            whiteSpace: 'nowrap',
            color: 'white',
          }}
        >
          <CreateIssueIcon color="white" />
          <div style={{ display: 'flex', columnGap: '10px' }}>
            <Typography
              classes={{
                root: classes.root,
              }}
            >
              Issue Creation - {selectedLotNumber} - {selectedLotSuffix}
            </Typography>
            <Divider
              classes={{ root: classes.dividerRoot }}
              orientation="vertical"
              flexItem
            />
            <Typography
              classes={{
                root: classes.root,
              }}
            >
              {selectedLotDescription}
            </Typography>
            <Divider
              classes={{ root: classes.dividerRoot }}
              orientation="vertical"
              flexItem
            />
            <Typography
              classes={{
                root: classes.root,
              }}
            >
              {reverseDispatchStatusMapper[selectedDispatchStatus]}
            </Typography>
          </div>
          <IconButton
            onClick={handleClose}
            style={{ position: 'absolute', right: '8px', top: '8px' }}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent dividers maxWidth="xs">
        <div style={{ display: 'flex', minHeight: '500px' }}>
          {rowData.length > 1 && (
            <MultiViewSidebar
              selectedRowData={selectedRowData}
              setSelectedRowData={setSelectedRowData}
              completedRowData={createdIssues}
              resetFormData={resetFormData}
            />
          )}
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div
              className="col-2-3"
              style={
                rowData.length > 1
                  ? {
                      borderLeft: '1px solid rgba(0,0,0,0.12)',
                      paddingLeft: '10px',
                    }
                  : {}
              }
            >
              <Grid container spacing={2}>
                {/* Select Issue */}
                <Grid item xs={6}>
                  {issueData?.available_issues &&
                  issueData?.available_issues.length > 0 ? (
                    <TextField
                      fullWidth
                      required
                      select
                      label="Issue Type"
                      variant="outlined"
                      name="type"
                      value={formData.type}
                      onChange={handleIssueInputChange}
                    >
                      {issueData?.available_issues.map((issue) => (
                        <MenuItem key={issue.code} value={issue.code}>
                          {issue.description}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : null}
                </Grid>

                {/* Select subIssue */}
                {formData.type && (
                  <Grid item xs={6}>
                    {issueData?.available_issues?.find(
                      (issue) => issue.code === formData.type,
                    )?.subtypes && (
                      <TextField
                        fullWidth
                        required
                        select
                        label="Sub Issue Type"
                        variant="outlined"
                        name="subtype"
                        value={formData.subtype}
                        onChange={handleInputChange}
                      >
                        {issueData?.available_issues
                          .find((issue) => issue.code === formData.type)
                          ?.subtypes.map((subtype) => (
                            <MenuItem key={subtype.code} value={subtype.code}>
                              {subtype.description}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  </Grid>
                )}

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    required={['other'].includes(formData.type)}
                    label="Comments"
                    variant="outlined"
                    name="comments"
                    value={formData.comments}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>

              {/* Location Details */}
              <Accordion
                expanded={location}
                onChange={() => handleChange('location')}
                style={{ marginTop: '20px' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ background: '#E8EDF5' }}
                >
                  <Typography>Location Details</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '20px' }}>
                  {issueData?.lot_info && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Location"
                          variant="outlined"
                          name="location"
                          value={locationAddress}
                          onChange={handleInputChange}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Towable"
                          variant="outlined"
                          name="towable"
                          value={isTowable}
                          onChange={handleInputChange}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Other Lots"
                          variant="outlined"
                          name=""
                          value={' '}
                          onChange={handleInputChange}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Zone"
                          variant="outlined"
                          name="tripZone"
                          value={lotTripZone}
                          onChange={handleInputChange}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </AccordionDetails>
              </Accordion>

              {/* Transporter Information */}
              <Accordion
                expanded={transporter}
                onChange={() => handleChange('transporter')}
                style={{ marginTop: '20px' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  style={{ background: '#E8EDF5' }}
                >
                  <Typography>Transporter Information</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '20px' }}>
                  {issueData?.lot_info && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Phone #"
                          variant="outlined"
                          name="phoneNumber"
                          value={phoneNumber}
                          onChange={handleInputChange}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Email"
                          variant="outlined"
                          name="email"
                          value={emailId}
                          onChange={handleInputChange}
                          InputProps={{
                            readOnly: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                <Tooltip title="Click to email">
                                  <IconButton onClick={handleSendEmailClick}>
                                    <EmailIcon />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="truck Info"
                          variant="outlined"
                          name="truckType"
                          value={towTruckType}
                          onChange={handleInputChange}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Driver's Name"
                          variant="outlined"
                          name="driverName"
                          value={createdByName}
                          onChange={handleInputChange}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Tow provider"
                          variant="outlined"
                          name="towProvider"
                          value={createdByVendor}
                          onChange={handleInputChange}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Co. Manager #"
                          variant="outlined"
                          name="managerPhone"
                          value={vendorPhoneNumber}
                          onChange={handleInputChange}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </AccordionDetails>
              </Accordion>

              {/* Seller Information */}
              <Accordion
                expanded={seller}
                onChange={() => handleChange('seller')}
                style={{ marginTop: '20px' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  style={{ background: '#E8EDF5' }}
                >
                  <Typography>Seller Information</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '20px' }}>
                  {issueData?.lot_info && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Seller Code"
                          variant="outlined"
                          name="sellerCode"
                          value={sellerCode}
                          onChange={handleInputChange}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Owner Name"
                          variant="outlined"
                          name="ownerName"
                          value={ownerName}
                          onChange={handleInputChange}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Adjuster"
                          variant="outlined"
                          name="adjuster"
                          value={adjusterName}
                          onChange={handleInputChange}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Insued Name"
                          variant="outlined"
                          name="insuredName"
                          value={insuredName}
                          onChange={handleInputChange}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Adjuster #"
                          variant="outlined"
                          name="adjusterPhone"
                          value={adjusterPhoneNumber}
                          onChange={handleInputChange}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Claim #"
                          variant="outlined"
                          name="claimNumber"
                          value={claimNumber}
                          onChange={handleInputChange}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Policy"
                          variant="outlined"
                          name="policyNumber"
                          value={policyNumber}
                          onChange={handleInputChange}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </div>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          loading={globalLoading}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!areRequiredFieldsFilled() || globalLoading}
        >
          Create Issue
        </LoadingButton>
      </DialogActions>
      {sendEmail && (
        <SendEmailDialog
          isOpen={sendEmail}
          setSendEmail={setSendEmail}
          emailId={emailId}
        />
      )}
    </Dialog>
  )
}

export default CreateIssueDialog
