import { appNavigatorItems } from 'constants/sidebarItems'

export const appNavigatorStyles = {
  openedMixin: (theme) => ({
    width: appNavigatorItems.navigatorWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  }),
  closedMixin: (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  }),
  drawer: ({ theme, open }) => ({
    width: appNavigatorItems.navigatorWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    transition: 'width 0.3s ease-in-out',
    ...(open && {
      ...appNavigatorStyles.openedMixin(theme),
      '& .MuiDrawer-paper': appNavigatorStyles.openedMixin(theme),
    }),
    ...(!open && {
      ...appNavigatorStyles.closedMixin(theme),
      '& .MuiDrawer-paper': appNavigatorStyles.closedMixin(theme),
    }),
    '.MuiDrawer-paper': {
      overflowY: 'hidden',
      position: 'initial',
      backgroundColor: 'hsl(217deg 18% 28%)',
      transition: 'width 0.3s ease-in-out',
      '@media(max-width: 760px)': {
        ...(!open && { display: 'none' }),
      },
    },
  }),
  listItems: () => ({
    '&:hover': {
      backgroundColor: 'white',
      borderRadius: '5px',
      transition: 'background-color 0.3s ease-in-out',
      '.MuiListItemText-root': {
        color: 'black',
      },
      '.MuiListItemIcon-root': {
        color: 'black',
      },
      '.MuiSvgIcon-root': {
        color: 'black',
      },
    },
    '&.Mui-selected': {
      backgroundColor: 'white',
      borderRadius: '5px',
      '.MuiListItemText-root': {
        color: 'black',
      },
      '.MuiListItemIcon-root': {
        color: 'black',
      },
      '.MuiSvgIcon-root': {
        color: 'black',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'white',
      borderRadius: '5px',
      '.MuiListItemText-root': {
        color: 'black',
      },
      '.MuiListItemIcon-root': {
        color: 'black',
      },
      '.MuiSvgIcon-root': {
        color: 'black',
      },
    },
  }),
}
