import React, { useEffect } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { GlobalStyles } from '@mui/system'
import { SnackbarProvider } from 'notistack'
import { useAtom } from 'jotai'
import { CircularProgress } from '@mui/material'

import Main from 'components/main'
import Login from 'components/login'

import { getItem, removeItem } from './utils/localstorage'
import { isInPast } from 'utils/date'
// import { getRefreshToken } from './components/login/loginActions'
import { isAuthorizedAtom, loadingAtom } from 'constants/atoms'
import customAxios from 'services/customAxios'

import './App.css'

const theme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#1d5ab9',
          '&.Mui-focused': {
            color: '#1d5ab9',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: '#1d5ab9',
          color: 'white',
          '&.Mui-focused': {
            backgroundColor: '#1d5ab9',
            color: 'white',
          },
        },
      },
    },
  },
})

const App = () => {
  const [isAuthorized, setIsAuthorized] = useAtom(isAuthorizedAtom)
  const [isLoading, setIsLoading] = useAtom(loadingAtom)
  useEffect(() => {
    const validateSession = async () => {
      try {
        const accessToken = getItem('accessToken')
        const accessTokenExpiry = getItem('accessTokenExpiry')
        // const refreshTokenExpiry = getItem('refreshTokenExpiry')
        if (accessToken && accessTokenExpiry && !isInPast(accessTokenExpiry)) {
          setIsAuthorized(true)
        }
        // else if (refreshTokenExpiry && !isInPast(refreshTokenExpiry)) {
        //   const newToken = await getRefreshToken()
        //   setAccessToken(newToken)
        //   setIsAuthorized(true)
        // }
        else {
          setIsAuthorized(false)
        }
      } catch (error) {
        console.error('Error refreshing token:', error)
        setIsAuthorized(false)
      }
      setIsLoading(false)
    }

    validateSession()
  }, [setIsAuthorized])

  useEffect(() => {
    customAxios.setAuthenticationUpdater(setIsAuthorized)
    return () => {
      customAxios.setAuthenticationUpdater(null)
    }
  }, [setIsAuthorized])

  const logOutOnClick = () => {
    setIsAuthorized(false)
    removeItem('accessToken')
    removeItem('accessTokenExpiry')
    removeItem('refreshToken')
    removeItem('refreshTokenExpiry')
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          '.MuiFormLabel-asterisk': {
            color: 'red',
          },
        }}
      />
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transitionDuration={{ enter: 200, exit: 195 }}
      >
        <div className="App" style={{ flex: 1 }}>
          {isLoading ? (
            <CircularProgress sx={{ position: 'absolute', top: '45vh' }} />
          ) : (
            <header>
              {isAuthorized ? (
                <Main logOutOnClick={logOutOnClick} />
              ) : (
                <Login setIsAuthorized={setIsAuthorized} />
              )}
            </header>
          )}
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
