import React from 'react'
import { useAtom } from 'jotai'
import { useSnackbar } from 'notistack'
import { MuiOtpInput } from 'mui-one-time-password-input'

// import { Button } from 'semantic-ui-react'
import { Button } from '@mui/material'
import FiberPinRoundedIcon from '@mui/icons-material/FiberPinRounded'

import {
  selectedNumberAtom,
  externalUserDataAtom,
  accountStatusAtom,
} from 'constants/atoms'
import { loginLabels } from 'utils/labels'
import { verifyOTP } from './loginActions'
import BackButton from '../customInputFields/backButton'

const OTPVerification = ({ mode, phoneNumber, inputEmail, otp, setOtp }) => {
  const [externalUserData] = useAtom(externalUserDataAtom)
  const [selectedNumber] = useAtom(selectedNumberAtom)
  const [, setAccountStatus] = useAtom(accountStatusAtom)
  const { enqueueSnackbar } = useSnackbar()

  const validateOtp = (otp) => {
    const payload = {
      otp: otp,
      external_user_id: externalUserData.external_user_id,
      email: externalUserData.email,
    }
    verifyOTP(payload)
      .then((response) => {
        setAccountStatus('changePassword')
        enqueueSnackbar(loginLabels.otpValidated, { variant: 'success' })
      })
      .catch((error) => {
        enqueueSnackbar(loginLabels.invalidOtp, { variant: 'error' })
      })
  }

  const otpComplete = otp.length >= 6

  return (
    <div>
      <div className="avatarIconWrap">
        <FiberPinRoundedIcon className="avatarDiv" />
      </div>
      <div className="loginStep1Wrap divBox">
        <BackButton
          accountStatus={'verifyAccount'}
          setAccountStatus={setAccountStatus}
        />
        <p>
          {loginLabels.sentCodeTo(
            mode === 'TEXT' ? selectedNumber : inputEmail,
          )}
        </p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <MuiOtpInput
            length={6}
            value={otp}
            TextFieldsProps={{
              size: 'large',
              disabled: false,
              placeholder: '•',
              sx: {
                '& .MuiInputBase-input': {
                  fontWeight: 900,
                },
              },
            }}
            onChange={setOtp}
          />
        </div>
        <Button
          variant="contained"
          color="success"
          disabled={!otpComplete}
          onClick={() => validateOtp(otp)}
        >
          Verify OTP
        </Button>
      </div>
    </div>
  )
}

export default OTPVerification
