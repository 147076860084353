// import gsmen from './gsm/en'
// import refen from './ReferenceData/referenceData_en'

export default {
//   ...gsmen,
//   ...refen,
  New: 'New',
  Search: 'Search',
  'New Assignment for:': 'New Assignment for:',
  'Enter New Assignment': 'Enter New Assignment',
  'Contact:': 'Contact:',
  'Adjuster:': 'Adjuster:',
  'Are you sure you are ready to submit?': 'Are you sure you are ready to submit?',
  'Assignment Entry Submission successful for Lot number:': 'Assignment Entry Submission successful for Lot number:',
  ERRORS: 'ERRORS',
  'Correlation ID:': 'Correlation ID:',
  'Assignment Entry Submission': 'Assignment Entry Submission',
  Cancel: 'Cancel',
  Submit: 'Submit',
  'Go to Summary': 'Go to Summary',
  'Restart with Different Seller': 'Restart with Different Seller',
  'All the unsaved changes will be lost': 'All the unsaved changes will be lost',
  'Are you sure you want to restart with a different Seller?':
    'Are you sure you want to restart with a different Seller?',
  'Assignment entry submission': 'Assignment entry submission',
  'Submission failed': 'Submission failed',
  'Submission successful': 'Submission successful',
  successful: 'successful',
  failed: 'failed',
  Alert: 'Alert',
  'Error Occured': 'Error Occured',
  Password: 'Password',
  Email: 'Email',
  'You landed on this page by mistake! Please go back to': 'You landed on this page by mistake! Please go back to',
  Home: 'Home',
  'Please review your submission before pressing SUBMIT!': 'Please review your submission before pressing SUBMIT!',
  'Responsible Party': 'Responsible Party',
  'Pickup Address': 'Pickup Address',
  'Lot Description: ': 'Lot Description: ',
  'Facility Name: ': 'Facility Name: ',
  Facility: 'Facility',
  yard: 'Yard #',
  newDestinationAddressAdded: 'New destination address added',
  failedToAddNewDestinationAddress: 'Could not add new destination address',
  'Invalid Search String': 'Invalid Search String',

  // BEGIN FIELD LABELS
  additionalFee: 'Other Advanced charges',
  additionalFeeComment: 'Invoice Comments (for Other Advance Charges)',
  additionalInfo: 'Additional Information',
  assignmentCreationDate: 'Assignment Entered Date',
  laborFeeComment: 'Invoice Comments (for Labor Charges)',
  additionalFeeVAT: 'Other Advanced charges VAT',
  airbagsDeployed: 'Airbags Deployed?',
  auctionDateDisplayOnly: 'Auction Date',
  amount: 'Amount',
  applicationDate: 'Application Date',
  areAdvancedChargesPaid: 'Advance charges paid',
  auctionId: 'Auction',
  auctionName: 'Auction',
  awardDate: 'Award Date',
  bidToBeApprovedTypeId: 'Bid Approval',
  bodyStyle: 'Body Style',
  buyNow: 'Buy It Now',
  calcOdometerBrand: 'Calculated Odometer Brand',
  claimNumber: 'Reference Number',
  closedDate: 'Closed Date',
  co2Emission: 'CO2 Emission',
  co2EmissionUnit: 'CO2 Emission Unit',
  color: 'Color',
  contact: 'Contact',
  contactEmail: 'Email',
  contactPrimaryPhone: 'Phone',
  contactPrimaryPhoneExtension: 'Extension',
  contactSecondaryPhone: 'Alt. Phone',
  comment: 'Resolution Comment',
  copartHandlesCustomFlag: 'Copart Handles Customs?',
  createdUser: 'Created User',
  cylinders: 'Cylinders',
  dateClaimReported: 'Claim Reported Date',
  dateOfLoss: 'Loss Date',
  deliveredOnDate: 'Delivered On',
  documentedOdometer: 'Documented Odometer',
  documentedOdometerBrand: 'Documented Odometer Brand',
  documentedOdometerUnit: 'Documented Odometer Unit',
  drive: 'Drive',
  engineDisplacement: 'Engine Displacement',
  engineDisplacementUnit: 'Engine Displacement Unit',
  engineOutput: 'Engine Output',
  engineOutputUnit: 'Engine Output Unit',
  engineType: 'Engine Type',
  estimatedCostOfRepair: 'Repair Cost',
  euronorm: 'Euronorm',
  feeType: 'Member Fee Type',
  firstRegistrationDate: 'First Registration Date',
  fuel: 'Fuel',
  grossWeight: 'Gross Weight',
  grossWeightUnit: 'Gross Weight Unit',
  hasEngine: 'Has Engine',
  hasTransmission: 'Has Transmission?',
  insuredAddress1: 'Insured Address 1',
  insuredAddress2: 'Insured Address 2',
  insuredCity: 'Insured City',
  insuredCompanyName: 'Insured Company Name',
  insuredCountry: 'Insured Country',
  insuredEmail: 'Insured Email',
  insuredFirstName: 'Insured First Name',
  insuredLastName: 'Insured Last Name',
  insuredPhone: 'Insured Phone',
  insuredPhoneExt: 'Insured Phone Ext',
  insuredSecondaryPhone: 'Insured Alt Phone',
  insuredSecondaryPhoneExt: 'Insured Alt Ext',
  insuredState: 'Insured State/Region/Province',
  insuredVatId: 'Insured VAT ID',
  insuredZip: 'Insured Postal Code',
  isInsuredCompany: 'Is the Insured a Company?',
  isOdometerReadable: 'Is Odometer readable?',
  isOwnerCompany: 'Is the Owner a Company?',
  isOwnerInsured: 'Insured Same as Owner?',
  itemDescription: 'Item Description',
  itemNumber: 'Item Number',
  itemValue: 'Item Value',
  keys: 'Keys',
  labor: 'Labor',
  laborVAT: 'Labor VAT',
  leftLocation: 'Left Facility Date',
  licensePlateNumber: 'License Plate Number',
  lossType: 'Loss Type',
  tripType: 'Trip Type',
  lotIdentificationNumber: 'Verify Vehicle Identification Number',
  lotDiscrepancy: 'Lot Discrepancy',
  lotLength: 'Length',
  lotLengthUnit: 'Length Unit',
  lotNote: 'Lot Notes',
  lotNumber: 'Lot Number',
  'Lot Number': 'Lot Number',
  lotStage: 'Lot Stage',
  make: 'Make',
  makeName: 'Make',
  memberDestinationAddress: 'Destination Address',
  memberDestinationAddressId: 'Destination Address',
  memberId: 'Member ID',
  memberInvoiceComplete: 'Member Invoice Complete?',
  memberNumber: 'Member Number',
  memberName: 'Member Name',
  memberEmail: 'Member Email',
  memberPhone: 'Member Phone',
  memberAddress: 'Member Address',
  memberCity: 'Member City',
  memberCountry: 'Member Country',
  memberChargeAmount: 'Member Charge Amount',
  memberFeeTypeName: 'Member Fee Type',
  memberPaidDate: 'Member Paid Date',
  memberPaidFlag: 'Member Paid?',
  minimumBidReserve: 'Reserve',
  model: 'Model',
  modelName: 'Model',
  modelYear: 'Model Year',
  newPickupContact: 'Contact',
  newPickupLocationType: 'Location Type',
  newPickupLocationName: 'Location Name',
  newPickupVatId: 'VAT ID',
  newPickupAddress1: 'Address Line1',
  newPickupAddress2: 'Address Line2',
  newPickupCountry: 'Country',
  newPickupCity: 'City',
  newPickupState: 'State/Region/Province',
  newPickupPhone: 'Phone',
  newPickupAltPhone: 'Alternate Phone',
  newPickupEmail: 'Email',
  newPickupZip: 'Postal Code',
  newInvoiceIssued: 'New Invoice Issued?',
  numberOfReruns: 'Number of Reruns',
  numberOfRelists: 'Number of Relists',
  odometer: 'Odometer',
  odometerUnit: 'Odometer Unit',
  okToPayCharges: 'Are there Advance Charges?',
  origCountry: 'Original Document Country',
  origDocumentType: 'Original Document Type',
  originalOdometerBrand: 'Original Odometer Brand',
  originalOdometerUnit: 'Original Odometer Unit',
  orignalOdometer: 'Original Odometer',
  origState: 'Original Document State/Region/Province',
  offsite: 'Offsite?',
  offsiteLotSideId: 'Offsite Lot Site Id',
  ownerAddress1: 'Owner Address 1',
  ownerAddress2: 'Owner Address 2',
  'Owner Address': 'Owner Address',
  ownerCity: 'Owner City',
  ownerCompanyAddress: 'Owner Company',
  ownerCompanyName: 'Owner Company Name',
  ownerCountry: 'Owner Country',
  ownerEmail: 'Owner Email',
  ownerNumber: 'Owner Number',
  ownerName: 'Owner Name',
  ownerFirstName: 'Owner First Name',
  ownerLastName: 'Owner Last Name',
  ownerPhone: 'Owner Phone',
  ownerPhoneExt: 'Owner Phone Ext',
  ownerSecondaryPhone: 'Owner Alt Phone',
  ownerSecondaryPhoneExt: 'Owner Alt Phone Ext',
  ownerState: 'Owner State/Region/Province',
  ownerVatId: "Owner's VAT ID/Fiscal ID",
  ownerZip: 'Owner Postal Code',
  deliveryAddress: 'Delivery Address',
  paymentType: 'Payment Type',
  paymentReferenceNumber: 'Payment Reference #',
  paymentAmount: 'Payment Amount',
  paymentDate: 'Payment Date',
  pickupAddress1: 'Pickup Address 1',
  pickupAddress2: 'Pickup Address 2',
  pickupAltPhone: 'Pickup Alt Phone',
  pickupCity: 'Pickup City',
  pickupContact: 'Contact',
  pickupCountry: 'Pickup Country',
  pickupEmail: 'Pickup Email',
  pickupHoldDate: 'Pickup Hold Follow-up Date',
  pickupHoldNote: 'Hold Reason',
  pickupLocationName: 'Pickup Location Name',
  pickupVatId: 'Pickup VAT ID',
  pickupLotSiteId: 'Pickup Lot Site Id',
  pickupPhone: 'Pickup Phone',
  pickupRequired: 'Pickup Required',
  pickupState: 'Pickup State/Region/Province',
  pickupZip: 'Pickup Postal Code',
  primaryDamageCode: 'Primary Damage Code',
  primarySeverityOfDamage: 'Severity of Primary Damage',
  priority: 'Service Orders Priority',
  promisedTripDate: 'Promised Trip Date',
  proofOfTransportReceived: 'Proof of Transport Received?',
  purchasePrice: 'Purchase Price',
  receivedBy: 'Received by',
  recoveryDate: 'Recovery Date',
  regCertificateNumber: 'Registration Certificate Number',
  registrationNumber: 'Registration Number',
  contactId: 'Contact',
  rerunReason: 'Rerun Reason',
  residence: 'Residence',
  residualValue: 'Residual Value',
  rowLocation: 'Row Location',
  runCondition: 'Run Condition',
  saleActionApprovedBy: 'Action Approved by',
  saleConfirmationStatus: 'Sale Confirmation Status',
  saleConfirmationDate: 'Sale Confirmation Date',
  saleDocAtCopart: 'Sale Documents at Copart',
  saleDocToMember: 'Sale Documents Given to Member',
  saleDocumentCountry: 'Sale Document Country',
  saleDocumentState: 'Sale Document State/Region/Province',
  saleDocumentType: 'Sale Document Type',
  saleHoldDate: 'Sale Hold Follow-up Date',
  saleHoldNote: 'Hold Reason',
  salePrice: 'Sale Price',
  highBidAmount: 'High Bid Amount',
  scheduledTripDate: 'Scheduled Trip Date',
  searchByLocation: 'Pickup Location Phone',
  seats: 'Seats',
  secondaryDamageCode: 'Secondary Damage Code',
  secondarySeverityOfDamage: 'Severity of Secondary Damage',
  sellerChargeDate: 'Seller Charge Date',
  sellerChargeAmount: 'Seller Charge Amount',
  sellerFeeTypeName: 'Seller Fee Type',
  sellerPaidDate: 'Seller Paid Date',
  sellerTotalFees: 'Total Fees',
  sellerTotalDue: 'Total Due',
  sellerId: 'Seller',
  Seller: 'Seller',
  sellerInvoiceComplete: 'Seller Invoice Complete?',
  serviceId: 'Service',
  serviceDate: 'Service Date',
  serviceStatus: 'Service Status',
  serviceCost: 'Service Cost',
  servicePriority: 'Service Priority',
  serviceAmount: 'Service Order Amount',
  serviceFeeType: 'Service Order Fee Type',
  serviceOrderInstructions: 'Service Order Instructions',
  serviceOrders: 'Service Order',
  settlementModel: 'Settlement Model',
  specialTransportInstructions: 'Special Transport Instructions',
  statusUpdatedDate: 'Service Order Completed Date',
  storageAmount: 'Storage Amount',
  storageAmountVAT: 'Storage Amount VAT',
  storageDaily: 'Flat or Daily?',
  storageFromDate: 'Storage From Date',
  storageThruDate: 'Storage Thru Date',
  tearDownEstimate: 'Tear Down Estimate',
  tearDownEstimateVAT: 'Tear Down Estimate VAT',
  totalCharges: 'Total Charges',
  totalPayments: 'Total',
  totalFees: 'Member Total Fees (Not Including Tax)',
  totalRuns: 'Total Runs',
  totalTax: 'Total VAT',
  towable: 'Towable',
  towType: 'Tow Type',
  towing: 'Towing',
  highPriority: 'High Priority Service Order Exists',
  'Long Term Hold Note text Maintenance': 'Long Term Hold Note text Maintenance',
  towingVAT: 'Towing VAT',
  towProviderId: 'Select Tow Provider',
  transferType: 'Ownership Transfer Type',
  transmission: 'Transmission',
  trim: 'Trim',
  trimName: 'Trim',
  tripDate: 'Trip Date',
  tripDistance: 'Trip Distance',
  syncStatus: 'Sync Status',
  userNotes: 'Service Order Notes',
  vatEligible: 'Value Added Tax Eligible?',
  vatSection: 'VAT Section',
  vehicleType: 'Item Type',
  vin: 'Vehicle Identification Number',
  whoIsResponsibleForAdvancedCharges: 'Responsible Party',
  yardGate: 'Facility Gate',
  yardGateVAT: 'Facility Gate VAT',
  yardId: 'Responsible Facility',
  yearOfManufacture: 'Year of Manufacture',
  'History of Runs': 'History of Runs',
  'Sale Date': 'Sale Date',
  'Minimum Bid': 'Minimum Bid',
  'Sale Price': 'Sale Price',
  'Number of Bidders': 'Number of Bidders',
  'Member ID': 'Member ID',
  'Number of States': 'Number of States',
  'Number of Countries': 'Number of Countries',
  /** *** BEGIN FIELD LABELS **** */
  'Bulk Lot Updates for ': 'Bulk Lot Updates for ',
  'Add multiple lots at once: (eg: 50003236, 50003186)': 'Add multiple lots at once: (eg: 50003236, 50003186)',
  'Save Changes': 'Save Changes',
  'Left Yard': 'Left Yard',
  'Total Towing': 'Total Towing',
  'Assign Tow Provider': 'Assign Tow Provider',
  'Please add the lots first': 'Please add the lots first',
  'is/are not in Driver Dispatch.': 'is/are not in Driver Dispatch.',
  'This field is not editable for this lot': 'This field is not editable for this lot.',
  'Please enter a Lot Number first': 'Please enter a Lot Number first.',
  'The following lots have already been added:': 'The following lots have already been added:',
  'The following lot numbers are invalid:': 'The following lot numbers are invalid:',
  'Adding following lots:': 'Adding following lots:',
  'No lot number provided.': 'No lot number provided.',
  'Not all your changes have been saved successfully': 'Not all your changes have been saved successfully',
  'Your changes have been saved successfully': 'Your changes have been saved successfully',
  'has already been entered': 'has already been entered.',
  'Could not fetch seller company Id': 'Could not fetch seller company Id',
  'Could not fetch seller company hierarchy': 'Could not fetch seller company hierarchy',
  'Could not fetch form data. Please refresh the page.': 'Could not fetch form data. Please refresh the page.',
  'Awaiting form data to be initialized. Please try again in a moment.':
    'Awaiting form data to be initialized. Please try again in a moment.',
  'No field data in Redux store corresponding to ': 'No field data in Redux store corresponding to ',
  'Please enter a whole number value': 'Please enter a whole number value',
  'Please enter a valid email address': 'Please enter a valid email address',
  'Only two digits after decimal allowed': 'Only two digits after decimal allowed',
  'Sorry, updated values cannot resolve discrepancy': 'Sorry, updated values cannot resolve discrepancy',
  'Discrepancy Error Dialog': 'Discrepancy Error Dialog',
  'There was trouble unlocking this lot.': 'There was trouble unlocking this lot.',
  'Could not fetch data for lot': 'Could not fetch data for lot',
  'Your changes have successfully been saved.': 'Your changes have successfully been saved.',
  'There was a problem saving your changes.': 'There was a problem saving your changes.',
  'Could not fetch Notes': 'Could not fetch Notes',
  'Could not fetch Recent Lots': 'Could not fetch Recent Lots',
  Lot: 'Lot',
  roleSelectMessage: 'Please select a role',
  'Updating data for Lot': 'Updating data for Lot',
  'Invalid lot number:': 'Invalid lot number:',
  'Required Error': 'This field is required',
  'Invalid Number': 'Invalid Number',
  'Address Line 1 is required': 'Address Line 1 is required',
  'City is required': 'City is required',
  'Postal Code is required': 'Postal Code is required',
  'Country is required': 'Country is required',
  'State is required': 'State is required',
  'Row Number': 'Row Number',
  'Item Number': 'Item Number',
  'DD/MM/YYYY': 'DD/MM/YYYY',
  'Update all rows': 'Update all rows',
  'Updating data for following lots:': 'Updating data for following lots:',
  Update: 'Update',
  'New Assignment': 'New Assignment',
  'Assignment Entry Success': 'Assignment Entry Success',
  'Same Caller New Assignment': 'Same Caller New Assignment',
  'Maintain Lot': 'Maintain Lot',
  Exit: 'Exit',
  'Assignment Entry Errors': 'Assignment Entry Errors',
  'Create Location Errors': 'Create Location Errors',
  'Create Owner Errors': 'Create Owner Errors',
  OK: 'OK',
  'Damage Type': 'Damage Type',
  'Drive Status': 'Drive Status',
  'Model Year': 'Model Year',
  'Make Model Trim': 'Make Model Trim',
  'Duplicate Registration Number': 'Duplicate Registration Number',
  'Service Order': 'Service Order',
  'Service Order Pricing': 'Service Order Pricing',
  'Service Order Status': 'Service Order Status',
  'VIN Length': 'VIN Length',
  'VIN Changed': 'VIN Changed',
  'VIN Missing': 'VIN Missing',
  'Duplicate VIN': 'Duplicate VIN',
  'Creation of new location failed': 'Creation of new location failed',
  'Please fill all the required fields': 'Please fill all the required fields',
  'Seller And Contact': 'Seller And Contact',
  'Lot Information': 'Lot Information',
  'Billing And Pickup': 'Billing And Pickup',
  'Ownership Documents': 'Ownership Documents',
  'Sale To Member': 'Sale To Member',
  'Critical Dates': 'Critical Dates',
  Auction: 'Auction',
  'Ownership Transfer': 'Ownership Transfer',
  'Original Documents': 'Original Documents',
  'Sale Documents': 'Sale Documents',
  'Date Created': 'Date Created',
  'Last Modified': 'Last Modified',
  Odometer: 'Odometer',
  Error: 'Error',
  'Lot is locked by': 'Lot is locked by',
  'Please try another lot.': 'Please try another lot.',
  'Internal server Error': 'Internal server Error',
  'Gateway Timeout Error': 'Gateway Timeout Error',
  'Please try again later': 'Please try again later',
  'Re Run': 'Re Run',
  'Re List': 'Re List',
  'Re List with Copart': 'Re List with Copart',
  'Award Lot': 'Award Lot',
  'Remove from Auction': 'Remove from Auction',
  'Cancel Lot': 'Cancel Lot',
  Discrepancies: 'Discrepancies',
  'Confirm Rerun': 'Confirm Rerun',
  'Confirm Relist': 'Confirm Relist',
  'Confirm Relist with Copart': 'Confirm Relist with Copart',
  'Confirm Award Lot': 'Confirm Award Lot',
  'Confirm Cancel Lot': 'Confirm Cancel Lot',
  'Confirm Discrepancies': 'Confirm Discrepancies',
  'Confirm Unassign': 'Confirm Unassign',
  RerunConfirmation: 'Are you sure you want to Rerun this lot now?',
  RelistConfirmation: 'Are you sure you want to Relist this lot now?',
  RelistWithCopartConfirmation: 'Are you sure you want to Relist this lot with Copart now?',
  AwardLotConfirmation:
    'Are you sure you want to Award this lot now? Any auction date and preliminary bids will be removed.',
  CancelLotConfirmation: 'Are you sure you want to Cancel this lot now?',
  DiscrepanciesConfirmation: 'Are you sure you want to open the lot in Discrepancy Popup?',
  UnassignConfirmation: 'Are you sure you want to Unassign this lot now?',
  'Problem in relisting the lot': 'Problem in relisting the lot',
  'Lot Review Errors': 'Lot Review Errors',
  'Lot Discrepancy': 'Lot Discrepancy',
  'Lot Discrepancy Errors': 'Lot Discrepancy Errors',
  'Are there Advance Charges? No': 'Are there Advance Charges? No',
  'Advance Charges': 'Advance Charges',
  'Advance Charges Detail': 'Advance Charges Detail',
  'Add Contact for ': 'Add Contact for ',
  'Charge Type': 'Charge Type',
  Amount: 'Amount',
  'Payment Information': 'Payment Information',
  'Advance Charge Payment': 'Advance Charge Payment',
  'Tow Provider': 'Tow Provider',
  'Pickup Details': 'Pickup Details',
  'Assignment and Pickup': 'Assignment and Pickup',
  'Ownership Transfer and Auction': 'Ownership Transfer and Auction',
  'Sale and Settlement': 'Sale and Settlement',
  'Vehicle Description': 'Vehicle Description',
  'Vehicle Attributes': 'Vehicle Attributes',
  Inventory: 'Inventory',
  'Hold Information': 'Hold Information',
  'Additional Information': 'Additional Information',
  Contact: 'Contact',
  Adjuster: 'Adjuster',
  'Add Contact': 'Add Contact',
  'Add Adjuster': 'Add Adjuster',
  'Add Contact for': 'Add Contact for',
  'Add Adjuster for': 'Add Adjuster for',
  'Owner and Insured': 'Owner and Insured',
  'Owner Id': 'Owner Id',
  Owner: 'Owner',
  Insured: 'Insured',
  'Service Orders': 'Service Orders',
  'Auction Information': 'Auction Information',
  'Virtual Bid Sale To Enquiry': 'Virtual Bid Sale To Enquiry',
  'Virtual Bid Log': 'Virtual Bid Log',
  'Awaiting Release': 'Awaiting Release',
  'Awaiting clear pickup': 'Awaiting Clear Pickup',
  'Awaiting driver dispatch': 'Awaiting Driver Dispatch',
  'Awaiting Ownership Documents': 'Awaiting Ownership Documents',
  'Awaiting Member Settlement': 'Awaiting Member Settlement',
  'Awaiting trip confirmation': 'Awaiting Trip Confirmation',
  'Awaiting Inventory': 'Awaiting Inventory',
  'Awaiting Inventory/Receiving': 'Awaiting Inventory/Receiving',
  'Ready for auction': 'Ready for Auction',
  'Awaiting sale confirmation': 'Awaiting Sale Confirmation',
  'Awaiting settlement confirmation': 'Awaiting Settlement Confirmation',
  'Awaiting sale docs': 'Awaiting Sale Documents',
  'Awaiting Seller Settlement': 'Awaiting Seller Settlement',
  'Partial Assignments': 'Partial Assignments',
  Canceled: 'Canceled',
  'Assigned to Auction': 'Assigned to Auction',
  'Awaiting Dispatch': 'Awaiting Dispatch',
  'Awaiting Driver Dispatch': 'Awaiting Driver Dispatch',
  'Awaiting Ownership Document': 'Awaiting Ownership Document',
  'Awaiting Sale Confirmation': 'Awaiting Sale Confirmation',
  'Awaiting Sale Documents': 'Awaiting Sale Documents',
  'Awaiting Trip Confirmation': 'Awaiting Trip Confirmation',
  'Awaiting Settlement Confirmation': 'Awaiting Settlement Confirmation',
  'Partial Assignment': 'Partial Assignment',
  'Ready for Auction': 'Ready for Auction',
  Closed: 'Closed',
  'Assignment Entry': 'Assignment Entry',
  Edit: 'Edit',
  Saving: 'Saving',
  'Discard changes': 'Discard Changes',
  Unassign: 'Unassign',
  'Save and Transition': 'Save and Transition',
  Save: 'Save',
  Country: 'Country',
  Start: 'Start',
  Yard: 'Yard',
  Feedback: 'Feedback',
  '8 DIGIT PH#': '8 DIGIT PH#',
  'Transporter Portal': 'Transporter Portal',
  'Cobalt Portal': 'Cobalt Portal',
  Dispatch: 'Dispatch',
  'Lot Review': 'Lot Review',
  'Auction Board Control': 'Auction Board Control',
  'Virtual Walk Through': 'Virtual Walk Through',
  DTLE: 'DTLE',
  'Mass Updates': 'Mass Updates',
  'Mass Update': 'Mass Update',
  'Pro Quote': 'Pro Quote',
  MiHub: 'MiHub',
  'Member Payments': 'Member Payments',
  'Casbo Management': 'Management Menu',
  'Casbo Support': 'Support Menu',
  'Transporter Setup': 'Transporter Setup',
  AUCTIONS: 'Auctions',
  'Recent Lots': 'Recent Lots',
  'My Recent Lots': 'My Recent Lots',
  'Pickup Order': 'Pickup Order',
  'Call For Release': 'Call For Release',
  Unit: 'Unit',
  'Delivery Address': 'Delivery Address',
  Settings: 'Settings',
  Role: 'Role',
  'Title Portal': 'Title Portal',
  lotNumberInvalidMessage: 'Invalid Lot Number. Please check.',
  Mild: 'Mild',
  Medium: 'Medium',
  Severe: 'Severe',
  check: 'check',
  cash: 'cash',
  electronic: 'electronic',
  'High Bid Amount': 'High Bid Amount',
  'Current High Bid': 'Current High Bid',
  'Total Due From Seller': 'Total Due From Seller',
  'VAT ID/Fiscal ID': 'VAT ID/Fiscal ID',
  'First Name': 'First Name',
  'Last Name': 'Last Name',
  'Address 1': 'Address 1',
  'Address 2': 'Address 2',
  'State/Region/Province': 'State/Region/Province',
  'Postal Code': 'Postal Code',
  'Phone Number': 'Phone Number',
  'Phone Ext': 'Phone Ext',
  'VAT ID': 'VAT ID',
  'Location Name': 'Location Name',
  Phone: 'Phone',
  'Alt Phone': 'Alt Phone',
  'Alt Phone Ext': 'Alt Phone Ext',
  'Document Type': 'Document Type',
  'View and Print': 'View and Print',
  'Edit Document Type': 'Edit Document Type',
  'Document lot type updated': 'Document lot type updated',
  'Updated document type cannot be same as current document type':
    'Updated document type cannot be same as current document type',
  'Cannot perform Mass Updates': 'Cannot perform Mass Updates when All Facilities is selected.',

  /** *** C STACK FIELD LABELS **** */
  'Accepts Credit Card': 'Accepts Credit Card',
  'Statutory Charges Code': 'Statutory Charges Code',
  'Action/Reason Description': 'Action/Reason Description',
  'ACTION REASON': 'ACTION REASON',
  'TRIP TYPE': 'TRIP TYPE',
  'Actual Charges': 'Actual Charges',
  'Add Lot Note': 'Add Lot Note',
  'Adjuster Email': 'Adjuster Email',
  'Adjuster Name': 'Adjuster Name',
  'Adjuster Phone #': 'Adjuster Phone #',
  'Advance Check Amount': 'Advance Check Amount',
  'Alternate Pickup Phone #': 'Alternate Pickup Phone #',
  'Assignment Entered Date': 'Assignment Entered Date',
  'Charges Allowed': 'Charges Allowed',
  'Charges Paid By Seller': 'Charges Paid By Seller',
  City: 'City',
  'Claim #': 'Claim #',
  'Cleared For Charges Date': 'Cleared For Charges Date',
  'Cleared For Pickup': 'Cleared For Pickup',
  'Cleared To Pickup Date': 'Cleared To Pickup Date',
  'Click to Dial': 'Click to Dial',
  Color: 'Color',
  'Credit Card': 'Credit Card',
  'Cross Street': 'Cross Street',
  'Date of Loss': 'Date of Loss',
  'Days to Pickup': 'Days to Pickup',
  'Deliver Location Name': 'Deliver Location Name',
  'Deliver To': 'Deliver To',
  'Delivery Address Line 1': 'Delivery Address Line 1',
  'Delivery Address Line 2': 'Delivery Address Line 2',
  'Delivery Location Address': 'Delivery Location Address',
  'Delivery Phone': 'Delivery Phone',
  'Delivery Phone #': 'Delivery Phone #',
  'Delivery Yard Phone #': 'Delivery Yard Phone #',
  'Estimated Charges': 'Estimated Charges',
  'Anticipated Charges': 'Anticipated Charges',
  'Negotiated Charges': 'Negotiated Charges',
  'Flat/Negotiated Storage': 'Flat/Negotiated Storage',
  'Follow Up In(HH:MM)': 'Follow Up In(HH:MM)',
  'Has Keys': 'Has Keys',
  'Hold Reason': 'Hold Reason',
  'Insured Name': 'Insured Name',
  Limit: 'Limit',
  'Limit Per Day': 'Limit Per Day',
  'Loss Type': 'Loss Type',
  Make: 'Make',
  'Miscellaneous Charges Description': 'Miscellaneous Charges Description',
  Model: 'Model',
  'Override Seller Limit': 'Override Seller Limit',
  'Owner Name': 'Owner Name',
  'Owner Phone #': 'Owner Phone #',
  'Payable To': 'Payable To',
  'Picked Up Date': 'Picked Up Date',
  'Picked Up by': 'Picked Up by',
  'Pickup Address Line 1': 'Pickup Address Line 1',
  'Pickup Address Line 2': 'Pickup Address Line 2',
  'Pickup Aging Comment': 'Pickup Aging Comment',
  'Pickup Contact': 'Pickup Contact',
  'Pickup Directions': 'Pickup Directions',
  'Pickup Hold Date': 'Pickup Hold Date',
  'Pickup Location Name': 'Pickup Location Name',
  'Pickup Notes': 'Pickup Notes',
  'Pickup Phone #': 'Pickup Phone #',
  'Pickup Phone 2 #': 'Pickup Phone 2 #',
  'Plate #': 'Plate #',
  'Policy #': 'Policy #',
  'Primary Damage Code': 'Primary Damage Code',
  'Recovery Date': 'Recovery Date',
  Residence: 'Residence',
  RESIDENCE: 'RESIDENCE',
  Scheduled: 'Scheduled',
  'Scheduled Date': 'Scheduled Date',
  'Scheduled Time From': 'Scheduled Time From',
  'Scheduled Time To': 'Scheduled Time To',
  'Seller Address': 'Seller Address',
  'Seller Approver Email': 'Seller Approver Email',
  'Seller Approver Name': 'Seller Approver Name',
  'Seller Approver Phone': 'Seller Approver Phone',
  'Seller Loss Code': 'Seller Loss Code',
  'Seller Phone #': 'Seller Phone #',
  'Seller Reference #': 'Seller Reference #',
  'Seller Reference Number': 'Seller Reference Number',
  State: 'State',
  'Storage 2 From': 'Storage 2 From',
  'Storage 2 Rate': 'Storage 2 Rate',
  'Storage 2 To': 'Storage 2 To',
  'Storage From Date': 'Storage From Date',
  'Storage Rate': 'Storage Rate',
  'Storage To Date': 'Storage To Date',
  Tax: 'Tax',
  'Teardown/Estimating Fee': 'Teardown/Estimating Fee',
  Total: 'Total',
  Tow: 'Tow',
  'Tow Type': 'Tow Type',
  Towable: 'Towable',
  'VIN #': 'VIN #',
  'Vehicle Type': 'Vehicle Type',
  'Yard/Gate': 'Yard/Gate',
  Year: 'Year',
  Zip: 'Zip',
  Zip1: 'Zip1',
  'Zip 1': 'Zip 1',
  Zip2: 'Zip2',
  'Zip 2': 'Zip 2',
  Date: 'Date',
  Username: 'Username',
  Notes: 'Notes',
  Field: 'Field',
  'Old-Value': 'Old-Value',
  'New-Value': 'New-Value',
  'Charge Description': 'Description',
  'Enter Lot # / Lots ending with': 'Enter Lot # / Lots ending with',
  'Enter City': 'Enter City',
  'Enter Zip code': 'Enter Zip Code',
  'Enter Postal Code - Seperated': 'Enter Postal Code - Seperated',
  'Enter lot location': 'Enter lot location',
  Location: 'Location',
  'Filter By': 'Filter By',
  'Lot Result Count': 'Lot Result Count',
  'Delivery Phone 2 #': 'Delivery Phone 2 #',
  Labor: 'Labor',
  'Lot Notes': 'Lot Notes',
  'Billing Notes': 'Billing Notes',
  'Dispatch Notes': 'Dispatch Notes',
  'Seller Notes': 'Seller Notes',
  'Title Notes': 'Title Notes',
  'Buyer Notes': 'Buyer Notes',
  'SAVE & CLEAR HOLD': 'SAVE & CLEAR HOLD',
  'CLEAR HOLD': 'CLEAR HOLD',
  PREVIOUS: 'PREVIOUS',
  CLOSE: 'CLOSE',
  NEXT: 'NEXT',
  'LOT NOTES': 'LOT NOTES',
  'DISPATCH NOTES': 'DISPATCH NOTES',
  'PICKUP CLEARANCE': 'PICKUP CLEARANCE',
  'ADDITIONAL INFORMATION': 'ADDITIONAL INFORMATION',
  'CHARGES ENTRY': 'CHARGES ENTRY',
  'PROCEED TO CLEAR CHARGES': 'PROCEED TO CLEAR CHARGES',
  FINISH: 'FINISH',
  'MOVE TO CLEAR CHARGES': 'MOVE TO CLEAR CHARGES',
  'MOVE LOT TO DISPATCH': 'MOVE LOT TO DISPATCH',
  'SAVE & CLOSE': 'SAVE & CLOSE',
  SAVE: 'SAVE',
  LOCK: 'LOCK',
  'LOT #': 'LOT #',
  AGE: 'AGE',
  YEAR: 'YEAR',
  MAKE: 'MAKE',
  MODEL: 'MODEL',
  COLOR: 'COLOR',
  BALANCE: 'BALANCE',
  HOLD: 'HOLD',
  'PICKUP CLEARED': 'PICKUP CLEARED',
  COMMENT: 'COMMENT',
  'LOT LOCATION': 'LOT LOCATION',
  SELLER: 'SELLER',
  'FOLLOW UP DUE IN': 'FOLLOW UP DUE IN',
  'Member Information': 'Member Information',
  'Member Settlement': 'Member Settlement',
  'Member Billing': 'Member Billing',
  'Seller Billing': 'Seller Billing',
  'Authorization Number': 'Authorization Number',
  'Authorized By': 'Authorized By',
  // snack bar messages
  CFRLockedByYou: 'locked by you using OPS Portal.',
  CFRLockedByYouOtherApp: 'locked by you from some other application.',
  'locked by': 'locked by',
  Logout: 'Logout',
  'Copart Charges': 'Copart Charges',
  'Member Charges': 'Member Charges',
  // other messages
  'No, stay': 'No, stay',
  'No, Stay': 'No, Stay',
  'Yes, leave': 'Yes, leave',
  'Yes, Leave': 'Yes, Leave',
  No: 'No',
  Yes: 'Yes',
  'Enter Pickup Address here': 'Enter Pickup Address here',
  zipMisMatch: 'Zip does not match City and State. Please Check.',
  negotiatedTotalError: 'Total should be <= Anticipated Total',
  exitDialog: 'All the unsaved changes will be lost. Do you want to leave?',
  sessionInactivityCheck: 'Have you completed working on the lot?',
  unlockSuccessfulMessage: 'Successfully Unlocked Lot',
  AddLotNotes: 'Add Lot Notes. Press Enter for new line',
  CFRTowToolTip:
    'Charges Breakdown: Tow, Winch, Rollover, 2nd Tow, Recovery, Mileage, After Hours, Wait Time, Hook Up, Fuel',
  CFRLaborToolTip: 'Charges Breakdown: Labor, Extra Man, Clean Up, Tarp',
  CFRYardToolTip:
    'Charges Breakdown: Admin. Fee, City Fee, Service Fee, Impound Fee, Pull Out, Dispatch, Move Out, Outside Charges',
  CFRStorageToolTip: 'Storage by Day',
  CFRNegotiatedToolTip: 'Charges Breakdown: Negotiated Storage',
  CFRTeardownToolTip: 'Charges Breakdown: Teardown, Estimate, Diagnostic, Inspection',
  CFRMiscToolTip:
    'Charges Breakdown: Oil Dry, Tickets, Haz Mat, Fire Ext., Parts, Letters, Repo Fee, DMV Paperwork, PST, Lien Fee, Shop Markup',
  CFRTaxToolTip: 'Charges Breakdown: Tax, GST',
  approveServiceOrder: 'Approve Service Order',
  rejectServiceOrder: 'Reject Service Order',
  completeServiceOrder: 'Complete Service Order',
  // Event Notes changes:
  PINOC: 'Pickup not cleared',
  MBABN: 'Member payment applied by ${NAME}',
  DNDFL: 'Driver ${NAME} dispatched for ${LOT_NUMBER} on ${DATE}',
  ASTAA: 'Assigned to auction ${AUCTION_ID}',
  TCOTD: 'Trip confirmed on ${DATE} Trip date is ${DATE}',
  INCOB: 'Inventory completed by ${NAME}',
  PICOB: 'Pickup completed by ${NAME}',
  LOLEY: 'Lot left yard ${DATE}',
  SDGTM: 'Sale documents ${DOCUMENTS_NAME} given to member ${NAME}',
  ADSSA: 'Additional service ${SERVICE_ORDER_NAME} added',
  CLFOP: 'Cleared for pickup ${DATE}',
  CCLPC: 'Charges cleared for ${LOT_NUMBER} - Pickup cleared on ${DATE}',
  ROMCF: 'Received odometer mileage changed from ${NAME_OF_THE_ATTRIBUTE}to ${NAME_OF_THE_ATTRIBUTE}',
  ARBRF: '${NAME_OF_THE_ATTRIBUTE} Rejected by ${NAME} - Rerun from ${DATE}',
  PLCFN: 'Pickup location changed from ${NAME} to ${NAME}',
  PAVEN: 'PAV entered',
  SEPAA: 'Seller payment applied',
  BIABN: 'Bid approval by ${NAME}',
  PCNCA: 'Pickup cleared, no charged allowed',
  MBCDF: 'Minimum bid changed by ${NAME} from ${NAME_OF_THE_ATTRIBUTE} to ${NAME_OF_THE_ATTRIBUTE}',
  RFAAY: 'Removed from ${AUCTION_ID} at yard ${NAME} while in sale area',
  SSCFN: 'Simple seller change from ${NAME} to ${NAME}',
  THBOL: 'The high bid on this lot was ${NAME_OF_THE_ATTRIBUTE}',
  BTBRA: 'Bid to be approved requested at assignment entry',
  LOCAD: 'Lot cancelled ${DATE}',
  LUFPC: 'Lot uncleared for pickup and charges',
  LWRFN: 'Lot was automatically relisted from ${NAME} on sale ${DATE} . High bid was ${NAME_OF_THE_ATTRIBUTE}',
  LODON: 'Lot discrepancy on ${NAME_OF_THE_ATTRIBUTE}',
  ORTBO: 'Owner retained- to be picked up by owner',
  BINTL: 'Buy it now was used to win this lot',
  REFSH: 'Released from Sale hold',
  HOFSD: 'Hold for sale ${DATE}',
  MBEAT: 'Minimum bid entered at the time of assignment ${NAME_OF_THE_ATTRIBUTE}',
  IABCD: 'Inventory and billing completed ${DATE}',
  PAVCL: 'PAV cleared',
  SDACD: 'Sale documents at Copart ${DATE}',
  OWRLO: 'Owner retained - Lot offsite',
  OSLCB: 'Offsite sale lot created by ${NAME} at time (UTC)',
  UTASD: 'Unable to assign to sale due to outstanding high priority service order(s)',
  'Germany Sales Admin': 'Germany Sales Admin',
  'Germany Mihub': 'Germany Mihub',
  'Germany Logistics': 'Germany Logistics',
  'Germany Executive': 'Germany Executive',
  'Germany General Setup': 'Germany General Setup',
  'Germany Managing Director': 'Germany Managing Director',
  'Germany Restricted Pii Access': 'Germany Restricted Pii Access',
  'Germany Office Employee': 'Germany Office Employee',
  'Germany Sales Executive': 'Germany Sales Executive',
  'Germany Vp Of Sales International': 'Germany Vp Of Sales International',
  'Germany Audit Finance': 'Germany Audit Finance',
  'Spain Office Employee': 'Spain Office Employee',
  'Spain Yard Employee': 'Spain Yard Employee',
  'Spain Executive': 'Spain Executive',
  'Spain Manager': 'Spain Manager',
  'Spain Managing Director': 'Spain Managing Director',
  'Spain Sales Executive': 'Spain Sales Executive',
  'Spain Vp Of Sales International': 'Spain Vp Of Sales International',
  'Spain Audit Finance': 'Spain Audit Finance',
  'Spain Mihub': 'Spain Mihub',
  'India Office Employee': 'India Office Employee',
  'India Yard Employee': 'India Yard Employee',
  'India Office Clerk': 'India Office Clerk',
  'India Executive': 'India Executive',
  'India Manager': 'India Manager',
  'India Managing Director': 'India Managing Director',
  'India Sales Executive': 'India Sales Executive',
  'India Vp Of Sales International': 'India Vp Of Sales International',
  'India Audit Finance': 'India Audit Finance',
  'India Mihub': 'India Mihub',
  'India Mihub Executive': 'India Mihub Executive',
  'Germany Mihub Executive': 'Germany Mihub Executive',
  'Germany Gbp': 'Germany Gbp',
  'Spain Gbp': 'Spain Gbp',
  'India Gbp': 'India Gbp',
  'Spain Mihub Executive': 'Spain Mihub Executive',
  authorizationFailed:
    'Authorization and database services are currently down. Please contact Helpdesk for assistance.',
  noRoleMessage: 'No Roles assigned. Please contact Helpdesk to get some roles assigned to you.',
  noYardMessage: 'No Yards assigned. Please contact Helpdesk to get some yards assigned to you.',
  driverNotAllowed: 'User not authorized.',
  insuranceExpiration: 'Your insurance expires in ${DAYS} days.',
  Sale: 'Sale',
  'Lot #': 'Lot',
  VIN: 'VIN',
  Status: 'Status',
  'License Plate': 'License Plate',
  'Address Line 1': 'Address Line 1',
  'Promised Trip': 'Promised Trip',
  'Ok To Pay Charges?': 'Ok To Pay Charges',
  'Charges Paid?': 'Charges Paid',
  'Towable?': 'Towable',
  'Pickup Hold': 'Pickup Hold',
  'Sale Hold': 'Sale Hold',
  'Trip Confirmed': 'Trip Confirmed',
  Pricing: 'Pricing',
  'Scheduled Trip': 'Scheduled Trip',
  'Assignment Date': 'Assignment Date',
  Row: 'Row',
  Source: 'Source',
  'Pickup Hold Reason': 'Pickup Hold Reason',
  'Item Type': 'Item Type',
  'Run Condition': 'Run Condition',
  Reruns: 'Reruns',
  Relists: 'Relists',
  'Hold For Sale': 'Hold For Sale',
  'Follow up': 'Follow up',
  'High Bid %': 'High Bid %',
  Member: 'Member',
  'Lot Status': 'Lot Status',
  'Validation Type': 'Validation Type',
  memberFee: 'Member Fee',
  GST: 'GST',
  VAT: 'VAT',
  'Total GST': 'Total GST',
  'Total VAT': 'Total VAT',
  'Goods & Services Tax Eligible?': 'Goods & Services Tax Eligible?',
  'Owners GST ID': "Owner's GST ID/Fiscal ID",
  'Insured GST ID': 'Insured GST ID',
  'Follow Up': 'Follow Up',
  Lock: 'Lock',
  'Member Paid': 'Member Paid',
  'Member Pricing': 'Member Pricing',
  'Left Facility': 'Left Facility',
  'Documents Given to Member': 'Documents Given to Member',
  'Proof of Transport': 'Proof of Transport',
  'Seller Billing Complete': 'Seller Billing Complete',
  Claim: 'Claim',
  'GST ID/Fiscal ID': 'GST ID/Fiscal ID',
  'GST ID': 'GST ID',
  'All Facilities': 'All Facilities',
  'Select to search from all facilities': 'Select to search from all facilities',
  'If not selected, it will search selected facilities only':
    'If not selected, it will search selected facilities only',
  Language: 'Language',
  'VIN/ID': 'VIN/ID',
  'Identification Number': 'Identification Number',
  'Hull Number': 'Hull Number',
  'Vehicle Identification Number': 'Vehicle Identification Number',
  'Facility Details': 'Facility Details',
  'Facility ID': 'Facility ID',
  Address: 'Address',
  'General Manager': 'General Manager',
  'Time Zone': 'Time Zone',
  Close: 'Close',
  'No Service fees to show': 'No Service fees to show',
  'Lot Status Code': 'Lot Status Code',
  'Seller Company': 'Seller Company',
  'Loss Date': 'Loss Date',
  'Title Group': 'Title Group',
  'Title Description': 'Title Description',
  'Primary Damage': 'Primary Damage',
  'Secondary Damage': 'Secondary Damage',
  'Drivability Rating': 'Drivability Rating',
  'Mileage Range': 'Mileage Range',
  'Repair Estimate': 'Repair Estimate',
  'Sale State / Province': 'Sale State / Province',
  'Title State / Province': 'Title State / Province',
  Body: 'Body',
  'Drive Train': 'Drive Train',
  'Fuel Type': 'Fuel Type',
  'Service Order Name': 'Service Order Name',
  Priority: 'Priority',
  'Discrepancy Type': 'Discrepancy Type',
  'Document Processing Type': 'Document Processing Type',
  'Sale Date Range': 'Sale Date Range',
  'Claim Number': 'Claim Number',
  processingType: 'Processing Type',
  'Service Order Updated Date': 'Updated Date',
  'Lot Discrepancies': 'Lot Discrepancies',
  'Service Type': 'Service Type',
  'Date Completed': 'Date Completed',
  'Pricing Date': 'Pricing Date',
  'Approval Amount': 'Approval Amount',
  'Approval Date': 'Approval Date',
  'Approval Comment': 'Approval Comment',
  'Latest Price': 'Latest Price',
  Price: 'Price',
  'Number of Hours': 'Number of Hours',
  Units: 'Units',
  Cost: 'Cost',
  'Invoice Comments': 'Invoice Comments',
  Instructions: 'Instructions',
  'Close Type': 'Close Type',
  PAV: 'PAV',
  'Loss Information': 'Loss Information',
  'Pickup Location': 'Pickup Location',
  'First Name is required': 'First Name is required',
  'Last Name is required': 'Last Name is required',
  'Email is required': 'Email is required',
  'Phone is required': 'Phone is required',
  'Phone must be minimum of 9 digits': 'Phone must be minimum of 9 digits',
  'Alt. Phone must be minimum of 9 digits': 'Alt. Phone must be minimum of 9 digits',
  'Review and Submit': 'Review and Submit',
  Collapse: 'Collapse',
  Expand: 'Expand',
  'Please provide us your feedback!': 'Please provide us your feedback!',
  'Include Email': 'Include Email',
  'Please write your feedback': 'Please write your feedback',
  'Filter Options': 'Filter Options',
  'Lot Result Count:': 'Lot Result Count:',
  Process: 'Process',
  'Issue Type': 'Issue Type',
  closeType: 'Close Type',
  'Lot Search': 'Lot Search',
  'Select Operation': 'Select Operation',
  'Select Field': 'Select Field',
  'Clear All': 'Clear All',
  'Mass Updates Errors': 'Mass Updates Errors',
  'Click to Edit.': 'Click to Edit.',
  'Not Editable.': 'Not Editable.',
  'Search (F2)': 'Search (F2)',
  Lots: 'Lots',
  'Seller Specific Instructions': 'Seller Instructions',
  'Service Instructions': 'Service Instructions',
  'Click here to change Yard': 'Click here to change Yard',
  'Click here to change Facility': 'Click here to change Facility',
  'Lot Life Cycle': 'Lot Management',
  Vendors: 'Vendors',
  'Lot Management': 'Lot Management',
  'Payments & Accounting': 'Payments & Accounting',
  'Management Tools': 'Management Tools',
  'Reporting & Metrics': 'Reporting & Metrics',
  Utilities: 'Utilities',
  'IT Functions': 'IT Functions',
  'Account Maintenance': 'Account Maintenance',
  'Contract Pricing': 'Contract & Pricing',
  Facilities: 'Facilities',
  Locations: 'Locations',
  Members: 'Members',
  Owners: 'Owners',
  Sellers: 'Sellers',
  'Seller Companies': 'Seller Companies',
  'Seller Personnel': 'Seller Personnel',
  'Tow Providers': 'Tow Providers',
  Employees: 'Employees',
  'Service Templates': 'Service Templates',
  Agricultural: 'Agricultural',
  'All-terrain / Utility Vehicle': 'All-terrain / Utility Vehicle',
  Automobile: 'Automobile',
  Boat: 'Bus',
  Bus: 'Bus',
  'Caravan/Camper': 'Caravan/Camper',
  Commodity: 'Commodity',
  'Concrete Truck': 'Concrete Truck',
  Crane: 'Crane',
  'Dumper (non-Road)': 'Dumper (non-Road)',
  'Fire Engine': 'Fire Engine',
  Golfcart: 'Golfcart',
  'Jet Ski': 'Jet Ski',
  'Moped/Scooter': 'Moped/Scooter',
  'Motorcycle - Offroad': 'Motorcycle - Offroad',
  'Motorcycle - Street Bike': 'Motorcycle - Street Bike',
  'Other Good': 'Other Good',
  'Pallet Lifter': 'Pallet Lifter',
  'Plant/Industrial Equipment': 'Plant/Industrial Equipment',
  'Recreational Vehicle (RV)': 'Recreational Vehicle (RV)',
  Snowmobile: 'Snowmobile',
  'Trailer - Commercial': 'Trailer - Commercial',
  'Trailer - Personal': 'Trailer - Personal',
  'Truck - Heavy Duty': 'Truck - Heavy Duty',
  'Truck - Medium Duty/Box': 'Truck - Medium Duty/Box',
  'Processing lots': 'Processing lots:',
  'Add New Owner': 'Add New Owner',
  'Add New Location': 'Add New Location',
  'Check Price': 'Check Price',
  'Print Service Order': 'Print Service Order',
  'No Member fees to show': 'No Member fees to show',
  'Owner Setup': 'Owner Setup',
  'Access/Permissions': 'Access/Permissions',
  'Bug/Problem': 'Bug/Problem',
  'Missing Functionality': 'Missing Functionality',
  Comment: 'Comment',
  Improvement: 'Improvement',
  Question: 'Question',
  Other: 'Other',
  'Calculate Price': 'Calculate Price',
  'No storage fees to show': 'No storage fees to show',
  'No Seller fees to show': 'No Seller fees to show',
  'Please fill in the missing information': 'Please fill in the missing information',
  'Please ensure that you are uploading an image of size 20Mb or less, 640 * 480 minimum resolution.':
    'Please ensure that you are uploading an image of size 20Mb or less, 640 * 480 minimum resolution. ',
  'Accepted Image types are': 'Accepted Image types are ',
  'Please ensure that you are uploading a document of size 20Mb or less.':
    'Please ensure that you are uploading a document of size 20Mb or less. ',
  'Accepted document types are': 'Accepted document types are ',
  'Add New Document': 'Add New Document',
  'Refresh Files': 'Refresh Files',
  'Upload New Image': 'Upload New Image',
  'Upload New Document': 'Upload New Document',
  'Select Files': 'Select Files',
  All: 'All',
  'Failed to load Invoices': 'Failed to load Invoices',
  'Failed to load Images': 'Failed to load Images',
  'Failed to load lot Documents': 'Failed to load lot Documents',
  'Failed to update lot Document type': 'Failed to update lot Document type',
  'There was trouble unlocking this lot': 'There was trouble unlocking this lot',
  'Locking failed': 'Locking failed',
  'Transition successful': 'Transition successful',
  'There was a problem transitioning this lot': 'There was a problem transitioning this lot',
  'Lot is awarded to': 'Lot is awarded to',
  for: 'for',
  'Note successfully added': 'Note successfully added',
  'There was a problem adding note.': 'There was a problem adding note.',
  'Please enter a price manually for this service order.': 'Please enter a price manually for this service order.',
  'Settings Saved': 'Settings Saved',
  'Could not load seller data': 'Could not load seller data',
  'Please refresh the page': 'Please refresh the page',
  Prev: 'Prev',
  Next: 'Next',
  Previous: 'Previous',
  Current: 'Current',
  'What It Is Right Now': 'What It Is Right Now',
  'What It Should Be': 'What It Should Be',
  'Lots Having': 'Lots Having',
  Description: 'Description',
  Approve: 'Approve',
  'Approve & Next': 'Approve & Next',
  Non: 'Non',
  Insurance: 'Insurance',
  'Custom Make': 'Custom Make',
  'Custom Model': 'Custom Model',
  'Custom Trim': 'Custom Trim',
  'Storage Daily Amount': 'Storage Daily Amount',
  'Other Adv. Charges': 'Other Adv. Charges',
  'Invalid Year of Manufacture': 'Invalid Year of Manufacture',
  'Invalid First Registration Date': 'Invalid First Registration Date',
  Flat: 'Flat',
  Daily: 'Daily',
  Showing: 'Showing',
  Results: 'Results',
  Go: 'Go',
  Of: 'Of',
  goToPage: 'Go To Page',
  'Advanced Charge Payment': 'Advanced Charge Payment',
  'Actual Odometer': 'Actual Odometer',
  High: 'High',
  Standard: 'Standard',
  'Address Line 2': 'Address Line 2',
  'No Notes for this Lot found': 'No Notes for this Lot found',
  'No Advanced Charge Payments to show': 'No Advanced Charge Payments to show',
  'Please enter a decimal value': 'Please enter a decimal value',
  'Please enter only numbers or letters': 'Please enter only numbers or letters',
  'Please enter a currency value': 'Please enter a currency value',
  'Please select one of the provided options': 'Please select one of the provided options',
  'Exceeds maximum value': 'Exceeds maximum value',
  'Less than minimum value': 'Less than minimum value',
  'Please enter no more than': 'Please enter no more than',
  'Please enter no fewer than': 'Please enter no fewer than',
  characters: 'characters',
  Displacement: 'Displacement',
  'There was trouble fetching permissions for your account': 'There was trouble fetching permissions for your account',
  Verify: 'Verify',
  'Bill Type Code': 'Bill Type Code',
  'Light Damage': 'Light Damage',
  'Moderate Damage': 'Moderate Damage',
  'Severe Damage': 'Severe Damage',
  'Collapse All Filters': 'Collapse All Filters',
  'Open All Filters': 'Open All Filters',
  'Print Date': 'Print Date',
  User: 'User',
  'Received Date': 'Received Date',
  'Service Orders to be Completed': 'Service Orders to be Completed',
  'Service Eligible Date': 'Service Eligible Date',
  Comments: 'Comments',
  'Bid Date': 'Bid Date',
  'Bid Time': 'Bid Time',
  'Bid Amount': 'Bid Amount',
  'Bid Type': 'Bid Type',
  'Audit Logs': 'Audit Logs',
  'Event Notes': 'Event Notes',
  'All Notes': 'All Notes',
  'No lots were found for this search': 'No lots were found for this search',
  'Please select': 'Please select',
  'to search in all facilities': 'to search in all facilities',
  Multiple: 'Multiple',
  Open: 'Open',
  Pending: 'Pending',
  Optional: 'Optional',
  Completed: 'Completed',
  Rejected: 'Rejected',
  'Service Order Price Changed': 'Service Order Price Changed',
  'Make Model Trim Changed': 'Make Model Trim Changed',
  'Model Year Changed': 'Model Year Changed',
  'Service Order Status Changed': 'Service Order Status Changed',
  'Damage Type Changed': 'Damage Type Changed',
  'Storage Total': 'Storage Total',
  'Facility Name': 'Facility Name',
  'Seller Fees': 'Seller Fees',
  'Seller Charge Type': 'Seller Charge Type',
  'Member Fees': 'Member Fees',
  'Member Charge Type': 'Member Charge Type',
  Starting: 'Starting',
  Login: 'Login',
  Register: 'Register',
  'Please choose an appropriate yard': 'Please choose an appropriate yard',
  'Member Billing Complete': 'Member Billing Complete',
  Export: 'Export',
  'Add New Destination Address': 'Add New Destination Address',
  'Cannot Add New Destination Address': 'Cannot Add New Destination Address',
  'Edit Image': 'Edit Image',
  'Cannot Edit Image': 'Cannot Edit Image',
  'Cannot Edit Document Type': 'Cannot Edit Document Type',
  'Delete Image': 'Delete Image',
  'Delete Document': 'Delete Document',
  'Document deleted': 'Document deleted',
  'Image deleted successfully': 'Image deleted successfully',
  'Failed to delete Image': 'Failed to delete Image',
  'Cannot Delete Document': 'Cannot Delete Document',
  'Cannot display .tiff or .xps files': 'Cannot display .tiff or .xps files',
  '.XPS and .TIFF files cannot be previewed': '.XPS and .TIFF files cannot be previewed',
  'Cannot Delete Image': 'Cannot Delete Image',
  'Add New Image': 'Add New Image',
  'Cannot Upload New Image for this Lot. Lot Stage Violation':
    'Cannot Upload New Image for this Lot. Lot Stage Violation',
  'No Seller Storage fees to show': 'No Seller Storage fees to show',
  'Claim Reported Date': 'Claim Reported Date',
  'Completion Date': 'Completion Date',
  'Certificate Number': 'Certificate Number',
  Brand: 'Brand',
  'Max Length Reached': 'Max Length of 9999 characters reached',
  'Blank Note alert': 'Blank note cannot be added.',
  'Towing (Gross Amount Due)': 'Towing (Gross Amount Due)',
  'Labor (Gross Amount Due)': 'Labor (Gross Amount Due)',
  'Facility Gate (Gross Amount Due)': 'Facility Gate (Gross Amount Due)',
  'Tear Down Est (Gross Amount Due)': 'Tear Down Est (Gross Amount Due)',
  'Other Adv. charges (Gross Amount Due)': 'Other Adv. charges (Gross Amount Due)',
  'Storage Amount (Gross Amount Due)': 'Storage Amount (Gross Amount Due)',
  'Storage Daily Amount (Gross Amount Due)': 'Storage Daily Amount (Gross Amount Due)',
  'Management Dashboard': 'Management Dashboard',
  duplicateVinForOpenedLot: 'VIN already exists for open Lot:',
  duplicateVinForClosedLot: 'VIN already exists for closed Lot:',
  'Starting Date': 'Starting Date',
  'End Date': 'End Date',
  'Send Report': 'Send Report',
  'Report Type': 'Report Type',
  'Lots Assigned': 'Lots Assigned',
  'Lots Sold': 'Lots Sold',
  'Lots Closed': 'Lots Closed',
  'Extract Type': 'Extract Type',
  'Please select criteria to get your Extracts': 'Please select criteria to get your extracts',
  'File format not supported': 'File format not supported',
  'Image Resolution should be at least 640*480.': 'Image Resolution should be at least 640*480.',
  'File Size Exceeded. 20MB Max.': 'File Size Exceeded. 20MB Max.',
  'Removed files that do not meet rules. 20MB Max and 640*480 Min Resolution.':
    'Removed files that do not meet rules. 20MB Max and 640*480 Min Resolution.',
  'No Files Selected.': 'No Files Selected.',
  Documents: 'Documents',
  Step: 'Step',
  Images: 'Images',
  'Lot Rerun Approved by*': 'Lot Rerun Approved by*',
  'Rerun Reason*': 'Rerun Reason*',
  'Click to Copy Lot Number': 'Click to Copy Lot Number',
  'Error fetching': 'Error fetching',
  data: 'data',
  'Total Price': 'Total Price',
  Reset: 'Reset',
  'Title Direct Issue Comment': 'Title Direct Issue Comment',
  'Use Comment in Mail': 'Use Comment in Mail',
  'Seller TD Email': 'Seller TD Email',
  'Additional Email 1': 'Additional Email 1',
  'Additional Email 2': 'Additional Email 2',
  'Rejection Action': 'Rejection Action',
  'Auction Date': 'Auction Date',
  'Hold/Action Date': 'Hold/Action Date',
  'Title Direct': 'Title Direct',
  'Title Rejected Reason': 'Title Rejected Reason',
  'Title Direct Rejected Reason': 'Title Direct Rejected Reason',
  'Next Notification Date': 'Next Notification Date',
  'Sale Yard': 'Sale Yard',
  'Odometer Branding': 'Odometer Branding',
  'Submitted Date': 'Submitted Date',
  'Title Procurement': 'Title Procurement',
  'Certificate/Title Type': 'Certificate/Title Type',
  'Certificate/Title Number': 'Certificate/Title Number',
  'Sent To Buyer Date': 'Sent To Buyer Date',
  'Power Of Attorney': 'Power Of Attorney',
  'Amount Due': 'Amount Due',
  'Mail Title Documents': 'Mail Title Documents',
  'Seller#': 'Seller#',
  'Seller Name': 'Seller Name',
  'Seller Title Name': 'Seller Title Name',
  'Buyer Number': 'Buyer Number',
  Interval: 'Interval',
  Who: 'Who',
  What: 'What',
  Note: 'Note',
  save: 'save',
  switchLot: 'switchLot',
  detach: 'detach',
  Warning: 'Warning',
  'Skip to Next Lot': 'Skip to Next Lot',
  'Previous Lot': 'Previous Lot',
  'Title Direct Issue': 'Title Direct Issue',
  'Detach Title Confirmation': 'Detach Title Confirmation',
  'No Sale Title Image attached to this Lot': 'No Sale Title Image attached to this Lot',
  'Lot Data not found, please click on switch lot to search a lot manually':
    'Lot Data not found, please click on switch lot to search a lot manually',
  'Error Retrieving Lot Data': 'Error Retrieving Lot Data',
  'This field is required': 'This field is required',
  'Detach Failed, Please Try Again': 'Detach Failed, Please Try Again',
  'Do You want to detach the title from this Lot?': 'Do You want to detach the title from this Lot?',
  'Remove either Title Number or Rejection issue': 'Remove either Title Number or Rejection issue',
  'Remove lot from auction by placing on Hold for Sale?':
    'You are about to remove this lot from auction by placing on Hold for Sale?',
  'Outstand Title Direct issue exists': 'Outstand Title Direct issue exists',
  'Do you want to clear the salvage certificate by entering the rejection code?':
    'Do you want to clear the salvage certificate by entering the rejection code?',
  'Are you sure you want to detach the image': 'Are you sure you want to detach the image from the lot?',
  'Detaching will move the image to the unassigned queue':
    'Note: Detaching will move the image to the unassigned queue',
  'Special characters not allowed': 'Special characters not allowed',
  'Use Suggested': 'Use Suggested',
  'No continue': 'No continue',
  'Please select correct option': 'Please select correct option',
  pickupSuggestionNotMatch: 'Pickup Address does not match with google address.',
  'Print range is outside assigned check range.': 'Print range is outside assigned check range.',
  'Number of Advance Bar Codes': 'Number of Advance Bar Codes',
  'Max Limit $9999.99': 'Max Limit $9999.99',
  'Please select at least one data point!': 'Please select at least one data point!',
  newTemplateSelectConfirmation: 'Selecting a new template will change datapoints. Are you sure you want to proceed?',
  'Available Data Points': 'Available Data Points',
  'First 10 datapoints are mandatory': 'First 10 datapoints are mandatory',
  'Selected Data Points': 'Selected Data Points',
  'Please select at least one Datapoint.': 'Please select at least one Datapoint.',
  'Data Points': 'Data Points',
  'Failed to retrieve delivery clearance additional information data.':
    'Failed to retrieve delivery clearance additional information data.',
  "Lot Number can't be empty.": "Lot Number can't be empty.",
  'Failed to retrieve delivery clearance information.': 'Failed to retrieve delivery clearance information.',
  'Successfully saved delivery clearance information.': 'Successfully saved delivery clearance information.',
  'Unable to find serviceJsonPaths for: ': 'Unable to find serviceJsonPaths for: ',
  'Package Name': 'Package Name',
  'Template Name': 'Template Name',
  'Start Date': 'Start Date',
  'Select Option': 'Select Option',
  'Seller Company Code': 'Seller Company Code',
  'Contact Email': 'Contact Email',
  'SFTP Host': 'SFTP Host',
  'SFTP Username': 'SFTP Username',
  'SFTP Password': 'SFTP Password',
  'SFTP Port': 'SFTP Port',
  'Charge amounts on the Checks may be recalculated based on Storage Rates':
    'Charge amounts on the Checks may be recalculated based on Storage Rates',
  'Change Yard': 'Change Yard',
  fetchClosestYardFailed: 'Unable to fetch closest yard. Please try again later',
  'Successfully changed yard': 'Successfully changed yard',
  changeYardFailed: 'Unable to changed yard',
  changingYard: 'Changing Yard',
  'Invalid Charge Amount': 'No detail charge amount can exceed 9999.99 .',
  'Difference is not zero': 'Difference is not zero',
  'Add Card Payment': 'Add Card Payment',
  'Add Check Payment': 'Add Check Payment',
  'Maintain Trip': 'Maintain Trip',
  'Unclear Lot': 'Unclear Lot',
  'Remove Driver': 'Remove Driver',
  'Confirm Trip': 'Confirm Trip',
  'Unconfirm Trip': 'Unconfirm Trip',
  'Driver Assignment Complete': 'Driver Assignment Complete',
  saving: 'saving',
  saveError: 'Failed to save, please try again',
  saved: 'Changes have been successfully saved',
  detaching: 'Detaching',
  detachError: 'Failed to detach, please try again',
  detached: 'Document was successfully detached',
  updating: 'Updating',
  dataLoaded: 'Data was successfully loaded',
  lotDataFetchError: 'Failed to retrieve data, please try again',
  queueFetching: 'Fetching title queues',
  titleQueuesLoaded: 'Title Queues Loaded',
  queueFetchError: 'Failed to retrieve Queues, please try again',
  rejectionClearProhibited: 'Reject Reason Code may only be changed, not cleared.',
  'Invalid input. This is a numeric field': 'Invalid input. This is a numeric field',
  getIntegerMaxLengthError: (args) => `max Length ${args[0]} exceeded`,
  getIntegerMaxValueError: (args) => `can't be more than ${args[0]}`,
  remove: 'remove',
  'The lot is locked by {0} using {1}. Please try another lot':
    'The lot is locked by {0} using {1}. Please try another lot',
  'Unable to acquire lock on the lot. Please try another lot.':
    'Unable to acquire lock on the lot. Please try another lot.',
  'Updating ...': 'Updating ...',
  'Data Saved.': 'Data Saved.',
  'Could not get data. Please refresh the page.': 'Could not get data. Please refresh the page.',
  'No Promoter has been assigned for this Yard - Lane': 'No Promoter has been assigned for this Yard - Lane',
  'Promoter Registered': 'Promoter Registered',
  'Promoter Unregistered': 'Promoter Unregistered',
  'The entered Promoter Email is invalid or you do not have the required access.':
    'The entered Promoter Email is invalid or you do not have the required access.',
  'Cannot update Promoter as the Auction is already Live!': 'Cannot update Promoter as the Auction is already Live!',
  'You might not have access to Production CAS. Please contact Helpdesk.':
    'You might not have access to Production CAS. Please contact Helpdesk.',
  'Service Order Added': 'Service Order Added',
  'Service Order Not Added: $1': 'Service Order Not Added: $1',
  'Unable to retrieve make,model and year for the entered vin':
    'Unable to retrieve make,model and year for the entered vin',
  'Vin Decode Failed': 'Vin Decode Failed',
  'Email Sent Successfully.': 'Email Sent Successfully.',
  'Unable to send email. Please try again.': 'Unable to send email. Please try again.',
  'Loading ...': 'Loading ...',
  'Unable to update whether this has been reviewed or not': 'Unable to update whether this has been reviewed or not',
  'Unable to get whether lot has been reviewed or not': 'Unable to get whether lot has been reviewed or not',
  'Lock timed out due to system inactivity!!': 'Lock timed out due to system inactivity!!',
  'Driver removed for lot ': 'Driver was successfully removed from Lot ',
  'Lot {0} was successfully uncleared for pickup and charges':
    'Lot {0} was successfully uncleared for pickup and charges',
  'Unconfirm Trip Failed': 'Unconfirm Trip Failed',
  'Trip has successfully been unconfirmed for Lot {0}.': 'Trip has successfully been unconfirmed for Lot {0}.',
  'Fetching P card details failed': 'Fetching P card details failed',
  'Printing Failed': 'Printing Failed',
  'Lot not in Current Yard': 'Lot not in Current Yard',
  'Invalid Lot Number': 'Invalid Lot Number',
  getACGLockMessage: (args) => `Advance Check Generation Printer is locked by ${args[0]} in ${args[1]}`,
  Broadcast: 'Broadcast',
  'Unable to fetch lot info': 'Unable to fetch lot info',
  'Invalid lot number entered': 'Invalid lot number entered',
  'Unable to Load Trip Info': 'Unable to Load Trip Info',
  'Credit card not valid for given subhauler.': 'Credit Card not valid for given subhauler',
  'Check not valid.': 'Check not valid.',
  'Check numbers are not unique.': 'Check numbers are not unique.',
  'Charges difference is not zero.': 'Charges difference is not zero.',
  ACGExitDialog: 'Are you sure you want to leave this menu without confirming if checks printed correctly?',
  ACGUncheckedConfirmation:
    'The following checks are not verified : ${pendingReviewChecks}. The checks will be voided. Do you want to continue ?',
  'Vehicle qualifies for Primary Run Lights — Red': 'Vehicle qualifies for Primary Run Lights — Red',
  feedbackEnhancementMessage: 'Please enter your enhancement ideas in Innovation Forum at',
  'Unable to Remove Driver': 'Unable to Remove Driver',
  'Unable to Unclear Lot': 'Unable to Unclear Lot',
  'Trip confirmed for lot {0} successfully': 'Trip confirmed for lot {0} successfully',
  'Unable to confirm Trip': 'Unable to confirm Trip',
  'Trip Details saved for lot {0}': 'Trip Details saved for lot {0}',
  'Unable to Save Trip Details': 'Unable to Save Trip Details',
  'If Check # is entered, provide amount greater than zero.':
    'If Check # is entered, provide amount greater than zero.',
  'If CCard # is entered, provide amount greater than zero.':
    'If CCard # is entered, provide amount greater than zero.',
  'No subhauler entered.': 'No subhauler entered.',
  'Subhauler is not active.': 'Subhauler is not active.',
  'Subhauler insurance expired.': 'Subhauler insurance expired.',
  'Cash control system used. Lot: ${lotNumber}, Check: ${checkNumber}.':
    'Cash control system used. Lot: ${lotNumber}, Check: ${checkNumber}.',
  'The amount entered will override the negotiated tow rates.':
    'The amount entered will override the negotiated tow rates.',
  'Some lots are used by other users. Please select different lots':
    'Some lots are used by other users. Please select different lots',
  'Lot Number should be valid': 'Lot Number should be valid',
  'Oops, something went wrong. Try again later.': 'Oops, something went wrong. Try again later.',
  'Following check will be voided ${checkNumber}.': 'Following check will be voided ${checkNumber}.',
  'Pending Images/Documents Review': 'Pending Images/Documents Review',
  'Declined Trip, taken back Sucessfully': 'Declined Trip, taken back Sucessfully',
  'Marked Issue as Read': 'Marked Issue as Read',
  // Missed translations
  'Search using Trip ID or Lot Number': 'Search using Trip ID or Lot Number',
  'Total Trips': 'Total Trips',
  'Selected Trips': 'Selected Trips',
  'List View': 'List View',
  'Map View': 'Map View',
  Trips: 'Trips',
  Drivers: 'Drivers',
  'Work Requests': 'Work Requests',
  'Cancel Work Request': 'Cancel Work Request',
  'Create Request': 'Create Request',
  'Change Password': 'Change Password',
  'Old Password': 'Old Password',
  'New Password': 'New Password',
  'Confirm Password': 'Confirm Password',
  Vendor: 'Vendor',
  Distribute: 'Distribute',
  'Request New PCard': 'Request New PCard',
  'Are you sure you want to destroy the pcard': 'Are you sure you want to destroy the pcard',
  Delete: 'Delete',
  'View Lot': 'View Lot',
  'Pickup Location Information': 'Pickup Location Information',
  'Destination Information': 'Destination Information',
  'Scheduled date & time': 'Scheduled date & time',
  'Charges Due': 'Charges Due',
  'Pick Up Notes': 'Pick Up Notes',
  'Direction Notes': 'Direction Notes',
  'Acknowledge Trip': 'Acknowledge Trip',
  'Cash With Driver': 'Cash With Driver',
  'Awaiting Cash': 'Awaiting Cash',
  'No Lots are available': 'No Lots are available',
  'Edit Personnel': 'Edit Personnel',
  'Create Personnel': 'Create Personnel',
  'Middle Name': 'Middle Name',
  'Contact Number': 'Contact Number',
  'Email Address': 'Email Address',
  Dispatchable: 'Dispatchable',
  'Select Yard': 'Select Yard',
  'Request Date': 'Request Date',
  'Number of cars': 'Number of cars',
  'Need trips for location in': 'Need trips for location in',
  Assigned: 'Assigned',
  'Issues Assigned To Me': 'Issues Assigned To Me',
  'Issues Reported By Me': 'Issues Reported By Me',
  'Declined Trips Assigned To Me': 'Declined Trips Assigned To Me',
  'Declined Trips Reported By Me': 'Declined Trips Reported By Me',
  Distributed: 'Distributed',
  'Assigned to Me': 'Assigned to Me',
  'Raised by Me': 'Raised by Me',
  'Last Week': 'Last Week',
  'Responsible Party Name': 'Responsible Party Name',
  'Is Residence': 'Is Residence',
  'Pickup From': 'Pickup From',
  Zone: 'Zone',
  Sch: 'Sch',
  Pay: 'Pay',
  'Change Driver': 'Change Driver',
  'Can Pickup': 'Can Pickup',
  'Requesting Yard': 'Requesting Yard',
  'Requested Date': 'Requested Date',
  'No of Lots': 'No of Lots',
  'Due Date': 'Due Date',
  Payment: 'Payment',
  From: 'From',
  'Copart Location': 'Copart Location',
  'Not Scheduled': 'Not Scheduled',
  'No lots added to this trip': 'No lots added to this trip',
  'Provide Decline Reason': 'Provide Decline Reason',
  'Transporter Issue Reason': 'Transporter Issue Reason',
  'Take Back and Accept Trip?': 'Take Back and Accept Trip?',
  'Distribute To': 'Distribute To',
  Issue: 'Issue',
  'Issue Subtype': 'Issue Subtype',
  'Resolution Comments': 'Resolution Comments',
  'Issue Reason': 'Issue Reason',
  'Acknowledge/Decline Trip': 'Acknowledge/Decline Trip',
  'Distribute Trip': 'Distribute Trip',
  'Add Personnel': 'Add Personnel',
  'Override Decline': 'Override Decline',
  'Update Personnel': 'Update Personnel',
  'Take Back and Accept': 'Take Back and Accept',
  'Mark as read': 'Mark as read',
  Download: 'Download',
  'Other Lots': 'Other Lots',
  'Truck Info': 'Truck Info',
  'Mark Issue as Read': 'Mark Issue as Read',
  'Trip Zone': 'Trip Zone',
  'Enter zone': 'Enter zone',
  Buyer: 'Buyer',
  'Enter Buyer': 'Enter Buyer',
  'Enter Seller': 'Enter Seller',
  'Enter Make': 'Enter Make',
  'Enter Model': 'Enter Model',
  'Enter Lot': 'Enter Lot',
  'Truck Type': 'Truck Type',
  'Enter Zip': 'Enter Zip',
  'Shop name': 'Shop name',
  Subhauler: 'Subhauler',
  'Enter Subhauler': 'Enter Subhauler',
  Enter: 'Enter',
  'Enter year': 'Enter year',
  'Run Comments': 'Run Comments',
  'Accepts Cash': 'Accepts Cash',
  'Damage type': 'Damage type',
  'Remove From Trip': 'Remove From Trip',
  "Can't Clear": "Can't Clear",
  'Remove Driver/Group': 'Remove Driver/Group',
  'Override Decline Trip': 'Override Decline Trip',
  welcomePageTitle: 'Welcome to Transporter Portal!',
  welcomePageText:
    'This site tour will walk you through all the new features and tools introduced with this release. This release marks the largest change to the dispatch process in Copart history! Click next to explore all new functions this application has to offer!',
  listViewTitle: 'List View',
  listViewText:
    'Just like before this List View allows you to identify all the lots in which the trip has been created, but the driver has not yet been assigned.',
  mapViewTitle: 'Map view',
  mapViewText:
    'Along with List View, you also have Map View to identify all the lots in which trip has been created, but driver has not yet been assigned.',
  lotViewTitle: 'Lots view',
  lotViewText: 'Quickest way to view by Lots.',
  tripViewTitle: 'Trips view',
  tripViewText: 'Quickest way to view by Trips.',
  driverViewTitle: 'Drivers View',
  driverViewText: 'Quickest way to view all the Drivers/Group/Company Manager.',
  actionBarTitle: 'Actions Bar',
  actionBarText:
    'There are new dispatch functions available now from the actions list at the top. These actions will depend on the lot condition but include Acknowledge/Decline, Distribute, Override Decline, Remove Driver and Take Back Trip Actions!',
  filterTitle: 'Filters',
  filterText:
    'From this navigation bar, you can easily filter all the lots/trips in Transporter Portal. When you click the top icon the list of all available filters will display. Click next to view all the below quick searches available.',
  assignedViewTitle: 'Assigned',
  assignedViewText: 'You can view all the trips assigned by you.',
  acknowledgeViewTitle: 'Acknowledged',
  acknowledgeViewText: 'You can view all the trips acknowledged by you.',
  distributedViewTitle: 'Distributed',
  distributedViewText: 'You can view all the trips which went through you and distributed by you.',
  completedViewTitle: 'Completed',
  completedViewText: 'You can view all the trips completed by you.',
  issueByMeViewTitle: 'Issues Raised By Me',
  issueByMeViewText: 'You can view all the issues raised by you.',
  declinedByMeViewTitle: 'Declined Trips Raised By Me',
  declinedByMeViewText: 'You can view all the trips declined by you.',
  issuesForMeViewTitle: 'Issues Assigned To Me',
  issuesForMeViewText: 'You can view all the issues assigned to me.',
  declinedTripsTitle: 'Declined Trips',
  declinedTripsText: 'You can view all the declined trips.',
  'No requests to display. Please remove filters to view all requests':
    'No requests to display. Please remove filters to view all requests',
  'Please enter valid email to get the reset password link': 'Please enter valid email to get the reset password link',
  loginUnsuccessfulHeader: 'Login Unsuccessful',
  loginUnsuccessfulMessage: 'Please make sure to enter your email address and associated password',
  loginUnsuccessfulSessionExpired: 'Your Session Expired. Please login again.',
  'Advance & Pickup Information': 'Advance & Pickup Information',
}
