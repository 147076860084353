import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  TextField,
  Button,
  MenuItem,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/en'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useSnackbar } from 'notistack'
import { last, pathOr } from 'ramda'
import { useAtom } from 'jotai'
import CloseIcon from '@mui/icons-material/Close'
import {
  businessDaysAtom,
  globalPostAtom,
  actionCodeAtom,
} from 'constants/atoms'
import { getItem } from 'utils/localstorage'
import { getCurrentDate, formatDateObj } from 'utils/date'
import { createRequest } from 'services/workRequestApi'
import { useStyles } from 'utils/styles'
import { ACTION_CODES } from 'constants/actionCodes'
import { getBusinessDays } from 'services/fetchData'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('America/New_York')

const WorkRequestDialog = ({ initialData = null }) => {
  const userData = getItem('userData')
  const yardList = pathOr([], ['vendor', 'groups', '0', 'yards'], userData).map(
    ({ yard_name, yard_number }) => ({
      label: `${yard_number} - ${yard_name}`,
      value: yard_number,
    }),
  )
  const [actionCode, setActionCode] = useAtom(actionCodeAtom)
  const [businessDays, setBusinessDays] = useAtom(businessDaysAtom)
  const [, setGlobalLoading] = useAtom(globalPostAtom)
  const { enqueueSnackbar } = useSnackbar()

  const defaultData = {
    yard_number: '',
    requested_vehicles: '',
    requested_date: '',
    comments: '',
  }

  const areAllFieldsFilled = () => {
    return Object.values(formData).every((fieldValue) => Boolean(fieldValue))
  }

  const [formData, setFormData] = useState(initialData || defaultData)

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const onKeyDown = (e) => {
    e.preventDefault()
  }

  const handleSubmit = async () => {
    const payload = {
      yard_number: formData.yard_number,
      additional_data: {
        requested_vehicles: formData.requested_vehicles,
        requested_date: formatDateObj(formData.requested_date),
        comments: formData.comments,
        created_date: getCurrentDate().toISOString(),
      },
    }

    const response = await createRequest(payload)
    if (response.status === 200) {
      setGlobalLoading(true)
      handleClose()
      enqueueSnackbar('Work request created successfully', {
        variant: 'success',
      })
    } else if (response.status === 400) {
      enqueueSnackbar(
        'You have selected to open an additional Work Request - Previous Work Request needs to be resolved',
        {
          variant: 'error',
        },
      )
    } else {
      enqueueSnackbar('Unable to create work request', {
        variant: 'error',
      })
    }
  }

  const handleClose = () => {
    setFormData(defaultData)
    setActionCode('')
  }

  const classes = useStyles()

  useEffect(() => {
    if (formData.yard_number) {
      getBusinessDays(formData.yard_number).then((data) => {
        setBusinessDays(pathOr([], ['data', 'data', 'business_days'], data))
      })
    }
  }, [formData.yard_number])

  const isDateEnabled = (date) => {
    const dateString = dayjs(date).tz('America/New_York').format('YYYY-MM-DD')
    return businessDays.includes(dateString)
  }

  return (
    <Dialog
      open={actionCode === ACTION_CODES.CREATE_REQUEST}
      onClose={handleClose}
    >
      <DialogTitle>
        <div style={{ color: 'white' }}>
          <Typography
            classes={{
              root: classes.root,
            }}
          >
            Create Work Request
          </Typography>
        </div>
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: '8px', top: '8px' }}
        >
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="Yard"
              variant="outlined"
              name="yard_number"
              value={formData.yard_number}
              onChange={handleInputChange}
              select
            >
              {yardList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="Number of Cars"
              variant="outlined"
              type="number"
              name="requested_vehicles"
              value={formData.requested_vehicles}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            {/* <TextField
              fullWidth
              required
              label="Request Date"
              type="date"
              variant="outlined"
              name="requested_date"
              value={formData.requested_date}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              onKeyDown={onKeyDown}
              InputProps={{
                inputProps: {
                  min: businessDays[0],
                  max: last(businessDays),
                },
              }}
            /> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Request Date"
                format="MM/DD/YYYY"
                slotProps={{
                  textField: {
                    required: true,
                  },
                }}
                value={
                  formData.requested_date
                    ? dayjs(formData.requested_date, 'MM/DD/YYYY')
                    : null
                }
                onChange={(newValue) => {
                  const formattedDate = dayjs(newValue).format('MM/DD/YYYY')
                  setFormData((prevData) => ({
                    ...prevData,
                    requested_date: formattedDate,
                  }))
                }}
                shouldDisableDate={(date) => !isDateEnabled(date)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Comments"
              variant="outlined"
              name="comments"
              required
              multiline
              rows={3}
              value={formData.comments}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!areAllFieldsFilled()}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default WorkRequestDialog
