import { propOr, head } from 'ramda'

export const mergeCATDrivers = (catDrivers, drivers) => {
  const associatedDrivers = []
  const vendorPersonnels = []
  drivers.forEach((vendorPersonnel) => {
    const { vendor_personnel_id: vendorPersonnelId } = vendorPersonnel
    const catVendorPersonnel = catDrivers.find(
      ({ vendor_personnel_id: driverPersonnelId }) =>
        driverPersonnelId === vendorPersonnelId,
    )

    if (catVendorPersonnel) {
      const {
        estimated_time_of_arrival_date: estimatedArrivalDate,
        deployment_eligible_flag: isDeploymentRequired,
        subhauler_driver_id: subhaulerDriverId,
        truck_type_code: truckType,
        subhauler_deployment_id: subhaulerDeploymentId,
        deployment_approval_status_code: deploymentApprovalStatusCode,
        trip,
        catId,
        destination_facility_id: destinationFacilityId,
        created_by: associatedBy,
        deploymentTypeCode,
        catName,
      } = catVendorPersonnel
      const tripStageCode = propOr('', 'trip_stage_code', trip)
      const rejectionReason = propOr('', 'rejection_reason', trip)
      associatedDrivers.push({
        ...vendorPersonnel,
        estimatedArrivalDate,
        isDeploymentRequired: isDeploymentRequired === 1 ? 'Y' : 'N',
        subhaulerDriverId,
        truckType,
        subhaulerDeploymentId,
        deploymentApprovalStatusCode,
        tripStageCode,
        catId,
        destinationFacilityId,
        associatedBy,
        deploymentTypeCode,
        catName,
        rejectionReason,
      })
    } else vendorPersonnels.push(vendorPersonnel)
  })

  return [...associatedDrivers, ...vendorPersonnels]
}

export const formatDeploymentEventOptions = (associations) =>
  associations.map((association) => {
    const {
      cat_ids: catIds = [],
      cat_name: catName = '',
      deployed_facility_id: facilityId = [],
      deployment_subhauler_id: deploymentSubhaulerId = '',
      destination_facility_name: facilityName = '',
    } = association
    const option = { code: '', description: '', deploymentSubhaulerId }
    if (catIds.length) {
      option.code = String(head(catIds))
      option.description = catName
    } else {
      option.code = String(
        head(facilityId) ? head(facilityId) : deploymentSubhaulerId,
      )
      option.description = facilityName || 'Business Support'
    }
    return { ...option, ...association }
  })

export const getSelectedDepEvent = (selectedDriver) => {
  const {
    deploymentTypeCode = '',
    catId = '',
    destinationFacilityId = '',
  } = selectedDriver

  if (deploymentTypeCode === 'CAT') return catId
  else return destinationFacilityId
}
