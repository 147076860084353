import React from 'react'
import { useAtom } from 'jotai'
import { isEmpty, head } from 'ramda'
import {
  actionCodeAtom,
  rowDataAtom,
  selectedRowAtom,
  selectedRecordsAtom,
  resolveIssueAtom,
  completedRowDataAtom,
} from 'constants/atoms'
import { ACTION_CODES } from 'constants/actionCodes'

import PersonnelDialog from './personnelDialog'
import WorkRequestDialog from './workRequestDialog'
import DeleteWorkRequestDialog from './deleteWorkRequestDialog'
import ManageDriverDialog from './manageDriverDialog'
import TruckDialog from '../truckManagementView/truckDialog'
import CreateIssueDialog from './lotsViewDialogs/createIssueDialog'
import DistributeTripDialog from './lotsViewDialogs/distributeTripDialog'
import CanPickUpDialog from './lotsViewDialogs/canPickUpDialog'
import AcknowledgeTripDialog from './tripsViewDialogs/acknowledgeTripDialog'
import DriverScheduleDialog from './driverScheduleDialog'
import DeletePcardDialog from './deletePcardDialog'
import RequestPcardDialog from './requestPcardDialog'
import OverrideDeclineDialog from './tripsViewDialogs/overrideDeclineDialog'
import CancelDeclineDialog from './tripsViewDialogs/cancelDeclineDialog'
import MarkAsReadDialog from './lotsViewDialogs/markAsReadDialog'
import ViewLotDialog from './lotsViewDialogs/viewLotDialog'
import ResolveIssueDialog from './lotsViewDialogs/resolveIssueDialog'
import OverrideIssueDialog from './lotsViewDialogs/overrideIssueDialog'

const RenderDialog = ({ rowData }) => {
  const [actionCode] = useAtom(actionCodeAtom)
  const [, setRowData] = useAtom(rowDataAtom)
  const [selectedRows, setSelectedRows] = useAtom(selectedRowAtom)
  const [, setSelectedRecords] = useAtom(selectedRecordsAtom)
  const [, setCompletedRowData] = useAtom(completedRowDataAtom)
  const [resolveIssue] = useAtom(resolveIssueAtom)

  const handleDialogClose = () => {
    setRowData([])
    setSelectedRows([])
    setSelectedRecords(0)
    if (!resolveIssue) {
      setCompletedRowData([])
    }
  }

  const selectedRow = isEmpty(rowData) ? selectedRows : [rowData]
  switch (actionCode) {
    case ACTION_CODES.ADD_DRIVER:
      return (
        <PersonnelDialog
          rowData={head(selectedRow)}
          onClose={handleDialogClose}
        />
      )
    case ACTION_CODES.CREATE_REQUEST:
      return <WorkRequestDialog />
    case ACTION_CODES.DELETE_REQUEST:
      return <DeleteWorkRequestDialog rowData={rowData} />
    case ACTION_CODES.MANAGE_DRIVER:
      return (
        <ManageDriverDialog rowData={selectedRow} onClose={handleDialogClose} />
      )
    case ACTION_CODES.DRIVER_SCHEDULE:
      return <DriverScheduleDialog rowData={selectedRow} />
    case ACTION_CODES.DELETE_PCARD:
      return (
        <DeletePcardDialog rowData={selectedRow} onClose={handleDialogClose} />
      )
    case ACTION_CODES.REQUEST_PCARD:
      return (
        <RequestPcardDialog rowData={selectedRow} onClose={handleDialogClose} />
      )
    case ACTION_CODES.ADD_TRUCK:
      return <TruckDialog />
    case ACTION_CODES.CREATE_ISSUE:
      return (
        <CreateIssueDialog rowData={selectedRow} onClose={handleDialogClose} />
      )
    case ACTION_CODES.DISTRIBUTE_TRIP:
      return (
        <DistributeTripDialog
          rowData={selectedRow}
          onClose={handleDialogClose}
        />
      )
    case ACTION_CODES.OVERRIDE_DECLINE:
      return (
        <OverrideDeclineDialog
          onClose={handleDialogClose}
          rowData={selectedRow}
        />
      )
    case ACTION_CODES.OVERRIDE_ISSUE:
      return (
        <OverrideIssueDialog
          onClose={handleDialogClose}
          rowData={selectedRow}
          action="overrideRejection"
        />
      )
    case ACTION_CODES.CANT_CLEAR:
      return (
        <OverrideIssueDialog
          onClose={handleDialogClose}
          rowData={selectedRow}
          action="cantClear"
        />
      )
    case ACTION_CODES.REMOVE_DRIVER:
      return (
        <CancelDeclineDialog
          rowData={selectedRow}
          onClose={handleDialogClose}
          action="removeDriver"
        />
      )
    case ACTION_CODES.TAKE_BACK_AND_ACCEPT:
      return (
        <CancelDeclineDialog
          rowData={selectedRow}
          onClose={handleDialogClose}
          action="takeBack"
        />
      )
    case ACTION_CODES.ACKNOWLEDGE_TRIP:
      return (
        <AcknowledgeTripDialog
          onClose={handleDialogClose}
          rowData={selectedRow}
        />
      )
    case ACTION_CODES.CAN_PICKUP:
      return (
        <CanPickUpDialog onClose={handleDialogClose} rowData={selectedRow} />
      )
    case ACTION_CODES.RESOLVE_ISSUE:
      return (
        <ResolveIssueDialog onClose={handleDialogClose} rowData={selectedRow} />
      )
    case ACTION_CODES.MARK_ISSUE_AS_READ:
      return (
        <MarkAsReadDialog onClose={handleDialogClose} rowData={selectedRow} />
      )
    case ACTION_CODES.VIEW_LOT:
      return <ViewLotDialog rowData={head(selectedRow)} />
    default:
      return null
  }
}

export default RenderDialog
