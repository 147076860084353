import React from 'react'
import { useAtom } from 'jotai'
import {
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import AccountManagement from './accountMgmt'
import Dialog from '../../customInputFields/dialog'
import { getItem } from 'utils/localstorage'
import { AccountDialogAtom, ContactDialogAtom } from 'constants/atoms'
import Contact from 'utils/contact'
import locale from 'utils/locale'

const UserAvatar = ({ handleMenuOpen, handleMenuClose, initials }) => (
  <IconButton
    aria-controls="user-menu"
    aria-haspopup="true"
    onMouseEnter={handleMenuOpen}
    onMouseLeave={handleMenuClose}
    sx={{ ml: 2 }}
  >
    <Avatar sx={{ width: 45, height: 45 }}>{initials}</Avatar>
  </IconButton>
)

const UserMenu = ({
  anchorEl,
  handleMenuClose,
  handleProfileClick,
  setContactDialogOpen,
  logOutOnClick,
  setLogoutDialogOpen,
}) => (
  <Menu
    id="user-menu"
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={handleMenuClose}
    onMouseLeave={handleMenuClose}
    autoFocus={false}
  >
    <MenuItem onClick={handleProfileClick}>
      <AccountCircleIcon sx={{ fontSize: 30, paddingRight: '10px' }} />{' '}
      {locale('My Profile')}
    </MenuItem>
    {/* Temporarily commenting this out */}
    {/* <MenuItem onClick={() => setContactDialogOpen(true)}>
      <HelpOutlineIcon sx={{ pr: 1 }} /> Help & Contact Us
    </MenuItem> */}
    <MenuItem onClick={() => setLogoutDialogOpen(true)}>
      <LogoutIcon sx={{ fontSize: 30, paddingRight: '10px' }} />{' '}
      {locale('Logout')}
    </MenuItem>
  </Menu>
)

const Header = ({ logOutOnClick }) => {
  const userData = getItem('userData')
  const [, setAccountDialogOpen] = useAtom(AccountDialogAtom)
  const [, setContactDialogOpen] = useAtom(ContactDialogAtom)
  const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleMenuOpen = (event) => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleProfileClick = () => {
    setAccountDialogOpen(true)
    handleMenuClose()
  }

  const getInitials = () => {
    if (userData?.vendor) {
      const { first_name, last_name } = userData.vendor
      return `${first_name[0]}${last_name[0]}`.toUpperCase()
    }
    return ''
  }

  return (
    <div>
      <AppBar sx={{ backgroundColor: '#0d5db8', position: 'initial' }}>
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          style={{ paddingLeft: '0' }}
        >
          <div className="rowStack" style={{ alignItems: 'center' }}>
            <div className="copartLogo"></div>
            <Typography variant="h6" component="div">
              {locale('TRANSPORTER PORTAL')}
            </Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <UserAvatar
              handleMenuOpen={handleMenuOpen}
              initials={getInitials()}
            />
            <UserMenu
              anchorEl={anchorEl}
              handleMenuClose={handleMenuClose}
              handleProfileClick={handleProfileClick}
              setContactDialogOpen={setContactDialogOpen}
              logOutOnClick={logOutOnClick}
              setLogoutDialogOpen={setLogoutDialogOpen}
            />
          </div>
        </Toolbar>
      </AppBar>
      <AccountManagement userData={userData} />
      <Contact />
      <Dialog
        open={logoutDialogOpen}
        title={locale('Are you sure you want to logout?')}
        onClose={() => setLogoutDialogOpen(false)}
        actions={[
          {
            name: locale('Cancel'),
            onClick: () => setLogoutDialogOpen(false),
          },
          {
            name: locale('Logout'),
            onClick: () => {
              logOutOnClick()
              setLogoutDialogOpen(false)
            },
          },
        ]}
        customised={true}
        iconOnTop={<LockOpenIcon className="avatarDiv" />}
      ></Dialog>
    </div>
  )
}

export default Header
