import React, { useState, useEffect } from 'react'
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  DialogActions,
  Chip,
  InputAdornment,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { pathOr } from 'ramda'
import { useSnackbar } from 'notistack'
import CloseIcon from '@mui/icons-material/Close'
import EmailInput from 'components/customInputFields/emailInput'
import { getItem } from 'utils/localstorage'
import { EMAIL_REGEX } from 'utils/regex'
import { sendEmail } from 'services/lotsViewServicesApi'
import RichTextEditor from 'utils/quillEditor'

const SendEmailDialog = ({ isOpen, setSendEmail, emailId }) => {
  const userData = getItem('userData')
  const vendorName = pathOr('', ['vendor', 'full_name'], userData)

  const defaultData = {
    to: emailId,
    cc: [],
    subject: '',
    content: `<div><br><br>Thank you, <br><strong>${vendorName}</strong></div><div><br></div>`,
  }

  const [emailData, setEmailData] = useState({ ...defaultData })
  const [currentInput, setCurrentInput] = useState('')
  const invalidEmail = currentInput ? !EMAIL_REGEX.test(currentInput) : false
  const [isToEmailValid, setIsToEmailValid] = useState(true)
  const { enqueueSnackbar } = useSnackbar()

  const isFormValid = () => {
    return (
      !invalidEmail &&
      isToEmailValid &&
      emailData.subject &&
      emailData.content &&
      currentInput === ''
    )
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setEmailData((prevData) => ({ ...prevData, [name]: value }))
    if (name === 'to') {
      setIsToEmailValid(EMAIL_REGEX.test(value))
    }
  }

  const handleCcChange = (event) => {
    setCurrentInput(event.target.value)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      const newEmail = currentInput.trim()
      if (EMAIL_REGEX.test(newEmail)) {
        setEmailData((prevData) => ({
          ...prevData,
          cc: [...prevData.cc, newEmail],
        }))
        setCurrentInput('')
      }
    }
  }

  const handleClose = () => {
    setEmailData({ ...defaultData })
    setCurrentInput('')
    setSendEmail(false)
  }

  const getChipsAdornment = () => {
    return emailData.cc.map((email, index) => (
      <Chip key={index} label={email} onDelete={() => handleDelete(email)} />
    ))
  }

  const handleDelete = (emailToDelete) => {
    setEmailData((prevData) => ({
      ...prevData,
      cc: prevData.cc.filter((email) => email !== emailToDelete),
    }))
  }

  const handleSubmit = async () => {
    const htmlContent = emailData.content

    const apiData = new FormData()
    apiData.append('from', userData.email)
    apiData.append('to', emailData.to)
    apiData.append('cc', emailData.cc.join(','))
    apiData.append('subject', emailData.subject)
    apiData.append('content', htmlContent)
    apiData.append('data', htmlContent)
    const result = await sendEmail(apiData)
    if (result.success) {
      enqueueSnackbar('Email sent successfully', {
        variant: 'success',
      })
      handleClose()
    } else {
      enqueueSnackbar('Error occurred while sending email', {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    setEmailData((prevData) => ({ ...prevData, to: emailId }))
  }, [emailId])

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>
        Send Email
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: '8px', top: '8px' }}
        >
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ marginTop: '8px' }}>
          <Grid item xs={6}>
            <EmailInput
              label="From"
              name="from"
              value={userData.email}
              fullWidth
              variant="outlined"
              showAdornment={false}
              readOnly={true}
            />
          </Grid>
          <Grid item xs={6}>
            <EmailInput
              label="To"
              name="to"
              value={emailData.to}
              handleChange={handleInputChange}
              showAdornment={false}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="CC"
              value={currentInput}
              onChange={handleCcChange}
              onKeyDown={handleKeyDown}
              fullWidth
              variant="outlined"
              error={invalidEmail}
              helperText={
                invalidEmail
                  ? 'Invalid Email'
                  : currentInput === ''
                  ? ''
                  : 'Please Press Enter to add email to cc list'
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {getChipsAdornment()}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Subject"
              name="subject"
              value={emailData.subject}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
        <div style={{ marginTop: '10px' }}>
          <RichTextEditor
            value={emailData.content}
            onChange={(value) => setEmailData({ ...emailData, content: value })}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={!isFormValid()}
          onClick={handleSubmit}
        >
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default SendEmailDialog
