import React, { useRef, useEffect } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const RichTextEditor = ({ value, onChange }) => {
  const quillRef = useRef(null)

  useEffect(() => {
    if (quillRef.current) {
      quillRef.current.focus()
    }
  }, [])

  const modules = {
    toolbar: [['bold', 'italic', 'underline']],
  }

  return (
    <ReactQuill
      ref={quillRef}
      value={value}
      onChange={onChange}
      modules={modules}
    />
  )
}

export default RichTextEditor
