import { atom, useAtom } from 'jotai'
import { useSnackbar } from 'notistack'

import {
  Divider,
  Chip,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material'
import SafetyCheckRoundedIcon from '@mui/icons-material/SafetyCheckRounded'

import {
  externalUserDataAtom,
  selectedNumberAtom,
  accountStatusAtom,
} from 'constants/atoms'
import { loginLabels } from 'utils/labels'
import { sendOTP } from './loginActions'
import EmailInput from '../customInputFields/emailInput'
import BackButton from '../customInputFields/backButton'

const userIdAtom = atom(null)

const VerifyAccount = ({ inputEmail, handleChange, invalidEmail, setMode }) => {
  const [, setAccountStatus] = useAtom(accountStatusAtom)
  const [externalUserData] = useAtom(externalUserDataAtom)
  const [, setSelectedNumber] = useAtom(selectedNumberAtom)
  const [userId, setUserId] = useAtom(userIdAtom)
  const disableSubmitBtn = invalidEmail || !inputEmail
  const dropdownOptions = [
    {
      key: 'placeholder',
      text: 'Select Phone Number',
      value: null,
    },
    ...(externalUserData?.phone_numbers?.map((phone) => ({
      key: phone.user_id,
      value: phone.user_id,
      text: `+1***${phone.last_four}`,
    })) || []),
  ]
  const { enqueueSnackbar } = useSnackbar()

  const handleSendOtp = (mode) => {
    setMode(mode)
    setAccountStatus('otpVerification')
    const selectedUserId =
      mode === 'EMAIL' ? externalUserData.phone_numbers[0].user_id : userId
    const payload = {
      comm_pref: mode,
      user_id: selectedUserId,
    }
    sendOTP(payload)
      .then((response) => {
        enqueueSnackbar(loginLabels.otpSent, { variant: 'success' })
      })
      .catch((error) => {
        console.error('Error sending OTP.')
      })
  }

  return (
    <div>
      <div className="avatarIconWrap">
        <SafetyCheckRoundedIcon className="avatarDiv" />
      </div>
      <div className="deviceRegistrationWrap divBox">
        <BackButton
          accountStatus={'forgotPassword'}
          setAccountStatus={setAccountStatus}
        />
        <span className="mediumHeading">{loginLabels.verifyYourAccount()}</span>
        <p className="loginNormalText">
          {loginLabels.DeviceRegistrationInfo()}
        </p>
        <FormControl
          variant="outlined"
          style={{ marginLeft: '10%', marginRight: '10%' }}
        >
          <InputLabel htmlFor="phone-number-select">
            Select Phone Number
          </InputLabel>
          <Select
            label="Select Phone Number"
            value={userId || ''}
            onChange={(e) => {
              const selectedValue = e.target.value
              setUserId(selectedValue)
              const selectedOption = dropdownOptions.find(
                (i) => i.value === selectedValue,
              )
              if (selectedOption) {
                setSelectedNumber(selectedOption.text)
              }
            }}
            id="phone-number-select"
          >
            {dropdownOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Divider sx={{ width: '100%' }}>
          <Chip label="or" />
        </Divider>
        <EmailInput
          value={inputEmail}
          showAdornment={false}
          handleChange={({ target }) =>
            handleChange('inputEmail', target.value)
          }
          style={{ width: '80%', margin: '0 auto' }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            disabled={!userId}
            onClick={() => handleSendOtp('TEXT')}
          >
            Send code to Phone
          </Button>
          <Button
            variant="contained"
            disabled={disableSubmitBtn}
            onClick={() => handleSendOtp('EMAIL')}
          >
            Send code to Email
          </Button>
        </div>
      </div>
    </div>
  )
}

export default VerifyAccount
