export const resolveActions = {
  change_driver: {
    name: 'distributeTrip',
    label: 'Change Driver',
  },
  trip_maintenance: {
    name: 'distributeTrip',
    label: 'Change Driver',
  },
  override: {
    name: 'overrideRejection',
    label: 'Override Issue',
  },
  cant_clear: {
    name: 'cantClear',
    label: `Can't Clear`,
  },
  cancel: {
    name: 'cancel',
    label: 'Can Pickup',
  },
}
