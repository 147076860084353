import { useState } from 'react'
import { useAtom } from 'jotai'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { loginLabels } from 'utils/labels'
import { isAuthorizedAtom } from 'constants/atoms'

const PasswordInput = ({
  value = '',
  onKeyDown = () => {},
  placeholder = '',
  fullWidth = true,
  label = 'Password',
  id = label,
  showAdornment = true,
  onChange = () => {},
  invalidPassword = false,
  errorMessage = loginLabels.invalidPassword,
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [isAuthorized] = useAtom(isAuthorizedAtom)
  return (
    <TextField
      id={id}
      label={label}
      value={value || ''}
      fullWidth={fullWidth}
      onChange={onChange}
      error={invalidPassword}
      placeholder={placeholder}
      type={showPassword ? 'text' : 'password'}
      helperText={invalidPassword ? errorMessage : ''}
      onKeyDown={onKeyDown}
      style={isAuthorized ? { marginBottom: '15px' } : {}}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              aria-label="toggle password visibility"
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => setShowPassword((show) => !show)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default PasswordInput
