export const loginLabels = {
  copartInfo:
    '100% Online Auto Auctions Over 3 Million Used, Wholesale and Repairable Cars, Trucks & SUVs sold per year!',
  findYourAccountInfo:
    "Enter your registered email, we'll need this to get you back in your account.",
  forgotPasswordBtn: 'First Time User? Forgot Password?',
  driverNotAllowed: 'You are not allowed to login',
  transporterNotRegistered: 'This account is not registered as a Transporter',
  invalidEmail: 'Invalid Email',
  invalidPassword: 'Password cannot be empty',
  invalidCredentials: 'Invalid Credentials',
  loginSuccess: 'Login Success',
  logoutSuccess: 'Logout Successful',
  registerNewPasswordInfo:
    'Create a new password matching below given criteria. Please re-enter to confirm the password.',
  otpSent: 'OTP sent successfully',
  otpValidated: 'OTP validated successfully',
  invalidOtp: 'Invalid OTP',
  passwordChanged: 'Password changed successfully',
  checkForCode: (mode) => <strong>{`Please check your ${mode}`}</strong>,
  sentCodeTo: (entity) => (
    <>
      Copart has sent a code to <strong>{`${entity}`}</strong>. Please type the
      verification code below.
    </>
  ),
  DeviceRegistrationInfo: () => (
    <>
      To help keep your account safe, <strong>Copart</strong> wants to make sure
      it's really you trying to sign in. <br />
      <span style={{ display: 'block', marginTop: '1em' }}>
        Choose registered email or phone number to receive a verification code.
      </span>
    </>
  ),
  findYourAccount: () => <strong>Let's find your account</strong>,
  loginStep1Label: () => (
    <>
      Let's get started!
      <br />
      Please sign in using your registered <strong> email address.</strong>
    </>
  ),
  deleteYourAccount: () => (
    <>
      <p
        style={{
          margin: '0px',
          padding: '5px',
          fontSize: '18px',
          fontWeight: '900',
        }}
      >
        Delete Account
      </p>
      <div style={{ paddingTop: '10px' }}>
        You'll permanently lose your
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'left',
          }}
        >
          - Profile
          <br />
          - Appointments
          <br />- History
        </div>
      </div>
    </>
  ),
  accountDeletionSuccess: () => <strong>Account deleted successfully</strong>,
  accountDeletionFailure: () => <strong>Account could not be deleted </strong>,
  accountDeletedMessage: () => (
    <strong>Account deleted successfully, Thank you! </strong>
  ),
  registerNewPassword: () => <strong>Register your new password</strong>,
  verifyYourAccount: () => <strong>Verify your account</strong>,
  unableToVerify: () => (
    <strong>
      Get in touch <span className="waveHand">👋</span>
    </strong>
  ),
  copartReachOut: () => (
    <>
      Please reach out to <strong>Copart</strong> at&nbsp;
      <strong>
        <a href="tel:0191-814-4248">0191 814 4248</a>
      </strong>
      &nbsp;or visit&nbsp;
      <strong>
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www.copart.co.uk/content/uk/en/contact-us/"
        >
          Support Center
        </a>
      </strong>
    </>
  ),
}

export const driverScheduleNote = {
  scheduleUpdateSuccess: 'Schedule updated successfully',
  scheduleUpdateFailure: 'Schedule cound not be updated',
}

export const truckManagementLabels = {
  addNewTruckSuccess: 'Truck has been successfully added',
  updateTruckSuccess: 'Truck Information has been successfully updated',
  removeTruckSuccess: 'Truck removed successfully',
  removeTruckFailure: 'Error removing truck',
  imageUploadSuccess: (code) =>
    `${
      code === 'DRSDV' ? 'Truck' : 'License Plate'
    } Image uploaded successfully`,
}

export const CATDriversLabels = {
  associationSuccess: 'Driver has been successfully associated',
  associationFailure: 'Failed to association the driver',
  removeAssocSuccess: 'Driver has been removed from the association event',
  removeAssocFailure: 'Failed to remove the association',
  updateAssocSuccess: 'Driver Information has been successfully updated',
  updateAssocFailure: 'Failed to update the information',
}
