import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import RoomRoundedIcon from '@mui/icons-material/RoomRounded'
import NumberedIcon from './numberedIcon'
import { Tooltip } from '@mui/material'

const MapLegend = () => {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: 25,
        left: 25,
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        gap: '5px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <NumberedIcon color={'red'} legend />
        Overdue
        <Tooltip
          title={
            <span style={{ fontSize: '1.3em' }}>Past Due and Due Today</span>
          }
          style={{ paddingLeft: '5px' }}
          enterDelay={200}
          leaveDelay={200}
        >
          <InfoOutlinedIcon sx={{ color: 'black' }} />
        </Tooltip>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <NumberedIcon color={'black'} legend />
        Future Pickup
        <Tooltip
          title={
            <span style={{ fontSize: '1.3em' }}>
              Pickups, Deliveries & Owner retains
            </span>
          }
          style={{ paddingLeft: '5px' }}
          enterDelay={200}
          leaveDelay={200}
        >
          <InfoOutlinedIcon sx={{ color: 'black' }} />
        </Tooltip>
      </div>
    </div>
  )
}

export default MapLegend
