import React, { useState } from 'react'
import { atom, useAtom } from 'jotai'

import { Button } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import ForgotPassword from './forgotPassword'
import ChangePassword from './changePassword'
import OTPVerification from './otpVerification'
import VerifyAccount from './verifyAccount'
import EmailInput from '../customInputFields/emailInput'
import PasswordInput from '../customInputFields/passwordInput'

import { loginLabels } from 'utils/labels'
import { EMAIL_REGEX } from 'utils/regex'
import { accountStatusAtom } from 'constants/atoms'
import Checkbox from '@mui/material/Checkbox'

const phoneNumberAtom = atom('')
const modeAtom = atom('')
const otpAtom = atom('')

const LoginForm = ({
  handleChange,
  loginInputData,
  onLogin,
  deleteAccount,
}) => {
  const { inputEmail = '', inputPassword = '' } = loginInputData
  const [accountStatus, setAccountStatus] = useAtom(accountStatusAtom)
  const [phoneNumber, setPhoneNumber] = useAtom(phoneNumberAtom)
  const [deleteAccountCheckbox, setDeleteAccountCheckbox] = useState(false)
  const [mode, setMode] = useAtom(modeAtom)
  const [otp, setOtp] = useAtom(otpAtom)

  const invalidEmail = inputEmail ? !EMAIL_REGEX.test(inputEmail) : false
  const invalidPassword = !inputPassword

  const disableSignInBtn =
    invalidEmail || invalidPassword || !inputPassword || !inputEmail

  return (
    <div>
      {accountStatus === 'deleted' && loginLabels.accountDeletedMessage()}
      {accountStatus !== 'deleted' && (
        <div className="avatarIconWrap">
          {deleteAccount ? (
            <DeleteIcon className="deleteAvatarDiv" />
          ) : (
            <AccountCircleIcon className="avatarDiv" />
          )}
        </div>
      )}
      {accountStatus === 'default' && (
        <div className="loginStep1Wrap divBox deleteAccount">
          {!deleteAccount ? (
            <p className="loginNormalText">{loginLabels.loginStep1Label()}</p>
          ) : (
            <p className="deleteAccountText">
              {loginLabels.deleteYourAccount()}
            </p>
          )}

          <EmailInput
            value={inputEmail}
            showAdornment={false}
            handleChange={({ target }) =>
              handleChange('inputEmail', target.value)
            }
          />
          <PasswordInput
            value={inputPassword}
            showAdornment={false}
            onChange={({ target }) =>
              handleChange('inputPassword', target.value)
            }
            onKeyDown={(event) =>
              event.key === 'Enter' && !disableSignInBtn && onLogin()
            }
          />
          {deleteAccount && (
            <div>
              <Checkbox
                checked={deleteAccountCheckbox}
                onChange={() =>
                  setDeleteAccountCheckbox(!deleteAccountCheckbox)
                }
              />
              <span>Yes, I want to delete my account</span>
            </div>
          )}
          <Button
            fullWidth
            size="large"
            color={deleteAccount ? 'error' : 'success'}
            variant="contained"
            onClick={() => onLogin()}
            disabled={
              disableSignInBtn || (deleteAccount && !deleteAccountCheckbox)
            }
          >
            {deleteAccount ? 'Delete Account' : 'Sign In'}
          </Button>
          {!deleteAccount && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span
                style={{
                  paddingTop: '10px',
                  paddingLeft: '10px',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => setAccountStatus('forgotPassword')}
              >
                Forgot Password?
              </span>
              <Button
                href={
                  window.location.href.includes('tow.copart.com')
                    ? 'https://vendor-connect.copart.com/register?source=LDFRM'
                    : 'https://c-vendor-connect-qa4.copart.com/register?source=LDFRM'
                }
                target="_blank"
              >
                Register
              </Button>
            </div>
          )}
        </div>
      )}
      {accountStatus === 'forgotPassword' && (
        <ForgotPassword inputEmail={inputEmail} handleChange={handleChange} />
      )}
      {accountStatus === 'verifyAccount' && (
        <VerifyAccount
          phoneNumber={phoneNumber}
          inputEmail={inputEmail}
          handleChange={handleChange}
          invalidEmail={invalidEmail}
          setMode={setMode}
          setPhoneNumber={setPhoneNumber}
        />
      )}
      {accountStatus === 'otpVerification' && (
        <OTPVerification
          mode={mode}
          phoneNumber={phoneNumber}
          inputEmail={inputEmail}
          otp={otp}
          setOtp={setOtp}
        />
      )}
      {accountStatus === 'changePassword' && (
        <ChangePassword otp={otp} setOtp={setOtp} />
      )}
    </div>
  )
}

export default LoginForm
