import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Typography,
} from '@mui/material'
import { useAtom } from 'jotai'
import { head } from 'ramda'
import { useSnackbar } from 'notistack'
import { globalPostAtom, actionCodeAtom } from 'constants/atoms'
import CloseIcon from '@mui/icons-material/Close'
import { ACTION_CODES } from 'constants/actionCodes'
import { deletePcard } from 'services/driverApi'

const DeletePcardDialog = ({ rowData, onClose }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [actionCode, setActionCode] = useAtom(actionCodeAtom)
  const [globalLoading, setGlobalLoading] = useAtom(globalPostAtom)
  const handleSubmit = async () => {
    const result = await deletePcard(head(rowData).vendor_personnel_id)
    if (result.status === 200) {
      setGlobalLoading(true)
      enqueueSnackbar('Pcard has been deleted successfully', {
        variant: 'success',
      })
      handleClose()
    } else {
      enqueueSnackbar('Error occured while deleting Pcard', {
        variant: 'error',
      })
    }
  }

  const handleClose = () => {
    setActionCode('')
    onClose()
  }

  return (
    <Dialog
      open={actionCode === ACTION_CODES.DELETE_PCARD}
      onClose={handleClose}
    >
      <DialogTitle>
        Delete Pcard
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: '8px', top: '8px' }}
        >
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ width: '500px' }}>
        <Typography> Are you sure you want to destroy the pcard?</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeletePcardDialog
