import { allPass, complement, anyPass } from 'ramda'
import { Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import CancelIcon from '@mui/icons-material/Cancel'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import TaskIcon from '@mui/icons-material/Task'
import UndoIcon from '@mui/icons-material/Undo'

import {
  isActiveIssue,
  isLotStatusInRange,
  isBelongsToUser,
  isTripDistributable,
  isDeclinedLot,
  isAnyUnreadIssue,
  isIssueAssignedToMe,
  isIssueRaisedByMe,
  isTripDeclinedToUser,
  isTripRejected,
  isTripAcknowledged,
  isPCardFlagN,
  isPCardFlagY,
  isDriverRole,
  isActive,
} from 'utils/dispatchutils'
import {
  CreateIssueIcon,
  DistributeTripIcon,
  ResolveIssueIcon,
} from 'components/icons/icons'
import { viewSearchColumns } from './sidebarItems'

export const awaitingTripBuild = 200
export const awaitingAssignment = 210
export const awaitingVendorAcknowledgement = 220
export const awaitingGroupAcknowledgement = 230
export const awaitingDriverAcknowledgement = 240
export const awaitingInProgress = 250
export const awaitingFormSubmission = 260
export const awaitingArrival = 270
export const awaitingCheckIn = 280
export const awaitingTripVerification = 290

const lotActionMapper = {
  markIssueAsRead: [isAnyUnreadIssue],
  issueResolution: [isActiveIssue, anyPass([isIssueAssignedToMe])],
  cancel: [isActiveIssue, anyPass([isIssueRaisedByMe])],
  createIssue: [
    complement(isDeclinedLot),
    isBelongsToUser,
    complement(isActiveIssue),
    isLotStatusInRange(awaitingInProgress, awaitingArrival),
  ],
  distributeTrip: [
    complement(isActiveIssue),
    isTripDistributable,
    isLotStatusInRange(awaitingGroupAcknowledgement, awaitingArrival),
  ],
}

export const tripActionsMapper = {
  acknowledgeTrip: [
    isBelongsToUser,
    complement(isTripRejected),
    complement(isTripAcknowledged),
  ],
  distributeTrip: [
    isTripAcknowledged,
    isTripDistributable,
    anyPass([isBelongsToUser, isTripDeclinedToUser]),
  ],
  overrideDeclineTrip: [isTripDeclinedToUser],
  removeTransporter: [isTripDeclinedToUser],
  cancelDeclineTrip: [isTripRejected, isBelongsToUser],
}

export const driverActionsMapper = {
  editDriver: [() => true],
  requestPcard: [allPass([isPCardFlagN, isDriverRole, isActive])],
  deletePcard: [allPass([isPCardFlagY, isDriverRole, isActive])],
}

const actionMapper = {
  workRequestsView: ['cancelWorkRequest'],
  catTowProvidersView: ['manageDriver', 'associateDriver'],
  truckManagementView: ['editTruck'],
}

export const getActions = (data, view) => {
  if (view in actionMapper) {
    return actionMapper[view]
  }
  const mapper =
    view === 'lotsView'
      ? lotActionMapper
      : view === 'tripsView'
      ? tripActionsMapper
      : driverActionsMapper
  return Object.keys(mapper).reduce(
    (acc, action) => (allPass(mapper[action])(data) ? [...acc, action] : acc),
    [],
  )
}

export const displayActions = (action, data, handleActionClick) => {
  const { status: driverStatus = '', subhaulerDeploymentId: subhaulerId = '' } =
    data
  switch (action) {
    case 'markIssueAsRead':
      return (
        <Tooltip title="Mark As Read">
          <IconButton onClick={() => handleActionClick(data, action)}>
            <MarkChatReadIcon sx={{ color: 'black' }} />
          </IconButton>
        </Tooltip>
      )
    case 'cancel':
      return (
        <Tooltip title="Can Pick Up">
          <IconButton onClick={() => handleActionClick(data, action)}>
            <ThumbUpIcon sx={{ color: 'black' }} />
          </IconButton>
        </Tooltip>
      )
    case 'createIssue':
      return (
        <Tooltip
          title={<span style={{ fontSize: '1.3em' }}>Create Issue</span>}
        >
          <IconButton onClick={() => handleActionClick(data, action)}>
            <CreateIssueIcon />
          </IconButton>
        </Tooltip>
      )
    case 'issueResolution':
      return (
        <Tooltip
          title={<span style={{ fontSize: '1.3em' }}>Resolve Issue</span>}
        >
          <IconButton onClick={() => handleActionClick(data, action)}>
            <ResolveIssueIcon />
          </IconButton>
        </Tooltip>
      )
    case 'distributeTrip':
      return (
        <Tooltip title="Distribute Trip">
          <IconButton onClick={() => handleActionClick(data, action)}>
            <DistributeTripIcon />
          </IconButton>
        </Tooltip>
      )
    case 'acknowledgeTrip':
      return (
        <Tooltip
          title={
            <span style={{ fontSize: '1.3em' }}>Acknowledge/Decline Trip</span>
          }
        >
          <IconButton onClick={() => handleActionClick(data, action)}>
            <TaskIcon sx={{ fontSize: 25, color: 'black' }} />
          </IconButton>
        </Tooltip>
      )
    case 'overrideDeclineTrip':
      return (
        <Tooltip
          title={
            <span style={{ fontSize: '1.3em' }}>Override Decline Trip</span>
          }
        >
          <IconButton onClick={() => handleActionClick(data, action)}>
            <SwapHorizIcon sx={{ fontSize: 25, color: 'black' }} />
          </IconButton>
        </Tooltip>
      )
    case 'removeTransporter':
      return (
        <Tooltip
          title={<span style={{ fontSize: '1.3em' }}>Remove Driver</span>}
        >
          <IconButton onClick={() => handleActionClick(data, action)}>
            <PersonRemoveIcon sx={{ fontSize: 25, color: 'black' }} />
          </IconButton>
        </Tooltip>
      )
    case 'cancelDeclineTrip':
      return (
        <Tooltip
          title={
            <span style={{ fontSize: '1.3em' }}>Take Back and Accept</span>
          }
        >
          <IconButton onClick={() => handleActionClick(data, action)}>
            <UndoIcon sx={{ fontSize: 25, color: 'black' }} />
          </IconButton>
        </Tooltip>
      )
    case 'editDriver':
      return (
        <Tooltip title={<span style={{ fontSize: '1.3em' }}>Edit Driver</span>}>
          <IconButton
            onClick={() => handleActionClick(data, action)}
            aria-label="edit"
          >
            <EditIcon sx={{ color: 'black' }} />
          </IconButton>
        </Tooltip>
      )
    case 'cancelWorkRequest':
      return (
        <Tooltip
          title={<span style={{ fontSize: '1.3em' }}>Cancel Work Request</span>}
        >
          <IconButton onClick={() => handleActionClick(data)} aria-label="edit">
            <CancelIcon sx={{ color: 'black' }} />
          </IconButton>
        </Tooltip>
      )
    case 'manageDriver':
      return (
        driverStatus === 'A' &&
        subhaulerId && (
          <Tooltip
            title={<span style={{ fontSize: '1.3em' }}>Manage Driver</span>}
          >
            <IconButton
              onClick={() => handleActionClick(data, action)}
              aria-label="edit"
            >
              <EditIcon sx={{ color: 'black' }} />
            </IconButton>
          </Tooltip>
        )
      )
    case 'associateDriver':
      return (
        driverStatus === 'A' &&
        !subhaulerId && (
          <Tooltip
            title={<span style={{ fontSize: '1.3em' }}>Associate Driver</span>}
          >
            <IconButton
              onClick={() => handleActionClick(data, action)}
              aria-label="edit"
            >
              <NoteAddIcon sx={{ color: 'black' }} />
            </IconButton>
          </Tooltip>
        )
      )
    case 'editTruck':
      return (
        <Tooltip title={<span style={{ fontSize: '1.3em' }}>Edit Truck</span>}>
          <IconButton onClick={() => handleActionClick(data)} aria-label="edit">
            <EditIcon sx={{ color: 'black' }} />
          </IconButton>
        </Tooltip>
      )
    case 'requestPcard':
      return (
        <Tooltip
          title={<span style={{ fontSize: '1.3em' }}>Request PCard</span>}
        >
          <IconButton
            onClick={() => handleActionClick(data, action)}
            aria-label="edit"
          >
            <LocalAtmIcon sx={{ color: 'black' }} />
          </IconButton>
        </Tooltip>
      )
    case 'deletePcard':
      return (
        <Tooltip
          title={<span style={{ fontSize: '1.3em' }}>Delete PCard</span>}
        >
          <IconButton
            onClick={() => handleActionClick(data, action)}
            aria-label="edit"
          >
            <LocalAtmIcon sx={{ color: 'red' }} />
          </IconButton>
        </Tooltip>
      )
    default:
      return null
  }
}

export const filterDataByView = (data, selectedView, searchTerm) => {
  const searchColumn = viewSearchColumns[selectedView]

  if (['driversView', 'catTowProvidersView'].includes(selectedView)) {
    return searchTerm
      ? data.filter((item) => {
          const fullName = `${item.first_name} ${item.last_name}`.toLowerCase()
          return fullName.includes(searchTerm.toLowerCase())
        })
      : data
  } else {
    return searchTerm && searchColumn
      ? data.filter((item) =>
          String(item[searchColumn])
            .toLowerCase()
            .includes(searchTerm.toLowerCase()),
        )
      : data
  }
}
