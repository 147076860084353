import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Tooltip,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useAtom } from 'jotai'
import { head, isEmpty } from 'ramda'
import { CircularProgress } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EmailIcon from '@mui/icons-material/Email'
import MultiViewSidebar from 'components/views/dialogs/multiViewSidebar'
import DialogHeader from 'utils/dialogHeader'
import { resolveActions } from 'utils/resolveIssueActionUtils'
import { moveToNextItemInMultiView, formAddress } from 'utils/dispatchutils'
import {
  globalPostAtom,
  actionCodeAtom,
  resolveIssueAtom,
  completedRowDataAtom,
} from 'constants/atoms'
import { getIssueDetail } from 'services/lotsViewServicesApi'
import { ACTION_CODES } from 'constants/actionCodes'
import SendEmailDialog from '../sendEmailDialog'

const actionMap = {
  distributeTrip: ACTION_CODES.DISTRIBUTE_TRIP,
  changeDriver: ACTION_CODES.DISTRIBUTE_TRIP,
  overrideRejection: ACTION_CODES.OVERRIDE_ISSUE,
  cantClear: ACTION_CODES.CANT_CLEAR,
  cancel: ACTION_CODES.CAN_PICKUP,
}

const ResolveIssueDialog = ({ rowData, onClose }) => {
  const [actionCode, setActionCode] = useAtom(actionCodeAtom)
  const [globalLoading] = useAtom(globalPostAtom)
  const [, setResolveIssue] = useAtom(resolveIssueAtom)
  const [issueData, setIssueData] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(head(rowData))
  const [createdIssues, setCreatedIssues] = useAtom(completedRowDataAtom)
  const [expanded, setExpanded] = useState({
    location: true,
    transporter: false,
    seller: false,
  })
  const { dispatch_assignment_detail_id: selectedAssignmentId = '' } =
    selectedRowData

  const {
    is_towable: isTowable = ' ',
    lot_trip_zone: lotTripZone = ' ',
    phone_number: phoneNumber = ' ',
    email: emailId = ' ',
    tow_truck_type: towTruckType = ' ',
    created_by_name: createdByName = ' ',
    created_by_vendor: createdByVendor = ' ',
    vendor_phone_number: vendorPhoneNumber = ' ',
    seller_code: sellerCode = ' ',
    owner_name: ownerName = ' ',
    adjuster: adjusterName = ' ',
    insured_name: insuredName = ' ',
    adjuster_phone_number: adjusterPhoneNumber = ' ',
    claim_number: claimNumber = ' ',
    policy_number: policyNumber = ' ',
    message_type_description: messageTypeDescription = '',
    message_subtype_description: messageSubTypeDescription = ' ',
    created_by_comment: createdByComment = '',
    available_actions: availableActions = [],
  } = issueData

  const locationAddress = formAddress(issueData)

  const [sendEmail, setSendEmail] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const apiData = await getIssueDetail(selectedAssignmentId)
      setIssueData(apiData)
      setLoading(false)
    }
    fetchData()
  }, [selectedRowData])

  useEffect(() => {
    if (isEmpty(createdIssues)) {
      return
    }
    if (rowData.length === createdIssues.length) {
      handleClose()
    } else if (rowData.length > createdIssues.length) {
      let curIndex = rowData.indexOf(selectedRowData)
      const nextIndex = moveToNextItemInMultiView(
        curIndex,
        createdIssues,
        rowData,
        'lot_number',
      )
      setSelectedRowData(rowData[nextIndex])
    }
  }, [createdIssues])

  const handleClose = () => {
    setActionCode('')
    onClose()
    setCreatedIssues([])
    setResolveIssue(false)
  }

  const handleActionClick = (action) => {
    const code = actionMap[action]
    if (code) setActionCode(code)
  }

  const handleSendEmailClick = () => {
    setSendEmail(true)
  }

  const { location, transporter, seller } = expanded
  const handleChange = (name) =>
    setExpanded({ ...expanded, [name]: !expanded[name] })
  return (
    <Dialog
      open={actionCode === ACTION_CODES.RESOLVE_ISSUE}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      scroll="paper"
    >
      <DialogTitle style={{ display: 'flex' }}>
        <DialogHeader
          selectedRowData={selectedRowData}
          handleClose={handleClose}
        />
      </DialogTitle>

      <DialogContent dividers maxWidth="xs">
        <div style={{ display: 'flex', minHeight: '500px' }}>
          {rowData.length > 1 && (
            <MultiViewSidebar
              selectedRowData={selectedRowData}
              setSelectedRowData={setSelectedRowData}
              completedRowData={createdIssues}
            />
          )}
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div
              className="col-2-3"
              style={
                rowData.length > 1
                  ? {
                      borderLeft: '1px solid rgba(0,0,0,0.12)',
                      paddingLeft: '10px',
                    }
                  : {}
              }
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {issueData && (
                    <TextField
                      fullWidth
                      label="Reason"
                      variant="outlined"
                      name="type"
                      value={messageTypeDescription}
                      inputProps={{ readOnly: true }}
                    ></TextField>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Assigned To"
                    variant="outlined"
                    name="assignedTo"
                    value={createdByVendor}
                    inputProps={{ readOnly: true }}
                  ></TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Details"
                    variant="outlined"
                    name="subtype"
                    value={messageSubTypeDescription}
                    inputProps={{ readOnly: true }}
                  ></TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Comments"
                    variant="outlined"
                    name="comments"
                    value={createdByComment}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>

              {/* Location Details */}
              <Accordion
                expanded={location}
                onChange={() => handleChange('location')}
                style={{ marginTop: '20px' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ background: '#E8EDF5' }}
                >
                  <Typography>Location Details</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '20px' }}>
                  {issueData && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Location"
                          variant="outlined"
                          name="location"
                          value={locationAddress}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Towable"
                          variant="outlined"
                          name="towable"
                          value={isTowable}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Other Lots"
                          variant="outlined"
                          name=""
                          value={' '}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Zone"
                          variant="outlined"
                          name="tripZone"
                          value={lotTripZone}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </AccordionDetails>
              </Accordion>

              {/* Transporter Information */}
              <Accordion
                expanded={transporter}
                onChange={() => handleChange('transporter')}
                style={{ marginTop: '20px' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  style={{ background: '#E8EDF5' }}
                >
                  <Typography>Transporter Information</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '20px' }}>
                  {issueData && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Phone #"
                          variant="outlined"
                          name="phoneNumber"
                          value={phoneNumber}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Email"
                          variant="outlined"
                          name="email"
                          value={emailId}
                          InputProps={{
                            readOnly: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                <Tooltip title="Click to email">
                                  <IconButton onClick={handleSendEmailClick}>
                                    <EmailIcon />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="truck Info"
                          variant="outlined"
                          name="truckType"
                          value={towTruckType}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Driver's Name"
                          variant="outlined"
                          name="driverName"
                          value={createdByName}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Tow provider"
                          variant="outlined"
                          name="towProvider"
                          value={createdByVendor}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Co. Manager #"
                          variant="outlined"
                          name="managerPhone"
                          value={vendorPhoneNumber}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </AccordionDetails>
              </Accordion>

              {/* Seller Information */}
              <Accordion
                expanded={seller}
                onChange={() => handleChange('seller')}
                style={{ marginTop: '20px' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  style={{ background: '#E8EDF5' }}
                >
                  <Typography>Seller Information</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '20px' }}>
                  {issueData && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Seller Code"
                          variant="outlined"
                          name="sellerCode"
                          value={sellerCode}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Owner Name"
                          variant="outlined"
                          name="ownerName"
                          value={ownerName}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Adjuster"
                          variant="outlined"
                          name="adjuster"
                          value={adjusterName}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Insued Name"
                          variant="outlined"
                          name="insuredName"
                          value={insuredName}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Adjuster #"
                          variant="outlined"
                          name="adjusterPhone"
                          value={adjusterPhoneNumber}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Claim #"
                          variant="outlined"
                          name="claimNumber"
                          value={claimNumber}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Policy"
                          variant="outlined"
                          name="policyNumber"
                          value={policyNumber}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        {availableActions.map((actionKey) => {
          const action = resolveActions[actionKey]
          return (
            <LoadingButton
              key={action.name}
              loading={globalLoading}
              variant="contained"
              color="primary"
              onClick={() => handleActionClick(action.name)}
            >
              {action.label}
            </LoadingButton>
          )
        })}
      </DialogActions>
      {sendEmail && (
        <SendEmailDialog
          isOpen={sendEmail}
          setSendEmail={setSendEmail}
          emailId={emailId}
        />
      )}
    </Dialog>
  )
}

export default ResolveIssueDialog
