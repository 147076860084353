export const PasswordRequirements = ({ password = '' }) => {
  const requirements = [
    { label: 'ABC', satisfies: () => /[A-Z]+/.test(password) },
    { label: 'abc', satisfies: () => /[a-z]+/.test(password) },
    { label: '123', satisfies: () => /[0-9]+/.test(password) },
    { label: '!@%', satisfies: () => /[_#?!@$%^&*-]+/.test(password) },
    { label: '8 Chars', satisfies: () => password.length >= 8 },
  ]

  return (
    <div className="stack mediumText">
      {requirements.map((item, index) => (
        <div
          key={item.label}
          className={`column-list ${
            index !== requirements.length - 1 ? 'divider' : ''
          }`}
        >
          {item.label}
          {item.satisfies() ? (
            <span style={{ color: 'green' }}>✓</span>
          ) : (
            <span style={{ color: 'red' }}>✕</span>
          )}
        </div>
      ))}
    </div>
  )
}
