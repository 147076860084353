import axios from 'services/customAxios'
import { pathOr, head } from 'ramda'
import { getItem } from 'utils/localstorage'
import { getEndpoints } from 'constants/endpoints'

export const getIssues = async (assignmentDetailId) => {
  try {
    const endpoint = getEndpoints({ assignmentDetailId }).available_issues
    const response = await axios.get(endpoint)
    return response?.data
  } catch (error) {
    console.error('Error while fetching issue', error)
  }
}

export const submitIssue = async (payload, assignmentDetailId) => {
  try {
    const endpoint = getEndpoints({ assignmentDetailId }).submitIssue
    const response = await axios.post(endpoint, payload)

    return {
      success: true,
      data: response.data,
      status: response.status,
    }
  } catch (error) {
    console.error('Error while Creating Issue', error)

    return {
      success: false,
      error: error.response ? error.response.data : 'Failed to Create issue',
      status: error.response ? error.response.status : 500,
    }
  }
}

export const distributeTripGroups = async () => {
  try {
    const { vendor_id: vendorId } = getItem('userData')
    const endpoint = getEndpoints({ vendorId }).distributeTrip
    const response = await axios.get(endpoint)
    return response.data
  } catch (error) {
    console.error('Error while distributing trip:', error)
  }
}

export const submitDistributeTrip = async (payload, individualLot) => {
  try {
    const vendorData = getItem('vendor')
    const roleId = pathOr('', ['personnel_data', 'role_id'], head(vendorData))
    const endpoint = individualLot
      ? getEndpoints().submitDistributeTripAssignments
      : getEndpoints().submitDistributeTrips
    if (roleId === 2) {
      delete payload.group_id
    }
    const response = await axios.patch(endpoint, payload)

    return {
      success: true,
      data: response.data,
      status: response.status,
    }
  } catch (error) {
    console.error('Error while Creating Issue', error)

    return {
      success: false,
      error: error.response ? error.response.data : 'Failed to Create issue',
      status: error.response ? error.response.status : 500,
    }
  }
}

export const getIssueDetail = async (assignmentDetailId) => {
  try {
    const endpoint = getEndpoints({ assignmentDetailId }).getIssueDetail
    const response = await axios.get(endpoint)
    return response.data.data
  } catch (error) {
    console.error('Error while getting issue details', error)
  }
}

export const submitResolveIssue = async (payload, assignmentDetailId) => {
  try {
    const endpoint = getEndpoints({ assignmentDetailId }).resolveIssue
    const response = await axios.patch(endpoint, payload)

    return {
      success: true,
      data: response.data,
      status: response.status,
    }
  } catch (error) {
    console.error('Error while Creating Issue', error)

    return {
      success: false,
      error: error.response ? error.response.data : 'Failed to Create issue',
      status: error.response ? error.response.status : 500,
    }
  }
}

export const escalateIssue = async (payload, assignmentDetailId) => {
  try {
    const endpoint = getEndpoints({ assignmentDetailId }).escalate
    const response = await axios.patch(endpoint, payload)

    return {
      success: true,
      data: response.data,
      status: response.status,
    }
  } catch (error) {
    console.error('Error while Escalating Issue', error)

    return {
      success: false,
      error: error.response ? error.response.data : 'Failed to Escalate issue',
      status: error.response ? error.response.status : 500,
    }
  }
}

export const markAsRead = async (assignmentDetailId, messageId) => {
  try {
    const endpoint = getEndpoints({ assignmentDetailId, messageId }).markAsRead
    const response = await axios.patch(endpoint)

    return {
      success: true,
      data: response.data,
      status: response.status,
    }
  } catch (error) {
    console.error('Error while Creating Issue', error)

    return {
      success: false,
      error: error.response ? error.response.data : 'Failed to Create issue',
      status: error.response ? error.response.status : 500,
    }
  }
}

export const sendEmail = async (payload) => {
  try {
    const endpoint = getEndpoints().sendEmail
    const response = await axios.post(endpoint, payload)

    return {
      success: true,
      data: response.data,
      status: response.status,
    }
  } catch (error) {
    console.error('Error while Sending Email', error)

    return {
      success: false,
      error: error.response ? error.response.data : 'Failed to Send Email',
      status: error.response ? error.response.status : 500,
    }
  }
}
