import React, { useEffect, useState } from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import UploadIcon from '@mui/icons-material/CloudUpload'
import DeleteIcon from '@mui/icons-material/Delete'

const ImageUploadBox = ({
  text,
  uploadedImage = () => {},
  onDeleteImage = () => {},
  image = null,
}) => {
  const [imageSrc, setImageSrc] = useState(null)

  useEffect(() => {
    setImageSrc(image)
  }, [image])

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    uploadedImage(file)
  }

  const handleDeleteImage = () => {
    setImageSrc(null)
    onDeleteImage()
  }

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{
          height: '2rem',
        }}
      >
        <Typography
          variant="caption"
          style={{
            color: 'black',
            fontSize: '1rem',
          }}
        >
          {text}
        </Typography>
        {imageSrc && (
          <IconButton onClick={handleDeleteImage} sx={{ color: 'red' }}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>

      <Box
        border={imageSrc ? 'none' : '1px dashed #ccc'}
        borderRadius="3px"
        height="180px"
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        mb={2}
      >
        {!imageSrc && (
          <IconButton component="label">
            <input
              type="file"
              hidden
              onChange={handleImageChange}
              accept=".jpg, .jpeg, .png"
            />
            <UploadIcon />
          </IconButton>
        )}

        {imageSrc && (
          <img
            src={imageSrc}
            style={{ width: '100%', height: '100%', position: 'absolute' }}
            alt={text}
          />
        )}
        <Typography>Upload {text}</Typography>
      </Box>
    </div>
  )
}

export default ImageUploadBox
