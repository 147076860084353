import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  TextField,
  MenuItem,
  CircularProgress,
  Typography,
  Box,
  FormControl,
  Autocomplete,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useAtom } from 'jotai'
import {
  head,
  compose,
  uniq,
  flatten,
  map,
  isEmpty,
  pathOr,
  propOr,
  reject,
  innerJoin,
} from 'ramda'
import { useSnackbar } from 'notistack'
import CloseIcon from '@mui/icons-material/Close'
import { DistributeTripIcon } from 'components/icons/icons'
import MultiViewSidebar from 'components/views/dialogs/multiViewSidebar'
import { moveToNextItemInMultiView } from 'utils/dispatchutils'

import Table from 'components/main/Table/Table'
import {
  selectedViewAtom,
  declineReasonsAtom,
  globalPostAtom,
  actionCodeAtom,
  completedRowDataAtom,
} from 'constants/atoms'
import { columnConfig } from 'constants/columns'
import {
  submitDistributeTrip,
  distributeTripGroups,
} from 'services/lotsViewServicesApi'
import { getDeclineReasons, updateTransitions } from 'services/tripsApi'
import { useStyles } from 'utils/styles'
import { ACTION_CODES } from 'constants/actionCodes'
import { getItem } from 'utils/localstorage'

const defaultData = {
  assignment_detail_ids: '',
  group_id: '',
  personnel_id: '',
  trip_name: '',
  decline_reason: '',
  decline_subreason: '',
  comments: '',
  distributeTo: '',
}

const AcknowledgeTripDialog = ({ rowData = [], onClose }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [actionCode, setActionCode] = useAtom(actionCodeAtom)
  const [selectedView] = useAtom(selectedViewAtom)
  const [declineReasons, setDeclineReasons] = useAtom(declineReasonsAtom)
  const [globalLoading, setGlobalLoading] = useAtom(globalPostAtom)
  const [groupData, setGroupData] = useState([])
  const [selectedRowData, setSelectedRowData] = useState(head(rowData))
  const [completedTrips, setCompletedTrips] = useAtom(completedRowDataAtom)
  const [formData, setFormData] = useState(defaultData)
  const classes = useStyles()
  const userData = getItem('userData')
  const vendorId = pathOr('', ['vendor', 'vendor_id'], userData)
  const vendorData = getItem('vendor')
  const allGroups = propOr(
    [],
    'groups',
    vendorData.find((v) => v.vendor_id === vendorId),
  )
  const groupstoUse = allGroups.filter(
    (group) => group.dispatch_group_name !== 'DEFAULT DISPATCH GROUP',
  )
  const roleId = pathOr('', ['vendor', 'role_id'], userData)

  const { trip_id: selectedTripId = '' } = selectedRowData

  const allAssignmentDetailId = selectedRowData?.assignments?.map(
    ({ dispatch_assignment_detail_id: id }) => id,
  )

  const resetFormData = () => {
    setFormData(defaultData)
  }

  const updateCompletedTrips = () => {
    setCompletedTrips([...completedTrips, selectedTripId])
  }

  useEffect(() => {
    const fetchData = async () => {
      const apiData = await distributeTripGroups()
      if (roleId === 2) {
        const finalGroups = innerJoin(
          (r1, r2) => r1.dispatch_group_id === r2.dispatch_group_id,
          apiData.data,
          groupstoUse,
        )
        setGroupData(finalGroups)
      } else {
        setGroupData(apiData.data)
      }
    }
    fetchData()
  }, [rowData])

  useEffect(() => {
    if (isEmpty(completedTrips)) {
      return
    }
    if (rowData.length === completedTrips.length) {
      handleClose()
    } else if (rowData.length > completedTrips.length) {
      let curIndex = rowData.indexOf(selectedRowData)
      const nextIndex = moveToNextItemInMultiView(
        curIndex,
        completedTrips,
        rowData,
        'trip_id',
      )
      setSelectedRowData(rowData[nextIndex])
      resetFormData()
    }
  }, [completedTrips])

  const renderRows = (data) => {
    return data.map((item, index) => ({
      id: index + 1,
      ...item,
    }))
  }

  const areRequiredFieldsFilled = () => {
    const currentDeclineReason = declineReasons?.data?.find(
      (reason) => reason.code === formData.decline_reason,
    )
    if (!currentDeclineReason) return false

    if (
      formData.decline_reason === 'other' ||
      formData.decline_subreason === 'other'
    ) {
      return Boolean(formData.comments)
    }

    if (currentDeclineReason?.subtypes.length === 0) {
      return Boolean(formData.decline_reason)
    }

    return ['decline_reason', 'decline_subreason'].every((field) =>
      Boolean(formData[field]),
    )
  }

  const groupOptions = compose(
    uniq,
    flatten,
    map(
      ({
        dispatch_group_id: groupId = '',
        dispatch_group_name: groupDesc = '',
        personnel = [],
      } = {}) =>
        personnel
          .filter(Boolean)
          .map(
            ({
              vendor_personnel_id: personnelId,
              first_name: firstName,
              last_name: lastName,
            }) => ({
              key: `${groupId}-${personnelId}`,
              label: `${firstName} ${lastName}`,
              personnelId,
              groupId,
              groupDesc,
            }),
          ),
    ),
  )(groupData || [])

  const handleInputChange = ({ target: { name, value } }) => {
    if (name === 'decline_reason') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        decline_subreason: '',
      }))
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }))
    }
  }

  const handleSelectChange = (e, value) => {
    if (value) {
      const { groupId, personnelId } = value
      setFormData((prevData) => ({
        ...prevData,
        group_id: groupId,
        personnel_id: personnelId,
        assignment_detail_ids: allAssignmentDetailId,
      }))
    } else {
      setFormData((prevData) => ({
        ...prevData,
        group_id: '',
        personnel_id: '',
        assignment_detail_ids: '',
      }))
    }
  }

  const handleClose = () => {
    setFormData(defaultData)
    setActionCode('')
    onClose()
  }

  const handleAcknowledge = async () => {
    const payload = {
      action: 'acknowledge',
      trip_ids: [selectedRowData.trip_id],
      userAction: 'acknowledge',
    }
    const response = await updateTransitions(payload)
    if (response.status === 'success') {
      updateCompletedTrips()
      setGlobalLoading(true)
      enqueueSnackbar('Acknowledged Successfully', {
        variant: 'success',
      })
    } else {
      enqueueSnackbar('Failed to Acknowledge', {
        variant: 'error',
      })
    }
  }

  const handleDecline = async () => {
    const payload = {
      action: 'decline',
      trip_ids: [selectedRowData.trip_id],
      decline_reason: formData.decline_reason,
    }
    const response = await updateTransitions(payload)
    if (response && response.status === 'success') {
      updateCompletedTrips()
      setGlobalLoading(true)
      enqueueSnackbar('Decline Successfully', {
        variant: 'success',
      })
    } else {
      enqueueSnackbar('Failed to Decline', {
        variant: 'error',
      })
    }
  }

  const handleDistribute = async () => {
    const payload = {
      group_id: formData.group_id,
      personnel_id: formData.personnel_id,
      trip_ids: [selectedRowData.trip_id],
      trip_name: selectedRowData.trip_name,
      assignment_detail_ids: allAssignmentDetailId,
    }
    const result = await submitDistributeTrip(payload)

    if (result.success) {
      updateCompletedTrips()
      setGlobalLoading(true)
      enqueueSnackbar('Trip is distributed successfully', {
        variant: 'success',
      })
    } else {
      enqueueSnackbar('Error occurred while distributing trip', {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    const fetchDeclineReasons = async () => {
      const declineReasons = await getDeclineReasons()
      setDeclineReasons(declineReasons)
    }
    fetchDeclineReasons()
  }, [setDeclineReasons])

  return (
    <Dialog
      open={actionCode === ACTION_CODES.ACKNOWLEDGE_TRIP}
      onClose={handleClose}
      fullWidth
      scroll="paper"
      maxWidth="lg"
    >
      <DialogTitle style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '10px',
            whiteSpace: 'nowrap',
            color: 'white',
          }}
        >
          <DistributeTripIcon color="white" />
          <div style={{ display: 'flex', columnGap: '10px' }}>
            <Typography
              classes={{
                root: classes.root,
              }}
            >
              Acknowledge Trip - {selectedTripId}
            </Typography>
          </div>

          <IconButton
            onClick={handleClose}
            style={{ position: 'absolute', right: '8px', top: '8px' }}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ display: 'flex' }}>
          {rowData.length > 1 && (
            <MultiViewSidebar
              selectedRowData={selectedRowData}
              setSelectedRowData={setSelectedRowData}
              tripsView
              completedRowData={completedTrips}
              resetFormData={resetFormData}
            />
          )}
          {globalLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div
              className="col-2-3"
              style={
                rowData.length > 1
                  ? {
                      borderLeft: '1px solid rgba(0,0,0,0.12)',
                      paddingLeft: '10px',
                    }
                  : {}
              }
            >
              <Box sx={{ my: 1 }}>
                <Grid container spacing={0}>
                  <Grid item xs={0}>
                    <Typography variant="h6">Pickup: &nbsp;</Typography>
                  </Grid>{' '}
                  <Grid item xs={0.5}>
                    <Typography variant="h6">
                      {selectedRowData
                        ? selectedRowData?.assignments.filter(
                            ({ form_type: tripType }) => tripType !== 'D',
                          ).length
                        : 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={0}>
                    <Typography variant="h6">Delivery: &nbsp;</Typography>
                  </Grid>{' '}
                  <Grid item xs={0}>
                    <Typography variant="h6">
                      {selectedRowData
                        ? selectedRowData?.assignments.filter(
                            ({ form_type: tripType }) => tripType === 'D',
                          ).length
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl>
                    <Autocomplete
                      disablePortal
                      options={groupOptions}
                      sx={{ width: rowData.length > 1 ? 500 : 550 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Distribute To" />
                      )}
                      groupBy={(option) => option.groupDesc}
                      onChange={handleSelectChange}
                      ListboxProps={{ style: { maxHeight: '250px' } }}
                    />
                  </FormControl>
                </Grid>
                {formData.group_id && (
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Trip Name"
                      variant="outlined"
                      name="trip_name"
                      value={formData.trip_name}
                      onChange={(event) => {
                        const { name, value } = event.target
                        setFormData((prevData) => ({
                          ...prevData,
                          [name]: value,
                        }))
                      }}
                    />
                  </Grid>
                )}
              </Grid>
              {selectedRowData && (
                <Grid style={{ marginTop: '10px' }}>
                  <Table
                    columns={columnConfig()['distributeTripDialog']}
                    rows={renderRows(
                      selectedView === 'tripsView'
                        ? selectedRowData.assignments
                        : rowData,
                    )}
                    defaultHeight={true}
                    showCheckbox={false}
                    showPagination={false}
                  ></Table>
                </Grid>
              )}
              <Grid container spacing={2} sx={{ paddingTop: '10px' }}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    required
                    select
                    label="Decline Reason"
                    variant="outlined"
                    name="decline_reason"
                    value={formData.decline_reason}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="">None</MenuItem>
                    {declineReasons?.data &&
                      declineReasons?.data.map((issue) => (
                        <MenuItem key={issue.code} value={issue.code}>
                          {issue.description}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                {formData.decline_reason &&
                  declineReasons?.data.find(
                    (reason) => reason.code === formData.decline_reason,
                  )?.subtypes.length > 0 && (
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        required
                        select
                        label="Decline Sub Reason"
                        variant="outlined"
                        name="decline_subreason"
                        value={formData.decline_subreason}
                        onChange={handleInputChange}
                      >
                        {declineReasons?.data
                          .find(
                            (reason) => reason.code === formData.decline_reason,
                          )
                          ?.subtypes.map((subtype) => (
                            <MenuItem key={subtype.code} value={subtype.code}>
                              {subtype.description}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                  )}
              </Grid>
              {formData.decline_reason && formData.decline_subreason && (
                <Grid item xs={6} spacing={2}>
                  <TextField
                    label="Provide Decline Reason"
                    variant="outlined"
                    required={
                      formData.decline_reason === 'other' ||
                      formData.decline_subreason === 'other'
                    }
                    name="comments"
                    value={formData.comments}
                    onChange={handleInputChange}
                    sx={{ paddingTop: '10px', width: '50%' }}
                  ></TextField>
                </Grid>
              )}
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={globalLoading}
          variant="contained"
          onClick={handleDecline}
          disabled={!areRequiredFieldsFilled()}
        >
          Decline
        </LoadingButton>
        {!formData.group_id && (
          <LoadingButton
            loading={globalLoading}
            variant="contained"
            onClick={handleAcknowledge}
            sx={{ backgroundColor: 'green' }}
            disabled={formData.decline_reason}
          >
            Acknowledge
          </LoadingButton>
        )}
        {formData.group_id && (
          <LoadingButton
            loading={globalLoading}
            variant="contained"
            color="primary"
            onClick={handleDistribute}
            disabled={formData.decline_reason}
          >
            Distribute
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default AcknowledgeTripDialog
