import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  CircularProgress,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useAtom } from 'jotai'
import { head, isEmpty } from 'ramda'
import { useSnackbar } from 'notistack'
import MultiViewSidebar from '../multiViewSidebar'
import DialogHeader from 'utils/dialogHeader'
import { moveToNextItemInMultiView } from 'utils/dispatchutils'
import {
  globalPostAtom,
  actionCodeAtom,
  resolveIssueAtom,
  completedRowDataAtom,
} from 'constants/atoms'
import {
  getIssueDetail,
  submitResolveIssue,
  escalateIssue,
} from 'services/lotsViewServicesApi'
import { ACTION_CODES } from 'constants/actionCodes'

const OverrideIssueDialog = ({ rowData, onClose, action }) => {
  const [actionCode, setActionCode] = useAtom(actionCodeAtom)
  const [issueData, setIssueData] = useState(null)
  const [comments, setComments] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(head(rowData))
  const [createdIssues, setCreatedIssues] = useAtom(completedRowDataAtom)
  const [globalLoading, setGlobalLoading] = useAtom(globalPostAtom)
  const [resolveIssue, setResolveIssue] = useAtom(resolveIssueAtom)
  const { enqueueSnackbar } = useSnackbar()

  const {
    dispatch_assignment_detail_id: selectedAssignmentId = '',
    lot_number: selectedLotNumber = '',
  } = selectedRowData

  const {
    message_type_description: messageType = '',
    message_subtype_description: messageSubtype = '',
  } = issueData ? issueData : {}

  useEffect(() => {
    const fetchData = async () => {
      setComments('')
      setLoading(true)
      const apiData = await getIssueDetail(selectedAssignmentId)
      setIssueData(apiData)
      setLoading(false)
    }
    fetchData()
  }, [selectedRowData])

  useEffect(() => {
    if (isEmpty(createdIssues)) {
      return
    }
    if (rowData.length === createdIssues.length) {
      handleClose()
    } else if (rowData.length > createdIssues.length) {
      let curIndex = rowData.indexOf(selectedRowData)
      const nextIndex = moveToNextItemInMultiView(
        curIndex,
        createdIssues,
        rowData,
        'lot_number',
      )
      setSelectedRowData(rowData[nextIndex])
    }
  }, [createdIssues])

  const handleSubmit = async () => {
    let result
    if (action === 'overrideRejection') {
      result = await submitResolveIssue(
        { resolution_action: 'override', comments: comments },
        selectedAssignmentId,
      )
    } else {
      result = await escalateIssue(
        { action: 'escalate', comments: comments },
        selectedAssignmentId,
      )
    }
    if (result.success) {
      setGlobalLoading(true)
      enqueueSnackbar(`Successfully resolved issue ${selectedLotNumber}`, {
        variant: 'success',
      })
      setCreatedIssues([...createdIssues, selectedLotNumber])
    } else {
      enqueueSnackbar('Failed to resolve issue', {
        variant: 'error',
      })
    }
  }

  const isSaveButtonDisabled = () => {
    if (action === 'overrideRejection' && !comments) {
      return true
    }
    return globalLoading
  }

  const handleClose = () => {
    setActionCode('')
    setResolveIssue(false)
    onClose()
  }

  return (
    <Dialog
      open={
        actionCode === ACTION_CODES.OVERRIDE_ISSUE ||
        actionCode === ACTION_CODES.CANT_CLEAR
      }
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      scroll="paper"
    >
      <DialogTitle style={{ display: 'flex' }}>
        <DialogHeader
          selectedRowData={selectedRowData}
          handleClose={handleClose}
        />
      </DialogTitle>

      <DialogContent dividers maxWidth="xs">
        <div style={{ display: 'flex', width: '100%' }}>
          {rowData.length > 1 && (
            <MultiViewSidebar
              selectedRowData={selectedRowData}
              setSelectedRowData={setSelectedRowData}
              completedRowData={createdIssues}
            />
          )}
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div className="col-2-3" style={{ width: '100%' }}>
              {issueData ? (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Issue Reason"
                      variant="standard"
                      name="issueDetails"
                      value={`${messageType} - ${messageSubtype}`}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={
                        action === 'overrideRejection'
                          ? 'Override Reason'
                          : 'Comments'
                      }
                      required={action === 'overrideRejection'}
                      variant="standard"
                      name="issueDetails"
                      onChange={(event) => setComments(event.target.value)}
                      value={comments}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </div>
          )}
        </div>
      </DialogContent>

      <DialogActions>
        {resolveIssue && (
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={() => setActionCode(ACTION_CODES.RESOLVE_ISSUE)}
            style={{ marginRight: 'auto' }}
          >
            Back
          </LoadingButton>
        )}
        <LoadingButton
          loading={globalLoading}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isSaveButtonDisabled()}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default OverrideIssueDialog
