import React, { useEffect } from 'react'
import { atom, useAtom } from 'jotai'
import { CircularProgress } from '@mui/material'
import Header from './header/header'
import Breadcrumbs from './breadcrumbs'
import { ActionBar } from './actionBar'
import AppNavigator from './sidebar'
import {
  loadingAtom,
  selectedSubViewAtom,
  selectedViewAtom,
  dataFetchLoadingAtom,
  rowDataAtom,
  pageAtom,
  tableRecordsAtom,
  selectedRecordsAtom,
  selectedRowAtom,
  mapViewAtom,
  refreshDataAtom,
} from 'constants/atoms'
import { getItem } from 'utils/localstorage'
import Viewpages from 'components/views/viewPages'
import RenderDialog from 'components/views/dialogs'
import '../../appDefaultStyles.scss'

const navigatorOpenAtom = atom(false)
const openNavigatorOnHoverAtom = atom(false)

let intervalId = null

const Main = ({ logOutOnClick }) => {
  const [loading] = useAtom(loadingAtom)
  const [, setDataFetchLoading] = useAtom(dataFetchLoadingAtom)
  const [navigatorOpen, setNavigatorOpen] = useAtom(navigatorOpenAtom)
  const [selectedView, setSelectedView] = useAtom(selectedViewAtom)
  const [selectedSubView, setSelectedSubView] = useAtom(selectedSubViewAtom)
  const [, setRefreshAtom] = useAtom(refreshDataAtom)
  const [openNavigatorOnHover, setOpenNavigatorOnHover] = useAtom(
    openNavigatorOnHoverAtom,
  )
  const [rowData] = useAtom(rowDataAtom)
  const [, setPage] = useAtom(pageAtom)
  const [, setTableRecords] = useAtom(tableRecordsAtom)
  const [, setSelectedRecords] = useAtom(selectedRecordsAtom)
  const [, setSelectedRows] = useAtom(selectedRowAtom)
  const [mapView, setMapViewAtom] = useAtom(mapViewAtom)
  const vendorSelectionMode = getItem('vendorSelectionMode')

  const removeInterval = () => {
    if (intervalId) {
      clearInterval(intervalId)
    }
  }

  useEffect(() => {
    if (!mapView) {
      removeInterval()
      intervalId = setInterval(() => {
        setRefreshAtom(true)
      }, 180000)
    } else {
      removeInterval()
    }
    return () => removeInterval()
  }, [selectedView, mapView])

  const handleLogout = () => {
    setSelectedView('tripsView')
    setSelectedSubView('assigned')
    logOutOnClick()
  }

  useEffect(() => {
    setPage(0)
    setTableRecords(0)
  }, [selectedView, selectedSubView, setPage, setTableRecords])

  return loading ? (
    <CircularProgress />
  ) : (
    <div className="mainDiv">
      <Header logOutOnClick={handleLogout} />
      {!vendorSelectionMode && (
        <Breadcrumbs
          selectedView={selectedView}
          selectedSubView={selectedSubView}
          mapView={mapView}
        />
      )}
      {!vendorSelectionMode && (
        <div
          id="mainContent"
          style={{ flexDirection: 'row', display: 'flex', flex: 1 }}
        >
          <AppNavigator
            openNavigator={navigatorOpen}
            openNavigatorOnHover={openNavigatorOnHover}
            handleNavigatorOpen={(isOpen) => setNavigatorOpen(isOpen)}
            handleNavigatorOpenOnHover={(isOpen) =>
              setOpenNavigatorOnHover(isOpen)
            }
            handleNavigatorItemClick={(item) => {
              setSelectedView(item)
              setMapViewAtom(false)
              if (item !== 'tripsView') {
                setSelectedSubView(null)
              }
              setSelectedRecords(0)
              setSelectedRows([])
              setDataFetchLoading(true)
            }}
            selectedNavigatorItem={selectedView}
            setSelectedView={setSelectedView}
          />
          <div className="fullWidth" id="contentTableWrap">
            <ActionBar />
            <Viewpages
              selectedView={selectedView}
              selectedSubView={selectedSubView}
              setSelectedView={setSelectedView}
              setSelectedSubView={setSelectedSubView}
            ></Viewpages>
            <RenderDialog rowData={rowData} />
          </div>
        </div>
      )}
    </div>
  )
}

export default Main
