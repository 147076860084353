import React from 'react'

export const CreateIssueIcon = ({ color = '#000000' }) => (
  <svg
    fill={color}
    width="30"
    height="24"
    viewBox="0 0 300 240"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M121.492 30C127.234 30 132.531 33.0301 135.442 37.9996L222.776 186.678C225.728 191.688 225.728 197.869 222.857 202.879C219.986 207.889 214.609 211 208.827 211H34.1581C28.3762 211 22.9987 207.889 20.128 202.879C17.2573 197.869 17.2977 191.648 20.2089 186.678L107.543 37.9996C110.454 33.0301 115.751 30 121.492 30ZM121.492 81.7143C116.115 81.7143 111.789 86.0373 111.789 91.4107V136.661C111.789 142.034 116.115 146.357 121.492 146.357C126.87 146.357 131.196 142.034 131.196 136.661V91.4107C131.196 86.0373 126.87 81.7143 121.492 81.7143ZM134.431 172.214C134.431 168.785 133.068 165.497 130.641 163.072C128.215 160.648 124.924 159.286 121.492 159.286C118.061 159.286 114.77 160.648 112.344 163.072C109.917 165.497 108.554 168.785 108.554 172.214C108.554 175.643 109.917 178.932 112.344 181.356C114.77 183.781 118.061 185.143 121.492 185.143C124.924 185.143 128.215 183.781 130.641 181.356C133.068 178.932 134.431 175.643 134.431 172.214Z"
      fill={color}
    />
    <path
      d="M261.5 51.625H233.375V23.5C233.375 20.0488 230.576 17.25 227.125 17.25H220.875C217.424 17.25 214.625 20.0488 214.625 23.5V51.625H186.5C183.049 51.625 180.25 54.4238 180.25 57.875V64.125C180.25 67.5762 183.049 70.375 186.5 70.375H214.625V98.5C214.625 101.951 217.424 104.75 220.875 104.75H227.125C230.576 104.75 233.375 101.951 233.375 98.5V70.375H261.5C264.951 70.375 267.75 67.5762 267.75 64.125V57.875C267.75 54.4238 264.951 51.625 261.5 51.625Z"
      fill={color}
    />
  </svg>
)

export const DistributeTripIcon = ({ color = '#000000' }) => (
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <polygon
        id="path-1"
        points="0.309341645 0.166602019 6.60251669 0.166602019 6.60251669 9.43796721 0.309341645 9.43796721"
      />
      <polygon
        id="path-3"
        points="0.309341645 0.166819672 6.60251669 0.166819672 6.60251669 8.43796721 0.309341645 8.43796721"
      />
      <polygon
        id="path-5"
        points="0.309341645 0.166819672 6.60251669 0.166819672 6.60251669 8.43796721 0.309341645 8.43796721"
      />
    </defs>
    <g id="App-Pages" fill="none" fillRule="evenodd">
      <g id="Distribute-trip" transform="translate(-272 -74)">
        <g id="distributed_btn" transform="translate(269 74)">
          <g id="Group-3" transform="translate(11)">
            <mask id="mask-2" fill="#fff">
              <use xlinkHref="#path-1" />
            </mask>
            <path
              d="M3.13384918,0.334622951 L0.384078689,4.25881967 C0.255029508,4.44059016 0.297127869,4.69278689 0.479291803,4.82222951 C0.545783607,4.86944262 0.624865574,4.89540984 0.706308197,4.89698361 L1.6715541,4.89698361 L1.6715541,8.43796721 L5.24532459,8.43796721 L5.24532459,4.89698361 L6.20545574,4.89698361 C6.42893115,4.89304918 6.60676721,4.70891803 6.60243934,4.48583607 C6.60125902,4.40439344 6.5752918,4.32531148 6.52807869,4.25881967 L3.7783082,0.334622951 C3.65358689,0.156393443 3.40807869,0.113114754 3.23024262,0.237836066 C3.19247213,0.264196721 3.15981639,0.296852459 3.13384918,0.334622951 Z"
              id="Fill-1"
              fill={color}
              mask="url(#mask-2)"
            />
          </g>
          <g id="Group-2" transform="translate(0 2)">
            <g id="Group-3-Copy" transform="rotate(-45 7.414 1.879)">
              <mask id="mask-4" fill="#fff">
                <use xlinkHref="#path-3" />
              </mask>
              <path
                d="M3.13384918,0.334622951 L0.384078689,4.25881967 C0.255029508,4.44059016 0.297127869,4.69278689 0.479291803,4.82222951 C0.545783607,4.86944262 0.624865574,4.89540984 0.706308197,4.89698361 L1.6715541,4.89698361 L1.6715541,8.43796721 L5.24532459,8.43796721 L5.24532459,4.89698361 L6.20545574,4.89698361 C6.42893115,4.89304918 6.60676721,4.70891803 6.60243934,4.48583607 C6.60125902,4.40439344 6.5752918,4.32531148 6.52807869,4.25881967 L3.7783082,0.334622951 C3.65358689,0.156393443 3.40807869,0.113114754 3.23024262,0.237836066 C3.19247213,0.264196721 3.15981639,0.296852459 3.13384918,0.334622951 Z"
                id="Fill-1"
                fill={color}
                mask="url(#mask-4)"
              />
            </g>
            <g id="Group-3-Copy-2" transform="rotate(45 10.586 28.435)">
              <mask id="mask-6" fill="#fff">
                <use xlinkHref="#path-5" />
              </mask>
              <path
                d="M3.13384918,0.334622951 L0.384078689,4.25881967 C0.255029508,4.44059016 0.297127869,4.69278689 0.479291803,4.82222951 C0.545783607,4.86944262 0.624865574,4.89540984 0.706308197,4.89698361 L1.6715541,4.89698361 L1.6715541,8.43796721 L5.24532459,8.43796721 L5.24532459,4.89698361 L6.20545574,4.89698361 C6.42893115,4.89304918 6.60676721,4.70891803 6.60243934,4.48583607 C6.60125902,4.40439344 6.5752918,4.32531148 6.52807869,4.25881967 L3.7783082,0.334622951 C3.65358689,0.156393443 3.40807869,0.113114754 3.23024262,0.237836066 C3.19247213,0.264196721 3.15981639,0.296852459 3.13384918,0.334622951 Z"
                id="Fill-1"
                fill={color}
                mask="url(#mask-6)"
              />
            </g>
          </g>
          <path
            d="M14.7693115,8 C10.4784262,8 7,11.4784262 7,15.768918 C7,20.0594098 10.4784262,23.5378361 14.7693115,23.5378361 C19.0598033,23.5378361 22.5382295,20.0594098 22.5382295,15.768918 C22.5382295,11.4784262 19.0598033,8 14.7693115,8 M14.769277,10.9836066 C17.4079158,10.9836066 19.5545885,13.1302792 19.5545885,15.768918 C19.5545885,18.4075568 17.4079158,20.5542295 14.769277,20.5542295 C12.1306382,20.5542295 9.98364102,18.4075568 9.98364102,15.768918 C9.98364102,13.1302792 12.1306382,10.9836066 14.769277,10.9836066"
            id="Fill-10"
            fill={color}
          />
        </g>
      </g>
    </g>
  </svg>
)

export const ResolveIssueIcon = ({ color = '#000000' }) => (
  <svg
    fill={color}
    width="30"
    height="24"
    viewBox="0 0 300 240"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M120.492 40C126.234 40 131.531 43.0301 134.442 47.9996L221.776 196.678C224.728 201.688 224.728 207.869 221.857 212.879C218.986 217.889 213.609 221 207.827 221H33.1581C27.3762 221 21.9987 217.889 19.128 212.879C16.2573 207.869 16.2977 201.648 19.2089 196.678L106.543 47.9996C109.454 43.0301 114.751 40 120.492 40ZM120.492 91.7143C115.115 91.7143 110.789 96.0373 110.789 101.411V146.661C110.789 152.034 115.115 156.357 120.492 156.357C125.87 156.357 130.196 152.034 130.196 146.661V101.411C130.196 96.0373 125.87 91.7143 120.492 91.7143ZM133.431 182.214C133.431 178.785 132.068 175.497 129.641 173.072C127.215 170.648 123.924 169.286 120.492 169.286C117.061 169.286 113.77 170.648 111.344 173.072C108.917 175.497 107.554 178.785 107.554 182.214C107.554 185.643 108.917 188.932 111.344 191.356C113.77 193.781 117.061 195.143 120.492 195.143C123.924 195.143 127.215 193.781 129.641 191.356C132.068 188.932 133.431 185.643 133.431 182.214Z"
      fill={color}
    />
    <path
      d="M169 52L191.839 90.7733"
      stroke={color}
      stroke-width="23"
      stroke-miterlimit="5.75877"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M192.947 92.4941L263.658 21.7835"
      stroke={color}
      stroke-width="23"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
