import en from './en'

export default {
  ...en,
  'New Assignment for:': 'Nouvelle Assignation pour:',
  'Enter New Assignment': 'Nouvelle Assignation',
  'Contact:': 'Contact:',
  Actions: 'Actions',
  Showing: 'Résultats par page',
  Results: 'Résultats',
  Go: 'Aller',
  Of: 'De',
  goToPage: 'Aller à la page',
  'Loading ...': 'Chargement....',
  Driver: 'Conducteur',
  Group: 'Groupe',
  Back: 'Arrière',
  Skip: 'Sauter',
  Done: 'Fini',
  Close: 'Fermer',
  Next: 'Suivant',
  Standard: 'Norme',
  Medium: 'Moyen',
  Heavy: 'Lourd',
  Pickup: 'Récupération',
  'Owner Retain': 'Retrait du mandat',
  Delivery: 'Livraison',
  Transport: 'Transport',
  STOP: 'ARRÊT',
  Phone: 'Téléphone',
  'Create Trip': 'Créer un voyage',
  'Update Trip': 'Mettre à jour le voyage',
  'Check Generation': 'Création du chèque',
  'Check Review': 'Révision du chèque',
  'Awaiting Dispatch': "En attente d'attribution",
  'Action Reason Description': "Description de la raison de l'action",
  'Follow Up Due(HH:MM)': 'Moment du suivi requis (HH:MM)',
  'Adjuster Phone': "Numéro de téléphone de l'expert en sinistres",
  'Claim Number': 'Numéro de réclamation',
  'Updating ...': 'Mise à jour en cours...',
  'Unable to acquire lock on the lot. Please try another lot.':
    'Impossible de verrouiller le lot. Veuillez essayer avec un autre lot.',
  'Data Saved.': 'Données enregistrées.',
  'Could not get data. Please refresh the page.':
    'Impossible de récupérer les données. Veuillez actualiser la page.',
  'No Promoter has been assigned for this Yard - Lane':
    "Aucun promoteur n'a été affecté à ce dépôt/voie",
  'Promoter Registered': 'Promoteur inscrit',
  'Promoter Unregistered': 'Promoteur désinscrit',
  'The entered Promoter Email is invalid or you do not have the required access.':
    "L'adresse courriel entrée pour le promoteur est invalide, ou vous ne possédez pas les droits d'accès requis.",
  'Cannot update Promoter as the Auction is already Live!':
    'Impossible de mettre à jour le promoteur, car la vente aux enchères est déjà commencée!',
  'You might not have access to Production CAS. Please contact Helpdesk.':
    "Il est possible que vous n'ayez pas accès au Production CAS. Veuillez communiquer avec le centre d'aide.",
  'Service Order Added': 'Bon de travail ajouté',
  'Service Order Not Added: $1': 'Bon de travail non ajouté : 1 $',
  'Unable to retrieve make,model and year for the entered vin':
    "Impossible d'obtenir la marque, le modèle et l'année pour le NIV entré",
  'Vin Decode Failed': 'Le décodage du NIV a échoué.',
  'Email Sent Successfully.': 'Courriel envoyé avec succès.',
  'Unable to send email. Please try again.':
    "Impossible d'envoyer le courriel. Veuillez réessayer.",
  'Unable to update whether this has been reviewed or not':
    "Impossible de mettre à jour l'état de cet élément (révisé ou non)",
  'Unable to get whether lot has been reviewed or not':
    'Impossible de déterminer si ce lot a été révisé',
  'Lock timed out due to system inactivity!!':
    "Le verrou a expiré en raison de l'inactivité du système.",
  'Driver removed for lot ': 'Conducteur retiré pour le lot ',
  'Unable to Remove Driver': 'Impossible de retirer le conducteur',
  'Lot Number {0} was successfully Uncleared for Pickup and Charges':
    'La libération du lot numéro {0} a été annulée avec succès pour la récupération et les frais',
  'Unable to Unclear Lot': "Impossible d'annuler la libération du lot",
  'Unconfirm Trip Failed':
    "L'annulation de la confirmation du voyage a échoué.",
  'Trip has successfully been unconfirmed for Lot {0}.':
    'La confirmation du voyage a été annulée avec succès pour le lot {0}.',
  'Fetching P card details failed':
    "La récupération des détails de la carte d'achat a échoué.",
  'Printing Failed': "L'impression a échoué.",
  'Lot not in Current Yard': 'Lot non situé dans le dépôt actuel',
  'Invalid Lot Number': 'Numéro de lot non valide',
  'Trip confirmed for lot {0} successfully':
    'Voyage confirmé avec succès pour le lot {0}',
  'Unable to confirm Trip': 'Impossible de confirmer le voyage',
  'Trip Details saved for lot {0}':
    'Détails du voyage enregistrés pour le lot {0}',
  'Unable to Save Trip Details':
    "Impossible d'enregistrer les détails du voyage",
  'Split Trip performed for lot ': 'Découpage du voyage effectué pour le lot ',
  'Unable to Split Trip': 'Impossible de découper le voyage',
  'One Time Rate Override {0} for lot {1}':
    'Modification de tarif ponctuelle {0} pour le lot {1}',
  'Work Request Acknowledged': 'Réception de la demande de travail confirmée',
  'Driver Notes saved successfully':
    'Notes au conducteur enregistrées avec succès',
  'Supported file formats are: JPEG, PDF':
    'Les formats de fichiers pris en charge sont : JPEG, PDF',
  'Clear Selection': 'Annuler la sélection',
  'Upload File': 'Télécharger le fichier',
  'Select Document Type': 'Sélectionnez un type de documents',
  'Are All Pickup Docs Available ?':
    'Les documents de récupération sont-ils tous disponibles?',
  'Advance Charges Paid ?':
    "Est-ce qu'un montant a été payé pour le port antérieur?",
  'Do You Want to Override Tow Zone Rates ?':
    'Voulez-vous passer outre les tarifs des zones de remorquage?',
  'Over Ride Tow Zone Rate': 'Passer outre le tarif de la zone de remorquage',
  'Want To Remove From Junk Program ?':
    'Voulez-vous retirer cet élément du programme de ferraille?',
  'Was System Check Used ?': "Est-ce qu'un chèque du système a été utilisé?",
  'Do You Want To Check In ?': 'Voulez-vous vous inscrire?',
  'Do You Want To Un Check In ?': 'Voulez-vous vous désinscrire?',
  'Partial Trip Reason': 'Raison du voyage partiel',
  Sublot: 'Sous-lot',
  'Delivery Date': 'Date de livraison',
  'Owner Retain Date':
    "Date à laquelle l'élément a été retenu par le propriétaire",
  'Transport Date': 'Date de transport',
  'From Yard': 'Du dépôt',
  'To Location': "À l'emplacement",
  'Do You Want To Un Verify ?': "Voulez-vous annuler l'état « vérifié »?",
  'Arrival Date': "Date d'arrivée",
  'Do you want to cancel Yard-to-Yard Trip ?':
    "Voulez-vous annuler le voyage d'un dépôt à l'autre?",
  'Click to Email': 'Cliquer ici pour envoyer par courriel',
  'Please update 8 Digit Phone # in settings':
    'Veuillez mettre à jour le numéro de téléphone à huit chiffres dans les paramètres.',
  'Connecting 8 Digit Phone # ...':
    'Composition du numéro de téléphone à huit chiffres en cours...',
  'Call Failed. Please try again': "L'appel a échoué. Veuillez réessayer.",
  'Add charges breakdown': 'Ajouter la répartition des frais',
  'Put , for multiple emails.':
    "Pour entrer plusieurs adresses courriel, séparez-les à l'aide de virgules.",
  Send: 'Envoyer',
  'Send Email': 'Envoyer un courriel',
  'Location Notes': "Remarques sur l'emplacement",
  'Event Notes': "Remarques sur l'événement",
  'Are you sure you want to delete this trip ?':
    'Voulez-vous vraiment supprimer ce voyage?',
  'Enter a Lot Number to add a lot':
    'Entrez un numéro de lot pour ajouter un lot',
  'Click to Open Upload Dialog':
    'Cliquer ici pour ouvrir la boîte de dialogue de téléversement',
  'Enter Space separated Lot Numbers':
    "Entrez les numéros de lot en les séparant à l'aide d'espaces",
  'Lots To Search': 'Lots à rechercher',
  'Atlease one check needs to be Printed':
    'Au moins un chèque doit être imprimé',
  'Please select PRINT CHECK or remove First Check #':
    'Veuillez sélectionner IMPRIMER LE CHÈQUE ou retirer le numéro du premier chèque',
  'Please enter a First Check Number':
    'Veuillez entrer le numéro du premier chèque',
  'Print Successful': 'Impression réussie',
  'Check posted successfully, Proceed to print remaining checks':
    'Le chèque a été posté avec succès. Vous pouvez maintenant imprimer les chèques restants.',
  'Check posted successfully': 'Chèque posté avec succès',
  'Unable to Complete review': 'Impossible de terminer la révision',
  'No Lots for payments': 'Aucun lot associé aux paiements',
  'Insert Number of Blank Checks': 'Entrer le nombre de chèques en blanc',
  'Welcome to Dispatch 3.0!': 'Bienvenue dans Dispatch 3.0!',
  'Actions Bar': "Barre d'actions",
  'Drivers View': 'Vue des conducteurs',
  'Medium and Heavy Lots Awaiting Dispatch':
    "Lots moyens et lourds en attente d'attribution",
  Unconfirm: 'Annuler la confirmation',
  'Unclear Lot': 'Annuler la libération du lot',
  'Acknowledge on behalf of Tow Provider':
    'Confirmer au nom du fournisseur de services de remorquage',
  'Internal Dispatcher Comment': 'Commentaires du répartiteur interne',
  'Current Run / Capacity': 'Tournée de livraison et capacité actuelles',
  'License Restrictions': 'Restrictions du permis',
  Equipment: 'Équipement',
  Destination: 'Destination',
  'Assign & Print': 'Attribuer et imprimer',
  'Assign & Close': 'Attribuer et fermer',
  'Delete & Close': 'Supprimer et fermer',
  'Unclear & Close': 'Annuler la libération et fermer',
  'Override & Close': 'Passer outre et fermer',
  Search: 'Rechercher',
  Notes: 'Remarques',
  'Driver Decline Reason': 'Raison du refus par le conducteur',
  'Override Reason': 'Raison de la substitution',
  'Please enter override reason':
    'Veuillez indiquer la raison de la substitution',
  'Printed Documents': 'Documents imprimés',
  'Add Stop': 'Ajouter un arrêt',
  'Decline Reason': 'Raison du refus',
  'Remove Stop': "Retirer l'arrêt",
  'Refresh Routes': 'Actualiser les itinéraires',
  'Request Creation Date': 'Date de création de la demande',
  'Pickup Verification': 'Vérification de la récupération',
  'Trip Maintenance': 'Entretien lors du voyage',
  'Issue Resolution': 'Résolution du problème',
  'Issue Creation': 'Signalement du problème',
  'Override Rate Request': 'Demande pour passer outre les tarifs',
  'Advance Check Generation': "Préparation de chèques d'avance",
  'Remove Lot': 'Retirer le lot',
  'Driver Notes': 'Notes au conducteur',
  'Search using Trip Id, Created By':
    "Rechercher à l'aide du numéro de voyage ou du nom de la personne qui a créé le voyage",
  'Trip Name': 'Nom du transport',
  'Trip Date': 'Date du transport',
  'Tow Truck Type': 'Type de camion de remorquage',
  'Created By': 'Créé par',
  'Trip Comments': 'Commentaires sur le voyage',
  'Created Date': 'Date de création',
  'Filtered Lots': 'Lots filtrés',
  'Search with Tow Provider Name': 'Recherche avec le nom du remorqueur',
  'Search with Driver Name': "Rechercher à l'aide du nom du conducteur",
  'You are currently viewing filtered results. Remove filters to view all results.':
    "Vous visualisez actuellement les résultats filtrés. Retirez les filtres pour voir l'ensemble des résultats.",
  'Tow Provider Name': 'Nom du fournisseur de services de remorquage',
  'Tow Provider Short Name':
    'Nom court du fournisseur de services de remorquage',
  'Contact Name': 'Nom de la personne-ressource',
  'Sys Checks Printed': 'Chèques du système imprimés',
  'Blank Checks Issued': 'Chèques en blanc émis',
  'Filtered Trips': 'Voyages filtrés',
  'Total Trips': 'Nombre de transports',
  'Total Lots': 'Nombre total de lots',
  'My Profile': 'Mon Profil',
  'First Name': 'Prénom',
  'Last Name': 'Nom',
  'Phone Number': 'Numéro de téléphone',
  'Work Requests': 'Demandes de travail',
  'Total Checks': 'Nombre total de chèques',
  'Payment Type': 'Type de paiement',
  Amount: 'Montant',
  Check: 'Chèque',
  'Print Check': 'Imprimer le chèque',
  'Please complete the below review to proceed printing new checks.':
    "Veuillez effectuer la révision ci-dessous afin de poursuivre l'impression de nouveaux chèques.",
  'Pickup Order Documents': 'Documents relatifs au bon de récupération',
  'Owner Retain Receipt': 'Reçu de rétention par le propriétaire',
  'Seller Specific Forms': 'Formulaires spécifiques aux vendeurs',
  'Buyer Invoice': "Facture de l'acheteur",
  'Advance Charges Barcodes': 'Codes à barres pour le port antérieur',
  'Pickup Order Barcodes': 'Codes à barres pour le bon de récupération',
  'Inventory Barcodes': 'Codes à barres pour le dénombrement',
  'Z Barcodes': 'Codes à barres Z',
  'Windshield Label': 'Étiquette pour le pare-brise',
  'Standard Printer': 'Imprimante standard',
  'Barcode Printer': 'Imprimante de codes à barres',
  'Windshield Printer': "Imprimante d'étiquettes de pare-brise",
  'No blank note allowed!': "Aucun champ de remarque vide n'est permis.",
  'Delete File': 'Supprimer le fichier',
  'Edit File': 'Modifier le fichier',
  'Print File': 'Imprimer le fichier',
  'Refresh Files': 'Actualiser les fichiers',
  'Open in New Tab': 'Ouvrir dans un nouvel onglet',
  'Dispatch Status': 'Statut de la répartition',
  'Created On': 'Créé le',
  'Document Type': 'Type de document',
  'DISPATCH IMAGE': "IMAGE DE L'ATTRIBUTION",
  'ADVANCE CHARGE RECEIPT': 'REÇU POUR LE PORT ANTÉRIEUR',
  'PICKUP ORDER': 'BON DE RÉCUPÉRATION',
  Acknowledged: 'Reconnu',
  'Awaiting Acknowledgement': "En attente d'une confirmation",
  'In Progress': 'En cours',
  'Lot Files': 'Fichiers des lots',
  'Driver Type': 'Type de conducteur',
  'Trip Information': 'Renseignements sur le voyage',
  'Tow Provider Insurance':
    'Assurances du fournisseur de services de remorquage',
  'Insurance Company Name': "Nom de la compagnie d'assurances",
  'Policy Number': 'Numéro de politique',
  'Expiration Date': "Date d'expiration",
  'Unclear Lot for Pickup': 'Annuler la libération du lot pour récupération',
  'Remove Driver': 'Retirer le conducteur',
  'Print All & Close': 'Imprimer tout et fermer',
  'Email All & Close': 'Envoyer tout par courriel et fermer',
  'Verify & Close': 'Vérifier et fermer',
  'Remove & Close': 'Retirer et fermer',
  'Save & Close': 'Enregistrer et fermer',
  'Pickup Verification Successful.':
    'La vérification de la récupération a été effectuée avec succès.',
  'Lot has been removed from Trip successfully':
    'Le lot a été retiré du voyage avec succès.',
  'No Charges': 'Aucun frais',
  'All Checks Printed ?': 'Les chèques ont-ils tous été imprimés?',
  'First Check #': 'Numéro du premier chèque',
  'Printed Correctly?': 'Imprimé correctement?',
  'Check Amount': 'Montant du chèque',
  'Check Issued Yard': 'Dépôt auquel le chèque a été émis',
  'Check #': 'Numéro de chèque',
  'Credit Card Amount': 'Montant payé par carte de crédit',
  'Last Four Digits': 'Les quatre derniers chiffres',
  'Card Auth': 'Authentification de la carte',
  'Total Check Amount': 'Montant total payé par chèque',
  'Total CC Amount': 'Montant total payé par carte de crédit',
  Difference: 'Différence',
  ACGExitDialog:
    'Voulez-vous vraiment quitter ce menu sans confirmer si les chèques ont été imprimés correctement?',
  ACGUncheckedConfirmation:
    "Les chèques suivants n'ont pas été vérifiés : ${pendingReviewChecks}. Les chèques seront annulés. Voulez-vous continuer?",
  'Save & Confirm': 'Enregistrer et confirmer',
  'Uncheck All': 'Désélectionner tout',
  'Check All': 'Sélectionner tout',
  'formActions.yes': 'formActions.yes',
  'formActions.no': 'formActions.no',
  'Check Printer': "Vérifier l'imprimante",
  'Issue Details': 'Détails du problème',
  'Location Details': "Détails de l'emplacement",
  'Transporter Information': 'Information pour le transporteur',
  'Seller Information': 'Information pour le vendeur',
  'Seller ID': 'Identifiant du vendeur',
  'Co. Manager': 'Gestionnaire de la société',
  "Driver's Name": 'Nom du conducteur',
  'Other Lots In Trip': 'Autres lots dans le voyage',
  'Company Manager Comments': "Commentaires du gestionnaire de l'entreprise",
  'Group Manager Comments': 'Commentaires du gestionnaire du groupe',
  Comments: 'Commentaires',
  Details: 'Détails',
  Reason: 'Raison',
  'Sub Issue Type': 'Type de sous-problème',
  'Choose Destination': 'Choisir une destination',
  'Approve Image': "Approuver l'image",
  'Reject Image': "Refuser l'image",
  'Miscellaneous Fee': 'Frais divers',
  'Estimating Fee': 'Estimation des frais',
  'Negotiated Storage': 'Entreposage négocié',
  'Storage From': 'Entreposage fourni par',
  Gate: 'Porte',
  Labor: 'Travail',
  advanceChargesTowTitle:
    "Répartition des frais : remorquage, treuil, tonneau, deuxième remorquage, récupération, kilométrage, supplément pour traitement en dehors des heures normales, temps d'attente, arrimage et carburant",
  advanceChargesLaborTitle:
    "Répartition des frais : main-d'œuvre, employé supplémentaire, nettoyage et bâche",
  advanceChargesGateTitle:
    'Répartition des frais : frais administratifs, frais municipaux, frais de service, frais de garde, frais de retrait, frais de répartition, frais de déplacement et frais externes',
  advanceChargesStorageTitle: 'Répartition des frais : entreposage négocié',
  advanceChargesEstimateTitle:
    'Répartition des frais : démontage, estimation, diagnostic et inspection',
  advanceChargesMiscTitle:
    "Répartition des frais : manque d'huile, contraventions, gestion de matières dangereuses, lutte contre les incendies, pièces, lettres, frais de reprise de possession, documents pour les agences gouvernementales, TVP, frais d'inscription de privilèges et majoration des commerces",
  advanceChargesTaxLabelTitle: 'Répartition des frais : taxe, TPS',
  'Assigned To': 'Attribué à',
  'The Check': 'Le chèque',
  'will be voided': 'sera annulé',
  'Payment Information': 'Informations de paiement',
  'Charges Reconciliation': 'Rapprochement des frais',
  'Lot is currently part of declined trip':
    "Le lot fait actuellement partie d'un voyage refusé",
  'Driver will be removed for the Lot': 'Le conducteur sera retiré pour le lot',
  'No lots added to this Trip. Search for a lot number in the field above to add a lot to this Trip.':
    "Aucun lot n'a été ajouté pour ce voyage Recherchez un numéro de lot dans le champ ci-dessus pour ajouter un lot à ce voyage.",
  'Perfoming Unclear will remove any Split Trips from Lot.':
    'Si vous annulez la libération du lot, tout voyage découpé associé au lot sera retiré.',
  'Pending Images/Documents Review':
    'En attente de la révision des images/documents',
  'Charges were provided by Transporter':
    'Des frais ont été payés par le transporteur',
  'No charges entered from Transporter':
    "Aucuns frais n'ont été entrés par le transporteur",
  'Credit card not valid for given subhauler.':
    'Carte de crédit non valide pour ce transporteur sous-traitant.',
  'Check not valid.': 'Chèque non valide.',
  'Check numbers are not unique.':
    'Les numéros des chèques ne sont pas uniques.',
  'Invalid charge amount.': 'Montant de frais invalide.',
  'Charges difference is not zero.':
    "La différence entre les frais n'est pas nulle.",
  'If Check # is entered, provide amount greater than zero.':
    'Si vous inscrivez un numéro de chèque, entrez un montant supérieur à zéro.',
  'If CCard # is entered, provide amount greater than zero.':
    'Si vous inscrivez un numéro de carte de crédit, entrez un montant supérieur à zéro.',
  'If advance charges paid, charges cannot be zero':
    'Si un montant a été payé pour le port antérieur, ce montant ne peut être égal à zéro.',
  'Active Issue exists on the lot.':
    'Il existe des problèmes non résolus pour ce lot.',
  'Following check will be voided ${checkNumber}.':
    'Le chèque suivant sera annulé : ${checkNumber}.',
  'The amount entered will override the negotiated tow rates.':
    'Le montant entré remplacera les tarifs négociés pour le remorquage.',
  'System check will be voided if not used. Check: ${checkNumber}.':
    "Le chèque du système sera annulé s'il n'est pas utilisé. Chèque : ${checkNumber}.",
  'System check used. Check: ${checkNumber}.':
    'Le chèque du système a été utilisé. Chèque : ${checkNumber}.',
  'Cash control system used. Check: ${checkNumber}.':
    'Système de contrôle des fonds utilisé. Chèque : ${checkNumber}.',
  'Subhauler insurance expired.':
    "La police d'assurance du transporteur sous-traitant est expirée.",
  'Subhauler is not active.': "Le transporteur sous-traitant n'est pas actif.",
  'No subhauler entered.': 'Aucun transporteur sous-traitant entré.',
  'Search with Lot Number': 'Recherche avec numéro de lot',
  'Search with Trip ID': `'Rechercher avec l'ID de voyage'`,
  'Search with License Plate Number': `'Rechercher avec le numéro de plaque d'immatriculation'`,
  'Awaiting Trip Build': 'En attente de la création du voyage',
  'Awaiting Assignment': "En attente d'affectation",
  'Awaiting Advance Check Generation':
    "En attente de la préparation du chèque d'avance",
  'Awaiting Trip Confirmation': 'En attente de confirmation de voyage',
  'Awaiting Pickup Verification':
    'En attente de la vérification de la récupération',
  'Partial Pickup Verified': 'Ramassage partiel vérifié',
  'Medium & Heavy Lots - Awaiting Dispatch':
    "Lots moyens et lourds – En attente d'attribution",
  'Overdue and Due Today': "En retard ou à livrer aujourd'hui",
  'Declined Trips': 'Voyages refusés',
  'Issues Queue': 'Liste des problèmes',
  'UnConfirm Trip': 'Annuler la confirmation du voyage',
  Selected: 'Sélectionnés',
  'Selected lots': 'Lots sélectionnés',
  'Total lots': 'Nombre total de lots',
  'Map View': 'Voir la carte',
  'List View': 'Voir la liste',
  'Cash Status': 'Situation de trésorerie',
  'Check Printed': 'Chèque imprimé',
  'Declined Trip': 'Voyage refusé',
  'Due Today': "À livrer aujourd'hui",
  'Issue Assigned To': 'Problème assigné à',
  'Issue Raised By': 'Problème soulevé par',
  'Issue Type': 'Type de problème',
  'Location Name': "Nom de l'emplacement",
  'Loss Type': 'Type de perte',
  'Lot Arrival Date': "Date d'arrivée du lot",
  'Lot Status': 'Condition de Lot',
  'Partial Trip': 'Voyage partiel',
  'Payment Mode': 'Mode de paiement',
  'Promised Date': 'Date promise',
  'Selected By Me': "Éléments que j'ai sélectionnés",
  'Seller Number': "Numéro d'acheteur",
  'Trip Id': 'Identifiant du transport',
  'Trip Type': 'Type de transport',
  'Pickup Verified': 'Récupération vérifiée',
  'Vendor Personnel Full Name': "Nom complet de l'employé du fournisseur",
  'Sublot Name': 'Nom du sous-lot',
  'Deployment Type': 'Type de Déploiement',
  Filters: 'Filtres',
  Clear: 'Effacer',
  Trips: 'Transports',
  Trip: 'Transport',
  No: 'Non',
  Yes: 'Oui',
  'Awaiting Cash From Driver':
    "En attente d'un paiement en espèces par le conducteur",
  'Awaiting Check Generation': 'En attente de la création du chèque',
  Completed: 'Complété',
  'Cash Due from Driver': 'Montant en espèces dû par le conducteur',
  'Awaiting Cash Ready': 'En attente de la préparation du paiement en espèces',
  'Awaiting Cash Pickup':
    'En attente de la récupération du paiement en espèces',
  'Not Applicable': 'Sans objet',
  'Awaiting Driver Acknowledgement':
    'En attente de l’acceptation du conducteur',
  'Awaiting In Progress': 'En attente/En cours',
  'Awaiting Form Submission': 'En attente de la soumission du formulaire',
  'Awaiting Vendor Acknowledgement':
    'En attente de l’acceptation du fournisseur',
  'Awaiting Arrival': "En attente de l'arrivée",
  'Awaiting Check In': 'En attente de la réception',
  'Awaiting Group Acknowledgement': 'En attente de l’acceptation du groupe',
  OTHER: 'Autre',
  'Truck Out Of service': 'Camion hors service',
  'Additional Labor Required': "Main-d'œuvre supplémentaire requise",
  'Charges Over Limit': 'Frais supérieurs à la limite',
  'Pickup Location Not Releasing':
    "Libération en suspens à l'emplacement de récupération",
  'Unsuitable Trip': 'Voyage non convenable',
  'Wrong Vehicle': 'Mauvais véhicule',
  'Road Closure Or Hazard': 'Fermeture de route ou conditions dangereuses',
  'Issues In Transit': 'Problèmes en route',
  'Vehicle Not Accessible': 'Véhicule non accessible',
  'Vehicle Not At Location': "Véhicule non présent à l'emplacement prévu",
  'Incorrect Documentation': 'Documentation incorrecte',
  'Over Payment': 'Paiement excédentaire',
  'Unable To Check In': 'Inscription impossible',
  'CARD/CHECK': 'CARTE/CHÈQUE',
  CASH: 'COMPTANT',
  CHECK: 'CHÈQUE',
  Unknown: 'Inconnu',
  UNASSIGNED: 'NON ATTRIBUÉ',
  VENDOR: 'FOURNISSEUR',
  GROUP: 'Groupe',
  DISPATCHER: 'DISPATCHER',
  DRIVER: 'CONDUCTEUR',
  'Delete Trip': 'Supprimer le voyage',
  'Assign Trip to Tow Provider':
    'Attribuer le voyage à un fournisseur de services de remorquage',
  'Maintain Trip': 'Maintenir le voyage',
  'Verify Trip': 'Vérifier le voyage',
  'Print ACG': 'Imprimer ACG',
  'Remove Lot From Trip': 'Retirer le lot du voyage',
  'Resolve Issue': 'Résoudre le problème',
  'Create Issue': 'Créer un problème',
  'Split Trip': 'Découper le voyage',
  'Override Issue': 'Remplacer le problème',
  'Print Documents': 'Imprimer des documents',
  'Select Sublot': 'Sélectionner le sous-lot',
  'One Time Rate Request': 'Demande de tarif ponctuelle',
  'Work Request': 'Demande de travail',
  'Tow Providers': 'Remorqueurs',
  'Type Of Location': "Type d'emplacement",
  'Accept Cash': 'Accepte le comptant',
  'Promised Trip Date': 'Date du transport promis',
  'Seller Code': 'Code du vendeur',
  Drivers: 'Conducteurs',
  'Update trip': 'Mettre à jour le voyage',
  'Create new trip': 'Créer un nouveau voyage',
  'Active Issue': 'Problème actif',
  'Adjuster:': 'Ajusteur',
  'Are you sure you are ready to submit?':
    'Êtes-vous certain de vouloir soumettre?',
  'Are you sure you want to remove lot from Trip?':
    'Voulez-vous vraiment retirer ce lot du voyage?',
  'Assignment Entry Submission successful for Lot number:':
    "Soumission d'assignation réussie pour lot #:",
  ERRORS: 'ERREURS',
  'Correlation ID': 'ID de corrélation',
  'Assignment Entry Submission': "Soumettre l'assignation",
  Cancel: 'Annuler',
  Submit: 'Soumettre',
  'Go to summary': 'Aller au sommaire',
  'Restart with Different seller': 'Recommencer avec un autre vendeur',
  'Assignment entry submission': "Soumettre l'assignation",
  'Submission failed': 'La soumission a échoué',
  'Submission successful': 'Soumission réussie',
  Alert: 'Alerte',
  'Error Occured': 'Une erreur est survenue.',
  Password: 'Mot de passe',
  Email: 'Adresse électronique',
  Print: 'Imprimer',
  Damage: 'Dommage',
  Promised: 'Promis',
  'You landed on this page by mistake! Please go back to':
    'Vous avez atterri sur cette page par erreur! Retourner à',
  Home: 'Accueil',
  'Please review your submission before pressing SUBMIT':
    "S'il vous plaît réviser votre soumission avant d'appuyer sur SOUMETTRE",
  'Problem in relisting the lot':
    'Un problème est survenu lors de la remise en vente du lot.',
  'There was trouble unlocking this lot.':
    'Un problème est survenu lors du déverrouillage de ce lot.',
  'Could not fetch data for lot':
    'Impossible de récupérer les données pour le lot',
  'Your changes have successfully been saved.':
    'Les changements ont été enregistrés avec succès.',
  'There was a problem saving your changes.':
    "Un problème est survenu lors de l'enregistrement des changements.",
  'Who is responsible for advanced charges?':
    'Qui est responsable des frais avancés?',
  'Pickup Address': 'Adresse de récupération',
  'Lot Description: ': 'Description du lot: ',
  'Facility Name: ': "Nom de l'emplacement ",
  additionalFee: 'Autres frais avancés',
  additionalFeeComment:
    'Commentaires de la facture (pour les autres frais de port antérieur)',
  laborFeeComment:
    "Commentaires de la facture (pour les frais de main-d'œuvre)",
  additionalFeeVAT: 'Autres frais avancés VAT',
  airbagsDeployed: 'Coussins gonflables déployés?',
  amount: 'Montant',
  applicationDate: 'Date de la demande',
  areAdvancedChargesPaid: 'Frais avancé payés',
  auctionId: 'Vente aux enchères',
  bidToBeApprovedTypeId: 'Approbation des offres',
  bodyStyle: 'Type de carrosserie',
  buyNow: 'Achètez-le maintenant',
  calcOdometerBrand: "Marque de l'odomètre calculé",
  claimNumber: 'Numéro de référence',
  co2Emission: 'Émision de CO2',
  co2EmissionUnit: "Unité d'émissions de CO2",
  color: 'Couleur',
  contact: 'Contacter',
  copartHandlesCustomFlag: 'Copart gère les douanes?',
  cylinders: 'Cylindres',
  dateClaimReported: 'Date de la réclamation',
  dateOfLoss: 'Date de perte',
  documentedOdometer: 'Odomètre documenté',
  documentedOdometerBrand: "Marque d'odomètre documenté",
  documentedOdometerUnit: "Unité d'odomètre documenté",
  drive: 'Transmission',
  engineDisplacement: 'Cylindrée du moteur',
  engineDisplacementUnit: 'Unité de cylindrée du moteur',
  engineOutput: 'Puissance du moteur ',
  engineOutputUnit: 'Unité du puissance du moteur',
  engineType: 'Type de Moteur',
  estimatedCostOfRepair: 'Coût des réparations',
  euronorm: 'Euronorm',
  feeType: 'Type de frais de membre',
  firstRegistrationDate: "Première date d'enregistrement",
  fuel: 'Carburant',
  grossWeight: 'Poids brut',
  grossWeightUnit: 'Unité de poids brut',
  hasEngine: 'A un moteur',
  hasTransmission: 'A une transmission?',
  insuredAddress1: "Adresse de l'assuré 1",
  insuredAddress2: "Adresse de l'assuré 2",
  insuredCity: "Ville de l'assuré",
  insuredCompanyName: "Nom de l'entreprise du assurée",
  insuredCountry: "Pays de l'assuré",
  insuredEmail: "Adresse courriel de l'assuré",
  insuredFirstName: "Prénom de l'assuré",
  insuredLastName: "Nom de l'assuré",
  insuredPhone: "Numéro de téléphone de l'assuré",
  insuredPhoneExt: "Poste téléphonique de l'assuré",
  insuredState: "État/région/province de l'assuré",
  insuredVatId: "ID de VAT de l'assuré",
  insuredZip: "Code postal de l'assuré",
  isInsuredCompany: "L'assuré est-il une société?",
  isOdometerReadable: "L'odomètre est-il lisible?",
  isOwnerCompany: 'Le propriétaire est-il une société?',
  isOwnerInsured: "L'assuré est-il aussi le propriétaire?",
  itemDescription: "Description de l'article",
  itemNumber: "Numéro d'Item",
  itemValue: "Valeur de l'objet",
  keys: 'Clés',
  labor: 'Travail',
  laborVAT: 'VAT du travail',
  leftLocation: "Date le Lot a quitté l'installation",
  licensePlateNumber: 'Numéro de plaque',
  lossType: 'Type de perte',
  lotIdentificationNumber: "Vérifier le numéro d'identification du véhicule",
  lotLength: 'Longueur',
  lotLengthUnit: 'Unité du longueur',
  lotNote: 'Notes',
  lotNumber: 'Numéro de lot',
  lotStage: 'Lot Stage',
  make: 'Marque',
  makeName: 'Constructeur',
  memberDestinationAddress: 'Adresse de destination',
  memberInvoiceComplete: 'La facture des membres est-elle complète?',
  memberNumber: 'Numéro de membre',
  memberChargeAmount: 'Montant des charges au membre',
  memberFeeTypeName: 'Type de frais de membre',
  memberPaidDate: 'Date de paiement par le membre',
  memberPaidFlag: 'Membre payé?',
  minimumBidReserve: 'Réserve',
  model: 'Modèle',
  modelName: 'Modèle',
  modelYear: 'Année',
  newInvoiceIssued: 'Nouvelle facture créer?',
  odometer: 'Compteur kilométrique',
  odometerUnit: "Unité de l'odomètre",
  okToPayCharges: 'Y a-t-il des frais de port antérieur?',
  origCountry: 'Pays original du document',
  origDocumentType: 'Type de document original',
  originalOdometerBrand: 'Marque Odomètre Original',
  originalOdometerUnit: "Unité de l'Odomètre Original",
  orignalOdometer: 'Odomètre original',
  origState: 'Document original État / Région / Province',
  ownerAddress1: 'Adresse du propriétaire 1',
  ownerAddress2: 'Adresse du propriétaire 2',
  ownerCity: 'Ville du propriétaire',
  ownerCompanyAddress: 'Société propriétaire',
  ownerCompanyName: 'Nom de la société propriétaire',
  ownerCountry: 'Pays du propriétaire',
  ownerEmail: 'Adresse courriel du propriétaire',
  ownerFirstName: 'Prénom du propriétaire',
  ownerLastName: 'Nom du propriétaire',
  ownerPhone: 'Téléphone propriétaire',
  ownerPhoneExt: 'Poste téléphonique du propriétaire',
  ownerState: 'État/région/province du propriétaire',
  ownerVatId: 'Identifiant pour la TVA ou identifiant fiscal du propriétaire',
  ownerZip: 'Code postal du propriétaire',
  paymentType: 'Type de paiement',
  paymentReferenceNumber: '# Référence de paiement',
  paymentAmount: 'Montant du paiement',
  paymentDate: 'Date de paiement',
  pickupAddress1: 'Adresse de récupération 1',
  pickupAddress2: 'Adresse de récupération 1',
  pickupAltPhone: 'Téléphone Alt du lieu de récupération',
  pickupCity: 'Ville du lieu de récupération',
  pickupCountry: 'Pays du lieu de récupération',
  pickupEmail: 'Courriel du lieu de récupération',
  pickupHoldDate: 'Date de suivi concernant la retenue pour la récupération',
  pickupHoldNote: 'Raison de la retenue',
  pickupLocationName: "Nom de l'emplacement de récupération",
  pickupVatId: 'Identifiant pour la TVA – récupération',
  pickupPhone: 'Téléphone du lieu de récupération',
  pickupRequired: 'Récupération requise',
  pickupState: 'État/Province/Région de récupération',
  pickupZip: 'Code postal du lieu de récupération',
  primaryDamageCode: 'Code de dommages primaires',
  priority: "Priorité de l'ordre de service",
  promisedTripDate: 'Date de transport promis',
  proofOfTransportReceived: "Y-a-t'il une preuve de transport reçue?",
  purchasePrice: "Prix d'achat",
  receivedBy: 'Reçu par',
  regCertificateNumber: "Numéro d'enregistrement du certificat",
  registrationNumber: "Numéro d'enregistrement",
  residence: 'Résidence',
  residualValue: 'Valeur résiduelle',
  rowLocation: 'Emplacement cours',
  runCondition: 'Exécuter État',
  saleActionApprovedBy: 'Action autorisé par',
  saleConfirmationStatus: 'État de confirmation de vente',
  saleDocAtCopart: 'Documents de vente à Copart',
  saleDocToMember: 'Documents de vente remis au membre',
  saleDocumentCountry: 'Pays du document de vente',
  saleDocumentState: 'Document de vente État/ Région/ Province',
  saleDocumentType: 'Types de documents de vente',
  saleHoldDate: 'Date de suivi concernant la retenue pour la vente',
  saleHoldNote: 'Raison de la retenue',
  salePrice: 'Prix de vente',
  scheduledTripDate: 'Date prévue de transport',
  searchByLocation: "Numéro de téléphone de l'emplacement de récupération",
  seats: 'Sièges',
  secondaryDamageCode: 'Code de dommages secondaires',
  sellerChargeDate: 'Date de facturation au vendeur',
  sellerChargeAmount: 'Montant de facturation au vendeur',
  sellerTotalFees: 'Frais total',
  sellerTotalDue: 'Total en souffrance',
  sellerId: 'Vendeur',
  sellerInvoiceComplete: 'Facture du vendeur complète?',
  serviceId: 'Service',
  serviceDate: 'Date de service',
  serviceStatus: 'État du service',
  serviceCost: 'Coût du service',
  servicePriority: 'Priorité du service',
  serviceAmount: "Montant de l'ordre de service",
  serviceFeeType: "Type de frais d'ordre de service",
  serviceOrderInstructions: "Instructions de l'ordre de service",
  serviceOrders: 'Ordre de service',
  settlementModel: 'Modèle de règlement',
  specialTransportInstructions: 'Instructions spéciales de transport',
  statusUpdatedDate: "Date l'ordre de service était terminée",
  storageAmount: "Montant d'entreposage",
  storageAmountVAT: "VAT du montant d'entreposage",
  storageDaily: 'Fixé ou quotidien ?',
  storageFromDate: 'Entreposage de',
  storageThruDate: 'Entreposage à',
  tearDownEstimate: 'Estimation de démontage',
  tearDownEstimateVAT: "VAT de l'estimation de démontage",
  totalCharges: 'Charges totales',
  totalFees: 'Frais totaux du membre (taxes non comprises)',
  totalTax: 'VAT total',
  towable: 'Remorquable',
  towing: 'Remorquage',
  towingVAT: 'VAT de Remorquage',
  towProviderId: 'Sélectionnez le fournisseur de remorquage',
  transferType: 'Type de transfert de propriété',
  transmission: 'Transmission',
  trim: 'Garniture',
  trimName: 'Garniture',
  tripDate: 'Date de transport',
  tripDistance: 'Distance de transport',
  userNotes: 'Notes des ordres de services',
  vatEligible: 'Valeur ajoutée admissible à la taxe',
  vatSection: 'Section de VAT',
  vehicleType: "Type d'article",
  vin: 'Numéro de série',
  whoIsResponsibleForAdvancedCharges: 'Partie responsable',
  yardGate: 'Porte du cour',
  yardGateVAT: 'VAT du porte du cour',
  yardId: 'Installation responsable',
  yearOfManufacture: 'Année de fabrication',
  'Bulk Lot Updates for ': 'Mises à jour des lot en vrac pour ',
  'Add multiple lots at once: (eg: 50003236, 50003186)':
    'Ajoutez plusieurs lots à la fois: (par exemple: 50003236, 50003186)',
  'Save Changes': 'Enregistrer les modifications',
  'Lot Number': 'Numéro de lot',
  'Left Yard': 'Sorti de la cour',
  'Total Towing': 'Remorquage total',
  'Assign Tow Provider': 'Assigner un fournisseur de remorquage.',
  'Please add the lots first': 'En premier ajouter les lots',
  'is/are not in Driver Dispatch.':
    "n'est / ne sont pas trouver dans Dispatch de conducteur.",
  'This field is not editable for this lot':
    'Ce champ ne peut pas être modifié pour ce lot',
  'Please enter a Lot Number first': "Veuillez d'abord entrer un numéro de lot",
  'The following lots have already been added:':
    'Les lots suivants ont déjà été ajoutés:',
  'The following lot numbers are invalid:':
    'Les numéros de lot suivants sont invalides:',
  'No lot number provided.': 'Aucun numéro de lot fourni.',
  'Required Error': 'Erreur relative aux données requises',
  'Address Line 1 is required': "La première ligne de l'adresse est requise",
  'City is required': 'Ville est requis',
  'Postal Code is required': 'Code postal est nécessaire',
  'Country is required': 'Pays requis',
  'State is required': 'État est requis',
  'Not all your changes have been saved successfully':
    "Toutes vos modifications n'ont pas été enregistrées avec succès",
  'Your changes have been saved successfully':
    'Vos modifications ont été enregistrées avec succès',
  'has already been entered': 'a déjà été entré',
  'Could not fetch seller company Id':
    "Impossible d'obtenir l'identité de la société du vendeur",
  'Could not fetch seller company hierarchy':
    "Impossible d'obtenir la hiérarchie de l'entreprise du vendeur",
  'Could not fetch form data. Please refresh the page.':
    "Impossible d'extraire les données du formulaire. Veuillez actualiser la page.",
  'Awaiting form data to be initialized. Please try again in a moment.':
    'En attente de données de formulaire à initialiser. Veuillez réessayer',
  'No field data in Redux store corresponding to ':
    'Aucune donnée de champ dans Redux correspondant à ',
  'Please enter a whole number value': 'Entrez une valeur de nombre entier',
  'Please enter a valid email address':
    'Veuillez saisir une adresse électronique valide',
  'Only two digits after decimal allowed':
    'Seules deux décimales sont permises',
  'Could not fetch Note/Audit data for lot ':
    'Impossible de afficher les données de Note / Audit pour le lot ',
  Lot: 'Lot',
  'Invalid lot number:': 'Numéro de lot invalide:',
  'Row Number': 'Numéro de rangée',
  'Item Number': "Numéro d'Item",
  'DD/MM/YYYY': 'JJ/MM/AAAA',
  'Update all rows': 'Mettre à jour toutes les rangées',
  Update: 'Mise à jour',
  'New Assignment': 'Nouvelle Assignation',
  'Assignment Entry Success': "Succès d'entrée une assignation",
  'Same Caller New Assignment': 'Même appelant, nouvelle assignation',
  'Maintain Lot': 'Maintenir le lot',
  Exit: 'Sortie',
  'Assignment Entry Errors': "Erreurs de saisir de l'assignation",
  OK: 'OK',
  'Seller And Contact': 'Vendeur et contact',
  'Lot Information': 'Informations sur le lot',
  'Billing And Pickup': 'Facturation et récupération',
  'Ownership Documents': 'Documents de propriété',
  'Sale To Member': 'Vente au membre',
  'Critical Dates': 'Dates critiques',
  Auction: 'Vente aux enchères',
  'Service Order': 'Ordre de service',
  'Ownership Transfer': 'Transfert de propriété',
  'Original Documents': 'Documents originaux',
  'Sale Documents': 'Documents de vente',
  Odometer: 'Odomètre',
  Error: 'Erreur',
  'Re Run': 'Rerun',
  'Re List': 'Listé encore',
  'Re List with Copart': 'Listé encore avec Copart',
  'Award Lot': 'Accorder le lot',
  'Remove from Auction': "Enlever de l'encan",
  'Lot Review Errors': 'Erreurs de revue des lots',
  'Are there Advance Charges? No': 'Y a-t-il des frais de port antérieur? Non',
  'Advance Charges': 'Déboursés',
  'Advance Charges Detail': 'Détail des frais avancé',
  'Add Contact for ': 'Ajouter un contact pour ',
  Charges: 'Charges',
  'CHARGE TYPE': 'TYPE DE CHARGE',
  AMOUNT: 'Montant',
  'Tow Provider': 'Fournisseur de remorquage',
  'Pickup Details': 'Détails de récupération',
  'Assignment and Pickup': 'Assignation et Récupération',
  'Ownership Transfer and Auction': 'Transfert de propriété et Encan',
  'Sale and Settlement': 'Vente et règlement',
  'Hold Information': "Information d'attente",
  'Additional Info': 'Info additionelle',
  Seller: 'Vendeur',
  Contact: 'Contacter',
  Adjuster: 'Ajusteur',
  'Owner and Insured': 'Propriétaire et assuré',
  Owner: 'Propriétaire',
  Insured: 'Assuré',
  'Service Orders': 'Ordres de service',
  'Auction Information': "Informations sur l'encan",
  'Virtual Bid Sale To Enquiry':
    'Renseignements concernant une vente sur enchère virtuelle',
  'Virtual Bid Log': 'Registre des offres virtuelles',
  'Awaiting clear pickup': 'En attente de la libération pour récupération',
  'Awaiting driver dispatch': "En attente de l'attribution à un conducteur",
  'Awaiting trip confirmation': 'En attente de confirmation de voyage',
  'Awaiting Inventory': 'En attente du dénombrement',
  'Awaiting title': "En attente d'un titre",
  'Ready for auction': "Prêt pour l'encan",
  'Awaiting sale confirmation': "En attente d'une confirmation de vente",
  'Awaiting settlement confirmation':
    "En attente d'une confirmation de règlement",
  'Awaiting Member Settlement': "En attente d'un règlement avec le membre",
  'Awaiting sale docs': 'En attente des documents de vente',
  'Awaiting Seller Settlement': "En attente d'un règlement avec le vendeur",
  roleSelectMessage: 'Veuillez sélectionner un rôle',
  Canceled: 'Annulé',
  'Assigned to Auction': "Attribué à l'encan",
  Closed: 'Fermé',
  'Assignment Entry': 'Ouvrir une assignation',
  Edit: 'Modifier',
  Saving: 'Sauvegarde....',
  'Discard changes': 'Ignorer les changements',
  Unassign: 'Désassigner',
  'Save and Transition': 'Sauvegarder et Transition',
  Save: 'Sauvegarder',
  Country: 'Pays',
  Start: 'Début',
  Yard: 'Cour',
  Feedback: 'Commentaires',
  '8 DIGIT PH#': '# de téléphone (8 caractères)',
  'TRANSPORTER PORTAL': 'Portail de Transport',
  'CAS Portal': 'Portail CAS',
  'Cobalt Portal': 'Portail Cobalt',
  Dispatch: 'Dispatch',
  'Lot Review': 'Revue des lots',
  'Auction Board Control': 'Gestion des encans en direct',
  'Virtual Walk Through': 'Promenade virtuelle',
  DTLE: 'DTLE',
  'Mass Updates': 'Mises à jour en vrac',
  'Pro Quote': 'ProQuote',
  MiHub: 'MiHub',
  AUCTIONS: 'Ventes aux enchères',
  'My Recent Lots': 'Mes lots récents',
  'Pickup Order': 'Ordre de remorquage',
  'Call For Release': 'Appel pour récupération',
  Unit: 'Unité',
  'Delivery Address': 'Adresse de livraison',
  Settings: 'Paramètres',
  Role: 'Rôle',
  'Lot Life Cycle': 'Cycle de vie du lot',
  lotNumberInvalidMessage: "Numéro de lot invalide. Vérifiez s'il vous plaît.",
  'Accepts Credit Card': 'Accepte la carte de crédit',
  'Action/Reason Description': "Description de l'action / motif",
  'Actual Charges': 'Frais réels',
  'Add Lot Note': 'Ajouter des notes',
  'Adjuster Email': "Courriel de l'ajusteur",
  'Adjuster Name': "Nom de l'Ajusteur",
  'Adjuster Phone #': "Téléphone de l'ajusteur",
  'Advance Check Amount': 'Montant anticipé du chèque',
  'Alternate Pickup Phone #': 'Autre no. Téléphone',
  'Assignment Entered Date': 'Date de saisie',
  'Charges Allowed': 'Frais autorisés',
  'Charges Paid By Seller': 'Frais payés par le vendeur',
  City: 'Ville',
  Address: 'Adresse',
  'Claim #': '# Réclamation',
  'Cleared For Charges Date': "Date c'était libéré pour les frais",
  'Cleared For Pickup': 'Libéré pour récupération',
  'Cleared To Pickup Date': "Date c'était libéré pour récupération",
  'Click to Dial': 'Clique pour téléphoner',
  Color: 'Couleur',
  'Credit Card': 'Carte de crédit',
  'Cross Street': "Rue d'intersection",
  'Date of Loss': 'Date de perte',
  'Days to Pickup': 'Jours à récupérer',
  'Deliver Location Name': "Nom de l'emplacement de livraison",
  'Deliver To': 'Livrer à',
  'Delivery Address Line 1': 'Adresse de livraison Ligne 1',
  'Delivery Address Line 2': 'Adresse de livraison ligne 2',
  'Delivery Location Address': "Adresse de l'emplacement de livraison",
  'Delivery Phone': 'No. de téléphone de livraison',
  'Delivery Phone #': 'No. de téléphone de livraison',
  'Delivery Yard Phone #': 'No. de téléphone du cour de livraison',
  'Estimated Charges': 'Frais estimés',
  'Flat/Negotiated Storage': 'Stockage fixé / négocié',
  'Follow Up In(HH:MM)': 'Suivi en (HH: MM)',
  'Has Keys': 'A des clés',
  'Hold Reason': "Raison pour l'attente",
  'Insured Name': "Nom de l'assuré",
  Limit: 'Limite',
  'Limit Per Day': 'Limite par jour',
  Make: 'Marque',
  'Miscellaneous Charges Description': 'Description des frais divers',
  Model: 'Modèle',
  'Override Seller Limit': 'Annuler la limite du vendeur',
  'Owner Name': 'Nom du propriétaire',
  'Owner Phone #': 'Téléphone du propriétaire',
  'Payable To': 'Payable à',
  'Picked Up Date': 'Date récupérer',
  'Picked Up by': 'Récupéré par',
  'Pickup Address Line 1': 'Adresse de récupération ligne 1',
  'Pickup Address Line 2': 'Adresse de récupération ligne 2',
  'Pickup Aging Comment': 'Aging comment de récupération',
  'Pickup Contact': 'Contact de récupération',
  'Pickup Directions': 'Directions de récupération',
  'Pickup Hold Date': 'Date de retenu pour la récupération',
  'Pickup Location Name': 'Emplacement de récupération',
  'Pickup Notes': 'Notes de récupération',
  'Pickup Date': 'DATE DE REMORQUAGE',
  'Pickup Phone #': 'Téléphone du lieu de récupération',
  'Pickup Phone 2 #': 'Téléphone du lieu de récupération 2',
  'From Location': "De l'emplacement",
  'To Yard': 'Au dépôt',
  'Plate #': '# de plaque',
  'Policy #': '# Police',
  'Primary Damage Code': 'Code de dommages primaires',
  'Recovery Date': 'Date de récupération',
  Residence: 'Résidence',
  RESIDENCE: 'Résidence',
  Scheduled: 'Prévue',
  'Scheduled Date': 'Date prévue',
  'Scheduled Time From': 'Heure prévue depuis',
  'Scheduled Time To': 'Heure prévue pour',
  'Seller Address': 'Adresse du vendeur',
  'Seller Approver Email': "Email de l'approbateur du vendeur",
  'Seller Approver Name': "Nom de l'approbateur du vendeur",
  'Seller Approver Phone': "Téléphone de l'approbateur du vendeur",
  'Seller Loss Code': 'Code de perte du vendeur',
  'Seller Phone #': 'No. de téléphone du vendeur',
  'Seller Reference #': 'No. de référence du vendeur',
  State: 'État',
  'Storage 2 From': 'Entreposage 2 depuis',
  'Storage 2 Rate': "Taux d'entreposage 2",
  'Storage 2 To': 'Entreposage 2 à',
  'Storage From Date': 'Entreposage de',
  'Storage Rate': "Taux d'entreposage",
  'Storage To Date': 'Entreposage à',
  Tax: 'Impôts',
  'Teardown/Estimating Fee': 'Frais de démonter / Estimation',
  Total: 'Total',
  Tow: 'Remorquage',
  'Tow Type': 'Type de remorquage',
  Towable: 'Peut être remorqué',
  'VIN #': 'VIN #',
  'Vehicle Type': 'Type de véhicule',
  'Yard/Gate': 'Cour / Porte',
  Year: 'Année',
  Zip: 'Code postal',
  Zip1: 'Code postal 1',
  'Zip 1': 'Code postal 1',
  Zip2: 'Code postal 2',
  'Zip 2': 'Code postal 2',
  Date: 'Date',
  Username: "Nom d'utilisateur",
  Field: 'Champ',
  'Old-Value': 'Ancienne valeur',
  'New-Value': 'Nouvelle valeur',
  'Charge Description': 'Description de la charge',
  'Enter Lot # / Lots ending with': 'Entrez le lot # / Lots se terminant par',
  'Enter City': 'Saisir la ville',
  'Enter Zip code': 'Saisir le code postal',
  'Enter lot location': "Entrez l'emplacement du lot",
  Location: 'Emplacement',
  'Filter By': 'Filtrer par',
  'Lot Result Count': 'Nombre de résultats du lot',
  'Delivery Phone 2 #': '# Téléphone de livraison 2',
  'Lot Notes': 'Notes',
  'Billing Notes': 'Notes de facturation',
  'Dispatch Notes': 'Notes de Dispatch',
  'Seller Notes': 'Notes du vendeur',
  'Title Notes': 'Notes de Title',
  'View and Print': 'Visualiser et imprimer',
  'Cannot Edit Document Type': 'Impossible de modifier le type de document',
  'Edit Document Type': 'Modifier le type de document',
  'Cannot Delete Document': 'Impossible de supprimer le document',
  'Delete Document': 'Supprimer le document',
  'Date Created': 'Date créer',
  'Last Modified': 'Dernière modification',
  'Buyer Notes': "Notes de l'acheteur",
  AGE: 'ÂGE',
  'SAVE & CLEAR HOLD': "SAUVEGARDER & ENLEVER L'ATTENTE",
  'CLEAR HOLD': "ENLEVER L'ATTENTE",
  PREVIOUS: 'Précédant',
  CLOSE: 'Fermer',
  NEXT: 'Suivant',
  'LOT NOTES': 'Notes',
  'DISPATCH NOTES': 'NOTES DE DISPATCH',
  'PICKUP CLEARANCE': 'AUTORISÉ POUR RÉCUPÉRATION',
  'CHARGES ENTRY': 'SAISIR LES CHARGES',
  'PROCEED TO CLEAR CHARGES': 'AVANCER POUR SAISIR LES CHARGES',
  FINISH: 'TERMINER',
  'MOVE TO CLEAR CHARGES': "ENVOYER À L'AUTORISATION DES CHARGES",
  'MOVE LOT TO DISPATCH': 'ENVOYER LOT À DISPATCH',
  'SAVE & CLOSE': 'SAUVEGARDER & FERMER',
  SAVE: 'Sauvegarder',
  LOCK: 'BLOQUÉ',
  'LOT #': 'N° de LOT :',
  YEAR: 'Année',
  MAKE: 'Constructeur',
  MODEL: 'Modèle',
  COLOR: 'Couleur',
  HOLD: 'METTRE EN ATTENTE',
  'PICKUP CLEARED': 'REMORQUAGE AUTORISÉ',
  COMMENT: 'COMMENTAIRE',
  'LOT LOCATION': 'LIEU DU LOT',
  SELLER: 'VENDEUR',
  'FOLLOW UP DUE IN': 'DONNER SUITE DANS',
  CFRLockedByYou: 'Bloqué par vous en utilisant OPS Portal.',
  CFRLockedByYouOtherApp: "Bloqué par vous à partir d'une autre application.",
  'locked by': 'Bloqué par',
  Logout: 'Déconnexion',
  'Are you sure you want to logout?':
    'Êtes-vous sûr de vouloir vous déconnecter?',
  'No, stay': 'Non, rester',
  'Yes, leave': 'Oui, quitter',
  'Enter Pickup Address here': "Entrez l'adresse de récupération ici",
  zipMisMatch:
    "Code Postal ne correspond pas à la ville et au province. Vérifiez s'il vous plaît.",
  exitDialog:
    'Tous les changements non enregistrés seront perdus. Voulez-vous continuer?',
  AddLotNotes:
    'Ajouter des notes de lot. Appuyez sur Entrée pour une nouvelle ligne',
  CFRTowToolTip:
    "Répartition des charges: Remorquage, Treuillage, Roulement, 2ème remorquage, récupération, kilométrage, après heures ouvrables, temps d'attente, attelage, carburant",
  CFRLaborToolTip:
    'Répartition des charges: Travail, deuxième homme, nettoyage, bâche',
  CFRYardToolTip:
    "Répartition des charges: Frais d'administration frais de ville, frais de service, fourrière, frais d'enlèvement, dispatch, frais extérieur",
  CFRStorageToolTip: 'Entreposage par jour',
  CFRNegotiatedToolTip: 'Répartition des charges: entreposage négocié',
  CFRTeardownToolTip:
    'Répartition des charges: démontage, estimation, diagnostique, inspection',
  CFRMiscToolTip:
    "Répartition des charges: sèchement de l'huile, billets, matières dangereuses, extincteur, parties, lettres, frais de repo, tâches administrative du DMV, PST, frais de privilège, surcoût du garage",
  CFRTaxToolTip: 'Répartition des charges: Tax, GST',
  'Invalid Search String': 'Chaîne de recherche invalide',
  feedbackEnhancementMessage:
    "Veuillez soumettre vos idées d'améliorations sur le forum dédié à l'innovation à",
  dispatchWelcomeTourText:
    "Cette visite du site vous permettra de découvrir toutes les nouvelles fonctionnalités et tous les nouveaux outils qui ont été intégrés dans cette version. Cette version marque le plus important changement au processus de répartition de l'histoire de Copart! Cliquez sur Suivant afin d'explorer les nouvelles fonctionnalités offertes par l'application.",
  actionsBarTourText:
    "De nouvelles fonctions de répartition sont accessibles dans la liste d'actions du haut. Ces actions dépendront de l'état du lot, mais incluent le découpage de voyages, la vérification de la récupération, la résolution de problèmes et l'impression de documents.",
  driversViewTourText: 'driversViewTourText',
  dispatchFiltersTourText:
    "À l'aide de cette barre de navigation, vous pouvez filtrer facilement tous les lots compris dans Dispatch. Si vous cliquez sur l'icône du haut, la liste de tous les filtres disponibles s'affichera. Cliquez sur Suivant pour visualiser toutes les options de recherche rapide offertes ci-dessous.",
  awaitingTripBuildTourText:
    "En plus de ces nouvelles fonctionnalités, nous adoptons aussi une nouvelle terminologie à compter de cette version. Les « tournées de livraison » des versions précédentes sont dorénavant désignées comme des « voyages ». Le filtre En attente de la création du voyage demeure inchangé; il continuera d'afficher tous les lots en attente d'attribution à un transporteur.",
  awaitingAssignmentTourText:
    "Comme auparavant, cette option de recherche rapide vous permet de connaître tous les lots pour lesquels un voyage a été créé, mais auxquels aucun conducteur n'a été affecté.",
  awaitingPrintingTourText:
    "Le filtre En attente de la création du chèque d'avance sera toutefois utilisé très différemment dans la version 3.0. Désormais, seuls les lots payables en espèces pour lesquels un chèque du système est requis s'afficheront ici. Dans les autres cas, dès qu'un transporteur sera affecté à un lot, ce dernier sera placé à l'état En attente de la confirmation du voyage.",
  tripConfirmationTourText:
    "Afin que le voyage soit entièrement confirmé pour un lot donné, les étapes de confirmation par la société, de vérification de la récupération, d'arrivée et d'inscription doivent avoir été suivies. Ce filtre vous permet de visualiser rapidement tous les lots selon différents états d'attribution.",
  tripVerificationTourText:
    "Vous devriez utiliser le filtre Vérification de la récupération pour voir rapidement la file d'attente des lots pour lesquels des documents ont été soumis par le transporteur et pour lesquels l'étape de vérification de la récupération peut être réalisée.",
  partialTripConfirmationTourText:
    'Similairement à la façon dont les voyages partiels étaient traités dans les versions précédentes, ce filtre vous permet de voir tous les lots pour lesquels des documents supplémentaires doivent être obtenus avant que le voyage ne puisse être entièrement confirmé à leur égard. Toutefois, vous verrez dorénavant la raison du voyage partiel, qui peut être entrée lors de la vérification de la récupération. Au moment de leur saisie par le répartiteur, ces raisons seront également envoyées au transporteur en tant que notification.',
  awaitingDispatchTourText:
    "Ce filtre a le même effet qu'avant, même si tous les lots moyens ou lourds en attente d'attribution sont maintenant affichés.",
  overdueTourText:
    "Ce filtre demeure lui aussi inchangé. Le filtre En retard et à livrer aujourd'hui fournit au répartiteur un aperçu rapide de tous les lots qui sont en attente d'attribution et pour lesquels la date de livraison promise est le jour même ou une journée précédente.",
  rejectedTripsTourText:
    "Les deux filtres suivants mettent en évidence deux changements importants aux processus introduits dans Dispatch 3.0. Désormais, lorsque vous attribuerez un voyage à un fournisseur, ce dernier devra confirmer le voyage dans l'application avant qu'il ne lui soit entièrement attribué. Ce filtre permet au répartiteur de voir rapidement tous les lots qui ont été attribués à un transporteur, mais refusés par ce dernier dans l'application. Les lots qui sont refusés automatiquement lorsque le transporteur ne fournit pas de confirmation dans les 30 minutes de leur attribution s'afficheront également ici.",
  issuesQueueTourText:
    "Ce filtre vous permettra d'obtenir rapidement un aperçu de tout problème éprouvé par le transporteur à l'égard d'un lot pendant le processus de récupération. La majorité des problèmes de répartition peuvent maintenant être traités de façon électronique par l'intermédiaire de cette file d'attente. Il est extrêmement important d'effectuer un suivi constant de ce filtre afin d'assurer la résolution rapide de tous les problèmes.",
  unconfirmTourText:
    "Ici, vous pouvez annuler la confirmation en cliquant simplement sur l'icône et en entrant le numéro du ou des lots visés. Merci d'avoir pris le temps de découvrir les nouvelles fonctionnalités! Nous espérons que vous les apprécierez.",
  'Lot Description': 'Description du lot',
  additionalInfo: 'Informations complémentaires',
  assignmentCreationDate: "Date de saisie de l'affectation",
  auctionDateDisplayOnly: "Date d'encan",
  auctionName: 'Vente aux enchères',
  awardDate: "Date d'attribution",
  closedDate: 'Jour Fermé',
  contactEmail: 'Courriel',
  contactPrimaryPhone: 'Téléphone',
  contactPrimaryPhoneExtension: 'Extension',
  contactSecondaryPhone: 'Autre numéro de téléphone',
  comment: 'Commentaire sur la résolution',
  createdUser: 'Utilisateur créé',
  deliveredOnDate: 'Livré le',
  insuredSecondaryPhone: "Autre numéro de téléphone de l'assuré",
  insuredSecondaryPhoneExt: "Poste de l'autre numéro de téléphone de l'assuré",
  tripType: 'Type de voyage',
  lotDiscrepancy: 'Écart relatif au lot',
  memberDestinationAddressId: 'Adresse de destination',
  memberId: 'Identifiant du membre',
  memberName: 'Nom du membre',
  memberEmail: 'Adresse courriel du membre',
  memberPhone: 'Numéro de téléphone du membre',
  memberAddress: 'Adresse du membre',
  memberCity: 'Ville du membre',
  memberCountry: 'Pays du membre',
  newPickupContact: 'Contacter',
  newPickupLocationType: 'Type d’emplacement',
  newPickupLocationName: "Nom de l'emplacement",
  newPickupVatId: 'Identifiant pour la TVA',
  newPickupAddress1: 'Adressse ligne 1',
  newPickupAddress2: 'Adresse ligne 2',
  newPickupCountry: 'Pays',
  newPickupCity: 'Ville',
  newPickupState: 'État/région/Province',
  newPickupPhone: 'Téléphone',
  newPickupAltPhone: 'Autre no. Téléphone',
  newPickupEmail: 'Courriel',
  newPickupZip: 'Code postal',
  numberOfReruns: 'Nombre de reprises',
  numberOfRelists: 'Nombre de remises en vente',
  offsite: 'Hors site?',
  offsiteLotSideId: 'Identifiant du site du lot hors site',
  'Owner Address': 'Adresse du propriétaire',
  ownerNumber: 'Numéro du propriétaire',
  ownerName: 'Nom du propriétaire',
  ownerSecondaryPhone: 'Autre numéro de téléphone du propriétaire',
  ownerSecondaryPhoneExt:
    "Poste de l'autre numéro de téléphone du propriétaire",
  deliveryAddress: 'Adresse de livraison',
  pickupContact: 'Contacter',
  pickupLotSiteId: 'Identifiant du site de récupération du lot',
  primarySeverityOfDamage: 'Gravité des dommages primaires',
  recoveryDate: 'Date de récupération',
  contactId: 'Contacter',
  rerunReason: 'Raison de la reprise',
  saleConfirmationDate: 'Date de confirmation de la vente',
  highBidAmount: "Montant de l'offre la plus élevée",
  secondarySeverityOfDamage: 'Gravité des dommages secondaires',
  sellerFeeTypeName: 'Type de frais de vendeur',
  sellerPaidDate: 'Date du paiement au vendeur',
  totalPayments: 'Total',
  totalRuns: 'Nombre total de tournées de livraison',
  towType: 'Type de remorquage',
  highPriority: 'Il existe un bon de travail de priorité élevée',
  'Long Term Hold Note text Maintenance':
    'Gestion du texte de la remarque sur la retenue à long terme',
  syncStatus: 'État de la synchronisation',
  'Edit Personnel': 'Modifier le personnel',
  'Create Personnel': 'Ajouter du personnel',
  'Middle Name': 'Deuxième prénom',
  'Contact Number': 'Numéro de contact',
  'Email Address': 'Adresse électronique',
  'Start Date': 'Date de début',
  Status: 'Statut',
  Dispatchable: 'Peut être attribué',
  'Select Yard': 'Sélectionner la cour',
  'Request Date': 'Date de la demande',
  'Number of cars': 'Nombre de voitures',
  'Need trips for location in': 'Besoin de transport pour un emplacement',
  Assigned: 'Assigné',
  'Issues Assigned To Me': 'Problèmes attribués à moi',
  'Issues Reported By Me': 'Problèmes rapportés par moi',
  'Declined Trips Assigned To Me': 'Transports refusés attribués à moi',
  'Declined Trips Reported By Me': 'Transports refusés rapportés par moi',
  Distributed: 'Distribué',
  'Assigned to Me': 'Assigné à moi',
  'Raised by Me': 'Soulevés par moi',
  'Clear All Filters': 'Effacer tous les filtres',
  'Destination Facility': 'Installation de destination',
  'Associated By': 'Associé par',
  'Is Deployed?': 'Est déployé?',
  'Estimated Arrival Date': `'Date d'arrivée estimée'`,
  'Truck Image': 'Image de camion',
  'License Plate': `Plaque d'immatriculation`,
  Apply: 'Appliquer',
  'Supported Filetypes': 'Types de fichiers pris en charge',
  'Total upload file size limit':
    'Limite totale de la taille du fichier de téléchargement',
  'Ensure Images are clear and centered. For the Truck Side Image, you should be able to clearly read company name. For License plate Image, you should be able to clearly read license plate.':
    "Assurez-vous que les images sont claires et centrées. Pour l'image côté camion, vous devriez être en mesure de lire clairement le nom de l'entreprise. Pour la plaque d'immatriculation, vous devriez être capable de lire clairement la plaque d'immatriculation.",
  'Last Week': 'Semaine dernière',
  Upload: 'Télécharger',
  'Responsible Party Name': 'Nom du responsable',
  'Is Residence': 'Résidence',
  'Pickup From': 'Récupérer de',
  Zone: 'Zone',
  Sch: 'Céd.',
  Pay: 'Payer',
  'Change Driver': 'Changer de conducteur',
  'Can Pickup': 'Récupération possible',
  'Requesting Yard': 'Demande de la cour',
  'Requested Date': 'Date demandée',
  'No of Lots': 'Nombre de lots',
  'Due Date': 'Date due',
  Payment: 'Paiement',
  From: 'De',
  'Copart Location': 'Emplacement Copart',
  'Not Scheduled': 'Non cédulé',
  'No lots added to this trip': 'Pas de lots ajoutés à ce transport',
  'Provide Decline Reason': 'Fournir une raison de refus',
  'Transporter Issue Reason': 'Raison du problème du transporteur',
  'Take Back and Accept Trip?': 'Reprendre et accepter le transport?',
  'Distribute To': 'Distribuer à',
  Issue: 'Problème',
  'Issue Subtype': 'Sous-type de problème',
  'Resolution Comments': 'Commentaires de résolution',
  'Issue Reason': 'Raison du problème ',
  'Acknowledge/Decline Trip': 'Accepter/refuser le transport',
  'Distribute Trip': 'Distribuer le transport',
  'Add Personnel': 'Ajouter du personnel',
  'Override Decline': 'Annuler le refus',
  'Update Personnel': 'Mettre à jour le personnel',
  'Take Back and Accept': 'Reprendre et accepter',
  'Mark as read': 'Marquer comme lu',
  Download: 'Télécharger',
  Language: 'Langue',
  'Other Lots': 'Autres lots',
  'Truck Info': 'Info camion',
  'Mark Issue as Read': 'Marquer le problème comme lu',
  'Trip Zone': 'Zone du transport',
  'Enter zone': 'Entrer la zone',
  Buyer: 'Acheteur',
  'Enter Buyer': 'Entrer l’acheteur',
  'Enter Seller': 'Entrer le vendeur',
  'Enter Make': 'Entrer la marque',
  'Enter Model': 'Entrer le modèle',
  'Enter Lot': 'Entrer le lot',
  'Truck Type': 'Type de camion',
  'Enter Zip': 'Entrer le code postal',
  'Shop name': 'Nom du garage',
  Subhauler: 'Remorqueur',
  'Enter Subhauler': 'Entrer le remorqueur',
  Enter: 'Entrée',
  'Enter year': 'Entrer l’année',
  'Run Comments': 'Commentaires sur le transport',
  'Accepts Cash': 'Accepte l’argent comptant',
  'Damage type': 'Type de dommages',
  'Remove From Trip': 'Retirer du transport',
  "Can't Clear": 'Impossible de régler',
  'Remove Driver/Group': 'Retirer le conducteur/groupe',
  'Override Decline Trip': 'Annuler le refus de transport',
  // Missed translations
  'Search using Trip ID or Lot Number':
    'Recherche par transport ou numéro de lot',
  'Selected Trips': 'Transports sélectionnés',
  'Cancel Work Request': 'Annuler une demande de travail',
  'Request Pcard': 'Demander une Pcard',
  'Delete Pcard': 'Supprimer une Pcard',
  'Create Work Request': 'Créer une demande de travail',
  'Create Request': 'Faire une demande',
  'Change Password': 'Changer le mot de passe',
  'Old Password': 'Ancien mot de passe',
  'New Password': 'Nouveau mot de passe',
  'Confirm Password': 'Confirmez le mot de passe',
  'Passwords do not match': 'Les mots de passe ne correspondent pas',
  Vendor: 'Vendeur',
  Other: 'Autre',
  Distribute: 'Distribuer',
  'Request New PCard': 'Demander une nouvelle Pcard',
  'Are you sure you want to destroy the pcard':
    'Êtes-vous sûr de vouloir détruire la pcard',
  Delete: 'Effacer',
  'View Lot': 'Vue Lot',
  'Pickup Location Information': 'Information sur le lieu de ramassage',
  'Destination Information': 'Informations de destination',
  'Scheduled date & time': 'Date et heure prévues',
  'Charges Due': 'Frais dus',
  'Pick Up Notes': 'Notes de ramassage',
  'Direction Notes': 'Notes de direction',
  'Seller Name': 'Nom du Vendeur',
  'Loss Date': 'Date de perte',
  'Acknowledge Trip': 'Reconnaître Trip',
  'Cash With Driver': 'Cash avec chauffeur',
  'Awaiting Cash': 'En attente de trésorerie',
  'No Lots are available': "Aucun lot n'est disponible",
  'Walk through': 'Vérification',
  'Assignee Full Name': 'Nom complet',
  'Current Trip Id': 'Id du transport en cours',
  'Declined Flag': 'Indication de refus',
  'Declined Trips view': 'Voir les transports refusés',
  'Distributed View': 'Voir la distribution',
  'Issues View': 'Voir les problèmes',
  Anytime: 'À tout moment',
  'Reset Map': 'Réinitialiser la carte',
  Map: 'Carte',
  Satellite: 'Satellite',
  'Zoom into this location(Approximate Location)':
    'Faire un zoom sur cet emplacement (position approximative)',
  'Look into street view at this location': 'Vue de la rue de cet emplacement',
  'Click here for Lot Details': 'Cliquer ici pour les détails du lot',
  'Verify Pickup': 'Vérifier la récupération',
  welcomePageTitle: 'Bienvenue sur Portail de Transport!',
  welcomePageText:
    'Cette visite du site vous guidera à travers toutes les nouvelles fonctionnalités et outils introduits avec cette version. Cette version marque le plus grand changement dans le processus de répartition de l’histoire de Copart! Cliquez sur Suivant pour explorer toutes les nouvelles fonctions de cette application!',
  listViewTitle: 'List vue',
  listViewText:
    "Tout comme avant, cette vue en liste vous permet d'identifier tous les lots dans lesquels le voyage a été créé, mais le pilote n'a pas encore été attribué.",
  mapViewTitle: 'Vue de la carte',
  mapViewText:
    "En plus de la vue Liste, vous avez également la vue Carte pour identifier tous les lots dans lesquels le trajet a été créé, mais le pilote n'a pas encore été attribué.",
  lotViewTitle: 'Lots vue',
  lotViewText: 'Moyen le plus rapide de voir par Lots.',
  tripViewTitle: 'Trips vue',
  tripViewText: 'Moyen le plus rapide de voir par Trips.',
  driverViewTitle: 'Drivers Vue',
  driverViewText:
    'Le moyen le plus rapide de voir tous les gestionnaires / groupe / société gestionnaire.',
  actionBarTitle: "Barre d'action",
  actionBarText:
    "De nouvelles fonctions de répartition sont maintenant disponibles dans la liste des actions en haut. Ces actions dépendent de l'état du lot, mais comprennent les actions Accuser réception / Refuser, Distribuer, Refuser le remplacement, Supprimer le conducteur et Reprendre le voyage!",
  filterTitle: 'Les filtres',
  filterText:
    "À partir de cette barre de navigation, vous pouvez facilement filtrer tous les lots / trajets du portail de transporteur. Lorsque vous cliquez sur l'icône du haut, la liste de tous les filtres disponibles s'affiche. Cliquez sur suivant pour afficher toutes les recherches rapides ci-dessous disponibles.",
  assignedViewTitle: 'Attribué',
  assignedViewText: 'Vous pouvez voir tous les trips assignés par vous.',
  acknowledgeViewTitle: 'Reconnu',
  acknowledgeViewText: 'Vous pouvez voir tous les trips reconnus par vous.',
  distributedViewTitle: 'Distribué',
  distributedViewText:
    'Vous pouvez voir tous les trips qui vous ont traversé et distribués par vous.',
  completedViewTitle: 'Terminé',
  completedViewText: 'Vous pouvez voir tous les trips effectués par vous.',
  issueByMeViewTitle: 'Problèmes soulevés par moi',
  issueByMeViewText:
    'Vous pouvez voir toutes les questions soulevées par vous.',
  declinedByMeViewTitle: 'Trips refusés soulevés par moi',
  declinedByMeViewText: 'Vous pouvez voir tous les trips refusés par vous.',
  issuesForMeViewTitle: 'Problèmes qui me sont assignés',
  issuesForMeViewText:
    'Vous pouvez voir tous les problèmes qui me sont assignés.',
  declinedTripsTitle: 'Diminué Trips',
  declinedTripsText: 'Vous pouvez voir tous les trips refusés.',
  'Forgot Password': 'Mot de passe oublié',
  Login: "S'identifier",
  'No requests to display. Please remove filters to view all requests':
    'Aucune demande à afficher. Veuillez supprimer les filtres pour voir toutes les demandes',
  'Completed Date': 'Date de fin',
  'Declined Trips View': 'Refusée t Trips Vue',
  'Please enter valid email to get the reset password link':
    'Veuillez entrer un email valide pour obtenir le lien de réinitialisation du mot de passe',
  loginUnsuccessfulHeader: 'Connexion infructueuse',
  loginUnsuccessfulMessage:
    'Veuillez vous assurer de saisir votre adresse e-mail et le mot de passe associé',
  loginUnsuccessfulSessionExpired:
    'Votre session a expiré. Veuillez vous reconnecter',
  'Advance & Pickup Information': 'Informations avancées et ramassage',
  'Awaiting Driver Assignment': "En attente de Driver d'affectation",
  'Truck Management': 'Gestion des camions',
}
