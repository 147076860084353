import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Typography,
  Divider,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import { useAtom } from 'jotai'
import { head, isEmpty } from 'ramda'
import CloseIcon from '@mui/icons-material/Close'
import MultiViewSidebar from 'components/views/dialogs/multiViewSidebar'

import {
  globalPostAtom,
  actionCodeAtom,
  completedRowDataAtom,
} from 'constants/atoms'
import { useStyles } from 'utils/styles'
import { overrideDeclineTrip } from 'services/tripsApi'
import { ACTION_CODES } from 'constants/actionCodes'
import { moveToNextItemInMultiView } from 'utils/dispatchutils'

const defaultData = { override_reason: '' }

const OverrideDeclineDialog = ({ rowData, onClose }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [actionCode, setActionCode] = useAtom(actionCodeAtom)
  const [globalLoading, setGlobalLoading] = useAtom(globalPostAtom)
  const [selectedRowData, setSelectedRowData] = useState(head(rowData))
  const [completedTrips, setCompletedTrips] = useAtom(completedRowDataAtom)
  const [formData, setFormData] = useState(defaultData)

  const { trip_id: selectedTripId = '', trip_name: selectedTripName = '' } =
    selectedRowData

  const resetFormData = () => {
    setFormData(defaultData)
  }

  const handleInputChange = ({ target: { name, value } }) =>
    setFormData((prevData) => ({ ...prevData, [name]: value }))

  const handleClose = () => {
    resetFormData()
    setActionCode('')
    onClose()
  }

  const handleSubmit = async () => {
    const payload = { override_reason: formData.override_reason }
    const response = await overrideDeclineTrip(payload, selectedTripId)
    if (response && response.status === 'success') {
      setGlobalLoading(true)
      enqueueSnackbar(`Decline Override Successful : ${selectedTripId}`, {
        variant: 'success',
      })
      setCompletedTrips([...completedTrips, selectedTripId])
    } else {
      enqueueSnackbar('Failed to override decline', {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    if (isEmpty(completedTrips)) {
      return
    }
    if (rowData.length === completedTrips.length) {
      handleClose()
    } else if (rowData.length > completedTrips.length) {
      let curIndex = rowData.indexOf(selectedRowData)
      const nextIndex = moveToNextItemInMultiView(
        curIndex,
        completedTrips,
        rowData,
        'trip_id',
      )
      setSelectedRowData(rowData[nextIndex])
      resetFormData()
    }
  }, [completedTrips])

  const classes = useStyles()
  return (
    <Dialog
      open={actionCode === ACTION_CODES.OVERRIDE_DECLINE}
      onClose={handleClose}
    >
      <DialogTitle style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '10px',
            whiteSpace: 'nowrap',
            color: 'white',
          }}
        >
          <div style={{ display: 'flex', columnGap: '10px' }}>
            <Typography
              classes={{
                root: classes.root,
              }}
            >
              Override Decline Trip
            </Typography>
            <Divider
              classes={{ root: classes.dividerRoot }}
              orientation="vertical"
              flexItem
            />
            <Typography
              classes={{
                root: classes.root,
              }}
            >
              {`${selectedTripId} - ${selectedTripName}`}
            </Typography>
          </div>
        </div>
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: '8px', top: '8px' }}
        >
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ width: '500px' }}>
        <div
          style={
            rowData.length > 1 ? { display: 'flex', alignItems: 'center' } : {}
          }
        >
          {rowData.length > 1 && (
            <MultiViewSidebar
              selectedRowData={selectedRowData}
              setSelectedRowData={setSelectedRowData}
              tripsView
              completedRowData={completedTrips}
              resetFormData={resetFormData}
            />
          )}
          <div
            className="col-2-3"
            style={
              rowData.length > 1
                ? {
                    borderLeft: '1px solid rgba(0,0,0,0.12)',
                    paddingLeft: '10px',
                    height: '100px',
                    whiteSpace: 'no-wrap',
                    width: '100%',
                  }
                : {}
            }
          >
            <TextField
              fullWidth
              required
              label="Override Rejection Reason"
              variant="outlined"
              name="override_reason"
              value={formData.override_reason}
              onChange={handleInputChange}
              mt={rowData.length > 1 ? 3 : 0}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={globalLoading}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!formData.override_reason || globalLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default OverrideDeclineDialog
