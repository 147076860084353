import RoomRoundedIcon from '@mui/icons-material/RoomRounded'
import { Box } from '@mui/material'

const NumberedIcon = ({
  count,
  letter = ' ',
  showCard,
  color,
  legend = false,
  isSelected = false,
}) => {
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        transform: showCard ? 'scale(1.5)' : 'scale(1)',
        transformOrigin: 'center bottom',
        transition: 'transform 0.5s',
      }}
    >
      <RoomRoundedIcon
        style={{
          fontSize: legend ? '2em' : '3em',
          color: isSelected ? 'blue' : color,
        }}
      />
      <Box
        component="span"
        sx={{
          position: 'absolute',
          fontWeight: 'bold',
          fontSize: legend ? '8px' : '12px',
          userSelect: 'none',
          pointerEvents: 'none',
          color: isSelected || !legend ? '#fff' : color,
          top: legend ? '50%' : '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: isSelected ? 'blue' : color,
        }}
      >
        {isSelected ? '✔' : legend ? 'P' : letter}
      </Box>
    </Box>
  )
}

export default NumberedIcon
