import React from 'react'

import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import './styles.scss'

const Dialog = ({
  open = false,
  iconName = '',
  title = '',
  content = '',
  actions = [],
  customised = false,
  onClose = () => {},
  iconOnTop = '',
}) => {
  return customised ? (
    <MuiDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <div className="LogoutWrap">
        <div className="avatarIconWrap">{iconOnTop}</div>
        <div className="divBox">
          <DialogTitle
            id="alert-dialog-title"
            style={{ backgroundColor: 'white', color: 'black' }}
          >
            {title}
          </DialogTitle>
          {content && <DialogContent>{content}</DialogContent>}
          <DialogActions sx={{ backgroundColor: 'white' }}>
            {actions.map((action) => (
              <LoadingButton
                fullWidth
                key={action.name}
                loading={false}
                variant="contained"
                onClick={action.onClick}
              >
                {action.name}
              </LoadingButton>
            ))}
          </DialogActions>
        </div>
      </div>
    </MuiDialog>
  ) : (
    <MuiDialog open={open} onClose={onClose}>
      <DialogTitle>
        {iconName && (
          <div className="column-list">
            <div className={iconName}></div>
          </div>
        )}
        {title}
      </DialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions sx={{ backgroundColor: 'white' }}>
        {actions.map(({ name, ...remActions }) => (
          <Button key={name} {...remActions}>
            {name}
          </Button>
        ))}
      </DialogActions>
    </MuiDialog>
  )
}

export default Dialog
