import React, { useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material'
import { atom, useAtom } from 'jotai'
import { useSnackbar } from 'notistack'
import { AccountDialogAtom } from 'constants/atoms'
import CloseIcon from '@mui/icons-material/Close'
import ChangePassword from './changePasswordAuth'
import { formatDateObj } from 'utils/date'
import { getItem, setItem } from 'utils/localstorage'
import { updateProfile } from 'services/api'
import { isValidUSPhoneNumber, isNumeric } from 'utils/regex'
import locale from 'utils/locale'

const changePasswordDialogAtom = atom(false)
const editModeAtom = atom(false)
const editedUserDataAtom = atom({ email: '', vendor: {} })

const AccountManagement = ({ userData = {} }) => {
  const [isAccountDialogOpen, setAccountDialogOpen] = useAtom(AccountDialogAtom)
  const [changePasswordDialog, setChangePasswordDialog] = useAtom(
    changePasswordDialogAtom,
  )
  const [isEditMode, setEditMode] = useAtom(editModeAtom)
  const [editedUserData, setEditedUserData] = useAtom(editedUserDataAtom)
  const vendorSelectionMode = getItem('vendorSelectionMode')
  const vendors = getItem('vendor')
  const vendor = editedUserData?.vendor
  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    if (userData) setEditedUserData(userData)
  }, [userData])

  useEffect(() => {
    if (vendorSelectionMode) {
      setEditMode(true)
    }
  })

  const handleToggleEditMode = () => {
    setEditMode(!isEditMode)
    setEditedUserData(userData)
  }

  const handleOpenChangePassword = () => {
    setChangePasswordDialog(true)
    setAccountDialogOpen(false)
  }

  const handleVendorChange = (event) => {
    const { value } = event.target
    const data = vendors.find((vendor) => vendor.vendor_id === value)
    setEditedUserData({
      ...editedUserData,
      vendor: data?.personnel_data,
      vendor_id: data?.vendor_id,
      vendor_name: data?.vendor_name,
    })
  }

  const handleInputChange = (event, field) => {
    const { name, value } = event.target
    if (name === 'phone_number' && !isNumeric(value)) {
      return
    }
    setEditedUserData((prevData) => ({
      ...prevData,
      vendor: { ...prevData.vendor, [field]: value },
    }))
  }

  const handleCloseChangePassword = () => {
    setChangePasswordDialog(false)
    setAccountDialogOpen(true)
  }

  const areRequiredFieldsFilled = () => {
    const requiredFields = ['first_name', 'last_name', 'phone_number']

    return (
      requiredFields.every((field) => Boolean(vendor[field])) &&
      isValidUSPhoneNumber(vendor.phone_number)
    )
  }

  const handleSaveChanges = async () => {
    const userLanguage = editedUserData.user_language
      ? editedUserData.user_language.toLowerCase()
      : ''
    const phoneNum = vendor?.phone_number
    const lastName = vendor?.last_name
    const firstName = vendor?.first_name
    const fullName = `${vendor?.first_name} ${vendor?.last_name}`
    const lastUpdateUser = editedUserData.email

    const newUserData = {
      email: editedUserData.email,
      vendor: {
        ...vendor,
        phone_number: vendor?.phone_number,
        first_name: vendor?.first_name,
        last_name: vendor?.last_name,
        full_name: `${vendor?.first_name} ${vendor?.last_name}`,
      },
      user_language: editedUserData.user_language,
      vendor_id: editedUserData.vendor_id,
      vendor_name: editedUserData.vendor_name,
    }

    const response = vendorSelectionMode
      ? null
      : await updateProfile({
          language: userLanguage,
          phoneNum,
          lastName,
          firstName,
          vendor: fullName,
          lastUpdateUser,
        })

    if (!vendorSelectionMode && response && response.status === 200) {
      enqueueSnackbar('Userdata updated successfully', { variant: 'success' })
    }

    localStorage.setItem('userData', JSON.stringify(newUserData))
    setEditMode(false)
    setAccountDialogOpen(false)
    setItem('vendorSelectionMode', false)
    window.location.reload()
  }

  return (
    <div>
      <Dialog
        open={isAccountDialogOpen || vendorSelectionMode}
        onClose={() => setAccountDialogOpen(false)}
      >
        <DialogTitle>
          {locale('My Profile')}
          <IconButton
            onClick={() => {
              setAccountDialogOpen(false)
              setEditMode(false)
            }}
            style={{ position: 'absolute', right: '8px', top: '8px' }}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {vendorSelectionMode && (
            <Typography>Please select a vendor and save to proceed</Typography>
          )}
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant={
                  !isEditMode || vendorSelectionMode ? 'filled' : 'outlined'
                }
                label={locale('Email')}
                margin="normal"
                value={editedUserData.email}
                InputProps={{
                  readOnly: !isEditMode || vendorSelectionMode,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant={
                  !isEditMode || vendorSelectionMode ? 'filled' : 'outlined'
                }
                margin="normal"
                label={locale('Language')}
                value={editedUserData.user_language}
                select
                onChange={(event) =>
                  setEditedUserData((prev) => ({
                    ...prev,
                    user_language: event.target.value,
                  }))
                }
                InputProps={{
                  readOnly: !isEditMode || vendorSelectionMode,
                }}
              >
                <MenuItem value="EN">English</MenuItem>
                <MenuItem value="FR">French Canadian</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant={
                  !isEditMode || vendorSelectionMode ? 'filled' : 'outlined'
                }
                label={locale('First Name')}
                margin="normal"
                name="first_name"
                value={vendor?.first_name}
                onChange={(event) => handleInputChange(event, 'first_name')}
                InputProps={{
                  readOnly: !isEditMode || vendorSelectionMode,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant={
                  !isEditMode || vendorSelectionMode ? 'filled' : 'outlined'
                }
                margin="normal"
                label={locale('Last Name')}
                name="last_name"
                value={vendor?.last_name}
                onChange={(event) => handleInputChange(event, 'last_name')}
                InputProps={{
                  readOnly: !isEditMode || vendorSelectionMode,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant={
                  !isEditMode || vendorSelectionMode ? 'filled' : 'outlined'
                }
                label={locale('Contact Number')}
                margin="normal"
                name="phone_number"
                value={vendor?.phone_number}
                onChange={(event) => handleInputChange(event, 'phone_number')}
                inputProps={{
                  readOnly: !isEditMode || vendorSelectionMode,
                  maxLength: 10,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant={
                  !isEditMode || vendorSelectionMode ? 'filled' : 'outlined'
                }
                margin="normal"
                type="date"
                label={locale('Start Date')}
                value={formatDateObj(vendor?.start_date, 'yyyy-MM-dd')}
                onChange={(event) => handleInputChange(event, 'start_date')}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Vendor"
                variant={!isEditMode ? 'filled' : 'outlined'}
                select
                name="vendor"
                value={editedUserData.vendor_id}
                onChange={(event) => handleVendorChange(event)}
                InputProps={{
                  readOnly: !isEditMode,
                }}
              >
                {vendors &&
                  vendors.map((vendor) => (
                    <MenuItem key={vendor.vendor_id} value={vendor.vendor_id}>
                      {vendor.vendor_name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {!isEditMode && (
            <Button variant="contained" onClick={handleOpenChangePassword}>
              {locale('Change Password')}
            </Button>
          )}
          {isEditMode ? (
            <>
              <Button variant="outlined" onClick={handleToggleEditMode}>
                {locale('Cancel')}
              </Button>
              <Button
                variant="contained"
                onClick={handleSaveChanges}
                disabled={!areRequiredFieldsFilled()}
              >
                {locale('Save')}
              </Button>
            </>
          ) : (
            <Button variant="contained" onClick={handleToggleEditMode}>
              {locale('Edit')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {changePasswordDialog && (
        <ChangePassword
          changePasswordDialog={changePasswordDialog}
          onCancel={handleCloseChangePassword}
          setChangePasswordDialog={setChangePasswordDialog}
        />
      )}
    </div>
  )
}

export default AccountManagement
