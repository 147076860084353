import { useAtom } from 'jotai'
import { Typography, Divider, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import { DistributeTripIcon } from 'components/icons/icons'
import { actionCodeAtom, resolveIssueAtom } from 'constants/atoms'
import { reverseDispatchStatusMapper } from 'utils/dispatchutils'
import { useStyles } from 'utils/styles'
import { ACTION_CODES } from 'constants/actionCodes'

const getActionTitle = (action) => {
  switch (action) {
    case ACTION_CODES.OVERRIDE_ISSUE:
      return 'Override Decline'
    case ACTION_CODES.CANT_CLEAR:
      return "Can't Clear"
    case ACTION_CODES.CAN_PICKUP:
      return 'Can Pickup'
    default:
      return ''
  }
}

const DialogHeader = ({ selectedRowData, handleClose }) => {
  const {
    lot_number: selectedLotNumber = '',
    lot_suffix: selectedLotSuffix = '',
    lot_description: selectedLotDescription = '',
    dispatch_status: selectedDispatchStatus = '',
  } = selectedRowData

  const [actionCode] = useAtom(actionCodeAtom)
  const [resolveIssue] = useAtom(resolveIssueAtom)

  const classes = useStyles()

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        columnGap: '10px',
        whiteSpace: 'nowrap',
      }}
    >
      {resolveIssue && <ReportProblemIcon color="white" />}
      {actionCode === ACTION_CODES.DISTRIBUTE_TRIP && <DistributeTripIcon />}
      <div style={{ display: 'flex', columnGap: '10px' }}>
        <Typography>
          {resolveIssue && 'Issue Resolution '}
          {getActionTitle(actionCode)} - {selectedLotNumber} -{' '}
          {selectedLotSuffix}
        </Typography>
        {selectedLotDescription && (
          <>
            <Divider
              classes={{ root: classes.dividerRoot }}
              orientation="vertical"
              flexItem={true}
            />
            <Typography>{selectedLotDescription}</Typography>
          </>
        )}
        <Divider
          classes={{ root: classes.dividerRoot }}
          orientation="vertical"
          flexItem={true}
        />
        <Typography>
          {reverseDispatchStatusMapper[selectedDispatchStatus]}
        </Typography>
      </div>
      <IconButton
        onClick={handleClose}
        style={{ position: 'absolute', right: '8px', top: '8px' }}
      >
        <CloseIcon sx={{ color: 'white' }} />
      </IconButton>
    </div>
  )
}

export default DialogHeader
