import React from 'react'
import { useAtom } from 'jotai'
import { selectedRowAtom, completedRowDataAtom } from 'constants/atoms'
import { MenuItem, Divider, Grid, Box } from '@mui/material'
import { useStyles } from 'utils/styles'
import DoneIcon from '@mui/icons-material/Done'

const MultiViewSidebar = ({
  selectedRowData,
  setSelectedRowData,
  tripsView = false,
  resetFormData = () => {},
}) => {
  const classes = useStyles()
  const [selectedRows] = useAtom(selectedRowAtom)
  const [completedRowData] = useAtom(completedRowDataAtom)
  const selectedLotNumber = selectedRowData?.lot_number
  const selectedTrip = selectedRowData?.trip_id
  return (
    <div className="col-1-3">
      <div style={{ padding: '10px' }}>
        <Grid item xs={5}>
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: 'auto',
              maxHeight: '400px',
              overflow: 'auto',
            }}
          >
            {selectedRows.map((selectedRow, index) => {
              const {
                lot_number: lotNumber,
                lot_suffix: lotSuffix,
                trip_id: tripId,
              } = selectedRow
              return (
                <div
                  key={`menu-item-${index}`}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {tripsView
                    ? completedRowData.includes(tripId) && <DoneIcon />
                    : completedRowData.includes(lotNumber) && <DoneIcon />}

                  <MenuItem
                    selected={
                      tripsView
                        ? selectedTrip === tripId
                        : selectedLotNumber === lotNumber
                    }
                    onClick={() => {
                      if (
                        (!tripsView && !completedRowData.includes(lotNumber)) ||
                        (tripsView && !completedRowData.includes(tripId))
                      ) {
                        setSelectedRowData(selectedRow)
                        resetFormData()
                      }
                    }}
                    sx={{ paddingTop: '10px', paddingBottom: '10px' }}
                    classes={{ selected: classes.selected }}
                  >
                    {tripsView ? tripId : `${lotNumber}-${lotSuffix}`}
                  </MenuItem>
                  {index !== Object.values(selectedRows).length - 1 ? (
                    <Divider sx={{ margin: '0 !important' }} />
                  ) : null}
                </div>
              )
            })}
          </Box>
        </Grid>
      </div>
    </div>
  )
}

export default MultiViewSidebar
