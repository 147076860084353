import React, { useEffect } from 'react'
import { atom, useAtom } from 'jotai'
import { useSnackbar } from 'notistack'

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PasswordInput from '../../customInputFields/passwordInput'
import { DialogActions } from '@mui/material'

import { loginLabels } from 'utils/labels'
import { matchPasswordRegex } from 'utils/regex'
import locale from 'utils/locale'
import { accountStatusAtom, isAuthorizedAtom } from 'constants/atoms'
import { changePassword } from '../../login/loginActions'
import { PasswordRequirements } from '../../customInputFields/passwordRequirements'

const oldPasswordAtom = atom('')
const newPasswordAtom = atom('')
const confirmNewPasswordAtom = atom('')
const passwordsMatchAtom = atom(true)

const ChangePassword = ({
  changePasswordDialog,
  onCancel,
  setChangePasswordDialog,
}) => {
  const [oldPassword, setOldPassword] = useAtom(oldPasswordAtom)
  const [newPassword, setNewPassword] = useAtom(newPasswordAtom)
  const [, setAccountStatus] = useAtom(accountStatusAtom)
  const [confirmNewPassword, setConfirmNewPassword] = useAtom(
    confirmNewPasswordAtom,
  )
  const [passwordsMatch, setPasswordsMatch] = useAtom(passwordsMatchAtom)
  const { enqueueSnackbar } = useSnackbar()
  const [, setIsAuthorized] = useAtom(isAuthorizedAtom)

  useEffect(() => {
    setPasswordsMatch(
      newPassword === confirmNewPassword ||
        (!newPassword && !confirmNewPassword),
    )
  }, [newPassword, confirmNewPassword, setPasswordsMatch])

  const handleChangePassword = async () => {
    const response = await changePassword({
      old_password: oldPassword,
      new_password: newPassword,
    })
    if (response.data.status === 'success') {
      setChangePasswordDialog(false)
      setAccountStatus('default')
      setIsAuthorized(false)
      enqueueSnackbar(loginLabels.passwordChanged, { variant: 'success' })
    } else {
      enqueueSnackbar('Unable to change password', { variant: 'error' })
    }
  }

  const checkPasswordMatch = (password) => {
    setPasswordsMatch(password === newPassword || (!password && !newPassword))
  }

  const passwordFieldsFilled = newPassword && confirmNewPassword
  const validNewPassword = matchPasswordRegex(newPassword)
  const changePasswordButtonDisabled =
    !passwordFieldsFilled || !passwordsMatch || !validNewPassword

  return (
    <div>
      <Dialog open={changePasswordDialog}>
        <DialogTitle>
          {locale('Change Password')}
          <IconButton
            onClick={onCancel}
            style={{ position: 'absolute', right: '8px', top: '8px' }}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <PasswordInput
            value={oldPassword}
            label={locale('Old Password')}
            placeholder={locale('Old Password')}
            onChange={({ target }) => setOldPassword(target.value)}
          />
          <PasswordInput
            value={newPassword}
            placeholder={locale('New Password')}
            label={locale('New Password')}
            onChange={({ target }) => {
              setNewPassword(target.value)
              checkPasswordMatch(confirmNewPassword)
            }}
          />
          <PasswordInput
            value={confirmNewPassword}
            errorMessage={locale('Passwords do not match')}
            invalidPassword={newPassword !== confirmNewPassword}
            placeholder={locale('Confirm Password')}
            label={locale('Confirm Password')}
            onChange={({ target }) => {
              setConfirmNewPassword(target.value)
              checkPasswordMatch(target.value)
            }}
            error={!passwordsMatch}
          />
          <PasswordRequirements password={newPassword} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCancel}>
            {locale('Cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={handleChangePassword}
            disabled={changePasswordButtonDisabled}
          >
            {locale('Submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ChangePassword
