import { propOr } from 'ramda'
import LotInfoTrip from 'components/custom/lotInfoTrip'
import tempVehicle from '../assets/images/V.jpg'

import {
  reverseDispatchStatusMapper,
  dispatchStatusColor,
  statusTextColor,
  formAddress,
  getIsScheduledText,
  getPickup,
  getDestination,
  getImageURL,
  getAssoicatedDriverName,
  statusMap,
  roleMap,
} from 'utils/dispatchutils'
import { formatPhoneNumberWithMask } from '../utils/regex'
import { displayActions } from 'constants/actionConfig'
import { getActions } from 'constants/actionConfig'
import { formatDateObj, getDateTimeFromDateFns } from 'utils/date'

/* function to combine columns using base, extra and action columns */
const createColumnSets = (
  baseColumns,
  extraColumns = [],
  handleActionClick,
  view,
) => {
  return [
    ...baseColumns,
    ...extraColumns,
    ...actionColumn(handleActionClick, view),
  ]
}

/* action column for all the view */
const actionColumn = (handleActionClick, view) => [
  {
    id: 'actions',
    label: 'Actions',
    display: (row) => {
      const { dispatch_assignment_detail_id: dispatchAssignmentId = '' } = row
      return (
        <div
          key={dispatchAssignmentId}
          style={{ display: 'flex', justifyContent: 'left' }}
        >
          {getActions(row, view).map((action) =>
            displayActions(action, row, handleActionClick),
          )}
        </div>
      )
    },
  },
]

/* lotsView base columns and lotsView extra columns */
const lotsViewBaseColumns = (openViewLotDialog) => [
  {
    id: 'lot_number',
    label: 'Lot Number',
    subView: '',
    sortable: true,
    display: (row) => {
      const { lot_number: lotNumber, lot_suffix: lotSuffix } = row
      return (
        <span
          style={{
            whiteSpace: 'nowrap',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() => openViewLotDialog(row)}
        >{`${lotNumber}-${lotSuffix}`}</span>
      )
    },
  },
  {
    id: 'current_trip_id',
    label: 'Trip Id',
    subView: '',
    sortable: true,
  },
  {
    id: 'lot_type',
    label: 'Vehicle Type',
    subView: '',
    sortable: true,
  },
  {
    id: 'lot_model_year',
    label: 'Year',
    subView: '',
    sortable: true,
  },
  {
    id: 'lot_make',
    label: 'Make',
    subView: '',
    sortable: true,
  },
  {
    id: 'lot_model',
    label: ' Model',
    subView: '',
    sortable: true,
  },
  {
    id: 'responsible_party_name',
    label: 'Responsible Party Name',
    sortable: true,
  },
  {
    id: 'is_residence',
    label: 'Is Residence',
    sortable: true,
    display: ({ is_residence: isResidece }) =>
      isResidece ? 'Yes' : isResidece === false ? 'No' : 'Unknown',
  },
  {
    id: 'src_nm',
    label: 'Pickup From',
    sortable: true,
    display: ({ source }) => getPickup(source),
  },
  {
    id: 'src_city',
    label: 'City',
    sortable: true,
    display: ({ source }) => propOr('', 'city', source),
  },
  {
    id: 'src_zip',
    label: 'Zip',
    sortable: true,
    display: ({ source }) => propOr('', 'zip', source),
  },
  {
    id: 'dest_nm',
    label: 'Deliver To',
    sortable: true,
    display: ({ destination }) => getDestination(destination),
  },
  {
    id: 'dispatch_status',
    label: 'Dispatch Status',
    display: ({ dispatch_status: value }) => {
      return (
        <div
          style={{
            backgroundColor: dispatchStatusColor[value],
            color: statusTextColor(value),
            borderRadius: '10px',
            textWrap: 'no-wrap',
            padding: '10px',
            fontWeight: 500,
          }}
        >
          {reverseDispatchStatusMapper[value]}
        </div>
      )
    },
    sortable: true,
  },
  {
    id: 'trip_date',
    label: 'Trip Date',
    sortable: true,
    display: ({ trip_date: tripDate }) => formatDateObj(tripDate, 'MM/dd/yyyy'),
  },
  {
    id: 'lot_trip_zone',
    label: 'Zone',
    sortable: true,
  },
]
const distributedExtras = [
  {
    id: 'is_towable',
    label: 'Towable',
    sortable: true,
  },
  {
    id: 'trip_type_code',
    label: 'Trip',
    sortable: true,
  },
  {
    id: 'tow_type_code',
    label: 'Tow',
    sortable: true,
  },
  {
    id: 'damage_type_code',
    label: 'Damage',
    sortable: true,
  },
  {
    id: 'scheduled_date',
    label: 'Sch',
    sortable: true,
    display: (row) => {
      const { scheduledDate, scheduledText } = getIsScheduledText(row)
      return (
        <div
          data-tip={scheduledText || 'Not Scheduled'}
          data-class="react-tooltip"
        >
          {scheduledDate}
        </div>
      )
    },
  },
  {
    id: 'payment_mode',
    label: 'Pay',
    sortable: true,
  },
]
const reportedIssuesExtras = [
  ...distributedExtras.slice(0, -1),
  {
    id: 'message_type_code',
    label: 'Issue Type',
    sortable: true,
    display: ({ active_issue: activeIssue }) =>
      propOr('', 'message_type_description', activeIssue),
  },
]
const assignedIssuesExtras = [
  ...reportedIssuesExtras,
  {
    id: 'created_by',
    label: 'Issue Raised By',
    display: ({ active_issue: activeIssue }) =>
      propOr('', 'created_by', activeIssue),
  },
]

/* final lotsView and lotSubView columns */
const lotsViewColumns = (handleActionClick, openViewLotDialog) =>
  createColumnSets(
    lotsViewBaseColumns(openViewLotDialog),
    [],
    handleActionClick,
    'lotsView',
  )
const distributedLotColumns = (handleActionClick, openViewLotDialog) =>
  createColumnSets(
    lotsViewBaseColumns(openViewLotDialog),
    distributedExtras,
    handleActionClick,
    'lotsView',
  )
const completedLotColumns = (handleActionClick, openViewLotDialog) => [
  ...distributedLotColumns(handleActionClick, openViewLotDialog),
]
const reportedIssuesLotColumns = (handleActionClick, openViewLotDialog) =>
  createColumnSets(
    lotsViewBaseColumns(openViewLotDialog),
    reportedIssuesExtras,
    handleActionClick,
    'lotsView',
  )
const assignedIssuesLotColumns = (handleActionClick, openViewLotDialog) =>
  createColumnSets(
    lotsViewBaseColumns(openViewLotDialog),
    assignedIssuesExtras,
    handleActionClick,
    'lotsView',
  )

/* tripsView base columns and tripsView extra columns */
const tripsViewBaseColumns = (handleActionClick) => [
  {
    id: 'trip_id',
    label: 'Trip Id',
    sortable: true,
  },
  {
    id: 'trip_name',
    label: 'Trip Name',
    sortable: true,
  },
  {
    id: 'responsible_party_name',
    label: 'Responsible Party Name',
  },
  {
    id: 'trip_date',
    label: 'Trip Date',
    sortable: true,
    display: ({ trip_date: tripDate }) => formatDateObj(tripDate, 'MM/dd/yyyy'),
  },
  {
    id: 'tow_truck_type',
    label: 'Tow Truck Type',
    sortable: true,
  },
  {
    id: 'created_at',
    label: 'Created Date',
    sortable: true,
    display: ({ created_at: createdDate }) =>
      formatDateObj(createdDate, 'MM/dd/yyyy'),
  },
  {
    id: 'total_lots',
    label: 'Total Lots',
    display: (row) => {
      const { assignments = '' } = row
      return (
        <div
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => handleActionClick(row, 'assignments')}
        >
          {assignments ? assignments.length : 0}
        </div>
      )
    },
  },
]
const declineReasonColumn = {
  id: 'rejection_reason',
  label: 'Decline Reason',
  sortable: true,
}

/* final tripsView and tripSubview columns */
const tripsViewColumns = (handleActionClick) =>
  createColumnSets(
    tripsViewBaseColumns(handleActionClick),
    [],
    handleActionClick,
    'tripsView',
  )
const assignedTripsColumns = (handleActionClick) =>
  createColumnSets(
    tripsViewBaseColumns(handleActionClick),
    [],
    handleActionClick,
    'tripsView',
  )
const acknowledgedtripsColumns = (handleActionClick) =>
  createColumnSets(
    tripsViewBaseColumns(handleActionClick),
    [],
    handleActionClick,
    'tripsView',
  )
const reportedDeclinedTripsColumns = (handleActionClick) =>
  createColumnSets(
    tripsViewBaseColumns(handleActionClick),
    [declineReasonColumn],
    handleActionClick,
    'tripsView',
  )
const reportedAssignedTripsColumns = (handleActionClick) =>
  createColumnSets(
    tripsViewBaseColumns(handleActionClick),
    [declineReasonColumn],
    handleActionClick,
    'tripsView',
  )

/* driver View Columns */
const driverViewColumns = (handleActionClick) => [
  { id: 'first_name', label: 'First Name', sortable: true },
  { id: 'last_name', label: 'Last Name', sortable: true },
  {
    id: 'role_id',
    label: 'Role',
    sortable: true,
    display: ({ role_id: value }) => roleMap[value],
  },
  {
    id: 'phone_number',
    label: 'Phone Number',
    sortable: true,
    display: ({ phone_number: phoneNumber }) =>
      formatPhoneNumberWithMask(phoneNumber),
  },
  {
    id: 'status',
    label: 'Status',
    sortable: true,
    display: ({ status: value }) => {
      return (
        <div
          style={{
            backgroundColor: value === 'A' ? 'green' : '#ccc',
            color: value === 'A' ? 'white' : 'black',
            borderRadius: '10px',
            textWrap: 'no-wrap',
            padding: '10px',
            fontWeight: 800,
          }}
        >
          {statusMap[value]}
        </div>
      )
    },
  },
  {
    id: 'dispatch_flag',
    label: ' Dispatchable',
    sortable: true,
    display: ({ dispatch_flag: dispatchFlag }) =>
      dispatchFlag ? 'Yes' : dispatchFlag === false ? 'No' : 'Unknown',
  },
  { id: 'pcard_flag', label: 'PCard', sortable: true },
  { id: 'filtered_trips', label: 'Filtered Trips' },
  { id: 'total_trips', label: 'Total Trips' },
  { id: 'total_assignments', label: 'Total Lots' },
  ...actionColumn(handleActionClick, 'driversView'),
]

/* work Request View Columns */
const workRequestViewColumns = (handleActionClick) => [
  { id: 'current_entity_id', label: 'Yard #' },
  { id: 'message_status', label: 'Status' },
  {
    id: 'requested_date',
    label: 'Requested Date',
    display: ({ message_type_json: dateObj }) =>
      propOr('', 'requested_date', dateObj),
  },
  {
    id: 'comments',
    label: 'Comments',
    display: ({ message_type_json: commentObj }) =>
      propOr('', 'comments', commentObj),
  },
  {
    id: 'requested_vehicles',
    label: 'No of Lots',
    display: ({ message_type_json: vehicleObj }) =>
      propOr('', 'requested_vehicles', vehicleObj),
  },
  {
    id: 'created_date',
    label: ' Created Date',
    display: ({ message_type_json: dateObj }) =>
      formatDateObj(propOr('', 'created_date', dateObj), 'MM/dd/yyyy'),
  },
  ...actionColumn(handleActionClick, 'workRequestsView'),
]

/* cat Tow Provider View Columns */
const catTowProviderViewColumns = (handleActionClick) => [
  { id: 'first_name', label: 'First Name', sortable: true },
  { id: 'last_name', label: 'Last Name', sortable: true },
  { id: 'role_id', label: 'Role', sortable: true },
  {
    id: 'phone_number',
    label: 'Phone Number',
    sortable: true,
    display: ({ phone_number: phoneNumber }) =>
      formatPhoneNumberWithMask(phoneNumber),
  },
  { id: 'email', label: 'Email' },
  { id: 'deploymentTypeCode', label: 'Deployment Type' },
  { id: 'catName', label: 'CAT Name' },
  { id: 'destinationFacilityId', label: 'Destination Facility' },
  { id: 'associatedBy', label: 'Associated By' },
  {
    id: 'truckType',
    label: 'Truck Type',
    display: ({ truckType }) => truckTypeMap[truckType],
  },
  { id: 'isDeploymentRequired', label: 'Is Deployed?' },
  {
    id: 'estimatedArrivalDate',
    label: 'Estimated Arrival Date',
    display: ({ estimatedArrivalDate }) =>
      estimatedArrivalDate
        ? new Date(
            getDateTimeFromDateFns(estimatedArrivalDate),
          ).toLocaleString()
        : '',
  },
  {
    id: 'status',
    label: 'Status',
    sortable: true,
    display: ({ status: value }) => {
      return (
        <div
          style={{
            backgroundColor: value === 'A' ? 'green' : '#ccc',
            color: value === 'A' ? 'white' : 'black',
            borderRadius: '10px',
            textWrap: 'no-wrap',
            padding: '10px',
            fontWeight: 800,
          }}
        >
          {statusMap[value]}
        </div>
      )
    },
  },
  ...actionColumn(handleActionClick, 'catTowProvidersView'),
]

/* truck management View Columns */
export const truckManagementViewColumns = (handleActionClick) => [
  {
    id: 'truck_image',
    label: 'Truck Image',
    display: ({ driver_side_view_images: sideViewImage }) => (
      <img
        src={sideViewImage ? getImageURL(sideViewImage, 'H') : tempVehicle}
        width="120"
        height="100"
        alt="Truck Sideview"
      />
    ),
  },
  { id: 'license_plate', label: 'License Plate', sortable: true },
  {
    id: 'address',
    label: 'Address',
    width: '400px',
    display: ({ location }) => formAddress(location),
  },
  {
    id: 'truck_type_code',
    label: 'Truck Type',
    sortable: true,
    display: ({ truck_type_code: truckTypeCode = 'Unknown Type' }) =>
      truckTypeMap[truckTypeCode],
  },
  {
    id: 'associatedDriver',
    label: 'Associated Driver',
    display: ({ vendor_personnel: vendorPersonnel }) =>
      getAssoicatedDriverName(vendorPersonnel),
  },
  ...actionColumn(handleActionClick, 'truckManagementView'),
]

/* distribute Trip DIALOG Columns */
const distributeTripDilogColumns = [
  {
    id: 'lot_number',
    label: 'Lot',
    display: ({ lot_number: lotNumber, lot_suffix: lotSuffix }) => (
      <span style={{ textWrap: 'nowrap' }}>
        {`${lotNumber} - ${lotSuffix}`}
      </span>
    ),
  },
  { id: 'lot_trip_zone', label: 'Zone' },
  { id: 'lot_model_year', label: 'Year' },
  { id: 'lot_make', label: 'Make' },
  { id: 'lot_model', label: 'Model' },
  { id: 'has_keys', label: 'Has Keys?' },
  {
    id: 'promised_date',
    label: 'Due date',
    display: ({ promised_date: promisedDate }) =>
      formatDateObj(promisedDate, 'MM/dd/yyyy'),
  },
  { id: 'payment_mode', label: 'Payment' },
  { id: 'source', label: 'From', display: ({ source }) => getPickup(source) },
  {
    id: 'destination',
    label: 'Copart Location',
    display: ({ destination }) => getDestination(destination),
  },
  { id: 'other', label: 'Other', display: (row) => <LotInfoTrip lot={row} /> },
  {
    id: 'sch',
    label: 'Sch',
    display: (row) => {
      const { scheduledDate, scheduledText } = getIsScheduledText(row)
      return (
        <div
          data-tip={scheduledText || 'Not Scheduled'}
          data-class="react-tooltip"
        >
          {scheduledDate}
        </div>
      )
    },
  },
]

export const truckTypeMap = {
  '1C': '1 Car',
  '2C': '2 Car (1 Flatbed, 1 Tow)',
  '2F': '2 Car (2 Flatbed)',
  '3C': '3 Car (2 Flatbed, 1 Tow)',
  '3F': '4 Car (3 Flatbed)',
  '4C': '4 Car (3 Flatbed, 1 Tow)',
  '5C': '5 Car',
}

export const columnConfig = (handleActionClick, openViewLotDialog) => ({
  lotsView: lotsViewColumns(handleActionClick, openViewLotDialog),
  distributed: distributedLotColumns(handleActionClick, openViewLotDialog),
  completed: completedLotColumns(handleActionClick, openViewLotDialog),
  reportedIssues: reportedIssuesLotColumns(
    handleActionClick,
    openViewLotDialog,
  ),
  assignedIssues: assignedIssuesLotColumns(
    handleActionClick,
    openViewLotDialog,
  ),
  tripsView: tripsViewColumns(handleActionClick),
  assigned: assignedTripsColumns(handleActionClick),
  acknowledged: acknowledgedtripsColumns(handleActionClick),
  reportedDeclinedTrips: reportedDeclinedTripsColumns(handleActionClick),
  reportedAssignedTrips: reportedAssignedTripsColumns(handleActionClick),
  driversView: driverViewColumns(handleActionClick),
  workRequestsView: workRequestViewColumns(handleActionClick),
  catTowProvidersView: catTowProviderViewColumns(handleActionClick),
  truckManagementView: truckManagementViewColumns(handleActionClick),
  distributeTripDialog: distributeTripDilogColumns,
})
