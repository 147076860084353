import axios from 'services/customAxios'
import { getEndpoints } from 'constants/endpoints'

export const createRequest = async (payload) => {
  try {
    const endpoint = getEndpoints().createRequest
    const response = await axios.post(endpoint, payload)

    return {
      success: true,
      data: response.data,
      status: response.status,
    }
  } catch (error) {
    console.error('Error while creating work request', error)
    return {
      success: false,
      error: error.response
        ? error.response.data
        : 'Failed to create work request',
      status: error.response ? error.response.status : 500,
    }
  }
}

export const deleteRequest = async (payload) => {
  try {
    const endpoint = getEndpoints().deleteRequest
    const response = await axios.patch(endpoint, payload)

    return {
      success: true,
      data: response.data,
      status: response.status,
    }
  } catch (error) {
    console.error('Error while cancelling work request', error)

    return {
      success: false,
      error: error.response
        ? error.response.data
        : 'Failed to cancel work request',
      status: error.response ? error.response.status : 500,
    }
  }
}
