import { useEffect, useState } from 'react'
import axios from 'services/customAxios'
import { pathOr } from 'ramda'
import { getItem } from 'utils/localstorage'
import transformFilters from 'utils/filters'
import { getEndpoints } from 'constants/endpoints'

export const fetchDrivers = async () => {
  const accessToken = getItem('accessToken')
  const userData = getItem('userData')
  try {
    const response = await axios.post(
      '/services/ycsdispatchus/transporter/drivers',
      null,
      {
        headers: {
          Authorization: `bearer ${accessToken}`,
          'X-Vendor-Id': userData.vendor.vendor_id,
        },
      },
    )
    if (response.data.status === 'success') {
      return response.data.data
    }
  } catch (error) {
    console.error('Failed to retrieve user details', error)
  }
}

export const useFiltersFetcher = (selectedView, selectedFilters) => {
  const [filtersData, setFiltersData] = useState([])
  const [loading, setLoading] = useState(false)
  const selectedFiltersInView = selectedFilters[selectedView]
    ? selectedFilters[selectedView]
    : {}

  useEffect(() => {
    fetchFilters()
  }, [])

  const fetchFilters = async () => {
    setLoading(true)
    const accessToken = getItem('accessToken')
    const userData = getItem('userData')
    try {
      const response = await axios.post(
        '/services/ycsdispatchus/transporter/facet_counts',
        { filter_by: selectedFiltersInView },
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
            'X-Vendor-Id': userData.vendor.vendor_id,
            'X-Source': 'portal',
          },
        },
      )

      if (response.data.status === 'success') {
        const rawFacets = pathOr([], ['data', 'data'], response)
        const { filtersByName: facetsFiltersByName } =
          transformFilters(rawFacets)
        const retainedFilterValues = Object.keys(facetsFiltersByName).reduce(
          (acc, filterValue) => [...acc, facetsFiltersByName[filterValue]],
          [],
        )

        setFiltersData(retainedFilterValues)
      }
    } catch (error) {
      console.error('Failed to fetch Filters response', error)
      setFiltersData([])
    } finally {
      setLoading(false)
    }
  }

  return [loading, filtersData]
}

export const updateProfile = async (payload) => {
  const accessToken = getItem('accessToken')
  const userData = getItem('userData')
  const vendorId = userData.vendor.vendor_id
  try {
    const response = await axios.patch(
      `ycsdispatchus/transporter/vendors/${vendorId}/personnel/${userData.vendor.vendor_personnel_id}`,
      payload,
      {
        headers: {
          Authorization: `bearer ${accessToken}`,
          'X-Vendor-Id': vendorId,
          'X-Countrycode': 'US',
        },
      },
    )
    if (response.status === 200) {
      return response
    }
  } catch (error) {
    console.error('Failed to retrieve user details', error)
  }
}

export const getQuickCounts = async () => {
  try {
    const endpoint = getEndpoints().quickCounts
    const endpoint2 = getEndpoints().issueQuickCounts
    const response = await axios.get(endpoint)
    const response2 = await axios.get(endpoint2)

    const data1 = pathOr({}, ['data', 'data'], response)
    const data2 = pathOr({}, ['data', 'data'], response2)
    return { ...data1, ...data2 }
  } catch (error) {
    console.error('Failed to get quick counts', error)
  }
}
