import { useAtom } from 'jotai'
import { Tooltip, IconButton } from '@mui/material'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import TaskIcon from '@mui/icons-material/Task'
import DownloadIcon from '@mui/icons-material/Download'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import UndoIcon from '@mui/icons-material/Undo'
import { intersection, isEmpty, replace, pathOr } from 'ramda'
import {
  actionCodeAtom,
  rowDataAtom,
  DriverScheduleDialogAtom,
  selectedRowAtom,
  selectedSubViewAtom,
  selectedFiltersAtom,
  selectedViewAtom,
  resolveIssueAtom,
  mapViewAtom,
} from 'constants/atoms'
import { appNavigatorItemsConst } from 'constants/sidebarItems'
import { ACTION_CODES } from 'constants/actionCodes'
import {
  CreateIssueIcon,
  DistributeTripIcon,
  ResolveIssueIcon,
} from 'components/icons/icons'
import { getActions } from 'constants/actionConfig'

import axios from 'services/customAxios'
import { getItem } from 'utils/localstorage'
import {
  downloadCSV,
  getFieldDataForLotsView,
  getCSVHeaderRow,
  addNewLine,
  getCsvData,
} from 'utils/dispatchutils'
import { columnConfig } from 'constants/columns'
import { useSnackbar } from 'notistack'

const accessToken = getItem('accessToken')
const userData = getItem('userData')
const vendorId = pathOr('', ['vendor', 'vendor_id'], userData)
const config = {
  headers: {
    Authorization: `bearer ${accessToken}`,
    'X-Vendor-Id': vendorId,
    'X-Countrycode': 'US',
  },
}
const params = (selectedView, selectedFilters) => {
  return {
    filter_by: selectedFilters[selectedView],
    page: 1,
    page_size: 5000,
    search: '',
    sort_by: {},
  }
}

const getCommonActions = (selectedRows, view) => {
  if (selectedRows.length === 0) {
    return []
  }

  const actionsPerRow = selectedRows.map((row) => getActions(row, view))
  const commonActions = actionsPerRow.reduce((accumulator, currentActions) => {
    return accumulator.filter((action) => currentActions.includes(action))
  })

  return commonActions
}

const GlobalAction = ({ view }) => {
  const [, setActionCode] = useAtom(actionCodeAtom)
  const [, setRowData] = useAtom(rowDataAtom)
  const [, setDriverScheduleDialogOpen] = useAtom(DriverScheduleDialogAtom)
  const [selectedRows] = useAtom(selectedRowAtom)
  const [selectedSubView] = useAtom(selectedSubViewAtom)
  const [selectedFilters] = useAtom(selectedFiltersAtom)
  const [selectedView] = useAtom(selectedViewAtom)
  const [mapView] = useAtom(mapViewAtom)
  const [, setResolveIssue] = useAtom(resolveIssueAtom)
  const { enqueueSnackbar } = useSnackbar()

  const effectiveView = mapView ? appNavigatorItemsConst.LOTS : view
  const commonActions = getCommonActions(selectedRows, effectiveView)
  if (commonActions.includes('issueResolution')) {
    setResolveIssue(true)
  }
  const handleExportCSV = async (view) => {
    try {
      enqueueSnackbar('Downloading..', { variant: 'info' })
      const endpoint = '/services/ycsdispatchus/transporter/assignments'
      const response = await axios.post(
        endpoint,
        params(selectedView, selectedFilters),
        config,
      )
      const data = response.data.data
      const finalData = getFieldDataForLotsView(data, columnConfig()[view])
      const columns = getCSVHeaderRow(columnConfig()[view])
      const csvdata = getCsvData(finalData)
      const CSV = replace(/_/g, ' ', addNewLine(columns)) + addNewLine(csvdata)
      downloadCSV('LotsData', CSV)
      enqueueSnackbar('Data Downloaded Successfully', { variant: 'success' })
    } catch (error) {
      console.error('Error while getting lot info:', error)
      enqueueSnackbar('ERROR while downloading data', { variant: 'error' })
    }
  }

  switch (effectiveView) {
    case appNavigatorItemsConst.LOTS:
      return (
        <div style={{ display: 'flex' }}>
          <Tooltip
            title={
              <span style={{ fontSize: '1.3em' }}>Download Lots Data</span>
            }
            enterDelay={500}
            leaveDelay={200}
          >
            <IconButton
              onClick={() => {
                handleExportCSV(selectedSubView ? selectedSubView : view)
              }}
            >
              <DownloadIcon sx={{ color: 'black' }} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={<span style={{ fontSize: '1.3em' }}>Create Issue</span>}
            enterDelay={500}
            leaveDelay={200}
          >
            <IconButton
              onClick={() => {
                if (commonActions.includes('createIssue')) {
                  setActionCode(ACTION_CODES.CREATE_ISSUE)
                }
              }}
            >
              <CreateIssueIcon
                color={
                  commonActions.includes('createIssue') ? '#000000' : 'grey'
                }
              />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={<span style={{ fontSize: '1.3em' }}>Distribute Trip</span>}
            enterDelay={500}
            leaveDelay={200}
          >
            <IconButton
              onClick={() => {
                if (commonActions.includes('distributeTrip')) {
                  setActionCode(ACTION_CODES.DISTRIBUTE_TRIP)
                }
              }}
            >
              <DistributeTripIcon
                color={
                  commonActions.includes('distributeTrip') ? '#000000' : 'grey'
                }
              />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={<span style={{ fontSize: '1.3em' }}>Resolve Issue</span>}
            enterDelay={500}
            leaveDelay={200}
          >
            <IconButton
              onClick={() => {
                if (commonActions.includes('issueResolution')) {
                  setActionCode(ACTION_CODES.RESOLVE_ISSUE)
                }
              }}
            >
              <ResolveIssueIcon
                color={
                  commonActions.includes('issueResolution') ? '#000000' : 'grey'
                }
              />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={<span style={{ fontSize: '1.3em' }}>Acknowledge Trip</span>}
            enterDelay={500}
            leaveDelay={200}
          >
            <IconButton
              onClick={() => {
                if (commonActions.includes('cancel')) {
                  setActionCode(ACTION_CODES.CAN_PICKUP)
                }
              }}
            >
              <ThumbUpIcon
                style={{
                  color: commonActions.includes('cancel') ? '#000000' : 'grey',
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      )
    case appNavigatorItemsConst.TRIPS:
      return (
        <div style={{ display: 'flex' }}>
          <Tooltip
            title={
              <span style={{ fontSize: '1.3em' }}>
                Acknowledge Or Decline Trip
              </span>
            }
            enterDelay={500}
            leaveDelay={200}
          >
            <IconButton
              onClick={() => {
                if (commonActions.includes('acknowledgeTrip')) {
                  setActionCode(ACTION_CODES.ACKNOWLEDGE_TRIP)
                }
              }}
            >
              <TaskIcon
                style={{
                  color: commonActions.includes('acknowledgeTrip')
                    ? '#000000'
                    : 'grey',
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip
            title={<span style={{ fontSize: '1.3em' }}>Distribute Trip</span>}
            enterDelay={500}
            leaveDelay={200}
          >
            <IconButton
              onClick={() => {
                if (commonActions.includes('distributeTrip')) {
                  setActionCode(ACTION_CODES.DISTRIBUTE_TRIP)
                }
              }}
            >
              <DistributeTripIcon
                color={
                  commonActions.includes('distributeTrip') ? '#000000' : 'grey'
                }
              />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              <span style={{ fontSize: '1.3em' }}>Override Decline Trip</span>
            }
            enterDelay={500}
            leaveDelay={200}
          >
            <IconButton
              onClick={() => {
                if (commonActions.includes('overrideDeclineTrip')) {
                  setActionCode(ACTION_CODES.OVERRIDE_DECLINE)
                }
              }}
            >
              <SwapHorizIcon
                sx={{
                  fontSize: 25,
                  color: commonActions.includes('overrideDeclineTrip')
                    ? '#000000'
                    : 'grey',
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={<span style={{ fontSize: '1.3em' }}>Remove Driver</span>}
            enterDelay={500}
            leaveDelay={200}
          >
            <IconButton
              onClick={() => {
                if (commonActions.includes('removeTransporter')) {
                  setActionCode(ACTION_CODES.REMOVE_DRIVER)
                }
              }}
            >
              <PersonRemoveIcon
                sx={{
                  fontSize: 25,
                  color: commonActions.includes('removeTransporter')
                    ? '#000000'
                    : 'grey',
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              <span style={{ fontSize: '1.3em' }}>Take Back and Accept</span>
            }
            enterDelay={500}
            leaveDelay={200}
          >
            <IconButton
              onClick={() => {
                if (commonActions.includes('cancelDeclineTrip')) {
                  setActionCode(ACTION_CODES.TAKE_BACK_AND_ACCEPT)
                }
              }}
            >
              <UndoIcon
                sx={{
                  fontSize: 25,
                  color: commonActions.includes('cancelDeclineTrip')
                    ? '#000000'
                    : 'grey',
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      )
    case appNavigatorItemsConst.DRIVERS:
      return (
        <div>
          <Tooltip
            title={
              <span style={{ fontSize: '1.3em' }}>Manage Driver Schedule</span>
            }
            enterDelay={500}
            leaveDelay={200}
          >
            <IconButton
              onClick={() => {
                setRowData([])
                setActionCode(ACTION_CODES.DRIVER_SCHEDULE)
                setDriverScheduleDialogOpen(true)
              }}
            >
              <EventAvailableIcon sx={{ fontSize: 30, color: 'black' }} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={<span style={{ fontSize: '1.3em' }}>Add New Driver</span>}
            enterDelay={500}
            leaveDelay={200}
          >
            <IconButton
              onClick={() => {
                setRowData([])
                setActionCode(ACTION_CODES.ADD_DRIVER)
              }}
            >
              <PersonAddIcon sx={{ fontSize: 30, color: 'black' }} />
            </IconButton>
          </Tooltip>
        </div>
      )
    case appNavigatorItemsConst.WORK_REQUESTS:
      return (
        <Tooltip
          title={<span style={{ fontSize: '1.3em' }}>Create Work Request</span>}
          enterDelay={500}
          leaveDelay={200}
        >
          <IconButton
            onClick={() => {
              setRowData([])
              setActionCode(ACTION_CODES.CREATE_REQUEST)
            }}
          >
            <BusinessCenterIcon sx={{ fontSize: 30, color: 'black' }} />
          </IconButton>
        </Tooltip>
      )
    case appNavigatorItemsConst.CAT_TOW_PROVIDERS:
      return (
        <Tooltip
          title={<span style={{ fontSize: '1.3em' }}>Add New Driver</span>}
          enterDelay={500}
          leaveDelay={200}
        >
          <IconButton
            onClick={() => {
              setRowData([])
              setActionCode(ACTION_CODES.ADD_DRIVER)
            }}
          >
            <PersonAddIcon sx={{ fontSize: 30, color: 'black' }} />
          </IconButton>
        </Tooltip>
      )
    case appNavigatorItemsConst.TRUCK_MANAGEMENT:
      return (
        <Tooltip
          title={<span style={{ fontSize: '1.3em' }}>Add New Truck</span>}
          enterDelay={500}
          leaveDelay={200}
        >
          <IconButton
            onClick={() => {
              setRowData([])
              setActionCode(ACTION_CODES.ADD_TRUCK)
            }}
          >
            <LocalShippingIcon sx={{ fontSize: 30, color: 'black' }} />
          </IconButton>
        </Tooltip>
      )
    default:
      return null
  }
}

export default GlobalAction
