import React, { useEffect, useState } from 'react'
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Chip,
  Tooltip,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import DeleteIcon from '@mui/icons-material/Delete'
import { useAtom } from 'jotai'
import {
  tableRecordsAtom,
  selectedRecordsAtom,
  selectedSubViewAtom,
  selectedViewAtom,
  searchTermAtom,
  selectedFiltersAtom,
  selectedRowAtom,
} from 'constants/atoms'
import {
  appNavigatorItemsConst,
  placeholdersMapping,
} from 'constants/sidebarItems'
import GlobalAction from './globalAction'
import Filters from './filters'
import { filterMapper } from 'utils/filters'
import { reverseDispatchStatusMapper } from 'utils/dispatchutils'
import locale from 'utils/locale'

const Search = () => {
  const [searchTerm, setSearchTerm] = useAtom(searchTermAtom)
  const [selectedView] = useAtom(selectedViewAtom)

  useEffect(() => {
    setSearchTerm('')
  }, [selectedView, setSearchTerm])

  const placeholderText = placeholdersMapping[selectedView] || 'Search'
  return (
    <TextField
      variant="outlined"
      size="small"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      placeholder={locale(placeholderText)}
      style={{
        width: '20%',
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export const ActionBar = () => {
  const [tableRecords] = useAtom(tableRecordsAtom)
  const [selectedRecords, setSelectedRecords] = useAtom(selectedRecordsAtom)
  const [selectedView] = useAtom(selectedViewAtom)
  const [selectedSubView] = useAtom(selectedSubViewAtom)
  const [selectedFilters, setSelectedFilters] = useAtom(selectedFiltersAtom)
  const [, setSelectedRows] = useAtom(selectedRowAtom)
  const [filterItemToRemove, setFilterItemToRemove] = useState([])

  useEffect(() => {
    setSelectedRecords(0)
    setSelectedRows([])
  }, [selectedView, selectedSubView, setSelectedRecords, setSelectedRows])

  const showSearch = selectedView !== appNavigatorItemsConst.WORK_REQUESTS

  const showFilters =
    selectedView === appNavigatorItemsConst.LOTS ||
    selectedView === appNavigatorItemsConst.TRIPS

  const onRemoveChip = (filterName, value) => {
    setFilterItemToRemove([filterName, value])
  }

  const generateChipLabel = (filterKey, selectedItem) => {
    let selectedLabel = selectedItem
    if (filterKey === 'dispatch_status') {
      if (typeof selectedItem === 'number') {
        selectedLabel = reverseDispatchStatusMapper[selectedItem]
      }
    }
    return `${filterMapper[filterKey] ?? filterKey} - ${selectedLabel}`
  }

  const handleClearAll = () => {
    setSelectedFilters({
      ...selectedFilters,
      [selectedView]: {},
    })
  }

  return (
    <div
      id="actionBarContainer"
      style={{
        display: 'flex',
        padding: '10px 5px',
        gap: '5px',
        flexDirection: 'column',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {showFilters && (
          <Filters
            filterItemToRemove={filterItemToRemove}
            selectedView={selectedView}
          />
        )}
        {showSearch && <Search />}
        <div
          style={{
            margin: '10px',
            display: 'flex',
          }}
        >
          <Typography
            variant="body1"
            style={{ marginRight: 10, fontWeight: 'bold' }}
          >
            {locale('Total')}: {tableRecords}
          </Typography>
          {showFilters && (
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              {locale('Selected')}: {selectedRecords}
            </Typography>
          )}
        </div>
        <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
          <GlobalAction view={selectedView} />
        </div>
      </div>
      <div
        style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
        id="filterChips"
      >
        {selectedFilters[selectedView] &&
          Object.keys(selectedFilters[selectedView]).map((filterKey) => {
            const selectedItems = selectedFilters[selectedView][filterKey]

            return selectedItems.map((item, index) => (
              <Chip
                key={`filter-chip-${index}`}
                label={locale(generateChipLabel(filterKey, item))}
                variant="outlined"
                onDelete={() => onRemoveChip(filterKey, item)}
              />
            ))
          })}
        {selectedFilters[selectedView] &&
          Object.keys(selectedFilters[selectedView]).length > 1 && (
            <Tooltip title={locale('Clear All Filters')}>
              <IconButton sx={{ padding: '0px' }} onClick={handleClearAll}>
                <DeleteIcon sx={{ color: 'red' }} />
              </IconButton>
            </Tooltip>
          )}
      </div>
    </div>
  )
}
