import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { useSnackbar } from 'notistack'

import { Button } from '@mui/material'
import LockResetIcon from '@mui/icons-material/LockReset'
import PasswordInput from '../customInputFields/passwordInput'

import { loginLabels } from 'utils/labels'
import { matchPasswordRegex } from 'utils/regex'
import { accountStatusAtom } from 'constants/atoms'
import { changePassword } from './loginActions'
import BackButton from '../customInputFields/backButton'
import { PasswordRequirements } from '../customInputFields/passwordRequirements'

const ChangePassword = ({ otp, setOtp }) => {
  const [newPassword, setNewPassword] = useState('')
  const [, setAccountStatus] = useAtom(accountStatusAtom)
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [passwordsMatch, setPasswordsMatch] = useState(true)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setPasswordsMatch(
      newPassword === confirmNewPassword ||
        (!newPassword && !confirmNewPassword),
    )
  }, [newPassword, confirmNewPassword, setPasswordsMatch])

  const handleChangePassword = async () => {
    const response = await changePassword({
      old_password: otp,
      new_password: newPassword,
    })
    if (response.data.status === 'success') {
      setAccountStatus('default')
      setOtp('')
      enqueueSnackbar(loginLabels.passwordChanged, { variant: 'success' })
    } else {
      enqueueSnackbar('Unable to change password', { variant: 'error' })
    }
  }

  const checkPasswordMatch = (password) => {
    setPasswordsMatch(password === newPassword || (!password && !newPassword))
  }

  const passwordFieldsFilled = newPassword && confirmNewPassword
  const validNewPassword = matchPasswordRegex(newPassword)
  const changePasswordButtonDisabled =
    !passwordFieldsFilled || !passwordsMatch || !validNewPassword

  return (
    <div>
      <div className="avatarIconWrap">
        <LockResetIcon className="avatarDiv" />
      </div>
      <div className={'loginStep1Wrap divBox'}>
        <>
          <BackButton
            accountStatus={'otpVerification'}
            setAccountStatus={setAccountStatus}
          />
          <span className="mediumHeading">
            {loginLabels.registerNewPassword()}
          </span>
          <span className="mediumHeading">
            {loginLabels.registerNewPasswordInfo}
          </span>
        </>
        <PasswordInput
          value={newPassword}
          placeholder="New Password"
          label="New Password"
          onChange={({ target }) => {
            setNewPassword(target.value)
            checkPasswordMatch(confirmNewPassword)
          }}
        />
        <PasswordInput
          value={confirmNewPassword}
          errorMessage="Passwords do not match"
          invalidPassword={newPassword !== confirmNewPassword}
          placeholder="Confirm New Password"
          label="Confirm New Password"
          onChange={({ target }) => {
            setConfirmNewPassword(target.value)
            checkPasswordMatch(target.value)
          }}
          error={!passwordsMatch}
        />
        <PasswordRequirements password={newPassword} />
        <div className="changePasswordButton">
          <Button
            variant="contained"
            onClick={handleChangePassword}
            disabled={changePasswordButtonDisabled}
          >
            Change Password
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
