import { Zoom, Fab } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

const BackButton = ({ accountStatus, setAccountStatus }) => {
  return (
    <div className="loginBackBtn">
      <Zoom in unmountOnExit>
        <Fab
          size="medium"
          color="primary"
          aria-label="add"
          variant="extended"
          onClick={() => setAccountStatus(accountStatus)}
        >
          <ArrowBackIosNewIcon sx={{ mr: 1 }} />
          Back
        </Fab>
      </Zoom>
    </div>
  )
}

export default BackButton
