import { add, parseISO, isPast, format, isToday, isBefore } from 'date-fns'

export const parseDate = (date) =>
  typeof date === 'string' ? parseISO(date) : date

export const isInPast = (date) => isPast(parseDate(date))

export const addHours = (date, hours) => add(parseDate(date), hours)

export const addDays = (date, params) => add(parseDate(date), params)

export const formatDateObj = (date, formatStr = 'MM/dd/yyyy') => {
  if (!date) return ''

  const formattedDate = format(new Date(date), formatStr)
  return formattedDate
}

export const getCurrentDate = () => new Date()

export const getDatesForCurrentWeek = (
  currentDate,
  format = 'MM/dd/yyyy',
  includeDay = false,
) => {
  const dates = []
  const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const today = new Date(currentDate)
  const dayOfWeek = today.getDay()
  const startDate = new Date(today)
  startDate.setDate(today.getDate() - dayOfWeek + 1)

  for (let i = 0; i < 14; i++) {
    //skipping Sunday and Saturday
    if ([0, 6].includes((startDate.getDay() + i) % 7)) continue

    const date = new Date(startDate)
    date.setDate(startDate.getDate() + i)
    const day = includeDay ? ` (${weekday[(startDate.getDay() + i) % 7]})` : ''
    dates.push(formatDateObj(date, format) + day)
  }

  return dates
}

export const isSameOrBeforeToday = (date) => {
  const inputDate = parseISO(date)
  return isToday(inputDate) || isBefore(inputDate, new Date())
}

export const getDateTimeFromDateFns = (
  dateTime,
  formatString = "yyyy-MM-dd'T'HH:mm:ssXXX",
) => {
  if (!dateTime) return ''

  const date = parseISO(dateTime)
  return format(date, formatString)
}
