import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  MenuItem,
} from '@mui/material'
import { useAtom } from 'jotai'
import { head, pathOr } from 'ramda'
import { useSnackbar } from 'notistack'
import { globalPostAtom, actionCodeAtom } from 'constants/atoms'
import CloseIcon from '@mui/icons-material/Close'
import { ACTION_CODES } from 'constants/actionCodes'
import { getItem } from 'utils/localstorage'
import { requestPcard } from 'services/driverApi'

const RequestPcardDialog = ({ rowData, onClose }) => {
  const { enqueueSnackbar } = useSnackbar()
  const userData = getItem('userData')
  const yardList = pathOr([], ['vendor', 'groups', '0', 'yards'], userData).map(
    ({ yard_name, yard_number }) => ({
      label: `${yard_number} - ${yard_name}`,
      value: yard_number,
    }),
  )
  const [actionCode, setActionCode] = useAtom(actionCodeAtom)
  const [globalLoading, setGlobalLoading] = useAtom(globalPostAtom)
  const [yard_number, setYardNumber] = useState('')
  const handleSubmit = async () => {
    const payload = { yardNumber: yard_number }
    const result = await requestPcard(
      head(rowData).vendor_personnel_id,
      payload,
    )
    if (result.status === 200) {
      setGlobalLoading(true)
      enqueueSnackbar('Pcard has been requested successfully', {
        variant: 'success',
      })
      handleClose()
    } else {
      enqueueSnackbar('Error occured while requesting Pcard', {
        variant: 'error',
      })
    }
  }

  const handleClose = () => {
    setActionCode('')
    onClose()
  }

  return (
    <Dialog
      open={actionCode === ACTION_CODES.REQUEST_PCARD}
      onClose={handleClose}
    >
      <DialogTitle>
        Request New Pcard
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: '8px', top: '8px' }}
        >
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ width: '500px' }}>
        <TextField
          fullWidth
          required
          label="Yard"
          variant="outlined"
          name="yard_number"
          value={yard_number}
          onChange={({ target }) => {
            setYardNumber(target.value)
          }}
          select
        >
          {yardList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={yard_number === ''}
          onClick={handleSubmit}
        >
          Request
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RequestPcardDialog
