import React from 'react'
import { useAtom } from 'jotai'
import { Card, Typography, CardContent, Checkbox } from '@mui/material'
import { getActions } from 'constants/actionConfig'
import LotInfoTrip from 'components/custom/lotInfoTrip'
import AddressStreetView from './addressStreetView'
import { displayActions } from 'constants/actionConfig'
import { ACTION_CODES } from 'constants/actionCodes'
import { rowDataAtom, actionCodeAtom } from 'constants/atoms'
import { formatDateObj } from 'utils/date'
import {
  getPickup,
  dispatchStatusColor,
  statusTextColor,
  reverseDispatchStatusMapper,
} from 'utils/dispatchutils'
import { styles } from './styles'
import './styles.scss'

const actions = (data, handleActionClick) => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    {getActions(data, 'lotsView').map((action, index) => (
      <div key={index}>
        {displayActions(action, data, handleActionClick, index)}
      </div>
    ))}
  </div>
)

const LotCard = React.memo(
  ({
    data,
    handleActionClick,
    selectedRowIds,
    handleRowSelectionChange,
    onClickLocation,
  }) => {
    const {
      dispatch_assignment_detail_id: dispatchAssignmentDetailId,
      lot_number: lotNumber,
      lot_suffix: suffix,
      lot_trip_zone: tripZone,
      lot_description: description,
      promised_date: promisedDate,
      source,
    } = data
    const [, setActionCode] = useAtom(actionCodeAtom)
    const [, setRowData] = useAtom(rowDataAtom)
    const openViewLotDialog = () => {
      setRowData(data)
      setActionCode(ACTION_CODES.VIEW_LOT)
    }
    const isChecked = selectedRowIds.has(dispatchAssignmentDetailId)
    return (
      <Card style={styles.card(isChecked)} variant="outlined">
        <CardContent style={styles.cardContent}>
          <div style={styles.leftPart}>
            <div style={styles.firstRow}>
              <Checkbox
                checked={isChecked}
                onChange={handleRowSelectionChange}
                inputProps={{ 'aria-label': 'Select lot' }}
                sx={styles.checkbox}
              />
              <Typography
                variant="h6"
                sx={styles.lotNumber}
                onClick={() => openViewLotDialog(data, 'viewLot')}
              >
                {lotNumber}-{suffix}
              </Typography>
              <span className="lotZone">{tripZone}</span>
            </div>
            <Typography
              style={{ ...styles.commonTypographyStyle, fontSize: '14px' }}
            >
              {description}
            </Typography>
            {promisedDate && (
              <Typography
                style={{
                  ...styles.commonTypographyStyle,
                }}
              >
                <span style={{ fontSize: '14px', fontWeight: '600' }}>
                  Promised Date:{' '}
                </span>
                {formatDateObj(promisedDate) || ''}
              </Typography>
            )}
            <div style={styles.address}>
              <Typography style={styles.commonTypographyStyle}>
                {source.line_1}
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={styles.commonTypographyStyle}>
                  {source.city}, {source.zip}
                </Typography>
                <AddressStreetView
                  data={data}
                  onClickLocation={onClickLocation}
                />
              </div>
            </div>
          </div>
          <div style={styles.rightPart}>
            <div style={styles.rightPartTop}>
              <LotInfoTrip lot={data} />
            </div>
            <div style={styles.actionsContainer}>
              {actions(data, handleActionClick)}
            </div>
          </div>
        </CardContent>
      </Card>
    )
  },
)

export const LotMapCard = ({
  data,
  onClickLocation,
  selectedRowIds,
  handleRowSelectionChange,
}) => {
  const {
    dispatch_assignment_detail_id: dispatchAssignmentDetailId,
    lot_description: description,
    dispatch_status: dispatchStatus,
    lot_trip_zone: tripZone,
    seller_number: sellerNumber,
    promised_date: promisedDate,
    source,
    lot_number: lotNumber,
    lot_suffix: suffix,
  } = data
  const rows = [
    {
      label: 'Lot Description',
      value: `${description}`,
    },
    {
      label: 'Dispatch Status',
      component: (
        <div
          style={{
            backgroundColor: dispatchStatusColor[dispatchStatus],
            color: statusTextColor(dispatchStatus),
            borderRadius: '10px',
            textWrap: 'no-wrap',
            padding: '10px',
            fontWeight: 500,
            fontSize: '12px',
          }}
        >
          {reverseDispatchStatusMapper[dispatchStatus]}
        </div>
      ),
    },
    { label: 'Lot Info', component: <LotInfoTrip lot={data} onMap={true} /> },
    { label: 'Zone', value: tripZone },
    { label: 'Seller', value: sellerNumber },
    { label: 'Promised Trip Date', value: formatDateObj(promisedDate) },
    {
      label: 'Address',
      component: (
        <div style={{ display: 'flex' }}>
          <Typography style={{ textAlign: 'left' }}>
            {getPickup(source, true)}
          </Typography>
          <AddressStreetView data={data} onClickLocation={onClickLocation} />
        </div>
      ),
    },
  ]

  const isChecked = selectedRowIds.has(dispatchAssignmentDetailId)

  return (
    <Card style={{ width: '350px', boxShadow: 'none' }}>
      <CardContent style={{ padding: '0px' }}>
        <span className="lotNumber">
          <Checkbox
            checked={isChecked}
            onChange={handleRowSelectionChange}
            inputProps={{ 'aria-label': 'Select lot' }}
            sx={styles.checkbox}
          />
          {`${lotNumber}-${suffix}`}
        </span>
        <div style={{ padding: '0 10px' }}>
          {rows.map(({ label, component, value }, index) => (
            <div
              key={index}
              className={
                label === 'Address' ? 'addressFieldWrap ' : 'inputFieldWrap'
              }
              style={
                index === rows.length - 1
                  ? { borderBottomColor: 'transparent' }
                  : {}
              }
            >
              <span className="lotInfoHeading">{label}</span>
              {component ? component : <Typography>{value}</Typography>}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  )
}

export default LotCard
