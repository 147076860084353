import { Button } from '@mui/material'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'

const ResetButton = ({ onReset }) => {
  return (
    <Button
      onClick={onReset}
      style={{
        position: 'absolute',
        top: '10px',
        left: '10px',
        zIndex: 11,
        padding: '5px 10px',
        backgroundColor: '#FFF',
        border: '1px solid #ccc',
        borderRadius: '3px',
        cursor: 'pointer',
      }}
    >
      <RadioButtonCheckedIcon sx={{ paddingRight: '5px' }} />
      Reset Map
    </Button>
  )
}

export default ResetButton
