import { useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Button,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import locale from 'utils/locale'

const SubFilterOptions = ({
  options = {},
  selectedFilter,
  selectedFilters,
  handleFiltersSelect = () => {},
  onClearFilters = () => {},
  handleApply = () => {},
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [filteredOptions, setFilteredOptions] = useState(options)

  useEffect(() => {
    handleSearch(searchValue)
  }, [searchValue, selectedFilter])

  const handleSearch = (searchString) => {
    if (searchString === '') setFilteredOptions({ ...options })
    else {
      const filteredOps = {}
      for (let [key, value] of Object.entries(options)) {
        const { label } = value
        if (label.toLowerCase().search(searchString.toLowerCase()) !== -1) {
          filteredOps[key] = value
        }
      }
      setFilteredOptions(filteredOps)
    }
  }

  return (
    <div style={{ padding: '5px 15px 0 15px', height: '100%' }}>
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          height: '100%',
        }}
        spacing={1}
      >
        <TextField
          variant="outlined"
          size="small"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder={locale('Search')}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ flex: '0.1' }}
        />
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            overflow: 'auto',
            padding: '10px',
            flex: '2',
          }}
        >
          {Object.values(filteredOptions).map(
            ({ label, count, name }, index) => (
              <Box
                key={`subFilters-${index}`}
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                spacing={1}
              >
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          '& .MuiSvgIcon-root': { fontSize: 24 },
                        }}
                        onChange={(e) =>
                          handleFiltersSelect(e.target.checked, label)
                        }
                        checked={
                          selectedFilters[selectedFilter]
                            ? selectedFilters[selectedFilter].includes(label)
                            : false
                        }
                      />
                    }
                    label={locale(label)}
                  />
                </div>
                <div>({count})</div>
              </Box>
            ),
          )}
        </Box>
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            gap: '5px',
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: 'white',
              color: 'black',
              ':hover': { backgroundColor: 'white', color: 'black' },
            }}
            onClick={onClearFilters}
          >
            {locale('Clear')}
          </Button>
          <Button variant="contained" onClick={handleApply}>
            {locale('Apply')}
          </Button>
        </Box>
      </Box>
    </div>
  )
}

export default SubFilterOptions
