import React from 'react'
import { Tooltip } from '@mui/material'
import { isEmpty } from 'ramda'
import cn from 'classnames'
import renderIf from 'render-if'
import { BusinessOutlined, HomeOutlined } from '@mui/icons-material'

import {
  isLotLatAndLngNotValid,
  anyLotPromisedTripDateIsBeforeToday,
} from 'utils/dispatchutils'

import './lotInfoTrip.scss'

const getMarkerColorClassObject = (lot) => ({
  'default-marker-color':
    !isLotLatAndLngNotValid(lot) && !anyLotPromisedTripDateIsBeforeToday([lot]),
  'invalid-latlong-marker-color': isLotLatAndLngNotValid(lot),
  'past-promise-date-marker-color':
    !isLotLatAndLngNotValid(lot) && anyLotPromisedTripDateIsBeforeToday([lot]),
})

const LotInfoTrip = ({ lot = {}, onMap = false }) => {
  const renderIfLotHasCashTag = renderIf(lot.cash_tag && !isEmpty(lot.cash_tag))
  const markerColorClasses = getMarkerColorClassObject(lot)
  const typeOfLocation = lot.is_residence ? 'home' : 'building'
  const isTowable = lot.is_towable ? lot.is_towable[0] : lot.is_towable
  return (
    <Tooltip
      id={`${lot.lot_trip_id}-info-${onMap}`}
      title={
        <table className="toolTip">
          <tbody>
            <tr>
              <td className="key">{'Type Of Location'}</td>
              <td className="value">
                {typeOfLocation === 'building' ? 'Business' : 'Residence'}
              </td>
            </tr>
            <tr>
              <td className="key">{'Damage type'}</td>
              <td className="value">{lot.damage_type_code}</td>
            </tr>
            <tr>
              <td className="key">{'Trip Type'}</td>
              <td className="value">{lot.trip_type_code}</td>
            </tr>
            <tr>
              <td className="key">{'Towable'}</td>
              <td className="value">{isTowable}</td>
            </tr>
            <tr>
              <td className="key">{'Tow Type'}</td>
              <td className="value">{lot.tow_type_code}</td>
            </tr>
            {renderIfLotHasCashTag(
              <tr>
                <td className="key">{'Accept Cash'}</td>
                <td className="value">Yes</td>
              </tr>,
            )}
          </tbody>
        </table>
      }
    >
      <div
        className={cn('LotInfoTrip', markerColorClasses, { onMap: onMap })}
        data-tip={!onMap}
        data-for={`${lot.lot_trip_id}-info-${onMap}`}
      >
        <div className="icon">
          {typeOfLocation === 'building' ? (
            <BusinessOutlined />
          ) : (
            <HomeOutlined />
          )}
        </div>
        <div className="info">
          <div className="type damageType">
            <span>{lot.damage_type_code}</span>
          </div>
          <div className="type tripType">
            <span>{lot.trip_type_code}</span>
          </div>
          <div className="type isTowable">
            <span>{isTowable}</span>
          </div>
          <div className="type towType">
            <span>{lot.tow_type_code}</span>
          </div>
          {renderIfLotHasCashTag(
            <div className="type cashTag">
              <span>{lot.cash_tag}</span>
            </div>,
          )}
        </div>
      </div>
    </Tooltip>
  )
}

export default LotInfoTrip
