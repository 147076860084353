import { Breadcrumbs, Link, Typography, Box } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  appNavigatorDisplayNames,
  appNavigatorItems,
  appNavigatorItemsConst,
} from 'constants/sidebarItems'
import ViewToggle from './toggleView'
import locale from 'utils/locale'

export default function CustomizedBreadcrumbs({
  paths,
  selectedView,
  selectedSubView,
  mapView,
}) {
  const selectedDisplayName = appNavigatorDisplayNames[selectedView]
  const displayToggle =
    selectedView === appNavigatorItemsConst.LOTS ||
    selectedView === appNavigatorItemsConst.TRIPS

  let selectedSubviewName = null

  const selectedItem = appNavigatorItems.navigatorItems.find(
    (item) => item.key === selectedView,
  )

  if (selectedItem && selectedItem.options) {
    const selectedOption = selectedItem.options.find(
      (option) => option.key === selectedSubView,
    )

    if (selectedOption) {
      selectedSubviewName = selectedOption.text
    }
  }

  return (
    <div role="presentation">
      <Box
        sx={{
          bgcolor: '#3c4656',
          paddingLeft: 2,
          display: 'flex',
          height: '45px',
        }}
      >
        <Breadcrumbs
          separator={
            <NavigateNextIcon fontSize="small" style={{ color: 'white' }} />
          }
          aria-label="breadcrumb"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Link
            color="white"
            underline="hover"
            href="/"
            style={{ fontWeight: '500' }}
          >
            {locale('Home')}
          </Link>
          {selectedDisplayName && (
            <Typography color="white">{locale(selectedDisplayName)}</Typography>
          )}
          {selectedSubviewName && (
            <Typography color="white">{locale(selectedSubviewName)}</Typography>
          )}
          <Typography color="white">
            {locale(mapView ? 'Map View' : 'List View')}
          </Typography>
        </Breadcrumbs>
        <div
          style={{
            marginLeft: 'auto',
            backgroundColor: 'white',
          }}
        >
          {displayToggle && <ViewToggle />}
        </div>
      </Box>
    </div>
  )
}
