import axios from 'services/customAxios'
import { pathOr } from 'ramda'
import { getItem } from 'utils/localstorage'
import { getEndpoints } from 'constants/endpoints'

export const handleAddTruck = ({ payload }) => {
  try {
    const { vendor_id: vendorId } = getItem('userData')
    const endpoint = getEndpoints({ vendorId }).addTruck
    const response = axios.post(endpoint, payload).then((res) => res)
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const handleUpdateTruck = async ({ payload, truckId }) => {
  try {
    const { vendor_id: vendorId } = getItem('userData')
    const endpoint = getEndpoints({ vendorId, truckId }).updateTruck
    const response = await axios
      .put(endpoint, payload)
      .then(({ status }) => status === 200)

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const uploadDocument = (
  file,
  subtaskName,
  subtaskCode,
  docType = 'DOCUMENT',
  additionalMetaData = {},
) => {
  const { vendor_id: vendorId, email } = getItem('userData')
  const data = new FormData()
  data.append('file', file)
  data.append('entityType', 'VENDOR')
  data.append('country', 'USA')
  data.append('uploadType', docType)
  const sourceKey = docType === 'DOCUMENT' ? 'document_source' : 'image_source'
  const sourceCode = docType === 'DOCUMENT' ? 'document_code' : 'image_code'
  data.append(
    'metadata',
    JSON.stringify({
      [sourceKey]: 'TSM',
      [sourceCode]: subtaskCode,
      file_name: subtaskName,
      vendor_id: vendorId,
      user_email: email,
      ...additionalMetaData,
    }),
  )
  data.append('fileInfos', JSON.stringify([{ name: 'file' }]))

  return axios.post(getEndpoints().upload, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Source: 'vendor',
    },
  })
}

export const removeTruckAssociation = async (truckId) => {
  try {
    const { vendor_id: vendorId } = getItem('userData')
    const endpoint = getEndpoints({ vendorId, truckId }).removeTruckAssociation

    const response = await axios.delete(endpoint).then((response) => {
      const status = pathOr('failed', ['data', 'status'], response)
      return status === 'success'
    })

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
