import { createStyles, makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => {
  return createStyles({
    menuRoot: {
      minWidth: '45vw',
      minHeight: '400px',
    },
    menuList: {
      minHeight: '400px',
    },
    selected: {
      fontWeight: 700,
    },
    root: {
      fontWeight: 800,
    },
    dividerRoot: {
      borderColor: 'white',
    },
  })
})
