import { addDays, addHours } from 'utils/date'

export const setItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const getItem = (key) => {
  const value = localStorage.getItem(key)
  return value && JSON.parse(value)
}

export const removeItem = (key) => {
  localStorage.removeItem(key)
}

export const setAccessToken = (accessToken) => {
  setItem('accessToken', accessToken)
  setItem(
    'accessTokenExpiry',
    addHours(new Date(), {
      hours: 1,
    }),
  )
}

export const setRefreshToken = (refreshToken) => {
  setItem('refreshToken', refreshToken)
  setItem(
    'refreshTokenExpiry',
    addDays(new Date(), {
      days: 1,
    }),
  )
}

export const getItemFromSessionStorage = (key) => {
  const value = sessionStorage.getItem(key)
  return value && JSON.parse(value)
}
