import axios from 'services/customAxios'
import { pathOr } from 'ramda'
import { getItem, setItem } from 'utils/localstorage'
import { getEndpoints } from 'constants/endpoints'

export const validateLoginCredentials = async (username, password) => {
  try {
    const response = await axios.post(
      getEndpoints().login,
      {
        username,
        password,
      },
      {
        headers: {
          Authorization: '',
        },
      },
    )
    if (response.data.status === 'success') {
      return response.data
    }
  } catch (error) {
    console.error('Invalid Login Credentials', error)
  }
}

export const getUserDetails = async () => {
  try {
    const response = await axios.get(getEndpoints().userDetails)
    if (response.data.status === 'success') {
      return response.data
    }
  } catch (error) {
    console.error('Failed to retrieve user details', error)
  }
}

export const getExternalUserSettings = async (email) => {
  try {
    const response = await axios.get(getEndpoints().externalUserSettings, {
      params: { email },
    })
    if (response.data.status === 'success') {
      return response.data.data
    }
  } catch (error) {
    console.error('Failed to retrieve external user settings', error)
  }
}

export const sendOTP = async (payload) => {
  try {
    const response = await axios.post(getEndpoints().sendOTP, payload)
    if (response.data.status === 'success') {
      return response.data.data
    }
  } catch (error) {
    console.error('Failed to send OTP', error)
  }
}

export const verifyOTP = async (payload) => {
  try {
    const response = await axios.post(getEndpoints().verifyOTP, payload)
    if (response.data.status === 'success') {
      setItem('accessToken', response.data.data.access_token)
      return response.data.data
    }
  } catch (error) {
    console.error('Failed to verify OTP', error)
  }
}

export const changePassword = async (payload) => {
  try {
    const response = await axios.put(getEndpoints().changePassword, payload)
    if (response.data.status === 'success') {
      return response
    }
  } catch (error) {
    console.error('Failed to change password', error)
  }
}

export const getRefreshToken = async () => {
  try {
    const response = await axios.post(getEndpoints().refreshToken)
    if (response.data.status === 'success') {
      return response.data.data
    }
  } catch (error) {
    console.error('Failed to refresh token', error)
  }
}

export const getReferenceData = async () => {
  try {
    const response = await axios.get(getEndpoints().referenceData)
    if (response.status === 'success') {
      return response.data
    }
  } catch (error) {
    console.error('Failed to get reference data', error)
  }
}

export const getPersonnel = async () => {
  try {
    const userData = getItem('userData')
    const vendorId = pathOr('', ['vendor', 'vendor_id'], userData)
    const response = await axios.get(getEndpoints({ vendorId }).personnels)

    if (response.status === 200) {
      const extractedData = response.data.map((person) => ({
        firstName: person.firstName,
        lastName: person.lastName,
        vendorPersonnelId: person.vendorPersonnelId,
        status: person.status,
        dispatchableFlag: person.dispatchableFlag,
      }))
      return extractedData
    }
  } catch (error) {
    console.error('Failed to get personnel data', error)
  }
}

export const deleteTransporterAccount = async (vendorId) => {
  try {
    const response = await axios.delete(
      getEndpoints({ vendorId }).deleteAccount,
    )
    if (response.data.status === 'success') {
      return response
    }
  } catch (error) {
    console.error('Failed to delete account', error)
  }
}
