export const styles = {
  textField: {
    marginBottom: '15px',
    width: '100%',
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    pl: 3,
  },
  closeBtn: {
    position: 'absolute',
    right: '8px',
    top: '8px',
  },
  verticalDivider: {
    orientation: 'vertical',
    flexItem: true,
    sx: { marginLeft: 2, marginRight: 2 },
  },
  homeLocationBox: {
    border: '1px solid #ccc',
    borderRadius: 2,
    p: 2,
    mb: 1,
  },
}
