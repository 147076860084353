import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Divider,
  TextField,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import Draggable from 'react-draggable'
import { CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import Table from 'components/main/Table'
import { getDateTimeFromDateFns } from 'utils/date'
import { useStyles } from 'utils/styles'

const notesColumns = [
  {
    id: 'createTime',
    label: 'DATE',
    sortable: true,
    display: ({ createTime }) =>
      getDateTimeFromDateFns(createTime, 'MM/dd/yyyy HH:mm:ss'),
  },
  { id: 'userName', label: 'USER NAME' },
  { id: 'notes', label: 'NOTES' },
]

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

const NotesDialog = ({
  lotNumber,
  lotSuffix,
  notesData,
  isOpen,
  handleClose,
}) => {
  const [loading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const classes = useStyles()
  const notesRows = notesData?.lotNotes?.map((note, index) => ({
    id: index + 1,
    ...note,
  }))

  const filteredNotesRows = notesRows?.filter((row) => {
    return (
      row.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.notes.toLowerCase().includes(searchTerm.toLowerCase())
    )
  })

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      scroll="paper"
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        style={{ display: 'flex', cursor: 'move' }}
        id="draggable-dialog-title"
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '10px',
            whiteSpace: 'nowrap',
          }}
        >
          <div style={{ display: 'flex', columnGap: '10px' }}>
            <Typography>LOT NOTES</Typography>
            <Divider
              classes={{
                root: classes.dividerRoot,
              }}
              orientation="vertical"
              flexItem
            />
            <Typography>
              {lotNumber} - {lotSuffix}
            </Typography>
          </div>
          <IconButton
            onClick={() => handleClose()}
            style={{ position: 'absolute', right: '8px', top: '8px' }}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers maxWidth="xs">
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div>
            <TextField
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={'Search...'}
              style={{ padding: '5px' }}
            />
            <div id="elem">
              <Table
                columns={notesColumns}
                rows={filteredNotesRows}
                showCheckbox={false}
                showPagination={false}
                defaultHeight={false}
                height="300px"
              />
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default NotesDialog
