import locales from 'constants/locale'
import { getItem } from 'utils/localstorage'
import { propOr } from 'ramda'

const userData = getItem('userData')
const language = propOr('en', 'user_language', userData || {})

let currentLocale = language

export default (text, ...args) => {
  if (text === undefined) return

  let translatedPhrases = locales[currentLocale.toLowerCase()]

  const translateWord = (word) => {
    const translation =
      typeof translatedPhrases[word] === 'function'
        ? translatedPhrases[word](args)
        : translatedPhrases[word]
    return translation !== undefined && translation !== null
      ? translation
      : word
  }

  const fullTranslation = translateWord(text)
  if (fullTranslation !== text) {
    return fullTranslation
  }

  const words = text.split(' ')
  const translatedWords = words.map((word) => translateWord(word))
  return translatedWords.join(' ')
}

export const changeLocale = (languageCode) => {
  currentLocale = languageCode
}
