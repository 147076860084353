import React from 'react'
import { useAtom } from 'jotai'
import { Tooltip } from '@mui/material'
import { mapViewAtom } from 'constants/atoms'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ListIcon from '@mui/icons-material/List'
import MapIcon from '@mui/icons-material/Map'
import locale from 'utils/locale'

const buttonStyles = {
  border: 'none',
  borderRadius: '0px',
  padding: '12px',
}

function CustomToggleGroup({ value, onChange }) {
  return (
    <ToggleButtonGroup
      value={value ? 'map' : 'list'}
      exclusive
      onChange={(_, newView) => {
        if (newView !== null) {
          onChange(newView === 'map')
        }
      }}
      aria-label="view switch"
    >
      <Tooltip
        title={<span style={{ fontSize: '1.3em' }}>{locale('List View')}</span>}
      >
        <ToggleButton
          value="list"
          aria-label="list view"
          style={{
            ...buttonStyles,
            backgroundColor: value ? '#ccc' : 'white',
          }}
        >
          <ListIcon />
        </ToggleButton>
      </Tooltip>
      <Tooltip
        title={<span style={{ fontSize: '1.3em' }}>{locale('Map View')}</span>}
      >
        <ToggleButton
          value="map"
          aria-label="map view"
          style={{
            ...buttonStyles,
            backgroundColor: value ? 'white' : '#ccc',
          }}
        >
          <MapIcon />
        </ToggleButton>
      </Tooltip>
    </ToggleButtonGroup>
  )
}

function CustomToggle() {
  const [mapView, setMapView] = useAtom(mapViewAtom)

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <CustomToggleGroup value={mapView} onChange={setMapView} />
    </div>
  )
}

export default CustomToggle
