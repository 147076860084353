import { useEffect, useState } from 'react'
import { useAtom } from 'jotai'

import {
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Grid,
  Box,
  CircularProgress,
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'

import { useStyles } from 'utils/styles'
import {
  selectedViewAtom,
  selectedFiltersAtom as savedSelectedFilters,
} from 'constants/atoms'

import { useFiltersFetcher } from 'services/api'
import SubFilterOptions from './subFilterOptions'
import { dispatchStatusMapper } from 'utils/dispatchutils'
import locale from 'utils/locale'

const FilterDialog = ({
  selectedFiltersAtom = [],
  anchorEl,
  onHandleClose,
  setSelectedFiltersAtom,
}) => {
  const [selectedView] = useAtom(selectedViewAtom)
  const [loading, filtersByName = []] = useFiltersFetcher(
    selectedView,
    selectedFiltersAtom,
  )
  const [selectedFilter, setSelectedFilter] = useState('')
  const [selectedFilters, setSelectedFilters] = useState(
    selectedFiltersAtom[selectedView] ?? {},
  )

  const handleFiltersSelect = (checked, label) => {
    const cloneSelectedFilters = { ...selectedFilters }

    if (!(selectedFilter in cloneSelectedFilters)) {
      cloneSelectedFilters[selectedFilter] = []
    }

    if (checked) {
      cloneSelectedFilters[selectedFilter].push(label)
    } else {
      cloneSelectedFilters[selectedFilter] = cloneSelectedFilters[
        selectedFilter
      ].filter((filter) => filter !== label)
      if (!cloneSelectedFilters[selectedFilter].length) {
        delete cloneSelectedFilters[selectedFilter]
      }
    }

    setSelectedFilters({ ...cloneSelectedFilters })
  }

  const handleApply = () => {
    setSelectedFiltersAtom({
      ...selectedFiltersAtom,
      [selectedView]: { ...selectedFilters },
    })
    if ('dispatch_status' in selectedFilters) {
      const prev = selectedFilters.dispatch_status
      const curr = prev.map((status) => Number(dispatchStatusMapper[status]))
      selectedFilters.dispatch_status = curr
      setSelectedFiltersAtom({
        ...selectedFiltersAtom,
        [selectedView]: { ...selectedFilters },
      })
    }
    onHandleClose()
  }

  const classes = useStyles()

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onHandleClose}
      classes={{
        paper: classes.menuRoot,
        list: classes.menuList,
      }}
    >
      <Grid container sx={{ height: '400px' }}>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Grid
              item
              xs={5}
              style={{ borderRight: '1px solid rgba(0,0,0,0.12)' }}
            >
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: 'auto',
                  maxHeight: '400px',
                  overflow: 'auto',
                  padding: '10px',
                }}
              >
                {filtersByName.map(({ name, label }, index) => (
                  <div key={`menu-item-${index}`}>
                    {' '}
                    <MenuItem
                      selected={selectedFilter === name}
                      onClick={() => setSelectedFilter(name)}
                      sx={{ paddingTop: '10px', paddingBottom: '10px' }}
                      classes={{ selected: classes.selected }}
                    >
                      {locale(label)}&nbsp;
                      {`${
                        selectedFilters[name] && selectedFilters[name].length
                          ? `(${selectedFilters[name].length})`
                          : ''
                      }`}
                    </MenuItem>
                    {index !== Object.values(filtersByName).length - 1 ? (
                      <Divider sx={{ margin: '0 !important' }} />
                    ) : null}
                  </div>
                ))}
              </Box>
            </Grid>
            <Grid item xs={7} sx={{ height: '100%' }}>
              <SubFilterOptions
                selectedFilter={selectedFilter}
                selectedFilters={selectedFilters}
                options={
                  filtersByName.find(({ name }) => name === selectedFilter)
                    ?.filterOptions[0]
                }
                handleFiltersSelect={handleFiltersSelect}
                onClearFilters={() => setSelectedFilters({})}
                handleApply={handleApply}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Menu>
  )
}

const Filters = ({ filterItemToRemove = [], selectedView = '' }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openFilterDialog, setOpenFilterDialog] = useState(false)
  const [selectedFiltersAtom, setSelectedFiltersAtom] =
    useAtom(savedSelectedFilters)

  const handleClick = () => {
    const filtersChip = document.getElementById('filterChips')
    setAnchorEl(filtersChip)
    setOpenFilterDialog(true)
  }

  const onHandleClose = () => {
    setAnchorEl(null)
    setOpenFilterDialog(false)
  }

  useEffect(() => {
    const [filterName, value] = filterItemToRemove
    let cloneSelectedFilters = { ...selectedFiltersAtom[selectedView] }
    if (filterName in cloneSelectedFilters) {
      cloneSelectedFilters[filterName] = cloneSelectedFilters[
        filterName
      ].filter((item) => item !== value)

      if (!cloneSelectedFilters[filterName].length)
        delete cloneSelectedFilters[filterName]
      setSelectedFiltersAtom({
        ...selectedFiltersAtom[selectedView],
        [selectedView]: { ...cloneSelectedFilters },
      })
    }
  }, [filterItemToRemove])

  return (
    <div>
      <IconButton onClick={handleClick} aria-label="filter list">
        <FilterListIcon sx={{ fontSize: 25 }} />
      </IconButton>
      {openFilterDialog && (
        <FilterDialog
          filterItemToRemove={filterItemToRemove}
          anchorEl={anchorEl}
          onHandleClose={onHandleClose}
          selectedFiltersAtom={selectedFiltersAtom}
          setSelectedFiltersAtom={setSelectedFiltersAtom}
        />
      )}
    </div>
  )
}

export default Filters
