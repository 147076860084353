import axios from 'axios'
import { pathOr } from 'ramda'
import { getItem } from 'utils/localstorage'

const customAxios = axios.create({})

let updateAuthenticationStatus

customAxios.setAuthenticationUpdater = (updater) => {
  updateAuthenticationStatus = updater
}

customAxios.interceptors.request.use((config) => {
  const accessToken = getItem('accessToken')
  const userData = getItem('userData')
  const vendorId = pathOr('', ['vendor', 'vendor_id'], userData)
  config.headers = {
    ...config.headers,
    Authorization: `bearer ${accessToken}`,
    'X-Vendor-Id': vendorId,
    'X-Countrycode': 'US',
    source: 'mobile',
    'X-Source': 'portal',
    languageCode: 'en',
    countryCode: 'US',
  }
  return config
})

customAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401 && updateAuthenticationStatus) {
      updateAuthenticationStatus(false)
    }
    return Promise.reject(error)
  },
)

export default customAxios
