import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  TextField,
  Divider,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useAtom } from 'jotai'
import { useSnackbar } from 'notistack'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { head, isEmpty, pathOr, prop, propOr } from 'ramda'
import MultiViewSidebar from '../multiViewSidebar'
import { globalPostAtom, actionCodeAtom } from 'constants/atoms'
import { markAsRead } from 'services/lotsViewServicesApi'
import { ACTION_CODES } from 'constants/actionCodes'
import { moveToNextItemInMultiView } from 'utils/dispatchutils'

const MarkAsReadDialog = ({ rowData }) => {
  const [actionCode, setActionCode] = useAtom(actionCodeAtom)
  const [globalLoading, setGlobalLoading] = useAtom(globalPostAtom)
  const [selectedRowData, setSelectedRowData] = useState(head(rowData))
  const [markedData, setMarkedData] = useState([])
  const { enqueueSnackbar } = useSnackbar()

  const selectedAssignmentId = propOr(
    '',
    'dispatch_assignment_detail_id',
    selectedRowData,
  )
  const selectedLotNumber = propOr('', 'lot_number', selectedRowData)
  const selectedLotDescription = propOr('', 'lot_description', selectedRowData)
  const unreadResolvedIssues = head(
    propOr('', 'unread_resolved_issues', selectedRowData),
  )
  const msgType = propOr('', 'message_type_description', unreadResolvedIssues)
  const msgSubtype = propOr(
    '',
    'message_subtype_description',
    unreadResolvedIssues,
  )
  const comments = propOr('', 'response_comments', unreadResolvedIssues)
  const activeIssueId = propOr('', 'message_header_id', unreadResolvedIssues)

  useEffect(() => {
    if (isEmpty(markedData)) {
      return
    }
    if (rowData.length === markedData.length) {
      handleClose()
    } else if (rowData.length > markedData.length) {
      let curIndex = rowData.indexOf(selectedRowData)
      const nextIndex = moveToNextItemInMultiView(
        curIndex,
        markedData,
        rowData,
        'lot_number',
      )
      setSelectedRowData(rowData[nextIndex])
    }
  }, [markedData])

  const handleSubmit = async () => {
    const result = await markAsRead(selectedAssignmentId, activeIssueId)

    if (result.success) {
      setGlobalLoading(true)
      enqueueSnackbar('Issue is marked as read', {
        variant: 'success',
      })
      setMarkedData([...markedData, selectedLotNumber])
    } else {
      enqueueSnackbar('Error occured while marking issue as read', {
        variant: 'error',
      })
    }
  }

  const handleClose = () => {
    setActionCode('')
  }

  return (
    <Dialog
      open={actionCode === ACTION_CODES.MARK_ISSUE_AS_READ}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      scroll="paper"
    >
      <DialogTitle style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '10px',
            whiteSpace: 'nowrap',
          }}
        >
          <div style={{ display: 'flex', columnGap: '10px' }}>
            <Typography>Mark As read Issue - {selectedLotNumber}</Typography>
            <Divider orientation="vertical" flexItem />
            <Typography>{selectedLotDescription}</Typography>
          </div>
          <IconButton
            onClick={handleClose}
            style={{ position: 'absolute', right: '8px', top: '8px' }}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent dividers maxWidth="xs">
        {rowData.length > 1 && (
          <MultiViewSidebar
            selectedRowData={selectedRowData}
            setSelectedRowData={setSelectedRowData}
            completedRows={markedData}
          />
        )}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Issue"
              variant="standard"
              name="issue"
              value={msgType}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Issue SubType"
              variant="standard"
              name="issueSubtype"
              value={msgSubtype}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Resolution Comment"
              variant="standard"
              name="issue"
              value={comments}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          loading={globalLoading}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Read
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default MarkAsReadDialog
