import React from 'react'
import Login from 'components/login'
import { useAtom } from 'jotai'
import { isAuthorizedAtom } from 'constants/atoms'
import { SnackbarProvider } from 'notistack'

const DeleteAccount = () => {
  const [, setIsAuthorized] = useAtom(isAuthorizedAtom)

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={2000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transitionDuration={{ enter: 200, exit: 195 }}
    >
      <div className="App" style={{ flex: 1 }}>
        <Login setIsAuthorized={setIsAuthorized} deleteAccount />
      </div>
    </SnackbarProvider>
  )
}

export default DeleteAccount
