export const filterConfig = {
  assigned: {
    dispatch_status: [240, 230, 220],
  },
  acknowledged: {
    dispatch_status: [250],
  },
  reportedDeclinedTrips: {
    declined_trips_view: ['Raised by Me'],
  },
  reportedAssignedTrips: {
    declined_trips_view: ['Assigned to Me'],
  },
  distributed: {
    oversight_view: ['Yes'],
  },
  completed: {
    completed_date: ['Last Week'],
    dispatch_status: [280, 290, 300],
    status: ['I', 'A'],
  },
  reportedIssues: {
    issues_view: ['Raised by Me'],
  },
  assignedIssues: {
    issues_view: ['Assigned to Me'],
  },
}
