import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import App from './App'
import ErrorPage from 'components/errorPage'
import DeleteAccount from 'components/DeleteAccount'
import { Provider, createStore } from 'jotai'
import './index.css'
import 'semantic-ui-css/semantic.min.css'

const myStore = createStore()

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Provider store={myStore}>
        <App />
      </Provider>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/deleteAccount',
    element: (
      <Provider store={myStore}>
        <DeleteAccount />
      </Provider>
    ),
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<RouterProvider router={router} />)
