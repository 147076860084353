import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import GoogleMapReact from 'google-map-react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useAtom } from 'jotai'
import MapMarker from './mapMarker'
import LotCard from './lotCard'
import { mapViewFetcher } from 'services/fetchData'
import {
  selectedViewAtom,
  selectedSubViewAtom,
  selectedFiltersAtom,
  searchTermAtom,
  selectedRowAtom,
  selectedRecordsAtom,
  refreshDataAtom,
  tableRecordsAtom,
  globalPostAtom,
} from 'constants/atoms'
import { isPickUpLot } from 'utils/dispatchutils'
import { CircularProgress } from '@mui/material'
import ResetButton from './resetButton'
import MapLegend from './mapLegend'
import './styles.scss'

const DEFAULT_PROPS = {
  center: { lat: 37.0902, lng: -95.7129 },
  zoom: 4.5,
}

const LoadingOverlay = () => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(255, 255, 255, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 10,
    }}
  >
    <CircularProgress />
  </div>
)

const getLocation = (item) => {
  const isPickUp = isPickUpLot(item)
  const { source, destination } = item
  const location = isPickUp ? source : destination
  return location
}

const MapView = ({ handleActionClick }) => {
  const [selectedView] = useAtom(selectedViewAtom)
  const [selectedSubView] = useAtom(selectedSubViewAtom)
  const [selectedFilters] = useAtom(selectedFiltersAtom)
  const [loading, setLoading] = useState(false)
  const [search] = useAtom(searchTermAtom)
  const [selectedRows, setSelectedRows] = useAtom(selectedRowAtom)
  const [, setSelectedRecords] = useAtom(selectedRecordsAtom)
  const [, setTableRecords] = useAtom(tableRecordsAtom)
  const [allData, setAllData] = useState([])
  const [mapState, setMapState] = useState(DEFAULT_PROPS)
  const [maps, setMaps] = useState({})
  const [mapKey, setMapKey] = useState(0)
  const mapDivRef = useRef(null)
  const [refreshData] = useAtom(refreshDataAtom)
  const [globalLoading] = useAtom(globalPostAtom)

  const paperHeight = useMemo(() => {
    const mainContentHeight =
      document.getElementById('mainContent')?.getBoundingClientRect().height ||
      0
    const actionBarHeight =
      document.getElementById('actionBarContainer')?.getBoundingClientRect()
        .height || 0
    return mainContentHeight - actionBarHeight
  }, [])

  const lotsByPosition = useMemo(() => {
    const lotsObj = {}

    allData.forEach((item) => {
      const location = getLocation(item)
      if (location && location.latitude && location.longitude) {
        const positionKey = `${location.latitude},${location.longitude}`
        lotsObj[positionKey] = lotsObj[positionKey] || []
        lotsObj[positionKey].push(item)
      }
    })

    return lotsObj
  }, [allData])

  const selectedRowIds = useMemo(
    () => new Set(selectedRows.map((row) => row.dispatch_assignment_detail_id)),
    [selectedRows],
  )

  const fetchData = async () => {
    try {
      setLoading(true)
      const result = await mapViewFetcher(
        selectedView,
        selectedSubView,
        selectedFilters,
        search,
      )

      setAllData(
        result.data.map((item, index) => ({
          id: item.dispatch_assignment_detail_id,
          ...item,
        })),
      )
      setTableRecords(result.totalCount)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [selectedView, selectedSubView, selectedFilters, search, globalLoading])

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchData()
    }, 180000)
    return () => intervalId && clearInterval(intervalId)
  }, [])

  const handleRowClick = (row) => {
    const updatedSelection = selectedRowIds.has(
      row.dispatch_assignment_detail_id,
    )
      ? selectedRows.filter(
          (r) =>
            r.dispatch_assignment_detail_id !==
            row.dispatch_assignment_detail_id,
        )
      : [...selectedRows, row]

    setSelectedRows(updatedSelection)
    setSelectedRecords(updatedSelection.length)
  }

  const onClickLocation = (item) => {
    const location = getLocation(item)
    if (location) {
      setMapState({
        center: { lat: location.latitude, lng: location.longitude },
        zoom: 20,
      })
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: paperHeight,
        border: '1px solid grey',
      }}
    >
      <div
        id="mapDiv"
        style={{ height: '100%', width: '75%', position: 'relative' }}
        ref={mapDivRef}
      >
        {loading && <LoadingOverlay />}
        <ResetButton
          onReset={() => {
            setMapState(DEFAULT_PROPS)
            setMapKey((prevKey) => prevKey + 1)
          }}
        />
        <GoogleMapReact
          key={mapKey}
          bootstrapURLKeys={{ key: process.env.GOOGLE_MAP_ACCESS }}
          {...mapState}
          onGoogleApiLoaded={({ maps }) => setMaps(maps)}
          options={{
            zoomControlOptions: {
              position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
            },
            mapTypeControl: true,
            mapTypeControlOptions: {
              position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
              mapTypeIds: [
                window.google.maps.MapTypeId.ROADMAP,
                window.google.maps.MapTypeId.SATELLITE,
              ],
            },
          }}
        >
          {Object.entries(lotsByPosition).map(([positionKey, lots]) => {
            const [lat, lng] = positionKey.split(',').map(parseFloat)
            return (
              <MapMarker
                maps={maps}
                mapDivRef={mapDivRef}
                key={positionKey}
                lat={lat}
                lng={lng}
                onClickLocation={() => onClickLocation(lat, lng)}
                data={lots.length > 1 ? lots : lots[0]}
              />
            )
          })}
        </GoogleMapReact>
        <MapLegend />
      </div>
      <div id="scrollableDiv" style={{ flex: 1, overflow: 'auto' }}>
        <InfiniteScroll
          key={refreshData}
          dataLength={allData.length}
          loader={<p>Loading..</p>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>End of list</b>
            </p>
          }
          scrollThreshold={0.5}
          scrollableTarget="scrollableDiv"
        >
          {selectedRows
            .filter((item) => item.dispatch_assignment_detail_id)
            .map((item, index) => (
              <LotCard
                key={index}
                data={item}
                handleActionClick={handleActionClick}
                selectedRowIds={selectedRowIds}
                handleRowSelectionChange={() => handleRowClick(item)}
                onClickLocation={() => onClickLocation(item)}
              />
            ))}
          {allData
            .filter(({ dispatch_assignment_detail_id: id }) => {
              return !selectedRowIds.has(id)
            })
            .map((item, index) => (
              <LotCard
                key={index}
                data={item}
                handleActionClick={handleActionClick}
                selectedRowIds={selectedRowIds}
                handleRowSelectionChange={() => handleRowClick(item)}
                onClickLocation={() => onClickLocation(item)}
              />
            ))}
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default MapView
