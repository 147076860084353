export const styles = {
  card: (isSelected) => ({
    margin: '10px',
    minWidth: '250px',
    border: '1px solid #ccc',
    boxShadow: isSelected
      ? '-4px 0 0 #1d5ab9, rgba(100,100,111,.2) 0px 0px 10px 0px'
      : 'rgba(100,100,111,.2) 0px 0px 10px 0px',
    borderRadius: '10px',
  }),
  leftPart: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10px',
    alignItems: 'baseline',
    width: '50%',
  },
  firstRow: {
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    padding: 0,
  },
  lotNumber: {
    marginLeft: '5px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  rightPart: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  address: {
    display: 'flex',
    marginBottom: '10px',
    flexDirection: 'column',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '16px',
  },
  rightPartTop: {
    display: 'flex',
    marginBottom: '10px',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  commonTypographyStyle: {
    textAlign: 'left',
    fontSize: '12px',
    width: 'max-content',
  },
}
