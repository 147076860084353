import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  TextField,
  Box,
  Autocomplete,
  FormControl,
  CircularProgress,
  Divider,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useAtom } from 'jotai'
import {
  flatten,
  head,
  isEmpty,
  compose,
  uniq,
  map,
  propOr,
  pathOr,
  innerJoin,
} from 'ramda'

import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'

import Table from 'components/main/Table/Table'
import MultiViewSidebar from 'components/views/dialogs/multiViewSidebar'
import {
  selectedViewAtom,
  globalPostAtom,
  actionCodeAtom,
  resolveIssueAtom,
  completedRowDataAtom,
  mapViewAtom,
} from 'constants/atoms'
import { columnConfig } from 'constants/columns'
import {
  submitDistributeTrip,
  distributeTripGroups,
  submitResolveIssue,
} from 'services/lotsViewServicesApi'
import { useSnackbar } from 'notistack'
import { DistributeTripIcon } from 'components/icons/icons'
import { useStyles } from 'utils/styles'
import { ACTION_CODES } from 'constants/actionCodes'
import { moveToNextItemInMultiView } from 'utils/dispatchutils'
import { getItem } from 'utils/localstorage'

const defaultData = {
  group_id: '',
  personnel_id: '',
  trip_name: '',
}

const DistributeTripDialog = ({ rowData = [], onClose }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [selectedView] = useAtom(selectedViewAtom)
  const [actionCode, setActionCode] = useAtom(actionCodeAtom)
  const [globalLoading, setGlobalLoading] = useAtom(globalPostAtom)
  const [resolveIssue, setResolveIssue] = useAtom(resolveIssueAtom)
  const [mapView] = useAtom(mapViewAtom)
  const [selectedRowData, setSelectedRowData] = useState(head(rowData))
  const [groupData, setGroupData] = useState([])
  let [formData, setFormData] = useState(defaultData)
  const [completedTrips, setCompletedTrips] = useAtom(completedRowDataAtom)
  const userData = getItem('userData')
  const vendorId = pathOr('', ['vendor', 'vendor_id'], userData)
  const individualLot =
    selectedView === 'lotsView' || (selectedView === 'tripsView' && mapView)

  const filteredRowData = rowData.filter(
    (record) => !completedTrips.includes(record.lot_number),
  )

  const vendorData = getItem('vendor')
  const allGroups = propOr(
    [],
    'groups',
    vendorData.find((v) => v.vendor_id === vendorId),
  )
  const groupstoUse = allGroups.filter(
    (group) => group.dispatch_group_name !== 'DEFAULT DISPATCH GROUP',
  )
  const roleId = pathOr('', ['vendor', 'role_id'], userData)
  const {
    lot_number: selectedLotNumber = '',
    trip_id: selectedTripId = '',
    lot_description: selectedLotDescription = '',
    trip_name: selectedTripName = '',
  } = selectedRowData

  const allAssignmentDetailId = individualLot
    ? filteredRowData?.map(({ dispatch_assignment_detail_id: id }) => id)
    : selectedRowData?.assignments?.map(
        ({ dispatch_assignment_detail_id: id }) => id,
      )

  const tripID = propOr('', 'trip_id', selectedRowData)

  const resetFormData = () => {
    setFormData(defaultData)
  }

  useEffect(() => {
    const fetchData = async () => {
      const apiData = await distributeTripGroups()
      if (roleId === 2) {
        const finalGroups = innerJoin(
          (r1, r2) => r1.dispatch_group_id === r2.dispatch_group_id,
          apiData.data,
          groupstoUse,
        )
        setGroupData(finalGroups)
      } else {
        setGroupData(apiData.data)
      }
    }
    fetchData()
  }, [selectedRowData, selectedView])

  useEffect(() => {
    if (rowData.length === completedTrips.length) {
      handleClose()
    } else if (
      rowData.length > completedTrips.length &&
      !isEmpty(completedTrips)
    ) {
      let curIndex = rowData.indexOf(selectedRowData)
      const nextIndex = moveToNextItemInMultiView(
        curIndex,
        completedTrips,
        rowData,
        'trip_id',
      )
      setSelectedRowData(rowData[nextIndex])
      resetFormData()
    }
  }, [completedTrips])

  const renderRows = (data) => {
    return data.map((item, index) => ({
      id: index + 1,
      ...item,
    }))
  }

  const areRequiredFieldsFilled = () => {
    const requiredFields = ['group_id']
    return requiredFields.every((field) => Boolean(formData[field]))
  }

  const handleSelectChange = (e, value) => {
    if (value) {
      const { groupId, personnelId } = value
      setFormData((prevData) => ({
        ...prevData,
        group_id: groupId,
        personnel_id: personnelId,
      }))
    } else {
      return
    }
  }

  const createPayload = () => {
    if (resolveIssue) {
      return {
        resolution_action: 'trip_maintenance',
        tow_provider: { ...formData, vendor_id: vendorId },
      }
    }
    if (individualLot) {
      return { ...formData, assignment_detail_ids: allAssignmentDetailId }
    }
    return { ...formData, trip_ids: [tripID] }
  }

  const submitData = async (payload) => {
    if (resolveIssue) {
      return await submitResolveIssue(payload, allAssignmentDetailId)
    }
    return await submitDistributeTrip(payload, individualLot)
  }

  const handleSubmit = async () => {
    const payload = createPayload()
    const result = await submitData(payload)

    if (result.success) {
      setGlobalLoading(true)
      const message = !individualLot
        ? `Trip is distributed for ${selectedTripId}`
        : 'Trip is distributed for selected lots successfully'
      enqueueSnackbar(message, { variant: 'success' })

      if (individualLot) {
        handleClose()
      } else {
        setCompletedTrips([...completedTrips, selectedTripId])
      }
    } else {
      enqueueSnackbar(
        `Error occurred while distributing trip ${selectedTripId}`,
        {
          variant: 'error',
        },
      )
    }
  }

  const classes = useStyles()
  const handleClose = () => {
    resetFormData()
    setResolveIssue(false)
    setActionCode('')
    onClose()
    setCompletedTrips([])
  }

  const groupOptions = compose(
    uniq,
    flatten,
    map(
      ({
        dispatch_group_id: groupId = '',
        dispatch_group_name: groupDesc = '',
        personnel = [],
      }) =>
        personnel
          .filter((p) => p != null)
          .map(
            ({
              vendor_personnel_id: personnelId,
              first_name: firstName,
              last_name: lastName,
              status,
            }) => ({
              key: `${groupId}-${personnelId}`,
              label: `${firstName} ${lastName}`,
              personnelId,
              groupId,
              groupDesc,
              status,
            }),
          ),
    ),
  )(groupData)

  return (
    <Dialog
      open={actionCode === ACTION_CODES.DISTRIBUTE_TRIP}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      scroll="paper"
    >
      <DialogTitle style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '10px',
            whiteSpace: 'nowrap',
            color: 'white',
          }}
        >
          <DistributeTripIcon color="white" />
          <div style={{ display: 'flex', columnGap: '10px' }}>
            {individualLot ? (
              <>
                <Typography
                  classes={{
                    root: classes.root,
                  }}
                >
                  {resolveIssue && 'Issue Resolution '}
                  Distribute Trip - {selectedLotNumber}
                </Typography>
              </>
            ) : (
              <Typography
                classes={{
                  root: classes.root,
                }}
              >
                Distribute Trip - {selectedTripId}
              </Typography>
            )}

            {individualLot ? (
              <>
                <Divider
                  classes={{ root: classes.dividerRoot }}
                  orientation="vertical"
                  flexItem={true}
                />
                <Typography
                  classes={{
                    root: classes.root,
                  }}
                >
                  {selectedLotDescription}
                </Typography>
              </>
            ) : (
              <>
                <Divider
                  classes={{ root: classes.dividerRoot }}
                  orientation="vertical"
                  flexItem={true}
                />
                <Typography
                  classes={{
                    root: classes.root,
                  }}
                >
                  {selectedTripName}
                </Typography>
              </>
            )}
          </div>

          <IconButton
            onClick={handleClose}
            style={{
              position: 'absolute',
              right: '8px',
              top: '8px',
            }}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ display: 'flex', minHeight: '400px' }}>
          {rowData.length > 1 && selectedView === 'tripsView' && (
            <MultiViewSidebar
              selectedRowData={selectedRowData}
              setSelectedRowData={setSelectedRowData}
              tripsView={selectedView === 'tripsView'}
              completedRowData={completedTrips}
              resetFormData={resetFormData}
            />
          )}
          {globalLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div
              className="col-2-3"
              style={
                rowData.length > 1 && selectedView === 'tripsView'
                  ? {
                      borderLeft: '1px solid rgba(0,0,0,0.12)',
                      paddingLeft: '10px',
                    }
                  : {}
              }
            >
              <Box sx={{ my: 1 }}>
                <Grid container spacing={0}>
                  <Grid item xs={0}>
                    <Typography variant="h6">Pickup: &nbsp;</Typography>
                  </Grid>{' '}
                  <Grid item xs={0.5}>
                    <Typography variant="h6">
                      {filteredRowData
                        ? filteredRowData.filter(
                            ({ form_type: tripType }) => tripType !== 'D',
                          ).length
                        : 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={0}>
                    <Typography variant="h6">Delivery: &nbsp;</Typography>
                  </Grid>{' '}
                  <Grid item xs={0}>
                    <Typography variant="h6">
                      {filteredRowData
                        ? filteredRowData.filter(
                            ({ form_type: tripType }) => tripType === 'D',
                          ).length
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Grid sx={{ my: 0 }} container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Trip Name"
                    variant="outlined"
                    name="trip_name"
                    value={formData.trip_name}
                    onChange={(event) => {
                      const { name, value } = event.target
                      setFormData((prevData) => ({
                        ...prevData,
                        [name]: value,
                      }))
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Autocomplete
                      disablePortal
                      options={groupOptions}
                      sx={{ width: filteredRowData.length > 1 ? 500 : 550 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Distribute To" required />
                      )}
                      groupBy={(option) => option.groupDesc}
                      onChange={handleSelectChange}
                      renderOption={(props, option, { selected }) => (
                        <li
                          {...props}
                          style={{
                            fontWeight: option.status === 'A' ? 800 : 400,
                            marginLeft: '25px',
                          }}
                        >
                          {option.label}
                        </li>
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              {!isEmpty(filteredRowData) && (
                <Grid style={{ marginTop: '10px' }}>
                  <Table
                    columns={columnConfig()['distributeTripDialog']}
                    rows={renderRows(
                      !individualLot
                        ? selectedRowData.assignments
                        : filteredRowData,
                    )}
                    defaultHeight={true}
                    showCheckbox={false}
                    showPagination={false}
                  ></Table>
                </Grid>
              )}
            </div>
          )}
        </div>
      </DialogContent>

      <DialogActions>
        {resolveIssue && (
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={() => setActionCode(ACTION_CODES.RESOLVE_ISSUE)}
            style={{ marginRight: 'auto' }}
          >
            Back
          </LoadingButton>
        )}
        <LoadingButton
          loading={globalLoading}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!areRequiredFieldsFilled()}
        >
          Distribute
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default DistributeTripDialog
