export const EMAIL_REGEX =
  /^([a-zA-Z0-9À-ž%+-_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9À-ž-]+\.)+))([a-zA-ZÀ-ž]{2,4}|[0-9]{1,3})(\]?)$/

export const matchPasswordRegex = (password) =>
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/.test(
    password,
  )

export function formatPhoneNumberWithMask(numericPhoneNumber) {
  if (
    !numericPhoneNumber ||
    numericPhoneNumber.replace(/\D/g, '').length !== 10
  ) {
    return ''
  }
  const regex = /(\d{3})(\d{3})(\d{4})/
  const mask = '($1) $2-$3'
  return numericPhoneNumber.replace(regex, mask)
}

export function isValidUSPhoneNumber(phoneNumber) {
  const phoneRegex =
    /^(?:\+1\s?)?\(?([2-9][0-8][0-9])\)?[\s.-]?([2-9][0-9]{2})[\s.-]?([0-9]{4})$/

  return phoneRegex.test(phoneNumber)
}

export const isNumeric = (value) => {
  return /^\d*$/.test(value)
}
