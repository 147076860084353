import { indexOf, filter as _filter } from 'ramda'
import { startCase } from 'lodash'

export const filterMapper = {
  dispatch_status_desc: 'Dispatch Status',
  scheduled_flag: 'Scheduled',
  residence_flag: 'Residence',
  damage_type_code: 'Damage Type',
  loss_type_code: 'Loss Type',
  lot_stage_desc: 'Lot Stage',
  cash_status_code: 'Cash Status',
  vendor_name: 'Tow Provider',
  tow_type_code: 'Tow Type',
  towable_flag: 'Towable',
  trip_type_code: 'Trip Type',
  lot_make: 'Make',
  lot_model_year: 'Year',
  location_zip: 'Zip',
  message_type_desc: 'Issue Type',
  current_entity_type: 'Issue Assigned To',
  created_by_name: 'Issue Raised By',
  declined_for_dispatcher_flag: 'Declined Trip',
  // partial_trip_lot_flag: 'Partial Trip',
  check_printed_flag: 'Check Printed',
  location_city: 'City',
  vendor_trip_id: 'Trip Id',
  trip_verification_flag: 'Trip Verified',
  active_issue_flag: 'Active Issue',
  dispatch_status: 'Dispatch Status', // Hack - may not always work while inverting
  cash_status: 'Cash Status',
  is_residence: 'Residence',
  is_scheduled: 'Scheduled',
  is_towable: 'Towable',
  completed_date: 'Completed Date',
  status: 'Status',
  oversight_view: 'Distributed View',
  vendor_personnel_full_name: 'Assignee Full Name',
  trip_date: 'Trip Date',
  payment_mode: 'Payment Mode',
  issues_view: 'Issues View',
  current_trip_id: 'Current Trip Id',
  declined_trips_view: 'Declined Trips',
  declined_flag: 'Declined Flag',
}

export function filterNameMapper(filterName) {
  return filterMapper[filterName] || filterName
}

function filterKeysAndCounts(filterSequence) {
  const isOddKey = (key) => indexOf(key, filterSequence) % 2 !== 0
  const isEvenKey = (key) => indexOf(key, filterSequence) % 2 === 0
  const filterKeys = _filter(isEvenKey, filterSequence)
  const filterKeyCounts = _filter(isOddKey, filterSequence)
  return { filterKeys, filterKeyCounts }
}

function transformFilter(filterName, filterSequence) {
  const { filterKeys, filterKeyCounts } = filterKeysAndCounts(filterSequence)
  return filterKeys.reduce((accu, filterKey, index) => {
    const filterCount = filterKeyCounts[index]
    return {
      ...accu,
      [filterName]: {
        ...accu[filterName],
        [filterKey]: {
          label: filterKey,
          ...(filterName === 'Trip Date'
            ? { name: null }
            : { count: filterCount, name: filterKey }),
        },
      },
    }
  }, {})
}

const getReverseFilterName = (filterName) =>
  ({
    dispatch_status_desc: 'dispatch_status',
    due_today_flag: 'is_due_today',
    scheduled_flag: 'is_scheduled',
    residence_flag: 'is_residence',
    towable_flag: 'is_towable',
    partial_trip_lot_flag: 'is_partial_trip_lot',
  }[filterName])

export default function transformFilters(filtersObject) {
  let filterValuesByName = {}
  const modifiedFiltersByName = Object.keys(filtersObject)
    .sort((a, b) =>
      filterNameMapper(a)
        .toLowerCase()
        .localeCompare(filterNameMapper(b).toLowerCase()),
    )
    .reduce((filterAccu, filterName) => {
      const modifiedfFilterName = filterNameMapper(filterName)
      const filter = filtersObject[filterName]
      const filterValuesByName = { ...transformFilter(filterName, filter) }

      return {
        ...filterAccu,
        [modifiedfFilterName]: {
          label: startCase(modifiedfFilterName),
          name: getReverseFilterName(filterName) || filterName,
          filterOptions: [...Object.values(filterValuesByName)],
          selectedValues: [],
        },
      }
    }, {})
  return {
    filtersByName: modifiedFiltersByName,
    filterValuesByName,
  }
}
