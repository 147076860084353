import React, { useMemo, useState } from 'react'
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Checkbox,
} from '@mui/material'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { styled } from '@mui/material/styles'
import { useAtom } from 'jotai'

import {
  pageAtom,
  rowsPerPageAtom,
  tableRecordsAtom,
  selectedViewAtom,
  DriverScheduleDialogAtom,
  customPageAtom,
  customRowsPerPageAtom,
  selectedRowAtom,
  selectedRecordsAtom,
  sortByAtom,
  searchTermAtom,
  actionCodeAtom,
} from 'constants/atoms'
import { filterData } from 'utils/tableUtils'
import locale from 'utils/locale'
import { ACTION_CODES } from 'constants/actionCodes'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': { backgroundColor: theme.palette.action.hover },
  '&:last-child td, &:last-child th': { border: 0 },
}))

const StyledTablePagination = styled(TablePagination)({
  '& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
    margin: 0,
  },
  '& .MuiTablePagination-select': { padding: 0 },
})

const renderTableCell = (row, id, display) => {
  return display ? display(row) : row[id]
}

const Table = ({
  columns = [],
  rows = [],
  showLoader = false,
  selected = [],
  onRowSelectionChange = () => {},
  onCheckboxChange = () => {},
  showCheckbox = true,
  defaultHeight = false,
  isEditing = false,
  customRows = false,
  showPagination = true,
  height = '',
}) => {
  const [page, setPage] = useAtom(customRows ? customPageAtom : pageAtom)
  const [rowsPerPage, setRowsPerPage] = useAtom(
    customRows ? customRowsPerPageAtom : rowsPerPageAtom,
  )
  const [selectedRows, setSelectedRows] = useAtom(selectedRowAtom)
  const [tableRecords, setTableRecords] = useAtom(tableRecordsAtom)
  const [selectedView] = useAtom(selectedViewAtom)
  const [driverScheduleDialogOpen] = useAtom(DriverScheduleDialogAtom)
  const [, setSelectedRecords] = useAtom(selectedRecordsAtom)
  const [sortBy, setSortBy] = useAtom(sortByAtom)
  const [search] = useAtom(searchTermAtom)
  const [actionCode] = useAtom(actionCodeAtom)
  const [localSortBy, setLocalSortBy] = useState({})

  const activeSortBy =
    selectedView === 'truckManagementView' ||
    actionCode === ACTION_CODES.VIEW_LOT
      ? localSortBy
      : sortBy

  const activeSetSortBy =
    selectedView === 'truckManagementView' ||
    actionCode === ACTION_CODES.VIEW_LOT
      ? setLocalSortBy
      : setSortBy

  const displayedRows = useMemo(() => {
    let processedRows = rows

    if (selectedView === 'workRequestsView') {
      processedRows = rows.filter((row) => row.message_status === 'A')
    }

    if (driverScheduleDialogOpen) {
      return processedRows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      )
    }

    if (
      selectedView === 'truckManagementView' ||
      actionCode === ACTION_CODES.VIEW_LOT
    ) {
      const data = filterData(processedRows, selectedView, search, activeSortBy)
      setTableRecords(data.length)
      return data
    }

    if (['lotsView', 'tripsView', 'driversView'].includes(selectedView)) {
      return processedRows
    }

    return processedRows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    )
  }, [
    rows,
    page,
    rowsPerPage,
    driverScheduleDialogOpen,
    selectedView,
    search,
    activeSortBy,
  ])

  const isSelected = (rowId) => selectedRows.some(({ id }) => id === rowId)

  const paperHeight =
    document.getElementById('contentTableWrap')?.getBoundingClientRect()
      .height -
    (document.getElementById('actionBarContainer')?.getBoundingClientRect()
      ?.height || 0)

  const handleSort = (columnId) => {
    const currentSortDirection = activeSortBy[columnId]
    const newSortDirection = currentSortDirection === 'asc' ? 'desc' : 'asc'
    activeSetSortBy({ [columnId]: newSortDirection })
  }

  const handleSelectAllClick = () => {
    if (selectedRows.length === 0) {
      setSelectedRows([...displayedRows])
      setSelectedRecords(displayedRows.length)
    } else {
      setSelectedRows([])
      setSelectedRecords(0)
    }
  }

  const handleRowClick = (e, row) => {
    const newSelected = isSelected(row?.id)
      ? selectedRows.filter(({ id: rowId }) => rowId !== row?.id)
      : [...selectedRows, row]
    setSelectedRows(newSelected)
    setSelectedRecords(newSelected.length)
  }

  return (
    <Paper
      style={{ height: height || (defaultHeight ? '' : `${paperHeight}px`) }}
    >
      <TableContainer style={{ height: '92%', overflow: 'auto' }}>
        <MuiTable stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {showCheckbox && (
                <TableCell
                  padding="checkbox"
                  style={{
                    backgroundColor: '#e8edf6',
                    border: '1px solid #fff',
                  }}
                >
                  <Checkbox
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < displayedRows.length
                    }
                    checked={
                      displayedRows.length > 0 &&
                      selectedRows.length === displayedRows.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
              )}
              {columns.map(({ id, label, sortable }) => (
                <TableCell
                  key={id}
                  variant="head"
                  align="center"
                  style={{
                    backgroundColor: '#e8edf6',
                    color: '#3c3d40',
                    border: '1px solid #fff',
                    fontWeight: 800,
                    fontSize: '14px',
                    cursor: sortable ? 'pointer' : '',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() => sortable && handleSort(id)}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {locale(label)}
                    {sortable &&
                      activeSortBy[id] &&
                      (activeSortBy[id] === 'asc' ? (
                        <ArrowDropDownIcon />
                      ) : (
                        <ArrowDropUpIcon />
                      ))}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!showLoader && (
            <TableBody>
              {displayedRows.map((row) => (
                <StyledTableRow key={row.id}>
                  {showCheckbox && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isSelected(row.id)}
                        onChange={(event) => handleRowClick(event, row)}
                      />
                    </TableCell>
                  )}
                  {columns.map(({ id, display }) => (
                    <TableCell key={id} align="center" sx={{ padding: 1 }}>
                      {renderTableCell(
                        row,
                        id,
                        display,
                        isEditing,
                        onCheckboxChange,
                      )}
                    </TableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </MuiTable>
      </TableContainer>
      {showPagination && (
        <StyledTablePagination
          component="div"
          count={tableRecords}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10))
            setPage(0)
          }}
          labelRowsPerPage="Rows per page"
          rowsPerPageOptions={[10, 20, 40, 100]}
        />
      )}
    </Paper>
  )
}

export default Table
