import { viewSearchColumns } from 'constants/sidebarItems'

export const filterData = (data, selectedView, searchTerm, sortBy) => {
  let filteredData = data

  // if (searchTerm) {
  //   filteredData = filteredData.filter((item) => {
  //     const searchColumn = viewSearchColumns[selectedView].toLowerCase()
  //     const columnData = (item[searchColumn] || '').toString().toLowerCase()
  //     return columnData.includes(searchTerm.toLowerCase())
  //   })
  // }

  if (sortBy) {
    const column = Object.keys(sortBy)[0]
    const order = sortBy[column]

    filteredData.sort((a, b) => {
      if (typeof a[column] === 'string') {
        const comparison = a[column].localeCompare(b[column])
        return order === 'asc' ? comparison : -comparison
      } else {
        const comparison = a[column] - b[column]
        return order === 'asc' ? comparison : -comparison
      }
    })
  }

  return filteredData
}
