import { useAtom } from 'jotai'
import { useSnackbar } from 'notistack'

import { Button } from '@mui/material'
import EmailInput from '../customInputFields/emailInput'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import BackButton from '../customInputFields/backButton'

import { EMAIL_REGEX } from 'utils/regex'
import { loginLabels } from 'utils/labels'
import { getExternalUserSettings } from './loginActions'
import { externalUserDataAtom, accountStatusAtom } from 'constants/atoms'

const ForgotPassword = ({ inputEmail, handleChange }) => {
  const [, setAccountStatus] = useAtom(accountStatusAtom)
  const [, setExternalUserData] = useAtom(externalUserDataAtom)
  const { enqueueSnackbar } = useSnackbar()

  const invalidEmail = !!inputEmail && !EMAIL_REGEX.test(inputEmail)

  const disableSubmitBtn = invalidEmail || !inputEmail

  const handleSubmit = async () => {
    const externalUserData = await getExternalUserSettings(inputEmail)
    if (externalUserData) {
      setAccountStatus('verifyAccount')
    } else {
      enqueueSnackbar(loginLabels.invalidEmail, {
        variant: 'error',
      })
    }
    setExternalUserData(externalUserData)
  }

  return (
    <div>
      <div className="avatarIconWrap">
        <PersonSearchIcon className="avatarDiv" />
      </div>
      <div className="loginStep1Wrap divBox">
        <BackButton
          accountStatus={'default'}
          setAccountStatus={setAccountStatus}
        />
        <span className="mediumHeading">{loginLabels.findYourAccount()}</span>
        <p className="loginNormalText">{loginLabels.findYourAccountInfo}</p>
        <EmailInput
          value={inputEmail}
          showAdornment={false}
          handleChange={({ target }) =>
            handleChange('inputEmail', target.value)
          }
        />
        <Button
          variant="contained"
          color="success"
          disabled={disableSubmitBtn}
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </div>
    </div>
  )
}

export default ForgotPassword
