import axios from 'services/customAxios'
import { getEndpoints } from 'constants/endpoints'

export const overrideDeclineTrip = async (payload, tripId) => {
  try {
    const endpoint = getEndpoints({ tripId }).overrideDeclineTrip
    const response = await axios.patch(endpoint, payload)

    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    console.error('Error while overriding decline', error)
  }
}

export const removeTransporter = async (tripId, action) => {
  try {
    const endpoint =
      action === 'removeDriver'
        ? getEndpoints().reclaimTrip
        : getEndpoints().transitionTrip
    const actionPerformed = action === 'removeDriver' ? '' : 'cancel_decline'

    const payload = { trip_ids: [tripId], action: actionPerformed }

    const response = await axios.patch(endpoint, payload)

    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    console.error('Error while removing transporter', error)
  }
}

export const cancelDeclineTrip = async (payload, tripId) => {
  try {
    const endpoint = getEndpoints({ tripId }).cancelDeclineTrip
    const response = await axios.patch(endpoint, payload)

    if (response.status === 200) {
      return response
    }
  } catch (error) {
    console.error('Error while cancelling decline', error)
  }
}

export const getDeclineReasons = async () => {
  try {
    const endpoint = getEndpoints().declineReason
    const response = await axios.get(endpoint)

    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    console.error('Error while cancelling decline:', error)
  }
}

export const updateTransitions = async (payload) => {
  try {
    const endpoint = getEndpoints().updateTransitions

    const response = await axios.patch(endpoint, payload)

    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    console.error('Error while cancelling decline', error)
  }
}
