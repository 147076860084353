import React from 'react'
import { TextField, InputAdornment } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'

import { adornmentStyle } from './styles'
import { EMAIL_REGEX } from 'utils/regex'
import { loginLabels } from 'utils/labels'

const EmailInput = ({
  value = '',
  name = '',
  placeholder = '',
  disabled = false,
  fullWidth = true,
  showAdornment = true,
  label = 'Email Address',
  adornmentBgColor = 'success.main',
  style = {},
  handleChange = () => {},
  autoFocus = true,
  required = false,
  readOnly = false,
}) => {
  const invalidEmail = value ? !EMAIL_REGEX.test(value) : false
  return (
    <TextField
      autoFocus={autoFocus}
      required={required}
      name={name}
      type="search"
      label={label}
      value={value || ''}
      disabled={disabled}
      id="loginEmailInput"
      error={invalidEmail}
      fullWidth={fullWidth}
      placeholder={placeholder}
      helperText={invalidEmail ? loginLabels.invalidEmail : ''}
      style={style}
      variant={readOnly ? 'filled' : 'outlined'}
      onChange={handleChange}
      InputProps={{
        startAdornment: showAdornment ? (
          <InputAdornment position="start">
            <EmailIcon
              color="white"
              fontSize="small"
              sx={{ ...adornmentStyle, backgroundColor: adornmentBgColor }}
            />
          </InputAdornment>
        ) : null,
        readOnly: readOnly,
      }}
    />
  )
}

export default EmailInput
