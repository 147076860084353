import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Divider,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { head, isEmpty } from 'ramda'
import { useSnackbar } from 'notistack'
import { useAtom } from 'jotai'
import CloseIcon from '@mui/icons-material/Close'
import MultiViewSidebar from 'components/views/dialogs/multiViewSidebar'
import { moveToNextItemInMultiView } from 'utils/dispatchutils'

import {
  globalPostAtom,
  actionCodeAtom,
  completedRowDataAtom,
} from 'constants/atoms'
import { useStyles } from 'utils/styles'
import { removeTransporter } from 'services/tripsApi'
import { ACTION_CODES } from 'constants/actionCodes'

const CancelDeclineDialog = ({ rowData, onClose, action }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [actionCode, setActionCode] = useAtom(actionCodeAtom)
  const [globalLoading, setGlobalLoading] = useAtom(globalPostAtom)
  const [cancelledTrips, setCancelledTrip] = useAtom(completedRowDataAtom)
  const [selectedRowData, setSelectedRowData] = useState(head(rowData))

  const handleClose = () => {
    setActionCode('')
    onClose()
  }

  useEffect(() => {
    if (isEmpty(cancelledTrips)) {
      return
    }
    if (rowData.length === cancelledTrips.length) {
      handleClose()
    } else if (rowData.length > cancelledTrips.length) {
      let curIndex = rowData.indexOf(selectedRowData)
      const nextIndex = moveToNextItemInMultiView(
        curIndex,
        cancelledTrips,
        rowData,
        'trip_id',
      )
      setSelectedRowData(rowData[nextIndex])
    }
  }, [cancelledTrips])

  const { trip_id: id = '', trip_name: name = '' } = selectedRowData || {}
  const handleSubmit = async () => {
    const response = await removeTransporter(id, action)
    if (response && response.status === 'success') {
      setGlobalLoading(true)
      enqueueSnackbar(
        action === 'removeDriver'
          ? 'Driver Removed Successfully'
          : 'Reclaimed Successfully',
        {
          variant: 'success',
        },
      )
      setCancelledTrip([...cancelledTrips, selectedRowData?.trip_id])
    } else {
      enqueueSnackbar('Failed to perform action. Please try again', {
        variant: 'error',
      })
    }
  }

  const classes = useStyles()

  return (
    <Dialog
      open={
        actionCode === ACTION_CODES.REMOVE_DRIVER ||
        ACTION_CODES.TAKE_BACK_AND_ACCEPT
      }
      onClose={handleClose}
    >
      <DialogTitle style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '10px',
            whiteSpace: 'nowrap',
            color: 'white',
          }}
        >
          <div style={{ display: 'flex', columnGap: '10px' }}>
            <Typography
              classes={{
                root: classes.root,
              }}
            >
              Override Decline Trip
            </Typography>
            <Divider
              classes={{ root: classes.dividerRoot }}
              orientation="vertical"
              flexItem
            />
            <Typography
              classes={{
                root: classes.root,
              }}
            >
              {`${id} - ${name}`}
            </Typography>
          </div>
        </div>
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: '8px', top: '8px' }}
        >
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ width: '500px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {rowData.length > 1 && (
            <MultiViewSidebar
              selectedRowData={selectedRowData}
              setSelectedRowData={setSelectedRowData}
              tripsView
              completedRowData={cancelledTrips}
            />
          )}
          <div
            className="col-2-3"
            style={
              rowData.length > 1
                ? {
                    borderLeft: '1px solid rgba(0,0,0,0.12)',
                    paddingLeft: '10px',
                    height: '100px',
                    whiteSpace: 'no-wrap',
                  }
                : {}
            }
          >
            <Typography
              variant="h6"
              gutterBottom
              mt={rowData.length > 1 ? 3 : 0}
              sx={{ whiteSpace: 'nowrap' }}
            >
              {action === 'removeDriver'
                ? 'Are you sure you want to remove the driver?'
                : 'Would you like to Take Back and Accept Trip?'}
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={globalLoading}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={globalLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default CancelDeclineDialog
