import { useState, useMemo } from 'react'
import { useAtom } from 'jotai'
import { Grid, Box, MenuItem, Divider, CircularProgress } from '@mui/material'
import { LotMapCard } from './lotCard'
import { selectedRowAtom, selectedRecordsAtom } from 'constants/atoms'
import { CheckCircle } from '@mui/icons-material'

const LotsMenu = ({ lots, loading, anchorEl, open, maps, onClickLocation }) => {
  const [selectedLotIndex, setSelectedLotIndex] = useState(0)
  const [selectedRows, setSelectedRows] = useAtom(selectedRowAtom)
  const [, setSelectedRecords] = useAtom(selectedRecordsAtom)

  const selectedRowIds = useMemo(
    () => new Set(selectedRows.map((row) => row.dispatch_assignment_detail_id)),
    [selectedRows],
  )

  const handleRowClick = (row) => {
    const updatedSelection = selectedRowIds.has(
      row.dispatch_assignment_detail_id,
    )
      ? selectedRows.filter(
          (r) =>
            r.dispatch_assignment_detail_id !==
            row.dispatch_assignment_detail_id,
        )
      : [...selectedRows, row]

    setSelectedRows(updatedSelection)
    setSelectedRecords(updatedSelection.length)
  }

  if (!lots || lots.length === 0) return null

  return (
    <div
      ref={(ref) => ref && maps.OverlayView.preventMapHitsAndGesturesFrom(ref)}
    >
      <Grid
        container
        sx={{ height: '420px', width: '600px', backgroundColor: 'white' }}
      >
        {loading ? (
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid
              item
              xs={5}
              sx={{
                borderRight: '1px solid rgba(0,0,0,0.12)',
                overflowY: 'auto',
                maxHeight: '420px',
                width: '25%',
              }}
            >
              {lots.map(
                (
                  {
                    lot_number: lotNumber,
                    lot_suffix: suffix,
                    dispatch_assignment_detail_id: id,
                  },
                  index,
                ) => (
                  <Box key={index}>
                    <MenuItem
                      selected={selectedLotIndex === index}
                      sx={{ padding: '15px' }}
                      onClick={() => setSelectedLotIndex(index)}
                    >
                      {`${lotNumber}-${suffix}`}
                      {selectedRowIds.has(id) && (
                        <CheckCircle sx={{ color: 'blue' }} />
                      )}
                    </MenuItem>
                    {index !== lots.length - 1 && (
                      <Divider sx={{ margin: '0 !important' }} />
                    )}
                  </Box>
                ),
              )}
            </Grid>
            <Grid item xs={7} sx={{ height: '100%', width: '100%' }}>
              <LotMapCard
                handleRowSelectionChange={() =>
                  handleRowClick(lots[selectedLotIndex])
                }
                selectedRowIds={selectedRowIds}
                data={lots[selectedLotIndex]}
                onClickLocation={onClickLocation}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}

export default LotsMenu
