import axios from 'services/customAxios'
import { getEndpoints } from 'constants/endpoints'

export const getLotInfo = async (assignmentDetailId) => {
  try {
    const endpoint = getEndpoints({ assignmentDetailId }).getLotInfo
    const response = await axios.get(endpoint)
    return response.data.assignment
  } catch (error) {
    console.error('Error while getting lot info:', error)
  }
}

export const getNotes = async (lotNumber) => {
  try {
    const endpoint = getEndpoints({ lotNumber }).getNotes
    const response = await axios.get(endpoint)
    return response.data
  } catch (error) {
    console.error('Error while getting notes:', error)
  }
}

export const getCharges = async (assignmentDetailId) => {
  try {
    const endpoint = getEndpoints({ assignmentDetailId }).getCharges
    const response = await axios.get(endpoint)
    return response.data
  } catch (error) {
    console.error('Error while getting charges:', error)
  }
}
