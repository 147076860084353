import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  Divider,
} from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'
import { LoadingButton } from '@mui/lab'
import { useAtom } from 'jotai'
import { pathOr } from 'ramda'
import { CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { reverseDispatchStatusMapper, formAddress } from 'utils/dispatchutils'
import { formatPhoneNumberWithMask } from '../../../../utils/regex'
import { globalPostAtom, actionCodeAtom, rowDataAtom } from 'constants/atoms'
import { ACTION_CODES } from 'constants/actionCodes'
import { useStyles } from 'utils/styles'
import { formatDateObj } from 'utils/date'
import {
  getLotInfo,
  getNotes,
  getCharges,
} from 'services/ViewLotDialogServices'
import NotesDialog from './notesDialog'

const ViewLotDialog = ({ rowData = {} }) => {
  const [loading, setLoading] = useState(false)
  const [actionCode, setActionCode] = useAtom(actionCodeAtom)
  const [globalLoading] = useAtom(globalPostAtom)
  const [lotData, setLotData] = useState({})
  const [noteData, setNoteData] = useState([])
  const [chargeData, setChargeData] = useState([])
  const [notesDialogOpen, setNotesDialogOpen] = useState(false)
  const [, setRowData] = useAtom(rowDataAtom)

  const {
    dispatch_assignment_detail_id: id,
    lot_number: lotNumber,
    lot_suffix: lotSuffix,
    lot_description: lotDesc,
    dispatch_status: dispatchStatus,
  } = rowData

  const {
    lot_trip_zone: lotTripZone = '',
    vin: lotVin = '',
    seller_name: sellerName = '',
    insured_name: insuredName = '',
    owner_name: ownerName = '',
    claim_number: claimNumber = '',
    damage_type_code: lossType = 'N/A',
    loss_date: lossDate = '',

    source: pickupSource = {},
    source: { name: pickupName = '' } = {},
    destination: destinationSource = {},
    destination: { name: destinationName = '' } = {},
    location_phone_number: pickupContact = '',
    scheduled_date: scheduledDate = '',
    scheduled_time_from: scheduledTimeFrom = '',
    scheduled_time_to: scheduledTimeTo = '',
    responsible_party_name: assignedTo = '',
    payment_mode: paymentMethod = '',
    pickup_notes: locationNotes = '',
    directions: directionNotes = '',
  } = lotData

  const chargesDue = pathOr('', ['data', 'charges', 'total'], chargeData)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const apiData = await getLotInfo(id)
      setLotData(apiData)
      const notes = await getNotes(lotNumber)
      setNoteData(notes)
      const charges = await getCharges(id)
      setChargeData(charges)
      setLoading(false)
    }
    fetchData()
  }, [id, lotNumber])

  const lotDetailRows = [
    { label: 'Zone', value: lotTripZone },
    { label: 'VIN', value: lotVin },
    { label: 'Seller Name', value: sellerName },
    { label: 'Insured Name', value: insuredName },
    { label: 'Owner Name', value: ownerName },
    { label: 'Claim Number', value: claimNumber },
    { label: 'Loss Type', value: lossType },
    { label: 'Loss Date', value: formatDateObj(lossDate) || 'N/A' },
  ]

  const handleClose = () => {
    setRowData([])
    setActionCode('')
  }
  const classes = useStyles()
  return (
    <Dialog
      open={actionCode === ACTION_CODES.VIEW_LOT}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      scroll="paper"
    >
      {rowData && (
        <>
          <DialogTitle style={{ display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '10px',
                whiteSpace: 'nowrap',
              }}
            >
              <div style={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  classes={{
                    root: classes.root,
                  }}
                >
                  {lotNumber} - {lotSuffix}
                </Typography>
                <Divider
                  classes={{
                    root: classes.dividerRoot,
                  }}
                  orientation="vertical"
                  flexItem
                />
                <Typography
                  classes={{
                    root: classes.root,
                  }}
                >
                  {lotDesc}
                </Typography>
                <Divider
                  classes={{ root: classes.dividerRoot }}
                  orientation="vertical"
                  flexItem
                />
                <Typography
                  classes={{
                    root: classes.root,
                  }}
                >
                  {reverseDispatchStatusMapper[dispatchStatus]}
                </Typography>
              </div>
              <IconButton
                onClick={handleClose}
                style={{ position: 'absolute', right: '8px', top: '8px' }}
              >
                <CloseIcon sx={{ color: 'white' }} />
              </IconButton>
            </div>
          </DialogTitle>
        </>
      )}

      <DialogContent dividers>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div>
            <Grid container spacing={1}>
              {!loading && (
                <Grid
                  container
                  item
                  md={7}
                  spacing={2}
                  style={{ display: 'flex' }}
                >
                  <Grid item md={6}>
                    <div>
                      <Typography variant="h6" color={'#0d5db8'}>
                        Pick Up Location Information
                      </Typography>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {pickupName}
                      </Typography>
                      <Typography>{formAddress(pickupSource)}</Typography>
                      <Typography>
                        Contact: {formatPhoneNumberWithMask(pickupContact)}
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item md={6}>
                    <Typography variant="h6" color={'#0d5db8'}>
                      Destination Information
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {destinationName}
                    </Typography>
                    <Typography>{formAddress(destinationSource)}</Typography>
                  </Grid>

                  <Grid item md={6}>
                    <Typography variant="h6" color={'#0d5db8'}>
                      Scheduled Date & Time
                    </Typography>
                    <Typography>
                      {scheduledDate
                        ? `${scheduledDate} ${scheduledTimeFrom}- ${scheduledTimeTo}`
                        : 'Not Scheduled'}
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="h6" color={'#0d5db8'}>
                      Assigned to
                    </Typography>
                    <Typography>{assignedTo}</Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Typography variant="h6" color={'#0d5db8'}>
                      Advanced Pick up Information
                    </Typography>
                    <span>
                      <b>Charges Due:</b> ${chargesDue}{' '}
                    </span>
                    <span style={{ marginLeft: '50px' }}>
                      <b>Accepts:</b> {paymentMethod}
                    </span>
                  </Grid>
                  <Grid item md={12}>
                    <Typography variant="h6" color={'#0d5db8'}>
                      Pick Up Notes
                    </Typography>
                    <Typography>
                      <b>Location Notes:</b> {locationNotes}
                    </Typography>
                    <Typography>
                      <b>Direction Notes:</b> {directionNotes}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              <Grid
                container
                item
                md={5}
                spacing={1}
                style={{ borderLeft: '1px solid #ccc', marginTop: '10px' }}
              >
                <div style={{ width: '100%' }}>
                  <ul
                    style={{
                      listStyleType: 'none',
                      padding: 0,
                    }}
                  >
                    {lotDetailRows.map((row, index) => (
                      <li key={index}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            lineHeight: '30px',
                          }}
                        >
                          <span style={{ color: '#0d5db8', padding: '2px' }}>
                            {row.label}
                          </span>
                          <span>{row.value}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <LoadingButton
          loading={globalLoading}
          variant="contained"
          color="primary"
          onClick={() => setNotesDialogOpen(true)}
        >
          <DescriptionIcon />
          <span style={{ paddingLeft: '10px' }}>NOTES</span>
        </LoadingButton>
      </DialogActions>
      <NotesDialog
        lotNumber={lotNumber}
        lotSuffix={lotSuffix}
        notesData={noteData}
        isOpen={notesDialogOpen}
        handleClose={() => setNotesDialogOpen(false)}
      />
    </Dialog>
  )
}

export default ViewLotDialog
