import React from 'react'
import { useAtom } from 'jotai'
import { useSnackbar } from 'notistack'
import { head, isEmpty, pathOr } from 'ramda'

import {
  loadingAtom,
  loginInputDataAtom,
  referenceDataAtom,
  personnelDataAtom,
  catViewAtom,
  accountStatusAtom,
} from 'constants/atoms'
import { loginLabels } from 'utils/labels'
import { setItem, setAccessToken, setRefreshToken } from 'utils/localstorage'
import locale from 'utils/locale'
import {
  validateLoginCredentials,
  getUserDetails,
  getReferenceData,
  getPersonnel,
  deleteTransporterAccount,
} from './loginActions'
import LoginForm from './loginForm'
import { getSubhaulers } from 'services/driverApi'

import './login.scss'
import '../../appDefaultStyles.scss'

// import { encryptPassword } from 'utils/encryptionUtils'

const Copyright = () => (
  <div className="copyRight">
    {`Copyright © `}
    <a
      color="inherit"
      href="https://copart.com/"
      target="_blank"
      rel="noreferrer"
    >
      Copart
    </a>
    &nbsp;{new Date().getFullYear()}
  </div>
)

const Login = ({ setIsAuthorized, deleteAccount }) => {
  const [loginInputData, setLoginInputData] = useAtom(loginInputDataAtom)
  const [, setReferenceData] = useAtom(referenceDataAtom)
  const [, setPersonnelData] = useAtom(personnelDataAtom)
  const [, setLoading] = useAtom(loadingAtom)
  const [, setCatView] = useAtom(catViewAtom)
  const [, setAccountStatus] = useAtom(accountStatusAtom)
  const { enqueueSnackbar } = useSnackbar()

  const handleLogin = async () => {
    setLoading(true)
    // const encryptedpassword = encryptPassword(loginInputData.inputPassword)
    const response = await validateLoginCredentials(
      loginInputData.inputEmail,
      loginInputData.inputPassword,
    )

    if (response) {
      setAccessToken(response.access_token)
      setRefreshToken(response.refresh_token)
      const userDetails = await getUserDetails()
      const { email, vendors, user_language, buyer_transporter } = userDetails

      if (deleteAccount) {
        if (buyer_transporter) {
          const { transporter_id } = buyer_transporter
          if (transporter_id) {
            const response = await deleteTransporterAccount(transporter_id)
            if (response.data.status === 'success') {
              enqueueSnackbar(loginLabels.accountDeletionSuccess(), {
                variant: 'success',
              })
            } else {
              enqueueSnackbar(loginLabels.accountDeletionFailure(), {
                variant: 'error',
              })
            }
          }
          setAccountStatus('deleted')
        } else {
          enqueueSnackbar(loginLabels.transporterNotRegistered, {
            variant: 'error',
          })
        }
        return
      }

      const filterdVendors = vendors.filter((vendor) =>
        [1, 2].includes(pathOr(0, ['personnel_data', 'role_id'], vendor)),
      )

      if (isEmpty(filterdVendors)) {
        enqueueSnackbar(loginLabels.driverNotAllowed, {
          variant: 'error',
        })
        setIsAuthorized(false)
      } else {
        const {
          phone_number: number,
          personnel_data: personnel,
          groups,
          vendor_id,
          vendor_name,
        } = head(filterdVendors)

        const userData = {
          email: email,
          vendor: {
            phone_number: number,
            vendor_personnel_id: personnel.vendor_personnel_id,
            vendor_id: personnel.vendor_id,
            external_user_id: personnel.external_user_id,
            first_name: personnel.first_name,
            last_name: personnel.last_name,
            full_name: personnel.full_name,
            start_date: personnel.start_date,
            role_id: personnel.role_id,
            groups,
          },
          user_language: user_language,
          vendor_id,
          vendor_name,
        }
        setItem('userData', userData)
        const referenceData = await getReferenceData()
        setReferenceData(referenceData)
        const personnelData = await getPersonnel()
        setPersonnelData(personnelData)
        setItem('vendor', filterdVendors)
        enqueueSnackbar(loginLabels.loginSuccess, { variant: 'success' })
        const subHaulers = await getSubhaulers()
        setCatView(subHaulers.length !== 0)
        setIsAuthorized(true)
        if (filterdVendors.length > 1) {
          setItem('vendorSelectionMode', true)
        }
      }
      setLoading(false)
    } else {
      setLoading(false)
      enqueueSnackbar(loginLabels.invalidCredentials, {
        variant: 'error',
      })
    }
  }

  const handleChange = (field, value) =>
    setLoginInputData((prev) => ({ ...prev, [field]: value }))

  return (
    <div className="LoginWrap">
      <div className="LoginBlueOverlay">
        <div className="LoginLeftWrap">
          <div className="LoginLeftItems">
            <div className="copart40Years" />
            <p className="LoginCopartInfo largeHeading">
              {loginLabels.copartInfo}
            </p>
          </div>
          <div className="LoginLeftCarsImage" />
        </div>
        <div className="LoginRightWrap">
          <div className="appHeading">
            <div className="copartConnectLogo" />
            <span className="logotextCopart">COPART</span>
            <span className="logotextConnect">&nbsp;CONNECT</span>
            <p className="appText">{locale('TRANSPORTER PORTAL')}</p>
          </div>
          <LoginForm
            handleChange={handleChange}
            loginInputData={loginInputData}
            onLogin={handleLogin}
            deleteAccount={deleteAccount}
          />
          <Copyright />
        </div>
      </div>
    </div>
  )
}

export default Login
