import React, { useState, useRef, useMemo } from 'react'
import { LotMapCard } from './lotCard'
import { useAtom } from 'jotai'
import LotsMenu from './lotsMenu'
import NumberedIcon from './numberedIcon'
import { isSameOrBeforeToday } from 'utils/date'
import { selectedRowAtom, selectedRecordsAtom } from 'constants/atoms'

const getMarkerColor = (data) => {
  if (Array.isArray(data)) {
    if (data.some((item) => isSameOrBeforeToday(item.promised_date))) {
      return 'red'
    } else {
      return 'black'
    }
  } else {
    if (isSameOrBeforeToday(data.promised_date)) {
      return 'red'
    } else {
      return 'black'
    }
  }
}

const getText = (data) => {
  if (Array.isArray(data) && data.length > 1) {
    return `${data.length}${data[0].trip_type_code}`
  } else {
    return data.trip_type_code
  }
}

const MapMarker = ({ data, maps, mapDivRef, onClickLocation }) => {
  const [showCard, setShowCard] = useState(false)
  const markerRef = useRef(null)
  const [selectedRows, setSelectedRows] = useAtom(selectedRowAtom)
  const [, setSelectedRecords] = useAtom(selectedRecordsAtom)

  const selectedRowIds = useMemo(
    () => new Set(selectedRows.map((row) => row.dispatch_assignment_detail_id)),
    [selectedRows],
  )

  const handleRowClick = (row) => {
    if (row.length > 1) {
      return
    }
    const updatedSelection = selectedRowIds.has(
      row.dispatch_assignment_detail_id,
    )
      ? selectedRows.filter(
          (r) =>
            r.dispatch_assignment_detail_id !==
            row.dispatch_assignment_detail_id,
        )
      : [...selectedRows, row]

    setSelectedRows(updatedSelection)
    setSelectedRecords(updatedSelection.length)
  }

  const multipleLots = Array.isArray(data) && data.length > 1
  const lotCount = multipleLots ? data.length : 1

  const getCardPosition = () => {
    const markerBounds = markerRef.current.getBoundingClientRect()
    const mapDivBounds = mapDivRef.current.getBoundingClientRect()
    const iconWidth = markerBounds.width
    const iconHeight = markerBounds.height
    const position = {}

    if (markerBounds.left > mapDivBounds.left + mapDivBounds.width / 2) {
      position.right = `${iconWidth}px`
    }

    if (markerBounds.top - 400 > mapDivBounds.top) {
      position.bottom = `${iconHeight}px`
    } else if (markerBounds.bottom + 400 > mapDivBounds.bottom) {
      position.bottom = `0px`
    }
    if (
      markerBounds.top - 400 < mapDivBounds.top &&
      markerBounds.bottom + 400 > mapDivBounds.bottom
    ) {
      position.bottom = `-100px`
      if (markerBounds.left > mapDivBounds.left + mapDivBounds.width / 2) {
        position.right = `${iconWidth + 10}px`
      } else {
        position.left = `${iconWidth + 10}px`
      }
    }

    return position
  }

  return (
    <div
      onClick={() => handleRowClick(data)}
      ref={markerRef}
      onMouseEnter={() => setShowCard(true)}
      onMouseLeave={() => setShowCard(false)}
      style={{ cursor: 'pointer', position: 'relative' }}
    >
      <NumberedIcon
        isSelected={
          Array.isArray(data)
            ? data.every(({ dispatch_assignment_detail_id: id }) =>
                selectedRowIds.has(id),
              )
            : selectedRowIds.has(data.dispatch_assignment_detail_id)
        }
        count={lotCount}
        showCard={showCard}
        color={getMarkerColor(data)}
        letter={getText(data)}
      />
      {showCard && (
        <div
          style={{
            position: 'absolute',
            zIndex: 10,
            ...getCardPosition(),
          }}
        >
          {multipleLots ? (
            <LotsMenu
              maps={maps}
              lots={data}
              open={showCard}
              onClickLocation={onClickLocation}
            />
          ) : (
            <LotMapCard
              data={data}
              onClickLocation={onClickLocation}
              handleRowSelectionChange={() => handleRowClick(data)}
              selectedRowIds={selectedRowIds}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default MapMarker
