import React, { useState, useRef, useEffect } from 'react'
import { atom, useAtom } from 'jotai'
import {
  Drawer as MuiDrawer,
  List,
  ListItemText,
  ListItemButton as MuiListItemButton,
  ListItemIcon,
  Divider,
  IconButton,
  Badge,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import { styled } from '@mui/material/styles'
import { equals } from 'ramda'
import { appNavigatorStyles } from './styles'
import {
  appNavigatorItems,
  appNavigatorItemsConst,
} from 'constants/sidebarItems'
import {
  selectedSubViewAtom,
  dataFetchLoadingAtom,
  selectedFiltersAtom as selectedFilters,
  catViewAtom,
  selectedRecordsAtom,
  selectedRowAtom,
  skipFilterAtom,
  mapViewAtom,
  globalPostAtom,
} from 'constants/atoms'
import { filterConfig } from 'utils/filterConfig'
import { getQuickCounts } from 'services/api'

const activeSubmenuAtom = atom(null)

const MyAppNavigator = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => appNavigatorStyles.drawer({ theme, open }))

const ListItemButton = styled(MuiListItemButton)(appNavigatorStyles.listItems())

const AppNavigatorList = ({
  listItems,
  isOpen,
  selectedView,
  handleOnClick,
  onMenuMouseEnter,
  onMenuMouseLeave,
  setSelectedView,
}) => {
  const [activeSubmenu, setActiveSubmenu] = useAtom(activeSubmenuAtom)
  const [selectedSubView, setSelectedSubView] = useAtom(selectedSubViewAtom)
  const [, setDataFetchLoading] = useAtom(dataFetchLoadingAtom)
  const [selectedFiltersAtom, setSelectedFiltersAtom] = useAtom(selectedFilters)
  const [, setSelectedRecords] = useAtom(selectedRecordsAtom)
  const [, setSelectedRows] = useAtom(selectedRowAtom)
  const [skipFilter] = useAtom(skipFilterAtom)
  const [, setMapView] = useAtom(mapViewAtom)
  const [globalLoading] = useAtom(globalPostAtom)
  const [quickCounts, setQuickCounts] = useState({})
  const toggleSubmenu = (key) => {
    setActiveSubmenu((prevKey) => (prevKey === key ? null : key))
    setSelectedRecords(0)
    setSelectedRows([])
  }

  useEffect(() => {
    const selectedMenuItem = listItems.find((item) => item.key === selectedView)
    if (selectedMenuItem && selectedMenuItem.options?.length) {
      setActiveSubmenu(selectedMenuItem.key)
    } else {
      setActiveSubmenu(null)
    }
  }, [selectedView, listItems, setActiveSubmenu])

  useEffect(() => {
    setSelectedSubView(null)
    const compareQFConfig = () => {
      if (
        selectedView == appNavigatorItemsConst.LOTS ||
        selectedView == appNavigatorItemsConst.TRIPS
      ) {
        setMapView(false)
      }
      const selectedViewAppliedFilters = selectedFiltersAtom[selectedView]
      if (selectedViewAppliedFilters) {
        if (skipFilter) return
        for (let [quickFilterName, quickFilterVal] of Object.entries(
          filterConfig,
        )) {
          let matched = false
          for (let [filterKey, filterValue] of Object.entries(quickFilterVal)) {
            if (!(filterKey in selectedViewAppliedFilters)) break
            if (!equals(selectedViewAppliedFilters[filterKey], filterValue))
              break

            matched = true
          }

          if (matched) {
            setSelectedSubView(quickFilterName)
            break
          }
        }
      }
    }

    compareQFConfig()
  }, [selectedFiltersAtom, selectedView, setSelectedSubView])

  useEffect(() => {
    const fetchData = async () => {
      const quickCounts = await getQuickCounts()
      quickCounts['reportedIssues'] = quickCounts['issue_raised']
      quickCounts['assignedIssues'] = quickCounts['issue_assigned']
      quickCounts['reportedDeclinedTrips'] = quickCounts['declined_by_me']
      quickCounts['reportedAssignedTrips'] = quickCounts['declined_to_me']
      setQuickCounts(quickCounts)
    }
    fetchData()
  }, [globalLoading])

  const getBadgeCount = (key) => {
    return quickCounts[key]
  }

  return (
    <List
      onMouseEnter={onMenuMouseEnter}
      onMouseLeave={onMenuMouseLeave}
      sx={{ padding: '0' }}
    >
      {listItems.map(({ text, key, icon, id, options }) => (
        <React.Fragment key={id}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: isOpen ? 'initial' : 'center',
              px: 2.5,
            }}
            onClick={() => {
              if (selectedView === key) return
              handleOnClick(key)
              if (options?.length) {
                setActiveSubmenu(key)
              } else {
                setActiveSubmenu(null)
              }
            }}
            selected={selectedView === key}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isOpen ? 3 : 'auto',
                justifyContent: 'center',
                color: 'white',
              }}
            >
              {icon}
            </ListItemIcon>
            <ListItemText
              primary={text}
              sx={{ opacity: isOpen ? 1 : 0, color: 'white' }}
            />
            {isOpen && options?.length && (
              <div
                onClick={(e) => {
                  e.stopPropagation() // Stop event from propagating up to the parent ListItemButton
                  toggleSubmenu(key)
                }}
              >
                {activeSubmenu === key ? (
                  <ExpandLessIcon sx={{ color: 'white' }} />
                ) : (
                  <ExpandMoreIcon sx={{ color: 'white' }} />
                )}
              </div>
            )}
          </ListItemButton>
          {isOpen && activeSubmenu === key && options?.length && (
            <List sx={{ paddingLeft: 1 }}>
              {options?.map(({ text, id, key, parentKey }) => (
                <ListItemButton
                  key={id}
                  onClick={() => {
                    setSelectedView(parentKey)
                    setSelectedSubView(key)
                    setDataFetchLoading(true)
                    setSelectedFiltersAtom({
                      ...selectedFiltersAtom,
                      [parentKey]: {
                        ...filterConfig[key],
                      },
                    })
                  }}
                  selected={selectedSubView === key}
                >
                  <ListItemText primary={text} sx={{ color: 'white' }} />
                  <Badge
                    badgeContent={getBadgeCount(key)}
                    color="primary"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: '#fff',
                        color: '#3A4554',
                        minWidth: 25,
                        height: 25,
                        borderRadius: '50%',
                        border: '1px solid #3A4554',
                        fontWeight: 'bold',
                      },
                    }}
                  />
                </ListItemButton>
              ))}
            </List>
          )}
          {!isOpen && (
            <Divider sx={{ backgroundColor: 'gray' }} variant="middle" />
          )}
        </React.Fragment>
      ))}
    </List>
  )
}

const AppNavigator = (props) => {
  const {
    openNavigator,
    openNavigatorOnHover,
    handleNavigatorOpen,
    handleNavigatorOpenOnHover,
    handleNavigatorItemClick,
    selectedNavigatorItem,
    setSelectedView,
  } = props

  const isOpen = openNavigator || openNavigatorOnHover
  const [isArrowButtonClicked, setArrowButtonClicked] = useState(false)
  const [catView] = useAtom(catViewAtom)
  const listItems = !catView
    ? appNavigatorItems?.navigatorItems.filter(
        (x) => x.key !== 'catTowProvidersView',
      )
    : appNavigatorItems?.navigatorItems

  const buttonRef = useRef(null)

  return (
    <MyAppNavigator variant="permanent" open={isOpen || isArrowButtonClicked}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <AppNavigatorList
          listItems={listItems}
          isOpen={isOpen}
          handleOnClick={handleNavigatorItemClick}
          selectedView={selectedNavigatorItem}
          onMenuMouseEnter={() => {
            handleNavigatorOpenOnHover(true)
          }}
          onMenuMouseLeave={() => {
            if (!isArrowButtonClicked) handleNavigatorOpenOnHover(false)
          }}
          setSelectedView={setSelectedView}
        />
        <div style={{ paddingBottom: '16px' }}>
          <IconButton
            ref={buttonRef}
            onClick={() => {
              setArrowButtonClicked((prev) => !prev)
              handleNavigatorOpen(!isArrowButtonClicked)
              handleNavigatorOpenOnHover(false)
            }}
            style={{ fontSize: '3em', width: '100%' }}
          >
            {!isOpen ? (
              <KeyboardDoubleArrowRightIcon sx={{ color: 'white' }} />
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <KeyboardDoubleArrowLeftIcon sx={{ color: 'white' }} />
              </div>
            )}
          </IconButton>
        </div>
      </div>
    </MyAppNavigator>
  )
}

export default AppNavigator
