import { useAtom } from 'jotai'
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ContactDialogAtom } from 'constants/atoms' // <-- ensure you've exported this from the atoms file

import { loginLabels } from './labels'

const Contact = () => {
  const [isContactDialogOpen, setContactDialogOpen] = useAtom(ContactDialogAtom)

  return (
    <div>
      <Dialog
        open={isContactDialogOpen}
        onClose={() => setContactDialogOpen(false)}
      >
        <DialogTitle>
          Contact Us
          <IconButton
            onClick={() => setContactDialogOpen(false)}
            style={{ position: 'absolute', right: '8px', top: '8px' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }} dividers>
          <p className="mediumHeading">{loginLabels.unableToVerify()}</p>
          <p style={{ marginBottom: '20px' }}>
            Contact your nearest yard location
          </p>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Contact
