import { useState, useEffect } from 'react'
import axios from 'services/customAxios'
import { useAtom } from 'jotai'
import { pathOr, propOr, head } from 'ramda'

import { getItem } from 'utils/localstorage'
import {
  tableRecordsAtom,
  dataFetchLoadingAtom,
  globalPostAtom,
  pageAtom,
  rowsPerPageAtom,
  searchTermAtom,
  catAssocListAtom,
  sortByAtom,
  skipFilterAtom,
  refreshDataAtom,
  mapViewAtom,
} from 'constants/atoms'
import { getEndpoints } from 'constants/endpoints'
import { getSubhaulerDriversInfo, getSubhaulers } from 'services/driverApi'
import {
  mergeCATDrivers,
  formatDeploymentEventOptions,
} from 'utils/driverUtils'

const responseExtractors = {
  default: (data) => data.data,
  workRequestsView: (data) => data.work_requests,
}

const fetchCatTowProvidersData = async (
  selectedFilters,
  url,
  search,
  sortBy,
) => {
  let associatedDrivers = []
  let allDrivers = []
  let catDrivers = []
  const subhaulers = await getSubhaulers()

  if (subhaulers && subhaulers.length > 0) {
    associatedDrivers = await Promise.all(
      subhaulers.map(({ deployment_subhauler_id: depSubhaulerId }) => {
        return getSubhaulerDriversInfo(depSubhaulerId)
      }),
    )
  }

  const response = await axios.post(url, {
    filter_by: selectedFilters.catTowProvidersView,
    page: 1,
    page_size: 5000,
    sort_by: sortBy,
    search: search,
  })

  if (response.data.status === 'success') {
    allDrivers = response.data.data
    associatedDrivers.forEach((assocDrivers) => {
      const catId = pathOr(
        [],
        ['data', 'data', 'subhauler_info', 'cat_id'],
        assocDrivers,
      )
      const catName = propOr(
        '',
        'cat_name',
        subhaulers.find(
          (subhauler) => head(subhauler?.cat_ids ?? {}) === catId,
        ),
      )
      const drivers = pathOr([], ['data', 'data', 'drivers'], assocDrivers)
      const deploymentTypeCode = pathOr(
        [],
        ['data', 'data', 'subhauler_info', 'deployment_type_code'],
        assocDrivers,
      )
      catDrivers = [
        ...catDrivers,
        ...drivers.map((driver) => ({
          ...driver,
          catId,
          deploymentTypeCode,
          catName,
        })),
      ]
    })
  }
  return [
    mergeCATDrivers(catDrivers, allDrivers),
    formatDeploymentEventOptions(subhaulers),
  ]
}

const FetchData = (selectedView, selectedSubView, selectedFilters) => {
  const [data, setData] = useState([])
  const [, setDataFetchLoading] = useAtom(dataFetchLoadingAtom)
  const [, setTableRecords] = useAtom(tableRecordsAtom)
  const [globalLoading, setGlobalLoading] = useAtom(globalPostAtom)
  const [page] = useAtom(pageAtom)
  const [rowsPerPage] = useAtom(rowsPerPageAtom)
  const [search] = useAtom(searchTermAtom)
  const [, setCATAssociationList] = useAtom(catAssocListAtom)
  const [sortBy] = useAtom(sortByAtom)
  const [, setSkipFilter] = useAtom(skipFilterAtom)
  const [refreshData, setRefreshData] = useAtom(refreshDataAtom)
  const [mapView] = useAtom(mapViewAtom)

  const fetchData = async () => {
    const {
      vendor: { vendor_id: vendorId },
    } = getItem('userData')
    const url = getEndpoints({ vendorId })[selectedView]

    try {
      if (selectedView === 'catTowProvidersView') {
        await fetchCatTowData(selectedFilters, url, search, sortBy)
      } else {
        await fetchOtherViewData(
          selectedView,
          selectedFilters,
          url,
          search,
          sortBy,
          page,
          rowsPerPage,
        )
      }
      setRefreshData(false)
    } catch (error) {
      console.error(`Failed to retrieve ${selectedView} details`, error)
      setDataFetchLoading(false)
      setGlobalLoading(false)
    }
  }

  const fetchCatTowData = async (selectedFilters, url, search, sortBy) => {
    const [result, catAssociationList] = await fetchCatTowProvidersData(
      selectedFilters,
      url,
      search,
      sortBy,
    )
    setData(result)
    setDataFetchLoading(false)
    setTableRecords(result.length)
    setGlobalLoading(false)
    setCATAssociationList(catAssociationList)
  }

  const fetchOtherViewData = async (
    selectedView,
    selectedFilters,
    url,
    search,
    sortBy,
    page,
    rowsPerPage,
  ) => {
    let response

    if (['lotsView', 'tripsView', 'driversView'].includes(selectedView)) {
      setDataFetchLoading(true)
      response = await axios.post(url, {
        filter_by: selectedFilters[selectedView],
        page: page + 1,
        page_size: rowsPerPage,
        search,
        sort_by: sortBy,
      })
    } else {
      response = await axios.get(url)
    }

    if (response.data.status === 'success') {
      handleSuccessResponse(selectedView, response)
    }
  }

  const handleSuccessResponse = (selectedView, response) => {
    const extractor =
      responseExtractors[selectedView] || responseExtractors.default
    const responseData = extractor(response.data)
    setData(responseData)
    setDataFetchLoading(false)
    let totalLength = ['tripsView', 'lotsView', 'driversView'].includes(
      selectedView,
    )
      ? response?.data.total_count
      : responseData?.length

    if (selectedView === 'workRequestsView') {
      totalLength = responseData?.filter(
        (row) => row.message_status === 'A',
      ).length
    }

    setTableRecords(totalLength)
    setGlobalLoading(false)
    setSkipFilter(false)
  }

  useEffect(() => {
    if (globalLoading) {
      setTimeout(() => {
        fetchData()
      }, 400)
    } else {
      fetchData()
    }
  }, [
    selectedView,
    selectedSubView,
    selectedFilters,
    globalLoading,
    rowsPerPage,
    page,
    search,
    sortBy,
    mapView,
  ])

  useEffect(() => {
    if (refreshData) {
      fetchData()
    }
  }, [refreshData])

  return { data }
}

export const getBusinessDays = async (yard) => {
  try {
    const response = await axios.get(getEndpoints().workingDays, {
      headers: {
        'X-Yard': yard,
      },
    })
    return response
  } catch (error) {
    console.error(error)
  }
}

export const getStates = async (country) => {
  try {
    const endpoint = getEndpoints({ country }).states
    const response = await axios.get(endpoint).then(({ data }) => data)

    return response
  } catch (error) {
    console.error(error)
  }
}

export const mapViewFetcher = async (
  selectedView,
  selectedSubView,
  selectedFilters,
  search,
) => {
  const { vendor_id: vendorId } = getItem('userData')
  const endpoints = getEndpoints({ vendorId })
  const url = endpoints['lotsView']
  let response

  try {
    response = await axios.post(url, {
      filter_by: selectedFilters[selectedView],
      page: 1,
      page_size: 5000,
      search,
    })

    if (response.data.status === 'success') {
      const totalLength = response?.data.total_count
      const dataForMapView = pathOr([], ['data', 'data'], response)
      return { data: dataForMapView, totalCount: totalLength }
    }
  } catch (error) {
    console.error(`Failed to retrieve ${selectedView} details`, error)
  }
}

export default FetchData
